/**
 * Utility function to fix file names with Turkish characters
 * This handles common encoding issues with Turkish characters in file names
 * 
 * @param {string} fileName - The file name to fix
 * @param {boolean} shortenName - Whether to shorten the name for display (default: false)
 * @returns {string} The fixed file name
 */
export const fixFileName = (fileName, shortenName = false) => {
  try {
    // Try to decode the filename in case it's URL-encoded
    let decodedName = decodeURIComponent(fileName);
    
    // Additional fixes for common Turkish character encoding issues
    decodedName = decodedName
      .replace(/iÅ/g, 'iş')
      .replace(/Ä±/g, 'ı')
      .replace(/Ä/g, 'İ')
      .replace(/Ã¶/g, 'ö')
      .replace(/Ã¼/g, 'ü')
      .replace(/ÅŸ/g, 'ş')
      .replace(/Ã§/g, 'ç')
      .replace(/ÄŸ/g, 'ğ')
      .replace(/Ã‡/g, 'Ç')
      .replace(/Ãœ/g, 'Ü')
      .replace(/Ã–/g, 'Ö')
      .replace(/Äž/g, 'Ğ')
      .replace(/ÅŸ/g, 'Ş')
      .replace(/Ä°/g, 'İ')
      .replace(/birliÄ/g, 'birliğ')
      .replace(/yÃ¶netime/g, 'yönetime')
      .replace(/katÄ±lma/g, 'katılma')
      .replace(/TÃKETÄ°CÄ°/g, 'TÜKETİCİ')
      .replace(/ÃRGÃTLENMESÄ°/g, 'ÖRGÜTLENMESİ');
    
    // If shortenName is true, shorten the name for display
    if (shortenName) {
      const fileExtension = decodedName.split('.').pop();
      const baseName = decodedName.substring(0, decodedName.lastIndexOf('.'));
      const shortName = baseName.length > 10 ? `${baseName.slice(0, 10)}...` : baseName;
      return `${shortName}.${fileExtension}`;
    }
    
    return decodedName;
  } catch (error) {
    console.error('Error decoding file name:', fileName);
    return fileName; // Fallback to original name
  }
};

/**
 * Utility function to check if a file name contains Turkish characters
 * 
 * @param {string} fileName - The file name to check
 * @returns {boolean} Whether the file name contains Turkish characters
 */
export const hasTurkishCharacters = (fileName) => {
  const turkishChars = /[ğüşıöçĞÜŞİÖÇ]/;
  return turkishChars.test(fileName);
};

/**
 * Utility function to encode a file name for safe storage
 * This is useful when sending file names to the backend
 * 
 * @param {string} fileName - The file name to encode
 * @returns {string} The encoded file name
 */
export const encodeFileName = (fileName) => {
  try {
    return encodeURIComponent(fileName);
  } catch (error) {
    console.error('Error encoding file name:', fileName);
    return fileName; // Fallback to original name
  }
}; 