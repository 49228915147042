import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Wand2,
  ChevronLeft,
  ChevronRight,
  X,
  CircleDollarSign,
  GraduationCap,
  PlusCircle,
  Trash2,
  Files,
  FileIcon,
  AlertTriangle
} from 'lucide-react';
import { useDebouncedValue } from '@mantine/hooks';
import {
  useSuggestTopicMutation,
  useSuggestTitlesMutation,
  useForgeArticleMutation,
  usePromptChainMutation,
} from '../../redux/api/articleForgerApiSlice';
import Lottie from 'lottie-react';
import loadingAnimationData from '../../components/assets/loading.json';
import { useNotifications } from '../../context/NotificationContext';
import ArticleGenerationLoading from '../../components/common/ArticleGenerationLoading';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import TokenCounter from "../../utils/tokenCounter";
import { Tooltip } from '@mantine/core';
import { fixFileName } from '../../utils/fileUtils';

// Add CSS for text selection
const selectableTextStyles = `
  .user-select-text {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
    cursor: text !important;
  }
  
  .article-content h1,
  .article-content h2,
  .article-content h3,
  .article-content p,
  .article-content li,
  .article-content span {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
    cursor: text !important;
  }
  
  .reference-tag {
    font-weight: 700 !important;
    display: inline-block;
    padding: 2px 8px;
    margin: 0 4px;
    background-color: #40286c1f;
    color: #40286c;
    border-radius: 4px;
    cursor: pointer !important;
    transition: background-color 0.2s;
    font-size: 1.125rem;
  }
  
  .reference-tag:hover {
    background-color: #DBEAFE;
  }
  
  /* Override any Mantine styles that might interfere with tooltips */
  .mantine-Tooltip-root {
    z-index: 1000;
  }
  
  .references-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }
`;

/** ---------------------------------------------
 *  CONFIG: Default topics, language options, etc
 *  --------------------------------------------- */
const defaultTopics = {
  tr: [
    "Shakespeare'in Hamlet'indeki intikam temasını analizi",
    "COVID-19 Kilitlenmesinin Ebeveynlerin Ruh Sağlığı Üzerindeki Etkisi",
    "Şirketlerin Tazminat Stratejilerini ve Uygulamalarını Etkileyen Faktörler",
    "Araştırma Katılımı için Bilgilendirilmiş Onay Alırken Etik Hususlar",
  ],
  en: [
    "Analysis the theme of revenge in Shakespeare's Hamlet",
    "The Impact of COVID-19 Lockdown on Parental Mental Health",
    "Factors Affecting Corporate Compensation Strategies and Practices",
    "Ethical Considerations in Obtaining Informed Consent for Research Participation",
  ],
};

const languageOptions = [
  { value: 'tr', label: 'Türkçe' },
  { value: 'en', label: 'English' }
];

const MAX_FILES = 20;
const FILE_WARNING_THRESHOLD = 3; // Show warning when more than 3 files
const FILE_SIZE_WARNING_MB = 10; // Show warning when total size exceeds 10MB

// Regular expression to match file references in the format {{ [n]: from filename.pdf }}
const FILE_REFERENCE_REGEX = /\{\{\s*\[(\d+)\]:\s*from\s+(.*?)(?:\s+\}\}|\}\})/g;

/** ----------------------------------------------------
 *               MAIN COMPONENT
 *  ---------------------------------------------------- */
const ArticleForgerWizard = ({ onComplete, onCancel, tokenBalance }) => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const user = useSelector((state) => state.auth.user);

  // Steps
  const steps = [1, 2, 3, 4, 5]; // for the UI stepper
  const [active, setActive] = useState(0);

  // Local form data to gather user wizard inputs
  const [formData, setFormData] = useState({
    topic: '',
    language: 'tr',
    wordCount: '750',
    course: '',
    // Outline is stored in suggestedTitles state below
    files: [],
    // These reflect the chosen "assistant" & "promptChain" in the logic
    selectedAssistant: 'uzman', // normal default
    promptChain: false,
  });

  // "normal" or "academic"
  const [selectedMode, setSelectedMode] = useState('normal');

  // Additional local states
  const [topic, setTopic] = useState('');
  const [debouncedTopic] = useDebouncedValue(topic, 2000);
  const [suggestion, setSuggestion] = useState('');
  const [suggestedTitles, setSuggestedTitles] = useState({ sections: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // tracks final step submission
  const [hasSubmitted, setHasSubmitted] = useState(false); // prevents double-submits

  // For partial results or final result
  const [result, setResult] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [brokenFiles, setBrokenFiles] = useState([]);

  // For repeated calls (prompt chain)
  const [queue, setQueue] = useState(0);
  const [threadId, setThreadId] = useState(null);
  const [listLength, setListLength] = useState(null);

  // Steps 1 & 2: topic suggestions
  const [suggestTopic] = useSuggestTopicMutation();
  const [suggestTitlesApi, { isLoading: isTitlesLoading }] = useSuggestTitlesMutation();

  // Article forging / prompt chain
  const [forgeArticle] = useForgeArticleMutation();
  const [promptChain] = usePromptChainMutation();

  // For token usage display
  const [tokenCount, setTokenCount] = useState(0);

  // Step validation
  const [showValidationError, setShowValidationError] = useState(false);

  // Add new state to track if titles have been fetched
  const [hasFetchedTitles, setHasFetchedTitles] = useState(false);

  // Ensure we go back to the academic step when there are broken files
  useEffect(() => {
    if (brokenFiles.length > 0) {
      setActive(4); // Go back to academic step
    }
  }, [brokenFiles]);

  /** ---------------------------------------------
   *  useEffect to fetch topic suggestions
   *  --------------------------------------------- */
  useEffect(() => {
    const fetchSuggestion = async () => {
      const wordCount = debouncedTopic.trim().split(/\s+/).length;
      if (debouncedTopic.length > 5 && wordCount < 7) {
        try {
          const result = await suggestTopic({
            topic: debouncedTopic,
            language: formData.language
          });
          if (result.data?.result) {
            setSuggestion(result.data.result);
          }
        } catch (error) {
          console.error('Error fetching topic suggestion:', error);
        }
      } else {
        setSuggestion('');
      }
    };
    fetchSuggestion();
  }, [debouncedTopic, formData.language, suggestTopic]);


  /** ---------------------------------------------
   *  Title Generation
   *  --------------------------------------------- */
  const fetchSuggestedTitles = async () => {
    if (!formData.topic || !formData.course || !formData.wordCount || !formData.language) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('validation.outlineCreator.required')
      });
      return;
    }
    try {
      const res = await suggestTitlesApi({
        topic: formData.topic,
        course: formData.course,
        language: formData.language,
        length: formData.wordCount
      }).unwrap();

      const lines = res.result.split('\n').filter(line => line.trim() !== '');
      const transformedSections = transformApiData(lines);

      setSuggestedTitles({ sections: transformedSections });
    } catch (error) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('errors.SUGGEST_TITLE_ERROR')
      });
    }
  };

  // Helper to transform bullet lines to { id, title, subsections[] }
  const transformApiData = (lines) => {
    const sections = [];
    let currentSection = null;

    lines.forEach(line => {
      const trimmedLine = line.trim();
      if (!trimmedLine) return;

      // Main section: e.g. "1- " or "2- "
      if (/^\d+-/.test(trimmedLine)) {
        const title = trimmedLine.split('-')[1].trim();
        currentSection = {
          id: sections.length + 1,
          title,
          subsections: []
        };
        sections.push(currentSection);
      }
      // Subsection: e.g. "1.1-" or "2.1-"
      else if (/^\s*\d+\.\d+-/.test(trimmedLine) && currentSection) {
        const title = trimmedLine.split('-')[1].trim();
        const subsectionId = `${currentSection.id}.${currentSection.subsections.length + 1}`;
        currentSection.subsections.push({ id: subsectionId, title });
      }
    });

    return sections;
  };

  // Add / remove sections & subsections
  const addNewSection = () => {
    setSuggestedTitles(prev => {
      const newId = prev.sections.length + 1;
      return {
        ...prev,
        sections: [
          ...prev.sections,
          {
            id: newId,
            title: t('articleForgerWizard.newSection', { number: newId }),
            subsections: []
          }
        ]
      };
    });
  };
  const addNewSubsection = (sectionId) => {
    setSuggestedTitles(prev => {
      const sectionIndex = prev.sections.findIndex(s => s.id === sectionId);
      if (sectionIndex < 0) return prev;

      const newSections = [...prev.sections];
      const subList = newSections[sectionIndex].subsections || [];
      const newSubId = `${sectionId}.${subList.length + 1}`;
      subList.push({
        id: newSubId,
        title: t('articleForgerWizard.newSubsection', { number: newSubId })
      });
      newSections[sectionIndex].subsections = subList;
      return { ...prev, sections: newSections };
    });
  };

  const handleDeleteClick = (sectionId, subsectionId = null) => {
    if (subsectionId) {
      // remove a subsection
      setSuggestedTitles(prev => {
        const newSections = [...prev.sections].map(sec => {
          if (sec.id === sectionId) {
            const filteredSubs = sec.subsections.filter(sub => sub.id !== subsectionId);
            // Renumber the remaining subsections
            const renumberedSubs = filteredSubs.map((sub, index) => ({
              ...sub,
              id: `${sectionId}.${index + 1}`
            }));
            return { ...sec, subsections: renumberedSubs };
          }
          return sec;
        });
        return { ...prev, sections: newSections };
      });
    } else {
      // Check if there is only one main section left
      if (suggestedTitles.sections.length <= 1) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('articleForgerWizard.steps.academic.minTitlesError')
        });
        return;
      }

      // remove entire section and renumber remaining sections
      setSuggestedTitles(prev => {
        const filteredSections = prev.sections.filter(s => s.id !== sectionId);
        
        // Renumber the remaining sections and their subsections
        const renumberedSections = filteredSections.map((section, index) => {
          const newSectionId = index + 1;
          
          // Update subsection IDs to match new section ID
          const updatedSubsections = section.subsections.map((sub, subIndex) => ({
            ...sub,
            id: `${newSectionId}.${subIndex + 1}`
          }));
          
          return {
            ...section,
            id: newSectionId,
            subsections: updatedSubsections
          };
        });
        
        return { ...prev, sections: renumberedSections };
      });
    }
  };
  

  const updateTitle = (sectionId, subsectionId, newVal) => {
    // If user hits backspace on empty => delete
    if (!newVal.trim()) {
      handleDeleteClick(sectionId, subsectionId);
      return;
    }

    setSuggestedTitles(prev => {
      const newSections = [...prev.sections];
      const secIndex = newSections.findIndex(s => s.id === sectionId);
      if (secIndex < 0) return prev;

      if (subsectionId) {
        const subIndex = newSections[secIndex].subsections.findIndex(sub => sub.id === subsectionId);
        if (subIndex >= 0) {
          newSections[secIndex].subsections[subIndex].title = newVal;
        }
      } else {
        newSections[secIndex].title = newVal;
      }
      return { ...prev, sections: newSections };
    });
  };

  /** ---------------------------------------------
   *  File Handling
   *  --------------------------------------------- */
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const totalFiles = formData.files.length + newFiles.length;
    
    // Check max files limit
    if (totalFiles > MAX_FILES) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('articleForgerWizard.steps.academic.maxFilesError', { max: MAX_FILES })
      });
      return;
    }

    // Check for duplicate filenames
    const existingFileNames = formData.files.map(file => fixFileName(file.name));
    const duplicates = newFiles.filter(file => existingFileNames.includes(fixFileName(file.name)));
    
    if (duplicates.length > 0) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('articleForgerWizard.steps.academic.duplicateFileError', {
          files: duplicates.map(f => `"${fixFileName(f.name)}"`).join(', ')
        })
      });
      return;
    }

    setFormData(prev => ({
      ...prev,
      files: [...prev.files, ...newFiles]
    }));
  };
  const removeFile = (fileToRemove) => {
    setFormData(prev => ({
      ...prev,
      files: prev.files.filter(file => file !== fileToRemove)
    }));
  };

  /** ---------------------------------------------
   *  Step triggers
   *  --------------------------------------------- */
  useEffect(() => {
    // Step 4 is the "Outline" step => auto fetch suggestions only first time
    if (active === 3 && !hasFetchedTitles) {
      fetchSuggestedTitles();
      setHasFetchedTitles(true);
    }
  }, [active]);

  /** ---------------------------------------------
   *  Submitting: Mode-based logic
   *  (1) Normal => single forgeArticle
   *  (2) Academic => repeated calls using promptChain
   *  --------------------------------------------- */
  const handleNext = async () => {
    // Check for minimum titles when on the titles step (step 4)
    if (active === 3 && suggestedTitles.sections.length <= 1) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('articleForgerWizard.steps.academic.minTitlesError')
      });
      return;
    }

    // Don't proceed if there are broken files
    if (brokenFiles.length > 0) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('errors.fileProcessing.broken')
      });
      setActive(4); // Ensure we're on the academic step
      return;
    }

    // Validate step
    if (!isStepValid(active)) {
      setShowValidationError(true);
      return;
    }
    setShowValidationError(false);

    // If we are not on the final step => next step
    if (active < steps.length - 1) {
      setActive(cur => cur + 1);
      return;
    }

    // If we are on the final step => show loading state immediately
    setActive(steps.length);
    setIsLoading(true);
    
    // Then call the logic to generate
    await handleGenerate();
  };

  // The final generate button logic
  const handleGenerate = async () => {
    if (hasSubmitted) return; // block repeated clicks
    setIsSubmitting(true);
    setHasSubmitted(true);
    setIsLoading(true);

    try {
      // Transform outline into the same shape that ArticleForger expects
      const fullOutline = [];
      suggestedTitles.sections.forEach((sec, idx) => {
        // push the main section
        fullOutline.push({
          id: `input-${idx + 1}`,
          inputLabel: sec.id.toString(),
          parentId: null,
          undeletable: false,
          done: false,
          value: sec.title,
        });
        // push subsections
        (sec.subsections || []).forEach((sub, subIdx) => {
          fullOutline.push({
            id: `input-${idx + 1}.${subIdx + 1}`,
            inputLabel: sub.id,
            parentId: `input-${idx + 1}`,
            undeletable: false,
            done: false,
            value: sub.title,
          });
        });
      });

      // Common formData for forging or chain
      const fd = new FormData();
      fd.append('topic', formData.topic);
      fd.append('length', formData.wordCount);
      fd.append('course', formData.course);
      fd.append('selectedLanguage', formData.language);
      fd.append('outline', JSON.stringify(fullOutline));

      // Distinguish normal vs. academic
      if (selectedMode === 'normal') {
        // Normal => 'uzman', single call
        fd.append('selectedAssistant', 'uzman');
        fd.append('isPromptChain', 'false');

        formData.files.forEach(file => {
          fd.append('files', file);
        });

        const response = await forgeArticle(fd).unwrap();
        setResult(response.result);
        // Return final text to parent if needed
        onComplete?.(response.result);

      } else {
        // Academic => 'profesor', repeated calls
        fd.append('selectedAssistant', 'profesor');
        fd.append('isPromptChain', 'true');
        // We'll do the same approach as in ArticleForger
        // i.e. start at queue=0, threadId=null
        setResult('');
        setQueue(0);
        setThreadId(null);
        setListLength(suggestedTitles.sections.length);

        // Attach files ONLY on first queue
        formData.files.forEach(file => {
          fd.append('files', file);
        });
        // Kick off chain
        await startPromptChainWizard(fd, 0, false);
      }

      setActive(steps.length); // go to final step
      setBrokenFiles([]);
    } catch (error) {
      console.error(error);
      setHasSubmitted(false);
      
      // Return to academic step if there's a file error
      if (error.data?.error === "FileUploadError") {
        // Force active step back to 4 (academic step)
        setActive(4);
        let failedFiles = [];
        
        // Handle the specific error format provided
        if (error.data.message && typeof error.data.message === 'string') {
          const match = error.data.message.match(/The following files failed to embed: (.+)/);
          if (match) {
            failedFiles = match[1].split(', ').map(file => file.trim());
          } else {
            failedFiles = [error.data.message];
          }
        } else if (Array.isArray(error.data.failedFiles)) {
          failedFiles = error.data.failedFiles;
        }
        
        setBrokenFiles(failedFiles.map(name => fixFileName(name)));
        
        const errorMessage = failedFiles.length === 1
          ? t('errors.fileProcessing.single', { fileName: fixFileName(failedFiles[0]) })
          : t('errors.fileProcessing.multiple', { 
              fileNames: failedFiles.map(name => `"${fixFileName(name)}"`).join(', ')
            });

      
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: errorMessage,
          title: t('fileError', { message: t('errors.fileProcessing.broken') }),
          duration: 10000 // Show for longer time
        });
        
        // Remove the broken files from the formData
        setFormData(prev => ({
          ...prev,
          files: prev.files.filter(file => !failedFiles.some(brokenFile => 
            fixFileName(file.name) === brokenFile || file.name === brokenFile
          ))
        }));
        
        // Ensure we're on the academic step
        setTimeout(() => {
          setActive(4);
        }, 0);
      } else {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: t('errors.generation'),
        });
      }
    } finally {
     
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  /**
   * The same queue-based approach as in ArticleForger
   * If queue < listLength, we call promptChain again.
   */
  const startPromptChainWizard = async (fd, queueValue = 0, threadValue = false) => {
    try {
      // Set up queue/thread in FormData
      fd.set('queue', queueValue);
      if (threadValue) fd.set('threadId', threadValue.toString());
      // Make the call
      const resultData = await promptChain(fd).unwrap();

      // Append partial result
      setResult(prev => prev + (resultData.result || ''));
      // For the next iteration
      setQueue(resultData.queue);
      setThreadId(resultData.threadId);
      setListLength(resultData.listLength);

      // If we haven't reached the end, continue
      if (resultData.queue < resultData.listLength) {
        await startPromptChainWizard(fd, resultData.queue, resultData.threadId);
      } else {
        // We are done; pass final result up
        onComplete?.(result + resultData.result);
      }
      setBrokenFiles([]);
    } catch (error) {
      if (error.data?.error === "FileUploadError") {
        // Force active step back to 4 (academic step)
        setActive(4);
        let failedFiles = [];
        
        // Handle the specific error format provided
        if (error.data.message && typeof error.data.message === 'string') {
          const match = error.data.message.match(/The following files failed to embed: (.+)/);
          if (match) {
            failedFiles = match[1].split(', ').map(file => file.trim());
          } else {
            failedFiles = [error.data.message];
          }
        } else if (Array.isArray(error.data.failedFiles)) {
          failedFiles = error.data.failedFiles;
        }
        
        setBrokenFiles(failedFiles.map(name => fixFileName(name)));
        
        const errorMessage = failedFiles.length === 1
          ? t('errors.fileProcessing.single', { fileName: fixFileName(failedFiles[0]) })
          : t('errors.fileProcessing.multiple', { 
              fileNames: failedFiles.map(name => `"${fixFileName(name)}"`).join(', ')
            });
        
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: errorMessage,
          title: t('fileError', { message: t('errors.fileProcessing.broken') }),
          duration: 10000 // Show for longer time
        });
        
        // Remove the broken files from the formData
        setFormData(prev => ({
          ...prev,
          files: prev.files.filter(file => !failedFiles.some(brokenFile => 
            fixFileName(file.name) === brokenFile || file.name === brokenFile
          ))
        }));
        
        // Reset loading and submitting states
        setIsLoading(false);
        setIsSubmitting(false);
        setHasSubmitted(false); // Allow resubmission
        
        // Ensure we're on the academic step
        setTimeout(() => {
          setActive(4);
        }, 0);
      } else {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: t('errors.generation'),
        });
      }
      // allow repeated tries
      setHasSubmitted(false);
    }
  };

  /** ---------------------------------------------
   *  Word/Char count watchers for final text
   *  --------------------------------------------- */
  useEffect(() => {
    if (result) {
      setWordCount(result.trim().split(/\s+/).length);
      setCharCount(result.length);
    }
  }, [result]);

  /** ---------------------------------------------
   *  Tokens Calculation: same formula as ArticleForger
   *  --------------------------------------------- */
  useEffect(() => {
    // we replicate the same logic from your ArticleForger
    function calculateFinalSum(y, x) {
      let r = 0;
      for (let n = 1; n <= y; n++) {
        r += n * 700;
      }
      return r + 2 * x;
    }

    const normalTokenCount = parseInt(formData.wordCount || '0', 10);
    if (!normalTokenCount) {
      setTokenCount(0);
      return;
    }

    let tokenFormula = 0;
    // promptChain = academic mode
    if (!formData.promptChain) {
      // normal
      // selectedAssistant=== 'uzman' => (5 * normalTokenCount)/1000
      // selectedAssistant=== 'profesor' => (5 * normalTokenCount)/40
      if (formData.selectedAssistant === 'uzman') {
        tokenFormula = (5 * normalTokenCount) / 1000;
      } else {
        tokenFormula = (5 * normalTokenCount) / 40;
      }
    } else {
      // chain
      if (formData.selectedAssistant === 'uzman') {
        tokenFormula = (5 * normalTokenCount) / 1000;
      } else {
        // 'profesor' => sum formula
        const totalSections = suggestedTitles?.sections?.length || 0;
        tokenFormula = calculateFinalSum(totalSections, normalTokenCount) / 40;
      }
      if (formData.files.length > 0) {
        // extra cost for doc attachments
        tokenFormula += (17000 / 40);
      }
    }

    setTokenCount(Math.max(Math.ceil(tokenFormula / 2), 1));
  }, [
    formData.wordCount,
    formData.promptChain,
    formData.selectedAssistant,
    formData.files,
    suggestedTitles?.sections,
  ]);

  /** ---------------------------------------------
   *  Step validation checks
   *  --------------------------------------------- */
  const isStepValid = (stepIndex) => {
    switch(stepIndex) {
      case 0:
        return formData.topic.trim().length > 0;
      case 1:
        return parseInt(formData.wordCount, 10) >= 100;
      case 2:
        return formData.course.trim().length > 0;
      case 3:
        return suggestedTitles.sections.length > 0;
      case 4:
        // academic step has no *required* fields
        return true;
      default:
        return true;
    }
  };

  // Process article text to transform file references
  const processArticleText = (text) => {
    if (!text) return { processedText: '', references: {}, allReferences: [] };
    
    // Extract all file references and create a mapping
    const references = {};
    let match;
    let allReferences = [];
    
    // Reset regex lastIndex to ensure we start from the beginning
    FILE_REFERENCE_REGEX.lastIndex = 0;
    
    while ((match = FILE_REFERENCE_REGEX.exec(text)) !== null) {
      const [, index, fileInfo] = match;
      if (!references[index]) {
        // Apply double decoding for Turkish characters
        // First decode any URL encoding, then fix specific Turkish character issues
        references[index] = { fileInfo: fixFileName(fileInfo) };
        allReferences.push(parseInt(index) + 1);
      }
    }
    
    // Remove all file references from the text
    let processedText = text.replace(FILE_REFERENCE_REGEX, '');
    
    // We'll handle references separately, so we don't add them to the text
    
    return { 
      processedText: processedText.trim(), 
      references,
      allReferences: allReferences.sort((a, b) => a - b)
    };
  };

  /** ---------------------------------------------
   *  RENDER: Steps
   *  --------------------------------------------- */
  const renderTopicStep = () => (
    <div className="w-full px-4">
      <h2 className="text-2xl font-bold text-[#40286C] mb-2">
        {t('articleForgerWizard.steps.topic.label')}
      </h2>
      <p className="text-gray-600 mb-4">
        {t('articleForgerWizard.steps.topic.description')}
      </p>
      <div className="relative">
        <textarea
          value={topic}
          onChange={(e) => {
            setTopic(e.target.value);
            setFormData({ ...formData, topic: e.target.value });
          }}
          placeholder={t('articleForgerWizard.steps.topic.placeholder')}
          className="w-full min-h-[240px] text-lg p-8 border border-gray-300 rounded-xl
                   focus:ring-2 focus:ring-black focus:border-black outline-none
                   transition-all duration-200 resize-none shadow-sm"
        />
        {/* Suggestion hint */}
        {suggestion && (
          <div className="absolute bottom-4 left-4 right-4 bg-gray-50 rounded-md p-3">
            <button
              onClick={() => {
                setTopic(suggestion);
                setFormData(prev => ({ ...prev, topic: suggestion }));
                setSuggestion('');
              }}
              className="text-sm text-gray-800 hover:text-black transition-colors duration-200 text-left w-full flex items-center"
            >
              <svg
                className="w-4 h-4 mr-2 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                />
              </svg>
              <span className="font-medium">{t('articleForgerWizard.suggestedTopic')}</span>{' '}
              {suggestion}
            </button>
          </div>
        )}
      </div>
      {/* Default Topics */}
      <div className="mb-6">
        <div className="flex flex-col gap-2">
          <span className="text-lg font-medium text-gray-700">
            {t('articleForgerWizard.suggestedTopics')}
          </span>
          <div className="grid grid-cols-2 gap-2">
            {defaultTopics[formData.language].map((sug, idx) => (
              <button
                key={idx}
                onClick={() => {
                  setTopic(sug);
                  setFormData(prev => ({ ...prev, topic: sug }));
                }}
                className="px-3 py-2.5 bg-white hover:bg-gray-50/80 rounded-2xl
                         text-xs text-gray-600 transition-all duration-200
                         border border-gray-200 text-left h-full
                         hover:border-gray-300 hover:shadow-sm
                         hover:scale-[1.02] active:scale-[0.98]"
                title={sug}
              >
                {sug}
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* Language Selection */}
      <div className="mt-8 mb-16">
        <h3 className="text-sm font-medium text-gray-700 mb-3">
          {t('articleForgerWizard.language.title')}
        </h3>
        <div className="flex gap-2">
          {languageOptions.map(opt => (
            <button
              key={opt.value}
              onClick={() => setFormData(prev => ({ ...prev, language: opt.value }))}
              className={`px-4 py-2 rounded-full text-sm transition-colors duration-200
                ${formData.language === opt.value
                  ? 'bg-[#40286C] text-white hover:bg-[#40286C]/90'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
            >
              {opt.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const renderWordCountStep = () => (
    <div className="max-w-3xl mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4 text-[#40286C]">{t('articleForgerWizard.wordCount.title')}</h2>
      <p className="text-gray-600 mb-8">{t('articleForgerWizard.wordCount.description')}</p>

      {/* Word count input */}
      <div className="mb-8">
        <div className="relative rounded-lg border border-gray-300">
          <input
            type="number"
            value={formData.wordCount}
            onChange={(e) => {
              const value = e.target.value === '' ? '' : parseInt(e.target.value, 10);
              setFormData(prev => ({
                ...prev,
                wordCount: value === '' ? '' : Math.max(0, Math.min(20000, value))
              }));
            }}
            onBlur={(e) => {
              const value = e.target.value === '' ? 100 : parseInt(e.target.value, 10);
              setFormData(prev => ({
                ...prev,
                wordCount: Math.max(100, Math.min(20000, value))
              }));
            }}
            className="w-full h-[80px] px-6 py-4 
                       border border-gray-300 rounded-xl
                       focus:outline-none focus:ring-2 focus:ring-[#40286C] focus:border-[#40286C]
                       transition-all duration-200
                       text-center text-2xl sm:text-4xl font-bold text-[#40286C] bg-transparent
                       [appearance:textfield] 
                       [&::-webkit-outer-spin-button]:appearance-none 
                       [&::-webkit-inner-spin-button]:appearance-none"
            min="100"
            max="20000"
          />
        </div>
        <p className="text-center text-gray-500 mt-2">
          {t('articleForgerWizard.pageLength', { pages: Math.ceil(parseInt(formData.wordCount || 100, 10) / 250) })}
        </p>
      </div>

      {/* Preset Options */}
      <div className="flex flex-wrap gap-3 justify-center">
        {[3000, 5000, 7500, 10000, 15000].map((count) => (
          <button
            key={count}
            onClick={() => setFormData(prev => ({ ...prev, wordCount: count.toString() }))}
            className={`px-6 py-3 rounded-md border transition-colors
              ${parseInt(formData.wordCount, 10) === count 
                ? 'border-[#40286C] bg-[#40286C] text-white' 
                : 'border-gray-300 hover:border-gray-400'
              }`}
          >
            {count} {t('articleForgerWizard.wordCount.word')}
          </button>
        ))}
      </div>
    </div>
  );

  const renderCourseStep = () => {
    const courseSuggestions = [
      { code: 'MAT101', name: 'Matematik I' },
      { code: 'EC101', name: 'Ekonomiye Giriş' },
      { code: 'PSY101', name: 'Psikolojiye Giriş' },
      { code: 'SOC101', name: 'Sosyolojiye Giriş' },
      { code: 'BIO101', name: 'Biyoloji I' },
      { code: 'HIST101', name: 'Tarih I' },
      { code: 'PHYS101', name: 'Fizik I' },
      { code: 'CHEM101', name: 'Kimya I' },
    ];
    return (
      <div className="max-w-3xl mx-auto">
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-[#40286C] mb-2">
            {t('articleForgerWizard.course.title')}
          </h2>
          <p className="text-gray-600 mb-6">
            {t('articleForgerWizard.course.description')}
          </p>
          {/* Large Course Input */}
          <div className="space-y-2">
            <input
              type="text"
              value={formData.course}
              onChange={(e) => setFormData({ ...formData, course: e.target.value })}
              className="w-full h-[80px] px-6 py-4 
                         border border-gray-300 rounded-xl
                         focus:outline-none focus:ring-2 focus:ring-[#40286C] focus:border-[#40286C]
                         transition-all duration-200
                         text-center text-2xl sm:text-4xl font-bold text-[#40286C] bg-transparent"
              placeholder="Ders kodu veya adı girin..."
            />
          </div>
          {/* Course Suggestions */}
          <div className="mt-6 flex flex-wrap gap-1.5">
            {courseSuggestions.map((course) => (
              <button
                key={course.code}
                onClick={() => setFormData({ ...formData, course: `${course.code} - ${course.name}` })}
                className="px-3 py-1.5 text-xs bg-gray-50 
                           hover:bg-gray-100 rounded-full transition-colors duration-200
                           border border-gray-200 flex items-center gap-1.5"
              >
                <span className="font-medium">{course.code}</span>
                <span className="text-gray-400">·</span>
                <span className="text-gray-600">{course.name}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderTitleStep = () => (
    <div className="w-full px-2 sm:px-4">
      <div className="bg-white rounded-lg shadow-sm">
        {/* Header */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between border-b border-gray-200 p-3 sm:p-4 gap-3">
          <div className="flex flex-col">
            <h2 className="text-lg font-medium text-[#40286C]">
              {t('articleForgerWizard.steps.titles.header')}
            </h2>
            <p className="text-gray-600">
              {t('articleForgerWizard.steps.titles.subheader')}
            </p>
          </div>
          <div className="flex items-center gap-2 flex-wrap">
            {/* Add Suggest New Titles button */}
            <button
              onClick={() => {
                setSuggestedTitles({ sections: [] }); // Clear existing titles
                fetchSuggestedTitles(); // Fetch new ones
              }}
              className="flex-1 sm:flex-none inline-flex items-center justify-center px-3 py-1.5 rounded-md 
                         bg-[#40286C] text-white hover:bg-[#40286C]/90 transition-colors 
                         duration-200 text-sm font-medium min-w-[120px]"
              disabled={isTitlesLoading}
            >
              {isTitlesLoading ? (
                <span className="animate-spin mr-2">⏳</span>
              ) : (
                <Wand2 className="w-4 h-4 mr-2" />
              )}
              {t('articleForgerWizard.suggestNewTitles')}
            </button>
            <button
              onClick={addNewSection}
              className="flex-1 sm:flex-none inline-flex items-center justify-center px-3 py-1.5 rounded-md 
                         bg-[#40286C] text-white hover:bg-[#40286C]/90 transition-colors 
                         duration-200 text-sm font-medium min-w-[120px]"
            >
              <PlusCircle className="w-4 h-4 mr-2" />
              {t('articleForgerWizard.addMainSection')}
            </button>
            <button
              onClick={() => {
                const lastSec = suggestedTitles.sections[suggestedTitles.sections.length - 1];
                if (lastSec) addNewSubsection(lastSec.id);
              }}
              className="flex-1 sm:flex-none inline-flex items-center justify-center px-3 py-1.5 rounded-md 
                         border border-[#40286C] text-[#40286C] hover:bg-[#40286C]/10 
                         transition-colors duration-200 text-sm font-medium min-w-[120px]"
              disabled={!suggestedTitles.sections.length}
            >
              <ChevronRight className="w-4 h-4 mr-2" />
              {t('articleForgerWizard.addSubsection')}
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="p-2 sm:p-4">
          {suggestedTitles.sections.length === 0 ? (
            <div className="text-center py-8">
              {isTitlesLoading ? (
                <div className="flex flex-col items-center gap-3">
                  <Lottie
                    animationData={loadingAnimationData}
                    style={{ width: 120, height: 120 }}
                    loop={true}
                  />
                  <span className="text-gray-600 font-medium">
                    {t('articleForgerWizard.generatingTitles')}
                  </span>
                </div>
              ) : (
                <div className="text-gray-500">
                  {t('articleForgerWizard.noTitles')}
                </div>
              )}
            </div>
          ) : (
            <div className="space-y-1">
              {suggestedTitles.sections.map((section) => (
                <div key={section.id} className="space-y-1">
                  {/* Main Section */}
                  <div className="relative flex items-center gap-2 py-2 hover:bg-gray-50 rounded-lg px-2">
                    <span className="text-md font-bold text-gray-500 min-w-[30px] sm:min-w-[40px]">
                      {section.id}.
                    </span>
                    <input
                      type="text"
                      value={section.title}
                      onChange={(e) => updateTitle(section.id, null, e.target.value)}
                      className="flex-1 bg-transparent border-0 focus:ring-0 p-0 text-[#40286C] 
                                 font-bold text-base sm:text-lg break-words"
                      placeholder={t('articleForgerWizard.sectionTitle')}
                    />
                    <div className="flex items-center gap-1 shrink-0">
                      <button
                        onClick={() => addNewSubsection(section.id)}
                        className="p-1 rounded-md hover:bg-gray-200 text-gray-600 transition-colors"
                      >
                        <ChevronRight className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(section.id)}
                        className="p-1 rounded-md hover:bg-red-100 text-red-600 transition-colors"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  {/* Subsections */}
                  {section.subsections?.map((sub) => (
                    <div
                      key={sub.id}
                      className="relative flex items-center gap-2 py-2 hover:bg-gray-50 
                                 rounded-lg px-2 ml-4 sm:ml-6"
                    >
                      <span className="text-md font-semibold text-gray-500 min-w-[40px] sm:min-w-[50px]">
                        {sub.id}
                      </span>
                      <input
                        type="text"
                        value={sub.title}
                        onChange={(e) => updateTitle(section.id, sub.id, e.target.value)}
                        className="flex-1 bg-transparent border-0 focus:ring-0 p-0 text-[#40286C] 
                                   font-semibold text-sm sm:text-base break-words"
                        placeholder={t('articleForgerWizard.subsectionTitle')}
                      />
                      <button
                        onClick={() => handleDeleteClick(section.id, sub.id)}
                        className="p-1 rounded-md hover:bg-red-100 text-red-600 transition-colors shrink-0"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderAcademicStep = () => {
    // Calculate total file size
    const totalFileSizeMB = formData.files.reduce((total, file) => total + file.size / (1024 * 1024), 0);
    const showFileWarning = formData.files.length > FILE_WARNING_THRESHOLD || totalFileSizeMB > FILE_SIZE_WARNING_MB;
    
    return (
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-[#40286C] mb-2">
            {t('articleForgerWizard.steps.academic.header')}
          </h2>
          <p className="text-gray-600">
            {t('articleForgerWizard.steps.academic.subheader')}
          </p>
        </div>
        {/* Mode Selection */}
        <div className="space-y-4 mb-8">
          {/* Normal Mode */}
          <button
            onClick={() => {
              setSelectedMode('normal');
              setFormData(prev => ({ ...prev, selectedAssistant: 'uzman', promptChain: false }));
            }}
            className={`w-full py-4 px-6 rounded-md border-2 transition-all duration-200
              flex items-center justify-between
              ${selectedMode === 'normal'
                ? 'border-[#40286C] bg-[#40286C] text-white'
                : 'border-gray-200 hover:border-gray-300'
              }`}
          >
            <div className="flex items-center gap-4">
              <CircleDollarSign
                size={24}
                className={selectedMode === 'normal' ? 'text-white' : 'text-gray-500'}
              />
              <div className="flex flex-col items-start">
                <span className="text-lg font-semibold">
                  {t('articleForgerWizard.steps.academic.normalMode')}
                </span>
                <span className={`text-sm ${selectedMode === 'normal' ? 'text-gray-300' : 'text-gray-500'}`}>
                  {t('articleForgerWizard.steps.academic.normalModeDescription')}
                </span>
              </div>
            </div>
            <div className={`w-6 h-6 rounded-full border-2 transition-all duration-200
              flex items-center justify-center
              ${selectedMode === 'normal' ? 'border-white' : 'border-gray-400'}`}>
              <div className={`w-3 h-3 rounded-full transition-all duration-200
                ${selectedMode === 'normal' ? 'bg-white' : 'bg-gray-400'}`} />
            </div>
          </button>

          {/* Academic Mode */}
          <button
            onClick={() => {
              setSelectedMode('academic');
              setFormData(prev => ({ ...prev, selectedAssistant: 'profesor', promptChain: true }));
            }}
            className={`w-full py-4 px-6 rounded-md border-2 transition-all duration-200
              flex items-center justify-between
              ${selectedMode === 'academic'
                ? 'border-[#40286C] bg-[#40286C] text-white'
                : 'border-gray-200 hover:border-gray-300'
              }`}
          >
            <div className="flex items-center gap-4">
              <GraduationCap
                size={24}
                className={selectedMode === 'academic' ? 'text-white' : 'text-gray-500'}
              />
              <div className="flex flex-col items-start">
                <span className="text-lg font-semibold">
                  {t('articleForgerWizard.steps.academic.academicMode')}
                </span>
                <span className={`text-sm ${selectedMode === 'academic' ? 'text-gray-300' : 'text-gray-500'}`}>
                  {t('articleForgerWizard.steps.academic.academicModeDescription')}
                </span>
              </div>
            </div>
            <div className={`w-6 h-6 rounded-full border-2 transition-all duration-200
              flex items-center justify-center
              ${selectedMode === 'academic' ? 'border-white' : 'border-gray-400'}`}>
              <div className={`w-3 h-3 rounded-full transition-all duration-200
                ${selectedMode === 'academic' ? 'bg-white' : 'bg-gray-400'}`} />
            </div>
          </button>
        </div>

        {/* File Upload Section */}
        <div className="space-y-4">
          {formData.files.length > 0 && (
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4 shadow-sm border border-gray-200">
              <div className="flex items-center gap-2 mb-3">
                <Files className="h-4 w-4 text-gray-600" />
                <h3 className="font-medium text-gray-700">
                  {t('articleForgerWizard.steps.academic.attachedFiles')}
                </h3>
              </div>
              
              {/* File Warning Message */}
              {showFileWarning && (
                <div className="mb-3 p-3 bg-amber-50 border border-amber-200 rounded-md">
                  <div className="flex items-start gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-amber-500 mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                    <div>
                      <p className="text-sm font-medium text-amber-800">
                        {t('articleForgerWizard.steps.academic.fileWarningTitle')}
                      </p>
                      <p className="text-xs text-amber-700 mt-1">
                        {t('articleForgerWizard.steps.academic.fileWarningMessage')}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              
              {/* File Upload Error Alert */}
              {brokenFiles.length > 0 && (
                <div className="mb-3 p-3 bg-red-50 border border-red-200 rounded-md">
                  <div className="flex items-start gap-2">
                    <AlertTriangle className="h-5 w-5 text-red-500 mt-0.5 flex-shrink-0" />
                    <div>
                      <p className="text-sm font-medium text-red-800">
                        {t('fileError', { message: t('errors.fileProcessing.broken') })}
                      </p>
                      <p className="text-xs text-red-700 mt-1">
                        {brokenFiles.length === 1
                          ? t('errors.fileProcessing.single', { fileName: brokenFiles[0] })
                          : t('errors.fileProcessing.multiple', { 
                              fileNames: brokenFiles.map(name => `"${name}"`).join(', ')
                            })}
                      </p>
                      <div className="mt-2">
                        <button
                          onClick={() => setActive(active - 1)}
                          className="text-xs font-medium text-red-700 hover:text-red-800 bg-red-100 hover:bg-red-200 px-3 py-1 rounded-md transition-colors"
                        >
                          {t('articleForgerWizard.buttons.back')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="space-y-2">
                {formData.files.map((file, index) => {
                  const isBroken = brokenFiles.includes(file.name);
                  const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
                  
                  return (
                    <div
                      key={index}
                      className={`group relative flex items-center justify-between p-2.5 rounded-lg
                        ${isBroken 
                          ? 'bg-red-50 border border-red-100' 
                          : 'bg-gray-50 border border-gray-100 hover:border-gray-200'
                        } transition-all duration-200`}
                    >
                      <div className="flex items-center gap-2 min-w-0 flex-1">
                        <FileIcon 
                          className={`h-4 w-4 flex-shrink-0 ${isBroken ? 'text-red-500' : 'text-gray-400'}`}
                        />
                        <div className="min-w-0 flex-1">
                          <Tooltip 
                            label={`${fixFileName(file.name)} (${fileSizeMB} MB)`}
                            position="top"
                            disabled={file.name.length <= 20}
                          >
                            <span className={`block truncate ${isBroken ? 'text-red-600' : 'text-gray-700'}`}>
                              {fixFileName(file.name)} <span className="text-xs text-gray-500">({fileSizeMB} MB)</span>
                            </span>
                          </Tooltip>

                          {isBroken && (
                            <span className="text-xs text-red-500 block mt-0.5">
                              {t('errors.fileProcessing.broken')}
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        onClick={() => removeFile(file)}
                        className={`ml-2 p-1.5 rounded-full cursor-pointer
                          ${isBroken 
                            ? 'hover:bg-red-200 text-red-500' 
                            : 'hover:bg-gray-200 text-gray-500'
                          } transition-all duration-200`}
                        aria-label="Remove file"
                      >
                        <X className="h-3.5 w-3.5" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* File Upload Button */}
          <label
            className={`block ${formData.files.length >= MAX_FILES ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              disabled={formData.files.length >= MAX_FILES}
              className="hidden"
              accept=".pdf,.doc,.docx,.txt"
            />
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-gray-400 transition-colors">
              <div className="flex flex-col items-center justify-center space-y-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <div className="text-sm text-gray-600">
                  {t('articleForgerWizard.steps.academic.dropzoneText')}
                </div>
                <div className="text-xs text-gray-500">
                  {t('articleForgerWizard.steps.academic.dropzoneSubtext', {
                    current: formData.files.length,
                    max: MAX_FILES
                  })}
                </div>
              </div>
            </div>
          </label>
        </div>
        
        {/* Token Counter */}
        <div className="flex justify-center mt-6">
          <TokenCounter
            tokenCount={tokenCount}
            tokenBalance={tokenBalance}
            isError={false}
          />
        </div>
      
      </div>
    );
  };

  // Final step "view" after generation completes
  const renderFinalStep = () => {
    // Process the article text to extract and format references
    const processedResult = processArticleText(result || '');
    const { processedText, references, allReferences = [] } = processedResult || { processedText: '', references: {}, allReferences: [] };
    
    // Create inline reference elements for the text
    const inlineReferenceElements = Array.isArray(allReferences) ? allReferences.map(refNumber => {
      const refIndex = refNumber - 1;
      const reference = references[refIndex];
      
      if (reference) {
        return (
          <Tooltip
            key={`ref-${refNumber}`}
            label={
              <div className="p-1">
                <div className="font-semibold">{t('articleForgerWizard.reference') || 'Reference'}:</div>
                <div>{reference.fileInfo}</div>
              </div>
            }
            position="top"
            withArrow
            multiline
            width={300}
            openDelay={100}
            closeDelay={200}
            events={{ hover: true, focus: true, touch: true }}
          >
            <div className="inline-block">
              <span className="reference-tag user-select-text" onClick={(e) => e.stopPropagation()}>
                [{refNumber}]
              </span>
            </div>
          </Tooltip>
        );
      } else {
        return (
          <span key={`ref-${refNumber}`} className="reference-tag user-select-text">
            [{refNumber}]
          </span>
        );
      }
    }) : [];
    
    return (
      <div className="max-w-6xl mx-auto px-4">
        {/* Add style tag for text selection */}
        <style>{selectableTextStyles}</style>
        
        {/* Copy & Word Count */}
        <div className="flex items-center justify-between mb-4 p-2 border-b">
          <button
            onClick={handleCopyArticle}
            className="flex items-center gap-2 px-4 py-2 bg-[#40286C] text-white rounded-md
                       hover:bg-[#40286C]/90 transition-colors duration-200"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
            </svg>
            {t('articleForgerWizard.copyArticle')}
          </button>
          <span className="text-sm text-gray-500">
            {wordCount} {t('articleForgerWizard.word')} / {charCount} {t('articleForgerWizard.character')}
          </span>
        </div>

        {/* Article Markdown */}
        <div className="relative mb-8">
          <div className="h-[600px] overflow-auto">
            <div className="prose prose-lg max-w-none article-content">
              <ReactMarkdown
                components={{
                  h1: ({children}) => (
                    <h1 className="text-3xl font-bold mb-6 user-select-text">
                      {children}
                    </h1>
                  ),
                  h2: ({children}) => (
                    <h2 className="text-2xl font-semibold mt-8 mb-4 user-select-text">
                      {children}
                    </h2>
                  ),
                  p: ({children}) => (
                    <p className="mb-4 leading-relaxed user-select-text">
                      {children}
                    </p>
                  ),
                  strong: ({children}) => (
                    <strong className="font-semibold user-select-text">
                      {children}
                    </strong>
                  ),
                  em: ({children}) => (
                    <em className="italic user-select-text">
                      {children}
                    </em>
                  ),
                  li: ({children}) => (
                    <li className="user-select-text">
                      {children}
                    </li>
                  ),
                  section: ({children}) => (
                    <section className="user-select-text">
                      {children}
                    </section>
                  ),
                  text: ({children}) => {
                    return <span className="user-select-text">{children}</span>;
                  }
                }}
              >
                {processedText}
              </ReactMarkdown>
              
              {/* Add inline references after the content */}
              <div className="inline-references mt-4">
                {inlineReferenceElements}
              </div>
            </div>
          </div>
          {/* If we're still loading more chain calls */}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90">
              <ArticleGenerationLoading
                showProgress={formData.promptChain}
                current={queue}
                total={listLength || 0}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  // Helper: copy final text
  const handleCopyArticle = () => {
    if (!result) return;
    // Use the processed text for copying to maintain the simplified references
    const { processedText } = processArticleText(result);
    navigator.clipboard.writeText(processedText).then(() => {
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: t('articleForgerWizard.copySuccess')
      });
    }).catch(() => {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('articleForgerWizard.copyError')
      });
    });
  };

  const forgerFormula = (normalTokenCount, isPromptChain, filesCount) => {
    let tokenFormula = 0;

    if (!isPromptChain) {
      tokenFormula = (5 * normalTokenCount) / 1000;
    } else {
      tokenFormula = (5 * normalTokenCount) / 40;
    }

    if (filesCount > 0) tokenFormula = tokenFormula + (17000 / 40);
    
    // Round up the result and ensure at least 1 token is charged
    return Math.max(Math.ceil(tokenFormula), 1);
  };

  /** ---------------------------------------------
   *  UI: Renders
   *  --------------------------------------------- */
  return (
    <div className="min-h-screen bg-white">
      {/* Top Header */}
      <div className="border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 text-center relative">
          <h2 className="text-3xl font-bold text-[#40286C]">
            {t('articleForgerWizard.title')}
          </h2>
          <p className="mt-2 text-gray-600">
            {t('articleForgerWizard.description')}
          </p>
        </div>
      </div>

      {/* Stepper */}
      <div className="border-b border-gray-200 bg-white">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-center">
            {steps.map((step, idx) => (
              <div key={step} className="flex items-center">
                <div
                  className={`
                    flex items-center justify-center
                    sm:w-9 sm:h-9 w-7 h-7
                    rounded-full border-2 transition-all
                    ${
                      idx <= active
                        ? 'border-[#40286C] bg-[#40286C] text-white'
                        : 'border-[#40286C] bg-white text-[#40286C]'
                    }
                    text-sm font-medium
                  `}
                >
                  {step}
                </div>
                {idx < steps.length - 1 && (
                  <div
                    className={`
                      sm:w-16 w-8 h-0.5 transition-all
                      ${idx < active ? 'bg-[#40286C]' : 'bg-gray-200'}
                    `}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Step Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {active === steps.length && !brokenFiles.length
          ? renderFinalStep()
          : (
            <>
              {/* If there are broken files, force show the academic step */}
              {brokenFiles.length > 0 
                ? renderAcademicStep()
                : (
                  <>
                    {active === 0 && renderTopicStep()}
                    {active === 1 && renderWordCountStep()}
                    {active === 2 && renderCourseStep()}
                    {active === 3 && renderTitleStep()}
                    {active === 4 && renderAcademicStep()}
                  </>
                )
              }
            </>
          )
        }

        {/* Navigation Buttons */}
        {(active !== steps.length || brokenFiles.length > 0) && (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center mt-2">
              {active === 0 ? (
                // Return to parent
                <button
                  onClick={onCancel}
                  className="flex items-center gap-2 px-6 py-2 text-gray-600 hover:text-gray-900 
                             transition-colors rounded-md"
                >
                  <ChevronLeft size={20} />
                  {t('returnToModeSelection')}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setShowValidationError(false);
                    setActive(cur => cur - 1);
                  }}
                  className="flex items-center gap-2 px-6 py-2 text-gray-600 hover:text-gray-900 
                             transition-colors disabled:opacity-50 disabled:cursor-not-allowed rounded-md"
                  disabled={isSubmitting || isTitlesLoading || isLoading}
                >
                  <ChevronLeft size={20} />
                  {t('common.back')}
                </button>
              )}

              <button
                onClick={handleNext}
                className={`px-4 py-2 rounded-md bg-[#40286C] text-white hover:bg-[#5a3d99] transition-colors flex items-center gap-1
                  ${brokenFiles.length > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={brokenFiles.length > 0}
              >
                {t('articleForgerWizard.buttons.next')}
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
            {/* Validation error message */}
            {showValidationError && !isStepValid(active) && (
              <p className="text-sm text-red-500 text-center">
                {active === 0 && t('articleForgerWizard.validation.topicRequired')}
                {active === 1 && t('articleForgerWizard.validation.wordCountRequired')}
                {active === 2 && t('articleForgerWizard.validation.courseRequired')}
                {active === 3 && t('articleForgerWizard.validation.titlesRequired')}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleForgerWizard;
