import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const transactionApiSlice = createApi({
  reducerPath: 'transactionApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Transactions', 'Billing'], // Tag both transaction and billing queries
  endpoints: (builder) => ({
    fetchTransactionHistory: builder.query({
      query: ({ skip = 0, limit = 10 } = {}) => `/payments/transaction-history?skip=${skip}&limit=${limit}`,
      providesTags: ['Transactions'],
    }),
    fetchBillingHistory: builder.query({
      query: ({ skip = 0, limit = 10 } = {}) => `/payments/billing-history?skip=${skip}&limit=${limit}`,
      providesTags: ['Billing'],
    }),
    
    fetchUserSubscription: builder.query({
      query: () => '/payments/getUserSubscription',
      providesTags: ['Subscription'],
    }),
    // New: Cancel user's subscription
    cancelSubscription: builder.mutation({
      query: () => ({
        url: '/payments/cancelSubscription',
        method: 'DELETE',
      }),
      invalidatesTags: ['Subscription'],
    }),
    // Add more endpoints as needed
  }),
});

export const {
  useFetchTransactionHistoryQuery,
  useFetchBillingHistoryQuery, // Export the billing history query
  useFetchUserSubscriptionQuery, // Export the fetch subscription query
  useCancelSubscriptionMutation, // Export the cancel subscription mutation
} = transactionApiSlice;
