import { useState, useEffect } from "react";
import {
  Modal,
  Group,
  Text,
  Card,
  Badge,
  Image,
  SimpleGrid,
  Grid,
  Button,
  ScrollArea,
  Box,
} from '@mantine/core';
import { useForm } from "@mantine/form";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import TokenCounter from "../../utils/tokenCounter";
import Loading from "../../components/common/Loading";
import InputOutput from "../../components/common/InputOutput";
import ErrorDisplay from "../../components/common/Notification";
import { useNotifications } from '../../context/NotificationContext'; 
import { sanitizeInput } from "../../utils/sanitizeInput";
import { languageOptions } from "../../utils/constants";
import { useHumanizerTextMutation, usePollHumanizerResultMutation } from '../../redux/api/humanizerApiSlice';

import { useDispatch, useSelector } from 'react-redux';
import { appendToHistory } from '../../redux/slices/toolHistoriesSlice';
import { useFetchHistoryDetailsQuery } from '../../redux/api/toolHistoriesApiSlice';
import { setTokens } from '../../redux/slices/authSlice';
import { faCircleCheck, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import StyledButton from "../../components/common/StyledButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { encode } from 'gpt-tokenizer'; // Ensure your tokenizer is ready
import * as Sentry from "@sentry/react";
import { CircleGauge, BookOpenText, Scale, Send   } from "lucide-react";
import Selector from "../../components/common/Selector"; 

import { useMediaQuery } from '@mantine/hooks';
import SingleStepTutorial from "../../components/common/Tutorial";

const AIDetectionModal = ({ results, opened, onClose }) => {
  const { t } = useTranslation();

  const detectors = [
    { key: 'scoreGptZero', name: 'GPTZero', logo: '/logos/gptzero.png' },
    { key: 'scoreCopyLeaks', name: 'Copyleaks', logo: '/logos/copyleaks.png' },
    { key: 'scoreZeroGPT', name: 'ZeroGPT', logo: '/logos/zerogpt.png' },
    { key: 'scoreCrossPlag', name: 'Crossplag', logo: '/logos/crossplag.png' },
    { key: 'scoreSapling', name: 'Sapling', logo: '/logos/sapling.png' },
    { key: 'scoreWriter', name: 'Writer', logo: '/logos/writer.svg' },
    { key: 'scoreContentAtScale', name: 'Originality', logo: '/logos/originalityAi.png' },
  ];

  const getResultData = (value) => {
    if (value > 50) return { class: 'human', text: t('aiDetector.humanWritten'), color: 'green', icon: faCircleCheck };
    if (value === 50) return { class: 'neutral', text: t('aiDetector.neutral'), color: 'yellow', icon: faExclamationTriangle };
    return { class: 'ai', text: t('aiDetector.aiCreated'), color: 'red', icon: faTimesCircle };
  };

  const getOverallResult = () => {
    if (!results) return '';
    const humanCount = Object.values(results).filter(value => value > 50).length;
    const aiCount = Object.values(results).filter(value => value < 50).length;
    if (humanCount > aiCount) return { text: t('aiDetector.overallHumanWritten'), color: 'green', icon: faCircleCheck };
    if (aiCount > humanCount) return { text: t('aiDetector.overallAICreated'), color: 'red', icon: faTimesCircle };
    return { text: t('aiDetector.inconclusive'), color: '#ff5722', icon: faExclamationTriangle };
  };

  const overallResult = getOverallResult();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="xl"
      centered
/*      fullScreen={window.innerWidth < 768} */
      className="ai-detection-modal"
    >
      <ScrollArea>
        <div className="modal-header">
          <Text size="xl" weight={700}>
            {t('aiDetector.results')}
          </Text>
        </div>

        <div className="modal-content">
          <Card 
            className={`overall-result-card ${overallResult.color === 'green' ? 'human-written' : ''}`}
            withBorder
          >
            <Group position="left" spacing="xs">
              <FontAwesomeIcon 
                icon={overallResult.icon} 
                color={overallResult.color} 
                size="lg" 
              />
              <Text className="result-title">
                {overallResult.text}
              </Text>
            </Group>
            <Text className="result-subtitle">
              {t('aiDetector.basedOnTools')}
            </Text>
          </Card>

          <div className="detectors-grid">
            {detectors.map(({ key, name, logo }) => {
              const resultData = getResultData(results[key]);
              return (
                <div key={key} className="detector-card">
                  <div className="card-header">
                    <Image 
                      src={logo} 
                      alt={name} 
                      width={30} 
                      height={30}
                    />
                    <Text className="detector-name">
                      {name}
                    </Text>
                  </div>

                  <div className="card-content">
                    <div className={`score-badge ${resultData.color}`}>
                      {results[key]}%
                    </div>
                    <Text className="result-text">
                      {resultData.text}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </ScrollArea>
    </Modal>
  );
};

function HumanizerTool() {
  const currentTool = useSelector(state => state.toolHistories.currentTool);
  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);
  const { addNotification } = useNotifications();
  const [humanizeText, { isLoading, error: humanizerError }] = useHumanizerTextMutation();
  const [pollHumanizerResult] = usePollHumanizerResultMutation();
  const [showAIDetectionResults, setAiDetectionResults] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const aiDetectionResults = {
    "scoreGptZero": 100,
    "scoreOpenAI": 100,
    "scoreWriter": 100,
    "scoreCrossPlag": 100,
    "scoreCopyLeaks": 100,
    "scoreSapling": 100,
    "scoreContentAtScale": 100,
    "scoreZeroGPT": 100,
    "human": 100,
  }

  const dispatch = useDispatch();
  const selectedHistoryId = useSelector(state => state.toolHistories.selectedHistoryId);
  const { data: historyDetails } = useFetchHistoryDetailsQuery({
    serviceType: 'humanizer',
    id: selectedHistoryId,
  }, {
    skip: !selectedHistoryId || currentTool !== 'humanizer',
  });
  
  const { t } = useTranslation();
  const [humanizerModelId, setHumanizerModelId] = useState(null);
  const [usageDataId, setUsageDataId] = useState(null);
  
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [readability, setReadability] = useState('High School');
  const [purpose, setPurpose] = useState('General Writing');
  const [strength, setStrength] = useState('Balanced');
  const [documentId, setDocumentId] = useState(null);
  const [polling, setPolling] = useState(false);
  const [isPollingLoading, setIsPollingLoading] = useState(false); // Track polling loading
  const [tokenCount, setTokenCount] = useState(0); // Track the calculated token count for humanizer
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)

  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  
  const form = useForm({
    initialValues: {
      selectedLanguage: languageOptions[0].value,
      readability: 'High School',
      purpose: 'General Writing',
      strength: 'Balanced',
    },
    validate: {
      selectedLanguage: (value) =>
        value ? null : t("validation.selectedLanguage.required"),

      readability: (value) =>
        ['High School', 'University', 'Doctorate', 'Journalist', 'Marketing'].includes(value)
          ? null
          : t('validation.readability.required'),
      purpose: (value) =>
        ['General Writing', 'Essay', 'Article', 'Marketing Material', 'Story', 'Cover Letter', 'Report', 'Business Material', 'Legal Material'].includes(value)
          ? null
          : t('validation.purpose.required'),
      strength: (value) =>
        ['Balanced', 'Quality', 'More Human'].includes(value)
          ? null
          : t('validation.strength.required'),
    },
  });
  
  const tutorialSeen =  localStorage.getItem(`tutorial_humanizer`);
  useEffect(() => {
    if (!tutorialSeen) {
      setShowTutorial(true);
    }
    if (historyDetails) {
      if (!selectedHistoryId) {
        form.reset();
        setOutputText('');
        setInputText('');
        setReadability('High School');
        setPurpose('General Writing');
        setStrength('Balanced');
        return;
      }
      setOutputText(historyDetails.result);
      setInputText(historyDetails.userInput);
      setReadability(historyDetails.readability || 'High School');
      setPurpose(historyDetails.purpose || 'General Writing');
      setStrength(historyDetails.strength || 'Balanced');
    }
  }, [selectedHistoryId, historyDetails]);

  const handleSubmit = async (values) => {
    setAiDetectionResults(false);
  
    // Check if the input length is within the valid range
    if (inputText.length < 50 || inputText.length > 1000000) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: inputText.length < 50 ? t('validation.inputText.tooShort') : t('validation.inputText.tooLong'),
      });
      return;
    }
  
    // Check if the user has enough tokens for the action
    if (tokenCount > tokenBalance) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('validation.tokenBalance.insufficient'),
      });
      setNotEnoughTokens(true);
      setTimeout(() => {
        setNotEnoughTokens(false);
      }
      , 3000)
      return; // Do not proceed if tokens are insufficient
    }
  
    try {
      const sanitizedInputText = DOMPurify.sanitize(inputText);
      const sanitizedValues = sanitizeInput(values);
  
      const humanizeData = {
        userInput: sanitizedInputText,
        ...sanitizedValues,
        readability,
        purpose,
        strength,
      };
  
      // Send the request to humanize the text only if tokens are sufficient
      const result = await humanizeText(humanizeData).unwrap();
  
      setDocumentId(result.id);
      setHumanizerModelId(result.humanizerId);
      setUsageDataId(result.usageDataId);
      setPolling(true);
  
    } catch (err) {
      Sentry.captureException(err); // Capture submission error
      console.error(err);
      
      // Check for specific error status codes
      if (err.status === 402 && err.data?.error === 'Insufficient tokens') {
        // Handle insufficient tokens error specifically
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.tokenBalance.insufficient'),
        });
        setNotEnoughTokens(true);
        setTimeout(() => {
          setNotEnoughTokens(false);
        }, 3000);
      } else {
        // Handle other errors
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('polling.error'),
        });
      }
    }
  };
  
  useEffect(() => {
    let pollInterval;
  
    if (polling && documentId && humanizerModelId && usageDataId) {
      setIsPollingLoading(true);
      pollInterval = setInterval(async () => {
        try {
          const response = await pollHumanizerResult({ documentId, humanizerModelId, usageDataId }).unwrap();
  
          if (response.output) {
            setOutputText(response.output);
            setPolling(false);
            setIsPollingLoading(false);
  
            dispatch(appendToHistory({
              tool: 'humanizer',
              entry: response.updatedUsageData 
            }));
          
            setAiDetectionResults(true);
            setShowModal(true); // Open modal when results are ready
            dispatch(setTokens({ tokenBalance: response.tokenBalance }));
          }
        } catch (error) {
          Sentry.captureException(error); // Capture polling error
          console.error("Error polling for result:", error);
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: t('polling.error'),
          });
          setPolling(false);
          setIsPollingLoading(false);
        }
      }, 8000); 
    }
  
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [polling, documentId, humanizerModelId, usageDataId, pollHumanizerResult, addNotification, t, dispatch]);

  const humanaizerFormula = (normalTokenCount) => {
    return Math.max(Math.ceil(2 * normalTokenCount), 1); 
  };

  const calculateTokens = () => {
    if (!inputText || inputText.trim() === "") {
      setTokenCount(0);
      return;
    }
    const normalTokenCount = encode(inputText).length;
    setTokenCount(humanaizerFormula(normalTokenCount) / 2);
  };
  
  useEffect(() => {
    calculateTokens();
 
  }, [inputText]);
  const isMobile = useMediaQuery('(max-width: 768px)');
  
    const tutorialSteps = [
      {
        title: t('tutorial.humanizer.0title'),
        content: t('tutorial.humanizer.0content'),
        position: 'center'
      },
      {
        elementId: ['readability-selector', 'purpose-selector', 'strength-selector'],
        title: t('tutorial.humanizer.selectorsTitle'),
        content: t('tutorial.humanizer.selectorsContent'),
        position: 'bottom'
      },
      {
        elementId: [ 'tokenCounter'],
        title: t('tutorial.humanizer.actionsTitle'),
        content: t('tutorial.humanizer.actionsContent'),
        position: 'center'
      }
    ];


  const resetChat = () => {
    setInputText('');
    setOutputText('');
    setReadability('High School');
    setPurpose('General Writing');
    setStrength('Balanced');
    form.reset();
    setAiDetectionResults(false);
    setShowModal(false);
    setPolling(false);
    setIsPollingLoading(false);
    setDocumentId(null);
    setHumanizerModelId(null);
    setUsageDataId(null);
    setTokenCount(0);
  };



  useEffect(() => {
    // Add the global event listener
    const handleNewChat = () => {
      resetChat();
    };
    window.addEventListener('newChat', handleNewChat);
    
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('newChat', handleNewChat);
    };
  }, []); // empty dependency array to register once

  const handleTutorialStepChange = (step) => {
    setCurrentTutorialStep(step);
  };

  return (
    <form
      onSubmit={form.onSubmit(
        (values) => handleSubmit(values),
        (errors) => console.log(errors)
      )}
      className="h-[calc(100vh-64px)] flex flex-col"
    >
      <div className="flex flex-col h-full gap-4">
        <div className="flex-none border-b border-gray-200 pb-4">
          {(isLoading || isPollingLoading) && <Loading type="humanizer" />}
          <div className="flex flex-wrap gap-4">
            <div className="flex flex-wrap w-full gap-4 md:flex-row">
              <Selector
                icon={<BookOpenText size={12}/>}
                label={t("humanizer.readabilityTitle")}
                value={readability}
                onChange={setReadability}
                data={[
                  { value: 'High School', label: t('humanizer.readability.highSchool') },
                  { value: 'University', label: t('humanizer.readability.university') },
                  { value: 'Doctorate', label: t('humanizer.readability.doctorate') },
                  { value: 'Journalist', label: t('humanizer.readability.journalist') },
                  { value: 'Marketing', label: t('humanizer.readability.marketing') },
                ]}
                radius="md"
                required
                description={t("humanizer.readabilityDescription")}
                id="readability-selector"
                className="flex-1 min-w-[calc(50%-8px)] md:min-w-0"
              />
              
              <Selector
                icon={<Scale size={12}/>}
                label={t("humanizer.purposeTitle")}
                value={purpose}
                onChange={setPurpose}
                data={[
                  { value: 'General Writing', label: t('humanizer.purpose.general') },
                  { value: 'Essay', label: t('humanizer.purpose.essay') },
                  { value: 'Article', label: t('humanizer.purpose.article') },
                  { value: 'Marketing Material', label: t('humanizer.purpose.marketing') },
                  { value: 'Story', label: t('humanizer.purpose.story') },
                  { value: 'Cover Letter', label: t('humanizer.purpose.coverLetter') },
                  { value: 'Report', label: t('humanizer.purpose.report') },
                  { value: 'Business Material', label: t('humanizer.purpose.business') },
                  { value: 'Legal Material', label: t('humanizer.purpose.legal') },
                ]}
                radius="md"
                required
                description={t("humanizer.purposeDescription")}
                id="purpose-selector"
                className="flex-1 min-w-[calc(50%-8px)] md:min-w-0"
              />
              
              <Selector
                icon={<CircleGauge size={12}/>}
                label={t("humanizer.strengthTitle")}
                value={strength}
                onChange={setStrength}
                data={[
                  { value: 'Balanced', label: t('humanizer.strength.balanced') },
                  { value: 'Quality', label: t('humanizer.strength.quality') },
                  { value: 'More Human', label: t('humanizer.strength.moreHuman') },
                ]}
                radius="md"
                required
                description={t("humanizer.strengthDescription")}
                id="strength-selector"
                className="flex-1 min-w-full md:min-w-0"
              />
            </div>
          </div>
        </div>

        <div className="flex-1 min-h-0">
          <InputOutput
            inputText={inputText}
            setInputText={setInputText}
            outputText={outputText}
            loading={isLoading || isPollingLoading}
            getInputProps={form.getInputProps}
            fieldName="inputText"
            className="h-full"
            t={t}
          />
        </div>

        <div className="flex-none border-t border-gray-200 pt-4">
          <div className="grid grid-cols-12 items-center gap-4">
            <div className={`${isMobile ? 'col-span-8' : 'col-span-10'}`}>
              <div className="flex gap-4">
                <StyledButton
                  icon={<Send />}
                  variant="primary"
                  type="submit"
                  disabled={isLoading || polling || !inputText}
                  id="submitHumanizer"
                  className="w-full"
                >
                  {t("humanizer.humanize")} & {t("aiDetector.detect")}
                </StyledButton>
              </div>
            </div>
            <div className={`${isMobile ? 'col-span-4' : 'col-span-2'}`}>
              <TokenCounter 
                isError={notEnoughTokens}
                tokenCount={tokenCount}
                tokenBalance={tokenBalance}
              />
            </div>
          </div>
        </div>
      </div>

      <AIDetectionModal 
        results={aiDetectionResults}
        opened={showModal}
        onClose={() => setShowModal(false)}
      />
      
      <SingleStepTutorial
        isVisible={showTutorial}
        onClose={() => {
          setShowTutorial(false);
          localStorage.setItem('tutorial_humanizer', 'true');
        }}
        toolType="humanizer"
        t={t}
        steps={tutorialSteps}
        currentStep={currentTutorialStep}
        onStepChange={handleTutorialStepChange}
      />
      
      <ErrorDisplay error={humanizerError} />
    </form>
  );
}

export default HumanizerTool;
