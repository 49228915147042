// src/pages/auth/SecureAccount.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { Button, PasswordInput, Box, Alert, Text, Title, Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../context/NotificationContext';
import { useSecureAccountMutation } from '../../redux/api/authApiSlice';
import DOMPurify from 'dompurify';

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const SecureAccount = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [secureAccount, { isLoading }] = useSecureAccountMutation();

  const form = useForm({
    initialValues: {
      newPassword: '',
      newPasswordConfirm: '',
    },
    validate: {
      newPassword: (value) => 
        passwordRegex.test(value) 
          ? null 
          : t('settings.form.validation.newPassword'),
      newPasswordConfirm: (value, values) => 
        value !== values.newPassword 
          ? t('settings.form.validation.newPasswordConfirm') 
          : null,
    },
  });

  const handleSubmit = async (values) => {
    const sanitizedNewPassword = DOMPurify.sanitize(values.newPassword);
    const sanitizedNewPasswordConfirm = DOMPurify.sanitize(values.newPasswordConfirm);

    if (sanitizedNewPassword !== sanitizedNewPasswordConfirm) {
      addNotification({
        type: 'error',
        message: t('settings.form.validation.passwordMismatch'),
      });
      return;
    }

    try {
      await secureAccount({ token, newPassword: sanitizedNewPassword, newPasswordConfirm: sanitizedNewPasswordConfirm }).unwrap();
      addNotification({
        type: 'success',
        message: t('settings.form.notifications.passwordUpdateSuccessSecure'),
      });
      navigate('/auth/login');
    } catch (err) {
      addNotification({
        type: 'error',
        message: err.data?.message || t('settings.form.notifications.passwordUpdateFailSecure'),
      });
    }
  };

  return (
    <Box className="np__account">
      <Title order={4}>{t('settings.form.secureAccount')}</Title>
      <Text size="sm" mb="md">
        {t('settings.form.passwordDescription')}
      </Text>
      <Alert color="yellow" mb="md">
        {t('settings.form.warnings.secureAccount')}
      </Alert>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <PasswordInput
              label={t('settings.form.newPassword')}
              placeholder={t('settings.form.enterNewPassword')}
              {...form.getInputProps('newPassword')}
              required
              mb="md"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label={t('settings.form.newPasswordConfirm')}
              placeholder={t('settings.form.enterNewPasswordConfirm')}
              {...form.getInputProps('newPasswordConfirm')}
              required
              mb="md"
            />
          </Grid.Col>
        </Grid>
        <Alert color="red" mb="md">
          {t('settings.form.passwordSecurityDescription')}
        </Alert>
        <Button type="submit" loading={isLoading} color="#40286c">
          {t('settings.form.update')}
        </Button>
      </form>
    </Box>
  );
};

export default SecureAccount;
