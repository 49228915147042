// src/redux/api/paraphraseApiSlice.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const articleForgerApiSlice = createApi({
    reducerPath: 'ArticleForgerApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['ArticleForger', 'PromptChain'], // Define both tag types
    endpoints: (builder) => ({
        forgeArticle: builder.mutation({
            query: (ArticleForgerData) => ({
                url: '/results/forger',
                method: 'POST',
                body: ArticleForgerData,
            }),
            invalidatesTags: ['ArticleForger', 'PromptChain'], // Invalidate both if needed
        }),
        promptChain: builder.mutation({
            query: (PromptChainData) => ({
                url: '/results/promptChain',
                method: 'POST',
                body: PromptChainData,
            }),
            invalidatesTags: ['ArticleForger', 'PromptChain'], // Invalidate both if needed
        }),
        suggestTitles: builder.mutation({
            query: (titleData) => ({
              url: `/results/suggestTitles`,
              method: 'POST',
              body: {
                topic: titleData.topic,
                course: titleData.course,
                language: titleData.language,
                length: titleData.length,
              },
            }),
            invalidatesTags: ['ArticleForger', 'PromptChain'], // Invalidate both if needed
          }),
          suggestTopic: builder.mutation({
            query: (topicData) => ({
              url: `/results/suggestTopic`,
              method: 'POST',
              body: {
                topic: topicData.topic,
                language: topicData.language
              },
            }),
            invalidatesTags: ['ArticleForger'],
        }),
       
        // Add more paraphrasing-related endpoints here
    }),
});

export const { useForgeArticleMutation, usePromptChainMutation, useSuggestTitlesMutation, useSuggestTopicMutation, useExportToPdfMutation, useExportToDocxMutation } = articleForgerApiSlice;
