import { useState, useEffect } from 'react';
import { useForm } from '@mantine/form';
import { Stack, Button, TextInput, Grid } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNotifications } from '../../../context/NotificationContext';
import { 
  useSendUpdateEmailVerificationMutation,
  useVerifyUpdateEmailMutation 
} from '../../../redux/api/authApiSlice';
import DOMPurify from 'dompurify';

const ChangeEmail = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const { addNotification } = useNotifications();
  const [emailStep, setEmailStep] = useState(1);
  const [emailToken, setEmailToken] = useState('');
  
  const [sendUpdateEmailVerification] = useSendUpdateEmailVerificationMutation();
  const [verifyUpdateEmail] = useVerifyUpdateEmailMutation();
  console.log('523')
  const form = useForm({
    initialValues: {
      currentEmail: '',
      newEmail: '',
    },
    validate: {
      newEmail: (value) => (/^\S+@\S+\.\S+$/.test(value) ? null : t('validation.newEmail')),
    },
  });

  useEffect(() => {
    if (user) {
      form.setValues({
        currentEmail: user.email || '',
      });
    }
  }, [user]);

  const handleSendEmailCode = async () => {
    try {
      await sendUpdateEmailVerification({ newEmail: DOMPurify.sanitize(form.values.newEmail) }).unwrap();
      setEmailStep(2);
      addNotification({ type: 'success', message: t('settings.form.notifications.emailCodeSent') });
    } catch (err) {
      addNotification({ type: 'error', message: err.data?.message || t('settings.form.notifications.emailCodeSendFail') });
    }
  };

  const handleVerifyEmailCode = async () => {
    try {
      await verifyUpdateEmail({ token: DOMPurify.sanitize(emailToken) }).unwrap();
      addNotification({ type: 'success', message: t('settings.form.notifications.emailUpdateSuccess') });
      form.setValues({
        currentEmail: form.values.newEmail,
        newEmail: '',
      });
      setEmailStep(1);
      setEmailToken('');
    } catch (err) {
      if (err.data?.message === 'Token has expired') {
        addNotification({ type: 'error', message: t('settings.form.notifications.tokenExpired') });
      } else {
        addNotification({ type: 'error', message: err.data?.message || t('settings.form.notifications.emailUpdateFail') });
      }
    }
  };

  // If user is OAuth user, show disabled state with explanation
  if (user?.isOAuthUser) {
    return (
      <div className="space-y-6">
        <h3 className="text-lg font-medium text-gray-900">
          {t('settings.form.changeEmail')}
        </h3>
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {t('settings.form.currentEmail')}
            </label>
            <input
              type="email"
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-gray-500"
              value={user.email}
              disabled
            />
          </div>
          <p className="text-sm text-gray-600">
            {t('settings.form.googleEmailInfo')}
          </p>
        </div>
      </div>
    );
  }

  // Regular email change component for non-OAuth users
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900">
        {t('settings.form.changeEmail')}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            {t('settings.form.currentEmail')}
          </label>
          <input
            type="email"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-gray-500"
            value={form.values.currentEmail}
            disabled
          />
        </div>
        <div className="space-y-4">
          {emailStep === 1 ? (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.form.newEmail')}
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={t('settings.form.enterNewEmail')}
                  {...form.getInputProps('newEmail')}
                />
              </div>
              <button
                type="button"
                onClick={handleSendEmailCode}
            className="w-full py-2 px-4 bg-[#40286C] text-white rounded-lg hover:bg-[#3e2866] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c]"
              >
                {t('settings.form.sendCode')}
              </button>
            </>
          ) : (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.form.enterVerificationCode')}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={emailToken}
                  onChange={(e) => setEmailToken(e.currentTarget.value)}
                />
              </div>
              <button
                type="button"
                onClick={handleVerifyEmailCode}
               className="w-full py-3 px-4 bg-[#40286C] text-white rounded-lg hover:bg-[#3e2866]   transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c]"
              >
                {t('settings.form.verifyCode')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
