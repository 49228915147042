import { useState } from 'react';
import { NavLink as RouterNavLink, useLocation, useNavigate } from 'react-router-dom';
import { Group, Button, useMantineTheme, Collapse, Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLogoutMutation } from '../../redux/api/authApiSlice';
import { logOut } from '../../redux/slices/authSlice';
import {
  // Better semantic icons for each function
  Beaker, // For Humanizer - represents experimentation/scientific tools
  BrainCircuit, // For Question/Answer - represents AI/intelligence
  Pencil, // For Article Forger - more intuitive for writing
  FileSearch, // For Summarizer - represents document analysis
  FileEdit, // For Paraphraser - represents text editing
  BookMarked, // For My Lessons - represents saved educational content
  CreditCard, // Keeping this as is - good for payments
  UserCog, // For User Information - represents user settings
  Settings, // Keeping this as is - universal settings icon
  Receipt, // Keeping this as is - good for billing
  History, // Keeping this as is - good for transactions
  LogOut, // Keeping this as is - universal logout icon
  MessageSquare, // Better for contact/messaging
  X, // For close button
} from 'lucide-react';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';

// DropdownNavLink Component remains the same
const DropdownNavLink = ({ label, Icon, color, children }) => {
  const theme = useMantineTheme();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location.pathname.startsWith('/settings'));

  return (
    <div style={{ borderRadius: '6px', marginBottom: theme.spacing.xs }}>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className="dropdownbar-link sidebar-link"
       
      >
        {Icon && <Icon size={18} style={{ marginRight: theme.spacing.xs }} />}
        {label}
      </div>
      <Collapse in={isOpen} style={{ paddingLeft: theme.spacing.sm }}>
        {children}
      </Collapse>
    </div>
  );
};

// CustomNavLink Component remains the same
const CustomNavLink = ({ to, label, Icon, color, onClick, ...props }) => {
  const theme = useMantineTheme();

  return (
    <RouterNavLink
      to={to}
      {...props}
      onClick={onClick}
      style={({ isActive }) => ({
       
        color: isActive ? 'white' : '#40286C',

        boxShadow: isActive ? 'rgba(0, 0, 0, 0.4) 0px 4px 12px' : null,
      
        
      })}
      className={({ isActive }) => (isActive ? 'sidebar-link sidebar-link--active bg-[#40286C]' : 'sidebar-link')}
    >
      {Icon && <Icon size={18} style={{ marginRight: theme.spacing.xs }} />}
      {label}
    </RouterNavLink>
  );
};

const Sidebar = ({ 
  isMobile, 
  mobileOpened, 
  toggleMobile, 
  historyContent, 
  setIsContactUsModalOpen 
}) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [activeTab, setActiveTab] = useState('menu');

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(logOut());
      navigate('/auth/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  const renderNavigationLinks = () => (
    <>
      <CustomNavLink id="humanizer-link" to="/tools/humanizer" label={t('baseLayout.humanizer')} Icon={Beaker} />
      <CustomNavLink id="questionAnswer-link" to="/tools/questionAnswer" label={t('baseLayout.questionAnswer')} Icon={BrainCircuit} />
      <CustomNavLink id="virtualTeacher-link" to="/tools/virtualTeacher" label={t('baseLayout.virtualTeacher')} Icon={({ size, style }) => (
        <FontAwesomeIcon 
          icon={faPersonChalkboard} 
          style={{ ...style, fontSize: size }} 
        />
      )} />
      <CustomNavLink id="articleForger-link" to="/tools/articleForger" label={t('baseLayout.articleForger')} Icon={Pencil} />
      <CustomNavLink to="/tools/summarizer" label={t('baseLayout.summarizer')} Icon={FileSearch} />
      <CustomNavLink to="/tools/paraphraser" label={t('baseLayout.paraphraser')} Icon={FileEdit} />
      <CustomNavLink to="/lessons/edit" label={t('baseLayout.myLessons')} Icon={BookMarked} />
      <CustomNavLink to="/payments/subscription" label={t('baseLayout.payment')} Icon={CreditCard} />
      <DropdownNavLink label={t('baseLayout.userInformation')} Icon={UserCog}>
        <CustomNavLink to="/settings/general" label={t('baseLayout.accountSettings')} Icon={Settings} />
        <CustomNavLink to="/settings/billing" label={t('baseLayout.billing')} Icon={Receipt} />
        <CustomNavLink to="/settings/transactions" label={t('baseLayout.transactions')} Icon={History} />
      </DropdownNavLink>
      <button 
        className="sidebar-link" 
        style={{
       

          borderTop: '1px solid #e4e4e4',
          width: '100%'
        }} 
        onClick={() => setIsContactUsModalOpen(true)}
      >
        <MessageSquare size={18} style={{ marginRight: theme.spacing.xs }} />
        {t('baseLayout.contactus')}
      </button>
      <RouterNavLink
        to="#"
        onClick={handleLogout}
       
        className="sidebar-link"
      >
        <LogOut size={18} style={{ marginRight: theme.spacing.xs }} />
        {t('baseLayout.logout')}
      </RouterNavLink>
    <LanguageSwitcher />
    </>
  );

  return (
    <div style={{ padding: '16px' }}>
      {isMobile && (
        <div style={{ 
          position: 'absolute', 
          right: '10px', 
          top: '10px',
          zIndex: 201 
        }}>
          <Button
            variant="subtle"
            size="sm"
            onClick={toggleMobile}
            id="close-sidebar"
            style={{ padding: 0 }}
          >
            <X size={24} />
          </Button>
        </div>
      )}

      <div className="flex items-center sm:mb-4 lg:mb-8">
        <img 
          src="https://static.easyegitim.com/easy_logo.svg" 
          alt="Logo" 
          style={{ width: isMobile ? 140 : 170 }} 
        />
      </div>

      {isMobile && historyContent ? (
        <>
          <div className="mobile-tabs" style={{ marginBottom: theme.spacing.md }}>
            <button
              className={`tab-button ${activeTab === 'menu' ? 'active' : ''}`}
              onClick={() => setActiveTab('menu')}
              id="features-tab"
            >
              {t('baseLayout.menu')}
            </button>
            <button
              className={`tab-button ${activeTab === 'messages' ? 'active' : ''}`}
              onClick={() => setActiveTab('messages')}
              id="messages-tab"
            >
              {t('baseLayout.messages')}
            </button>
          </div>

          <div id={isMobile ? 'toolHistory' : ''} style={{ display: activeTab === 'messages' ? 'block' : 'none' }}>
            {historyContent}
          </div>
          <div style={{ display: activeTab === 'menu' ? 'block' : 'none' }}>
            {renderNavigationLinks()}
          </div>
        </>
      ) : (
        renderNavigationLinks()
      )}
    </div>
  );
};

export default Sidebar;