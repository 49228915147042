/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Switch, Title, Text, Box, Grid, Button, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useUpdateContactPreferencesMutation } from '../../redux/api/authApiSlice';
import { useNotifications } from '../../context/NotificationContext'; // Adjust the import path as necessary


function ContactPreferences({ userContactPreferences }) {
  const { addNotification } = useNotifications();
  const { t } = useTranslation();
  
  const [updateContactPreferences, { isLoading, error }] = useUpdateContactPreferencesMutation();
  const handleSubmit = async () => {
    try {
      const preferences = {
        sms: smsEnabled,
        email: emailEnabled,
        calls: callEnabled,
      }
        await updateContactPreferences({  preferences });
        addNotification({
          id: new Date().getTime(), // Unique identifier
          type: 'success',
          message: t('settings.form.notifications.contactPreferencesUpdateSuccess'),
        });
    } catch (err) {
      addNotification({
        id: new Date().getTime(), // Unique identifier
        type: 'error',
        message: err.message || t('settings.form.notifications.contactPreferencesUpdateFail'),
      });

    }
   
  };


  // Initialize state with values from props
  const [smsEnabled, setSmsEnabled] = useState(userContactPreferences?.sms || false);
  const [emailEnabled, setEmailEnabled] = useState(userContactPreferences?.email || true);
  const [callEnabled, setCallEnabled] = useState(userContactPreferences?.calls || true);

  // Define the style for the text columns
  const textColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  // Define the style for the switch columns
  const switchColumnStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  // Effect to update state if props change
  useEffect(() => {
    if (userContactPreferences) {
      setSmsEnabled(userContactPreferences.sms);
      setEmailEnabled(userContactPreferences.email);
      setCallEnabled(userContactPreferences.calls);
    }
  }, [userContactPreferences]);

  return (
    <Box p="md">
      <Title order={4}>{t('settings.form.contactPreferences')}</Title>
      <Text size="sm" mb="lg">
        {t('settings.form.contactPreferencesDescription')}
      </Text>
      <Grid gutter="md">
        {/* SMS Preference */}
        <Grid.Col span={6} style={textColumnStyle}>
          <Text>{t('settings.form.contact1')}</Text>
          <Text size="sm" color="dimmed">
            {t('settings.form.contact1Description')}
          </Text>
        </Grid.Col>
        <Grid.Col span={6} style={switchColumnStyle}>
          <Switch
            checked={smsEnabled}
            onChange={(event) => setSmsEnabled(event.currentTarget.checked)}
            size="lg"
            color="#40286C"
          />
        </Grid.Col>

        {/* Email Preference */}
        <Grid.Col span={6} style={textColumnStyle}>
          <Text>{t('settings.form.contact2')}</Text>
          <Text size="sm" color="dimmed">
            {t('settings.form.contact2Description')}
          </Text>
        </Grid.Col>
        <Grid.Col span={6} style={switchColumnStyle}>
          <Switch
            checked={emailEnabled}
            onChange={(event) => setEmailEnabled(event.currentTarget.checked)}
            size="lg"
            color="#40286C"

          />
        </Grid.Col>

        {/* Call Preference */}
        <Grid.Col span={6} style={textColumnStyle}>
          <Text>{t('settings.form.contact3')}</Text>
          <Text size="sm" color="dimmed">
            {t('settings.form.contact3Description')}
          </Text>
        </Grid.Col>
        <Grid.Col span={6} style={switchColumnStyle} >
          <Switch
            checked={callEnabled}
            onChange={(event) => setCallEnabled(event.currentTarget.checked)}
            size="lg"
            color="#40286C"
           
          />
        </Grid.Col>
        <div style={{display: 'flex',flex: 1, marginTop: '1rem'}}>
      
          <Button disabled={isLoading} fullWidth onClick={handleSubmit}  color="#40286C">{t('settings.form.saveChanges')}</Button>
    
 
        </div>
       
      </Grid>
    </Box>
  );
}

export default ContactPreferences;
