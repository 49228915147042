/**
 * Utility functions for platform detection
 */

/**
 * Detects if the current device is iOS (iPhone, iPad, or iPod)
 * @returns {boolean} True if the device is an iOS device
 */
export const isIOS = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

/**
 * Detects if the current device is running macOS
 * @returns {boolean} True if the device is running macOS
 */
export const isMacOS = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mac os x/.test(userAgent);
};

/**
 * Determines if Apple Sign In should be shown based on device
 * This is relevant because Apple Sign In is most useful on Apple devices 
 * where users likely have an Apple ID already set up
 * @returns {boolean} True if Apple Sign In should be shown
 */
export const shouldShowAppleSignIn = () => {
  // Enable this line for testing on Windows or other non-Apple platforms
  // return true;
  console.log("isIOS()", isIOS());
  // Production behavior - only show on Apple devices
  return isIOS() || isMacOS();
};

export default {
  isIOS,
  isMacOS,
  shouldShowAppleSignIn
}; 