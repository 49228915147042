// src/components/VirtualTeacher/VirtualTeacher.jsx

import { useEffect, useState, useRef, useCallback } from "react"; 
import {
  Grid,
  Group,
  Button,
  Stack,
  Textarea,
  FileButton,
  Text,
  Modal,
  Loader,

} from "@mantine/core";
import { useForm } from "@mantine/form";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { encode } from "gpt-tokenizer";
import { useMediaQuery } from "@mantine/hooks";
import * as Sentry from "@sentry/react";
import { FileUp, Camera, Send, GraduationCap, X, Book, Languages, ClipboardList, FileText, Plus } from "lucide-react";

// Redux + RTK Query
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../../redux/slices/authSlice";
import { appendToHistory } from "../../redux/slices/toolHistoriesSlice";
import {
  useFetchHistoryDetailsQuery,useLazyFetchHistoryDetailsQuery
} from "../../redux/api/toolHistoriesApiSlice";
import { useGetAnswerMutation } from "../../redux/api/virtualTeacherApiSlice";
import { useFetchLessonsQuery } from '../../redux/api/lessonApiSlice';

// UI + Utils
import VTChat from "../../components/common/VTChat"; // Your chat message display component
import StyledButton from "../../components/common/StyledButton";
import TokenCounter from "../../utils/tokenCounter";
import LessonSelectorModal from "../../components/common/LessonSelectorModal";
import CourseCreationModal from "../../components/common/CourseCreationModal";

import { languageOptions, getAssistantOptions } from "../../utils/constants";
import { useNotifications } from '../../context/NotificationContext';

import SingleStepTutorial from "../../components/common/Tutorial";

import { v4 as uuidv4 } from 'uuid'; // Added import for uuid

import { detectToolUsage, defaultToolKeywords, toolDisplayNames } from '../../components/common/toolDetector';
import { fixFileName } from '../../utils/fileUtils';

function VirtualTeacher() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);
  const currentTool = useSelector(state => state.toolHistories.currentTool);
  const selectedHistoryId = useSelector(state => state.toolHistories.selectedHistoryId);
  const [newChatId, setNewChatId] = useState(null);

  const assistantOptions = getAssistantOptions(t);

  // Local states
  const [threadId, setThreadId] = useState(null);    // GPT thread ID
  const [messages, setMessages] = useState([]);      // Chat messages
  const [documents, setDocuments] = useState([]);    // Uploaded documents
  const [images, setImages] = useState([]);          // Uploaded images
  const [tokenCount, setTokenCount] = useState(0);   // Token count for next question
  const [notEnoughTokens, setNotEnoughTokens] = useState(false); // Token error state

  
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);

  // Pagination states for infinite scroll
  const [page, setPage] = useState(1);               // Current page
  const [hasMore, setHasMore] = useState(true);     // Flag to check if more messages exist
  const scrollContainerRef = useRef(null);          // Reference to the chat container

  const [cursor, setCursor] = useState(null);

  const [getAnswer, { isLoading, error }] = useGetAnswerMutation();

  // Add these constants and state variables
  const LAST_LESSON_KEY = 'lastSelectedLessonVirtualTeacher';
  
  // Add the lessons query
  const { data: lessons, error: lessonsError, isLoading: lessonsLoading } = useFetchLessonsQuery();
  const lessonsForUser = lessons?.map((lesson) => ({ _id: lesson._id, courseName: lesson.courseName })) || [];

  const userLessons = [
    { _id: 'noLesson', courseName: t('tumdersler'), model: 'uzman' },

    ...lessonsForUser
  ];
  const tutorialSeen =  localStorage.getItem(`tutorial_virtualteacher`);

  const [fetchHistory, { data: historyDetails, isFetching: isFetchingHistory }] =
  useLazyFetchHistoryDetailsQuery();

  // Load initial messages when a chat is selected
  useEffect(() => {
    if (selectedHistoryId && currentTool === "virtualTeacher") {
      // Reset state for new chat
      setMessages([]);
      setCursor(null);
      setHasMore(true);
      setInitialLoadComplete(false);
      
      // Fetch initial batch of messages
      fetchHistory({
        serviceType: "virtualTeacher",
        id: selectedHistoryId,
        cursor: null,
        limit: 50
      }).unwrap()
        .then(response => {
          setMessages(response.messages || []);
          setCursor(response.nextCursor);
          setHasMore(response.hasMore);
          setThreadId(response.usageData.threadId);
          setInitialLoadComplete(true);
          
          // Scroll to bottom after initial load
          setTimeout(() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
            }
          }, 100);
        })
        .catch(error => {
          console.error("Error fetching chat history:", error);
       
        });
    }
  }, [selectedHistoryId, currentTool, fetchHistory]);

  useEffect(() => {
    if (historyDetails) {
      // Deduplicate messages when setting them
      const uniqueMessages = deduplicateMessages(messages, historyDetails.messages);
      setMessages(uniqueMessages);

      // Update other state variables
      setThreadId(historyDetails.usageData.threadId);
      setCursor(historyDetails.nextCursor);
      setHasMore(historyDetails.hasMore);
      
      // If this is the first page, scroll to bottom after a short delay
      if (page === 1) {
        setTimeout(() => {
          if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
          }
        }, 100);
      }
    }
  }, [historyDetails]);


  // Add form initialization with course
  const form = useForm({
    initialValues: {
      selectedAssistant: assistantOptions[1].value,
      selectedLanguage: languageOptions[0].value,
      question: "",
      course: localStorage.getItem(LAST_LESSON_KEY) || "noLesson",
    },
    validate: {
      question: (value) => {
        if (!value && images.length === 0) return t("validation.question.required");
        if (value && value.length < 2) return t("validation.question.tooShort");
        if (value && value.length > 10000) return t("validation.question.tooLong");
        return null;
      },
      selectedAssistant: (value, values) => {
        if (!values.course && !value) return t("validation.selectedAssistant.required");
        return null;
      },
      selectedLanguage: (value) => value ? null : t("validation.selectedLanguage.required"),
    },
  });

  useEffect(() => {
    if (localStorage.getItem(LAST_LESSON_KEY)) {
      setSelectedLesson({
        id: localStorage.getItem(LAST_LESSON_KEY),
        courseName: localStorage.getItem(`${LAST_LESSON_KEY}course`)
      });
    }
  }, [selectedHistoryId]);

  // Add lesson selection handler
  const handleLessonSelect = (value) => {
    setSelectedLesson(value);
    
    // Store both the ID and the model type
    form.setFieldValue("course", value._id);
    localStorage.setItem(LAST_LESSON_KEY, value._id);
    localStorage.setItem(`${LAST_LESSON_KEY}_model`, value.model);
    localStorage.setItem(`${LAST_LESSON_KEY}course`, value.courseName);
  };

  // Modify the main effect to focus on processing history details
  useEffect(() => {
    if (!historyDetails || !selectedHistoryId) return;
    
    const { usageData, messages: serverMessages, totalPages } = historyDetails;

    console.log(historyDetails, "historyDetails");
    // Only update form values if they've changed
    if (usageData) {
      const assistantType = usageData.assistantType || assistantOptions[1].value;
      const language = usageData.language || languageOptions[0].value;
      const lesson = usageData.lesson || "noLesson";
      
      const newValues = {
        selectedAssistant: assistantType,
        selectedLanguage: language,
        course: lesson
      };

      // Only update if values are different
      if (JSON.stringify(form.values) !== JSON.stringify(newValues)) {
        form.setValues(newValues);
      }

      if (usageData.threadId && usageData.threadId !== threadId) {
        setThreadId(usageData.threadId);
      }
    }

    // Update messages only if they've changed
    if (Array.isArray(serverMessages) && serverMessages.length > 0 ) {
      setMessages(prev => {
        if (page === 1) {
          return serverMessages;
        }
        const existingMessageIds = new Set(prev.map(msg => msg.created_at));
        const newMessages = serverMessages.filter(msg => !existingMessageIds.has(msg.created_at));
        return [...prev, ...newMessages];
      });
     
    } else if (page === 1) {
      setMessages([]);
      setHasMore(false);
    }
  }, [historyDetails, page, selectedHistoryId]); 


  useEffect(() => {

   /*  if (selectedHistoryId !== prevSelectedHistoryIdRef.current) {
      setPage(1);
      setMessages([]); // Clear messages when switching chats
      setThreadId(null); // Reset thread ID when switching chats
      prevSelectedHistoryIdRef.current = selectedHistoryId;
    } */

    // Handle saved lesson loading
    if (localStorage.getItem(LAST_LESSON_KEY)) {
      setSelectedLesson({
        id: localStorage.getItem(LAST_LESSON_KEY),
        courseName: localStorage.getItem(`${LAST_LESSON_KEY}course`)
      });
    }

    // Handle history deselection
    if (!selectedHistoryId) {
      setMessages([]);
      setThreadId(null);
      form.reset();
    }
    if (!tutorialSeen) {
      setShowTutorial(true);
    }
  }, [selectedHistoryId]);


  const noteButtonRef = useRef(null);
  const examButtonRef = useRef(null);
  const lessonButtonRef = useRef(null);

  // Handle new question submission
  const handleSubmit = async (values) => {
    try {
      // Check if user has enough tokens
      if (tokenCount > tokenBalance) {
        setNotEnoughTokens(true);
        return;
      }

      // Check if message limit has been reached
      if (messages.length >= 50) {
        addNotification({
          id: Date.now(),
          type: 'warning',
          title: t('notifications.messageLimitReached.title'),
          message: t('notifications.messageLimitReached.message'),
          autoClose: 5000
        });
        return;
      }

      const detectedTool = detectToolUsage(values.question, defaultToolKeywords);
      if (detectedTool && ["note", "lesson", "exam"].includes(detectedTool)) {
        let buttonRef, buttonLabel;
        if (detectedTool === "note") {
          buttonRef = noteButtonRef;
          buttonLabel = t("notOlustur"); // Expected to resolve to "Ders Notu Oluştur" in Turkish
        } else if (detectedTool === "lesson") {
          buttonRef = lessonButtonRef;
          buttonLabel = t("dersOlustur"); // "Ders Oluştur"
        } else if (detectedTool === "exam") {
          buttonRef = examButtonRef;
          buttonLabel = t("sinavOlustur"); // "Sınav Oluştur"
        }
        addNotification({
          id: Date.now(),
          type: "warning",
          title: t("notifications.differentToolDetected.title"),
          message: t("notifications.differentToolDetected.message", { tool: buttonLabel }),
          autoClose: 5000
        });
        // Focus the corresponding button if available
        if (buttonRef && buttonRef.current) {
          buttonRef.current.focus();
        }
        return; // Prevent normal submission if these keywords are detected
      }
      
  
      // Create the FormData payload first
      const payload = new FormData();
      payload.append("question", DOMPurify.sanitize(values.question));
      payload.append("selectedAssistant", DOMPurify.sanitize(values.selectedAssistant));
      payload.append("selectedLanguage", DOMPurify.sanitize(values.selectedLanguage));
      payload.append("course", DOMPurify.sanitize(values.course));
      if (threadId) {
        payload.append("threadId", threadId);
      }
  
      // Create a user message object for immediate UI update
      const userMessage = {
        sender: "user",
        text: values.question,
        files: documents.length ? documents : [],
        images: images.length ? images : [],
      };
  
      // If images were attached, create local URLs for preview
      if (images.length > 0) {
        // Create an array of object URLs from the images
        userMessage.localImageUrls = images.map((img) => URL.createObjectURL(img));
        userMessage.questionType = "img";
        userMessage.id = uuidv4();
        
        // Append each image file to the payload
        images.forEach((img) => {
          payload.append("files", img);
        });
        // Indicate to the backend that this is an image message
        payload.append("type", "img");
      }
  
      // Append documents if any
      documents.forEach((file) => {
        payload.append("files", file);
      });
  
      // Add the user's message immediately so the chat shows the preview
      setMessages((prev) => [...prev, userMessage]);
  
      // Call the mutation
      const result = await getAnswer(payload).unwrap();
  
      // Set threadId if it's new
      if (result.threadId /* && !threadId */) {
        setThreadId(result.threadId);
      }
  
      // Append assistant messages to chat
      if (Array.isArray(result.result)) {
        result.result.id = uuidv4()
        setMessages((prev) => [...prev, ...result.result]);
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "assistant", text: result.result, key: uuidv4() },
        ]);
      }
      console.log(result, "result", result.usageData?.inputSummary);
  
      // Dispatch usageData and token balance updates
      if (result.usageData?.inputSummary) {
        setNewChatId(result.usageData.id);
        dispatch(appendToHistory({ tool: "virtualTeacher", entry: result.usageData }));
      }
      dispatch(setTokens({ tokenBalance: result.tokenBalance }));
      // Clear input and attachments
      setDocuments([]);
      setImages([]);
      form.setFieldValue("question", "");
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      
      if (err.data?.error === 'MessageLimitExceeded') {
        addNotification({
          id: Date.now(),
          type: 'warning',
          title: t('notifications.messageLimitReached.title'),
          message: t('notifications.messageLimitReached.message'),
          autoClose: 5000
        });
        
        // Reset thread ID to force creation of a new thread on next message
        setThreadId(null);
        return;
      }

      if (err.data?.error === 'TokenLimitExceeded') {
        // Show notification about token limit
        addNotification({
          id: Date.now(),
          type: 'info',
          message: t('errors.TOKEN_EXCEEDED'),
        });

        // Reset thread ID to start new conversation
        setThreadId(null);
        
        // Automatically retry the question in a new thread
        const newPayload = new FormData();
        newPayload.append("question", DOMPurify.sanitize(values.question));
        newPayload.append("selectedAssistant", DOMPurify.sanitize(values.selectedAssistant));
        newPayload.append("selectedLanguage", DOMPurify.sanitize(values.selectedLanguage));
        newPayload.append("course", DOMPurify.sanitize(values.course));
        
        // Re-append any files if present
        if (images.length > 0) {
          images.forEach((img) => {
            newPayload.append("files", img);
          });
          newPayload.append("type", "img");
        }
        
        if (documents.length > 0) {
          documents.forEach((doc) => {
            newPayload.append("files", doc);
          });
        }

        // Try the request again with a new thread
        const newResult = await getAnswer(newPayload).unwrap();
        
        if (newResult.threadId) {
          setThreadId(newResult.threadId);
        }

        // Update messages and other state
        if (Array.isArray(newResult.result)) {
          setMessages((prev) => [...prev, ...newResult.result]);
        } else {
          setMessages((prev) => [
            ...prev,
            { sender: "assistant", text: newResult.result },
          ]);
        }

        // Update token balance and history
        if (newResult.usageData?.inputSummary) {
          setNewChatId(newResult.usageData.id);
          dispatch(appendToHistory({ tool: "virtualTeacher", entry: newResult.usageData }));
        }
        dispatch(setTokens({ tokenBalance: newResult.tokenBalance }));
        
        return;
      }

      // Extract an error message
      const errorMsg = err?.message || "An error occurred while processing your request.";

    
      if (err.data?.error === "FileUploadError") {
        console.log(err.data, "err.data");
        let failedFiles = err.data.failedFiles;
        if (!failedFiles) {
          const match = err.data.message.match(/The following files failed to embed: (.+)/);
          failedFiles = match ? [match[1]] : [];
        }
        
        const errorMessage = failedFiles.length === 1
          ? t('errors.fileProcessing.single', { fileName: failedFiles[0] })
          : t('errors.fileProcessing.multiple', { 
              fileNames: failedFiles.map(name => `"${name}"`).join(', ')
            });
            console.log(errorMessage, "errorMessage");
      
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: errorMessage
        });
      }
      // If the error message indicates a missing text value error, notify the user accordingly.
      else if (errorMsg.includes("content with type `text` must have `text` values")) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: t("errors.content.questionanswer.missingText"),
        });
      } else {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: errorMsg,
        });
      }
  
      // If this is the very first message (or only a preview message), reset the messages
      if (messages.length <= 1) {
        setMessages([]);
      }
      
      form.reset();
    }
  };
  
  // Update the token calculation function
   const calculateTokens = useCallback(() => {
    let totalCost = 0;
    
    // Check if the selected lesson is "ücretsiz" (free)
    const isFreeCourse = form.values.course === "noLesson" || form.values.course === "ücretsiz";
    
    // Base cost depends on lesson type
    if (!isFreeCourse) {
      // If not a free lesson, base cost is 20
      totalCost += 20;
    } 

    // Cost for screenshots/images (for all lesson types)
    if (images.length > 0) {
      totalCost += 5;
    }

    // Cost for documents (for all lesson types)
    totalCost += documents.length * 5;

    setTokenCount(totalCost);
  }, [form.values.question, images.length, documents.length, form.values.course]); 
  // Update the useEffect to trigger calculation when files change
   useEffect(() => {
    calculateTokens();
  }, [form.values.question, images.length, documents.length, calculateTokens]);
 
  // Chat history state

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // Handle scroll to load more messages
 



  // Handle sending a new message


  // Handle copying text to clipboard
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      // Optionally show a success notification
    } catch (err) {
      console.error('Failed to copy text:', err);
      // Optionally show an error notification
    }
  };

  // Handle downloading images
  const handleImageDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `image-${Date.now()}.jpg`; // or use a more meaningful name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Failed to download image:', err);
      // Optionally show an error notification
    }
  };

  // Handle template button clicks
  const handleTemplateClick = (questionText) => {
    form.setFieldValue("question", questionText);
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  // New helper functions
  const { addNotification } = useNotifications();

  const handleSetFiles = (files, type) => {
    // Check if files are selected before proceeding
    if (!files || (Array.isArray(files) && files.length === 0)) {
      return; // Do nothing if no files were selected
    }
  
    if (type === "img") {
      // Directly set the array of images instead of wrapping it in another array
      setImages(files);
      // Create a notification message that joins all file names
      const fileNames = Array.isArray(files) ? files.map(file => file.name).join(', ') : files.name;
      addNotification({
        id: Date.now(),
        type: 'success',
        message: t('fileUpload.success.image', { fileName: fileNames })
      });
    } else {
      setDocuments(files);
      const fileNames = Array.isArray(files) ? files.map(file => file.name).join(', ') : files.name;
      addNotification({
        id: Date.now(),
        type: 'success',
        message: t('fileUpload.success.multiple', { fileNames })
      });
    }
  };

  const resetRef = useRef(null);
  const resetRefSecond = useRef(null);

  // Add this state near other state declarations
  const [isCourseCreationModalVisible, setIsCourseCreationModalVisible] = useState(false);

  // Update the showCourseCreationModal function
  const showCourseCreationModal = () => {
    setIsCourseCreationModalVisible(true);
  };

  // Add these state variables
  const [isLessonModalOpen, setIsLessonModalOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);

  // Add this state for language modal
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  // Add this handler for language selection
  const handleLanguageSelect = (value) => {
    form.setFieldValue("selectedLanguage", value);
    setIsLanguageModalOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      form.onSubmit(handleSubmit)(e);
    }
  };

  const resetChat = () => {
    setMessages([]);
    setThreadId(null);
    setDocuments([]);
    setImages([]);
    form.reset();
  };

  useEffect(() => {
    // Add the global event listener
    const handleNewChat = () => {
      resetChat();
    };
    window.addEventListener('newChat', handleNewChat);
    
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('newChat', handleNewChat);
    };
  }, []); // empty dependency array to register once

  const sidebarTutorialSteps = [
    {
      elementId: ['humanizer-link'],
      title: t('tutorial.sidebar.humanizer.title'),
      content: t('tutorial.sidebar.humanizer.content'),
      position: 'right'
    },
    {
      elementId: ['questionAnswer-link'],
      title: t('tutorial.sidebar.qa.title'),
      content: t('tutorial.sidebar.qa.content'),
      position: 'right'
    },
    {
      elementId: ['virtualTeacher-link'],
      title: t('tutorial.sidebar.vt.title'),
      content: t('tutorial.sidebar.vt.content'),
      position: 'right'
    },
    {
      elementId: ['articleForger-link'],
      title: t('tutorial.sidebar.af.title'),
      content: t('tutorial.sidebar.af.content'),
      position: 'right'
    }
  ];

  const vtTutorialSteps = [
    {
      elementId: ['course-selector', 'lesson-creator'],
      title: t('tutorial.virtualTeacher.first.title'),
      content: t('tutorial.virtualTeacher.first.content'),
      position: 'center'
    },
    {
      elementId: ['document-uploader', 'image-uploader'],
      title: t('tutorial.virtualTeacher.second.title'),
      content: t('tutorial.virtualTeacher.second.content'),
      position:'top'
    },
    {
      elementId: ['tokenCounter', 'question', 'submit'],
      title: t('tutorial.virtualTeacher.third.title'),
      content: t('tutorial.virtualTeacher.third.content'),
      position: 'center'
    }
  ];
  
  const handleTutorialStepChange = (step) => {
    setCurrentTutorialStep(step);
  };

   // Check which tutorial(s) have been seen on initial load
   useEffect(() => {
    const sidebarTutorialSeen = localStorage.getItem('tutorial_sidebar');
    const vtTutorialSeen = localStorage.getItem('tutorial_virtualteacher');

    if (!sidebarTutorialSeen) {
      setShowTutorial(true);
      setToolType('sidebar');
    } else if (!vtTutorialSeen) {
      setShowTutorial(true);
      setToolType('virtualTeacher');
    }
  }, []);
 

  const [toolType, setToolType] = useState('virtualTeacher'); // default if both seen

  const handleTutorialClose = () => {
    if (toolType === 'sidebar') {
      localStorage.setItem('tutorial_sidebar', 'true');
      if (!localStorage.getItem('tutorial_virtualteacher')) {
        // Switch to virtualTeacher tutorial immediately
        setToolType('virtualTeacher');
        setCurrentTutorialStep(0);
        setShowTutorial(true);
        return;
      }
    } else {
      localStorage.setItem('tutorial_virtualteacher', 'true');
    }
    setShowTutorial(false);
  };

  // Add these state variables near other state declarations
  const [isNoteConfirmModalOpen, setIsNoteConfirmModalOpen] = useState(false);
  const [isExamConfirmModalOpen, setIsExamConfirmModalOpen] = useState(false);

  // Add these handler functions
  const handleNoteCreation = async () => {
    // Check if using free/general lessons and message length
 

    setIsNoteConfirmModalOpen(false);
    const formData = new FormData();
    formData.append("selectedLanguage", form.values.selectedLanguage);
    formData.append("selectedAssistant", form.values.selectedAssistant);
    formData.append("question", ""); // Empty question since we're using the backend prompt
    formData.append("course", form.values.course);
    formData.append("specialMode", "lessonNotes"); // Flag to indicate exam creation mode
    formData.append("threadId", threadId);
    const response = await getAnswer(formData).unwrap();

    if (response.result) {
      // Add the assistant's response
      setMessages((prev) => [
        ...prev,
        { sender: "assistant", text: response.result },
      ]);
      
      // Update thread ID if this is a new conversation
      if (response.threadId && !threadId) {
        setThreadId(response.threadId);
      }
      
      // Update token balance
      if (response.tokenBalance) {
        dispatch(setTokens(response.tokenBalance));
      }
      
      // If this is a new conversation, add it to history
      if (response.usageData) {
        const historyEntry = {
          id: response.usageData.id,
          inputSummary: response.usageData.inputSummary,
          serviceType: "virtualTeacher",
          createdAt: new Date().toISOString(),
        };
        dispatch(appendToHistory(historyEntry));
        setNewChatId(response.usageData.id);
      }
      form.setFieldValue("question", "");
      setTokenCount(0);
      setImages([]);
      setDocuments([]);
      
      // Scroll to bottom after a short delay
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
      }, 100);
    }
    // Add your note creation logic here
    addNotification({
        id: Date.now(),
        type: 'success',
        message: t('noteCreation.success')
    });
  };

  const handleExamCreation = async () => {
    // Check if using free/general lessons and message length
    setIsExamConfirmModalOpen(false);
    const formData = new FormData();
    formData.append("selectedLanguage", form.values.selectedLanguage);
    formData.append("selectedAssistant", form.values.selectedAssistant);
    formData.append("question", ""); // Empty question since we're using the backend prompt
    formData.append("course", form.values.course);
    formData.append("specialMode", "examCreation"); // Flag to indicate exam creation mode
    formData.append("threadId", threadId);
  
    const response = await getAnswer(formData).unwrap();
    console.log(response)
     // Handle the response
     if (response.result) {
      // Add the assistant's response
      setMessages((prev) => [
        ...prev,
        { sender: "assistant", text: response.result },
      ]);
      
      // Update thread ID if this is a new conversation
      if (response.threadId && !threadId) {
        setThreadId(response.threadId);
      }
      
      // Update token balance
      if (response.tokenBalance) {
        dispatch(setTokens(response.tokenBalance));
      }
      
      // If this is a new conversation, add it to history
      if (response.usageData) {
        const historyEntry = {
          id: response.usageData.id,
          inputSummary: response.usageData.inputSummary,
          serviceType: "virtualTeacher",
          createdAt: new Date().toISOString(),
        };
        dispatch(appendToHistory(historyEntry));
        setNewChatId(response.usageData.id);
      }
      form.setFieldValue("question", "");
      setTokenCount(0);
      setImages([]);
      setDocuments([]);
      
      // Scroll to bottom after a short delay
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
      }, 100);
    }
   
    // Add your exam creation logic here
    addNotification({
        id: Date.now(),
        type: 'success',
        message: t('virtualTeacher.examCreation.success')
    });
  };

  // Update the show functions
  const showNoteCreationModal = () => {
    if ((form.values.course === 'noLesson' || form.values.course === 'general') && messages.length < 6) {
      addNotification({
          id: Date.now(),
          type: "warning",
          title: t("notifications.insufficientMessages.title"),
          message: t("notifications.insufficientMessages.message", { 
              required: 6, 
              current: messages.length 
          }),
          autoClose: 5000
      });
      return;
  } 
    setIsNoteConfirmModalOpen(true);
  };

  const showExamCreationModal = () => {
    if ((form.values.course === 'noLesson' || form.values.course === 'general') && messages.length < 6) {
      addNotification({
          id: Date.now(),
          type: "warning",
          title: t("notifications.insufficientMessages.title"),
          message: t("notifications.insufficientMessages.message", { 
              required: 6, 
              current: messages.length 
          }),
          autoClose: 5000
      });
      return;
  }
    setIsExamConfirmModalOpen(true);
  };

  // Add this state for controlling button menu visibility
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  console.log(messages.length, 'messages');

  const deduplicateMessages = (existingMessages, newMessages) => {
    // Create a map of existing messages by their ID
    const messageMap = new Map();
    
    // Add existing messages to the map
    existingMessages.forEach(msg => {
      if (msg.id) {
        messageMap.set(msg.id, msg);
      }
    });
    
    // Add new messages to the map (will not overwrite duplicates)
    newMessages.forEach(msg => {
      if (msg.id && !messageMap.has(msg.id)) {
        messageMap.set(msg.id, msg);
      }
    });
    
    // Convert map back to array and sort by created_at
    return Array.from(messageMap.values())
      .sort((a, b) => a.created_at - b.created_at);
  };
  // Render
  console.log(messages, "messages")
  return (
    <form onSubmit={form.onSubmit(handleSubmit)} style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Chat Container */}
      <div style={{ 
        flex: 1, 
        overflow: 'hidden', 
        marginBottom: isMobile ? '250px' : '180px' // Increased margin for mobile
      }}>
        <VTChat 
          messages={messages} 
          loading={isLoading || (isFetchingHistory && page > 1)}
          onCopy={handleCopy}
          onImageDownload={handleImageDownload}
          onTemplateClick={handleTemplateClick}
          selectedChatId={selectedHistoryId}
          t={t}
          form={form}
          scrollContainerRef={scrollContainerRef}
        />
      </div>

      {/* Input Area with Action Buttons - Fixed at bottom */}
      <div 
        style={{ 
          position: 'fixed', 
          bottom: 0, 
          left: isMobile ? 0 : "15rem", 
          right: isMobile ? 0 : "15rem",
          background: 'white',
          borderTop: '1px solid #ccc',
          padding: isMobile ? '0.5rem' : '1rem',
          zIndex: 10,
          maxHeight: isMobile ? '250px' : '180px', // Increased height for mobile
          overflow: 'visible'
        }}
      >
        {/* Mobile Button Menu */}
        {isMobile && (
          <>
            {/* Plus Button Trigger */}
            <div 
              style={{
                position: 'fixed',
                bottom: '130px', // Adjust based on your input area height
                right: '20px',
                zIndex: 1000,
                cursor: 'pointer',
                background: '#40286C',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
              }}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <Plus 
                size={24} 
                color="white" 
                style={{
                  transform: showMobileMenu ? 'rotate(45deg)' : 'rotate(0)',
                  transition: 'transform 0.3s ease'
                }}
              />
            </div>

            {/* Collapsible Button Menu */}
            <div style={{
              position: 'fixed',
              bottom: '180px', // Position above the plus button
              right: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              zIndex: 999,
              transition: 'transform 0.3s ease, opacity 0.3s ease',
              transform: showMobileMenu ? 'translateY(0)' : 'translateY(20px)',
              opacity: showMobileMenu ? 1 : 0,
              pointerEvents: showMobileMenu ? 'auto' : 'none'
            }}>
              <StyledButton 
                icon={<FileText size={16} />} 
                onClick={showNoteCreationModal}
                size="sm"
                style={{ width: 'auto' }}
              >
                {t("notOlustur")}
              </StyledButton>

              <StyledButton 
                icon={<ClipboardList size={16} />} 
                onClick={showExamCreationModal}
                size="sm"
                style={{ width: 'auto' }}
              >
                {t("sinavOlustur")}
              </StyledButton>

              <StyledButton 
                icon={<GraduationCap size={16} />} 
                onClick={showCourseCreationModal}
                size="sm"
                style={{ width: 'auto' }}
              >
                {t("dersOlustur")}
              </StyledButton>

              <FileButton
                onChange={(files) => handleSetFiles(files, "doc")}
                accept="application/pdf,application/msword,..."
                multiple
                disabled={images.length > 0}
              >
                {(props) => (
                  <StyledButton 
                    {...props} 
                    icon={<FileUp size={16} />}
                    size="sm"
                    style={{ 
                      opacity: images.length > 0 ? 0.5 : 1,
                      width: 'auto'
                    }}
                  >
                    {t("uploadDocuments")}
                  </StyledButton>
                )}
              </FileButton>

              <FileButton
                onChange={(files) => handleSetFiles(files, "img")}
                accept="image/*"
                multiple
                disabled={documents.length > 0}
              >
                {(props) => (
                  <StyledButton 
                    {...props} 
                    icon={<Camera size={16} />}
                    size="sm"
                    style={{ 
                      opacity: documents.length > 0 ? 0.5 : 1,
                      width: 'auto'
                    }}
                  >
                    {t("uploadImage")}
                  </StyledButton>
                )}
              </FileButton>

              <StyledButton
                onClick={() => setIsLessonModalOpen(true)}
                variant={form.values.course ? 'primary' : 'default'}
                icon={<Book size={16} />}
                size="sm"
                style={{ width: 'auto' }}
              >
                {t('ders')}: {form.values.course 
                  ? selectedLesson?.courseName || t('tumdersler')
                  : t("selectLesson")
                }
              </StyledButton>
            </div>
          </>
        )}

        {/* Desktop Button Group - Show only on desktop */}
        {!isMobile && (
          <Group position="center" spacing="md" mb="md" style={{justifyContent: 'center'}}>
            <StyledButton 
              icon={<FileText size={16} />} 
              onClick={showNoteCreationModal}
              id="note-creator"
              size="md"
            >
              {t("notOlustur")}
            </StyledButton>

            <StyledButton 
              icon={<ClipboardList size={16} />} 
              onClick={showExamCreationModal}
              id="exam-creator"
              size="md"
            >
              {t("sinavOlustur")}
            </StyledButton>

            <StyledButton 
              icon={<GraduationCap size={16} />} 
              onClick={showCourseCreationModal}
              id="lesson-creator"
              size="md"
            >
              {t("dersOlustur")}
            </StyledButton>

            <div style={{ 
              position: 'relative',
            }} className="file-button-container">
              <FileButton
                className="button button__tool"
                onChange={(files) => handleSetFiles(files, "doc")}
                resetRef={resetRef}
                accept="application/pdf,application/msword,..."
                multiple
                id="document-uploader"
                disabled={images.length > 0}
              >
                {(props) => (
                  <StyledButton 
                    {...props} 
                    style={{ 
                      opacity: images.length > 0 ? 0.5 : 1,
                    }}  
                    size="md"
                    icon={<FileUp size={16} />}
                  >
                    {t("uploadDocuments")}
                    {documents.length > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          background: '#40286C',
                          color: 'white',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '12px',
                        }}
                      >
                        {documents.length}
                      </div>
                    )}
                  </StyledButton>
                )}
              </FileButton>
              {documents.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    padding: '8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    whiteSpace: 'pre-line',
                    display: 'none',
                    zIndex: 1000,
                    marginBottom: '8px',
                    minWidth: '200px',
                    maxWidth: '300px',
                    wordWrap: 'break-word'
                  }}
                  className="file-names-tooltip"
                >
                  {documents.map((file, index) => `${index + 1}) ${fixFileName(file.name)}`).join('\n')}
                </div>
              )}
            </div>

            <div style={{ 
              position: 'relative',
            }} className="file-button-container">
              <FileButton
                className="button button__tool"
                onChange={(files) => handleSetFiles(files, "img")}
                resetRef={resetRefSecond}
                accept="image/*"
                multiple
                id="image-uploader"
                disabled={documents.length > 0}
              >
                {(props) => (
                  <StyledButton 
                    {...props} 
                    style={{ 
                      opacity: documents.length > 0 ? 0.5 : 1,
                    }} 
                    icon={<Camera size={16} />} 
                    size="md"
                  >
                    {t("uploadImage")}
                    {images.length > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          background: '#40286C',
                          color: 'white',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '12px',
                        }}
                      >
                        {images.length}
                      </div>
                    )}
                  </StyledButton>
                )}
              </FileButton>
              {images.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    padding: '8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    whiteSpace: 'pre-line',
                    display: 'none',
                    zIndex: 1000,
                    marginBottom: '8px',
                    minWidth: '200px',
                    maxWidth: '300px',
                    wordWrap: 'break-word'
                  }}
                  className="file-names-tooltip"
                >
                  {images.map((file, index) => `${index + 1}) ${fixFileName(file.name)}`).join('\n')}
                </div>
              )}
            </div>

            <StyledButton
              onClick={() => setIsLessonModalOpen(true)}
              variant={form.values.course ? 'primary' : 'default'}
              id="course-selector"
              icon={<Book size={16} />}
              size="md"
            >
              {t('ders')}: {form.values.course 
                ? selectedLesson?.courseName || t('tumdersler')
                : t("selectLesson")
              }
            </StyledButton>
          </Group>
        )}

        {/* Input and Submit - Modified for single row on desktop */}
        <Grid style={{ marginBottom: isMobile ? '8px' : 0 }}>
          <Grid.Col span={isMobile ? 12 : 8} mb={isMobile ? "xs" : 0}>
            <Textarea
              className="flex-grow rounded-2xl border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary"
              disabled={isLoading}
              placeholder={t("askQuestion")}
              {...form.getInputProps("question")}
              minRows={1}
              maxRows={3}
              autosize
              onKeyDown={handleKeyDown}
              id="question"
              styles={{
                root: {
                  width: '100%'
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 4}>
            <Group spacing="xs" position="apart" style={{ 
              height: '100%',
              alignItems: 'center'
            }}>
              <StyledButton
                variant='primary'
                id="submit"
                icon={<Send size={16} />}
                type="submit"
                disabled={isLoading || (!form.values.question && images.length === 0)}
                size={isMobile ? "sm" : "md"}
                style={{ 
                  flex: 1,
                  height: '36px',
                }}
              >
                {t("askQuestion")}
              </StyledButton>
              <div style={{ 
                minWidth: 'auto', 
                marginLeft: '8px',
                display: 'flex',
                alignItems: 'center',
                height: '36px'
              }}>
                <TokenCounter 
                  isError={notEnoughTokens}
                  tokenCount={tokenCount}
                  tokenBalance={tokenBalance}
                />
              </div>
            </Group>
          </Grid.Col>
        </Grid>
      </div>

      {/* Modals */}
      <LessonSelectorModal
        opened={isLessonModalOpen}
        onClose={() => setIsLessonModalOpen(false)}
        userLessons={userLessons}
        selectedLesson={form.values.course}
        onLessonSelect={handleLessonSelect}
        t={t}
      />

      {/* Add the language selector modal */}
      <Modal
        opened={isLanguageModalOpen}
        onClose={() => setIsLanguageModalOpen(false)}
        title={t("selectLanguage")}
        size="sm"
        styles={{
          title: {
            fontWeight: 600,
            fontSize: '1rem',
            color: '#40286C'
          },
          header: {
            padding: '1rem',
            marginBottom: 0
          },
          body: {
            padding: 0
          },
          close: {
            width: '24px',
            height: '24px',
            '& svg': {
              width: '14px',
              height: '14px'
            }
          }
        }}
      >
        <Stack spacing={0}>
          {languageOptions.map((language) => (
            <Button
              key={language.value}
              variant="subtle"
              onClick={() => handleLanguageSelect(language.value)}
              fullWidth
              styles={{
                root: {
                  height: '2.5rem',
                  backgroundColor: form.values.selectedLanguage === language.value ? '#40286C' : '#f8f9fa',
                  color: form.values.selectedLanguage === language.value ? 'white' : '#40286C',
                  '&:hover': {
                    backgroundColor: form.values.selectedLanguage === language.value ? '#40286C' : '#e9ecef',
                  },
                  borderRadius: 0,
                  fontSize: '0.875rem',
                  transition: 'all 0.2s ease',
                  padding: '0 1rem',
                }
              }}
            >
              {language.label}
            </Button>
          ))}
        </Stack>
      </Modal>

      {/* Add the CourseCreationModal component at the end of the form, before the closing tag */}
      <CourseCreationModal 
        t={t} 
        opened={isCourseCreationModalVisible} 
        setOpened={setIsCourseCreationModalVisible} 
      />

<SingleStepTutorial
        isVisible={showTutorial}
        onClose={handleTutorialClose}
        toolType={toolType}
        t={t}
        steps={toolType === 'sidebar' ? sidebarTutorialSteps : vtTutorialSteps}
        currentStep={currentTutorialStep}
        onStepChange={handleTutorialStepChange}
      />
      
      {/* Note Creation Confirmation Modal */}
      <Modal
        opened={isNoteConfirmModalOpen}
        onClose={() => setIsNoteConfirmModalOpen(false)}
        title={t("virtualTeacher.createNote.confirmTitle")}
        size="sm"
        styles={{
          title: {
            fontWeight: 600,
            fontSize: '1rem',
            color: '#40286C'
          }
        }}
      >
        <Stack>
          <Text>{t("virtualTeacher.createNote.confirmMessage")}</Text>
          <Group position="right" mt="md">
            <Button 
              variant="outline" 
              onClick={() => setIsNoteConfirmModalOpen(false)}
              color="red"
            >
              {t("close")}
            </Button>
            <Button 
              onClick={handleNoteCreation}
              styles={{
                root: {
                  backgroundColor: '#40286C',
                  '&:hover': {
                    backgroundColor: '#40286C',
                    opacity: 0.9
                  }
                }
              }}
            >
              {t("yes")}
            </Button>
          </Group>
        </Stack>
      </Modal>

      {/* Exam Creation Confirmation Modal */}
      <Modal
        opened={isExamConfirmModalOpen}
        onClose={() => setIsExamConfirmModalOpen(false)}
        title={t("virtualTeacher.createExam.confirmTitle")}
        size="sm"
        styles={{
          title: {
            fontWeight: 600,
            fontSize: '1rem',
            color: '#40286C'
          }
        }}
      >
        <Stack>
          <Text>{t("virtualTeacher.createExam.confirmMessage")}</Text>
          <Group position="right" mt="md">
            <Button 
              variant="outline" 
              onClick={() => setIsExamConfirmModalOpen(false)}
              color="red"
            >
              {t("close")}
            </Button>
            <Button 
              onClick={handleExamCreation}
              styles={{
                root: {
                  backgroundColor: '#40286C',
                  '&:hover': {
                    backgroundColor: '#40286C',
                    opacity: 0.9
                  }
                }
              }}
            >
              {t("yes")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </form>
  );
}

export default VirtualTeacher;