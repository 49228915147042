// toolDetector.js

/**
 * Detects if the provided text contains any keywords that are mapped to a tool.
 * @param {string} text - The text to check.
 * @param {object} keywordMap - An object where keys are tool identifiers and values are arrays of keywords.
 * @returns {string|null} - Returns the tool identifier if a keyword is found, otherwise null.
 */
export const detectToolUsage = (text = "", keywordMap) => {
  const lowerText = text.toLowerCase();
  for (const [tool, keywords] of Object.entries(keywordMap)) {
    for (const keyword of keywords) {
      if (lowerText.includes(keyword.toLowerCase())) {
        return tool;
      }
    }
  }
  return null;
};

// Default keyword mapping for tool detection.
export const defaultToolKeywords = {
  summarize: [
    "summarize",
    "summary",
    "summarisation",
    "summarize text",
    "make a summary",
    "özetle",
    "özet",
    "özeti çıkar",
    "özet çıkart"
  ],
  paraphrase: [
    "paraphrase",
    "paraphraser",
    "reword",
    "rewrite",
    "reword text",
    "paraphrase et",
    "parafraz",
    "parafraz et",
    "yeniden ifade et",
    "cümleyi yeniden düzenle",
    "paraphrase yap"
  ],
  article: [
    "article",
    "write article",
    "article writer",
    "compose article",
    "create article",
    "makale yaz",
    "makale oluştur",
    "makale yazısı",
    "makale hazırla"
  ],
  originalize: [
    "originalize",
    "originalizer",
    "make original",
    "make unique",
    "originalize text",
    "özgünleştir",
    "özgünleştirici",
    "orijinal hale getir",
    "orijinalleştir"
  ],
  note: [
    "create note",
    "make note",
    "take note",
    "note taking",
    "note oluştur",
    "not oluştur",
    "ders notu oluştur",
    "not al",
    "not ekle"
  ],
  lesson: [
    "create lesson",
    "make lesson",
    "build lesson",
    "generate lesson",
    "lesson oluştur",
    "ders oluştur",
    "ders planı oluştur",
    "ders düzenle",
    "ders oluşturucu"
  ],
  exam: [
    "create exam",
    "make exam",
    "compose exam",
    "build exam",
    "generate exam",
    "exam oluştur",
    "sınav oluştur",
    "sınav oluşturucu",
    "sınav yap",
    "test oluştur",
    "test hazırla"
  ]
};

// Mapping for tool display names.
export const toolDisplayNames = {
  summarize: { en: "Summarizer", tr: "Özetleyici" },
  paraphrase: { en: "Paraphraser", tr: "Parafraz" },
  article: { en: "Article Forger", tr: "Makale Oluşturucu" },
  originalize: { en: "Originalizer", tr: "Özgünleştirici" },
  // These three keys will be redirected to virtualTeacher.
  note: { en: "Note Creator", tr: "Not Oluşturucu" },
  lesson: { en: "Lesson Creator", tr: "Ders Oluşturucu" },
  exam: { en: "Exam Creator", tr: "Sınav Oluşturucu" },
  // Virtual Teacher mapping.
  virtualTeacher: { en: "Virtual Teacher", tr: "Sanal Öğretmen" }
};
