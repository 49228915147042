import { useState, useEffect } from 'react';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { sanitizeInput } from '../../utils/sanitizeInput';
import { useNotifications } from '../../context/NotificationContext';
import InputMask from 'react-input-mask';
import OTPVerification from '../../components/common/OTPVerification';
import AydinlatmaMetni from '../../components/AydinlatmaMetni';
import GizlilikMetni from '../../components/GizlilikMetni';
import UyelikSozlesmesi from '../../components/UyelikSozlesmesi';
import { useRegisterUserMutation, useSendOTPMutation } from '../../redux/api/authApiSlice';
import { Input, PasswordInput } from '../../components/common/Input';
import { Modal } from '@mantine/core';
import { translateError } from '../../utils/translateError';
import { shouldShowAppleSignIn } from '../../utils/platformDetect';

const disposableEmails = [
  'tempmail.com',
  'temp-mail.org',
  'tempm.com',
  'tmpmail.org',
  'guerrillamail.com',
  'guerrillamail.net',
  'guerrillamail.biz',
  'sharklasers.com',
  'throwawaymail.com',
  'yopmail.com',
  'yopmail.fr',
  'yopmail.net',
  'cool.fr.nf',
  'jetable.org',
  'nospam.ze.tc',
  'nomail.xl.cx',
  'mega.zik.dj',
  'speed.1s.fr',
  'courriel.fr.nf',
  'moncourrier.fr.nf',
  'monemail.fr.nf',
  'monmail.fr.nf',
  '10minutemail.com',
  '10minutemail.net',
  'mintemail.com',
  'mailinator.com',
  'mailinator.net',
  'mailinator2.com',
  'trashmail.net',
  'trashmail.com',
  'wegwerfemail.de',
  'einrot.de',
  'protonmail.com',
  'tutanota.com',
  'cock.li'
];

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [userIdForOtp, setUserIdForOtp] = useState('');
  const [registerUser] = useRegisterUserMutation();
  const [sendOTP] = useSendOTPMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState(null);
  
  const [openedAydinlatmaMetni, setOpenedAydinlatmaMetni] = useState(false);
  const [openedGizlilikMetni, setOpenedGizlilikMetni] = useState(false);
  const [openedUyelikSozlesmesi, setOpenedUyelikSozlesmesi] = useState(false);
  const [otpToken, setOtpToken] = useState('');
  const [isAppleSupported, setIsAppleSupported] = useState(false);
  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      agreeToTerms: false,
    },
    validate: {
      name: (value) => {
        if (!value) return t('register.errors.nameRequired');
        if (value.length < 2) return t('register.errors.nameTooShort');
        if (!/^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]+$/.test(value)) return t('register.errors.nameInvalidChars');
        return null;
      },
      email: (value) => {
        if (!value) return t('register.errors.emailRequired');
        if (!/^\S+@\S+\.\S+$/.test(value)) return t('register.errors.invalidEmail');
        if (disposableEmails.some(domain => value.toLowerCase().includes(domain))) {
          return t('register.errors.disposableEmail');
        }
        return null;
      },
      password: (value) => {
        if (!value) return t('register.errors.passwordRequired');
        if (value.length < 8) return t('register.errors.passwordTooShort');
        if (!/(?=.*[A-Z])/.test(value)) return t('register.errors.passwordUppercase');
        if (!/(?=.*[a-z])/.test(value)) return t('register.errors.passwordLowercase');
        if (!/(?=.*\d)/.test(value)) return t('register.errors.passwordNumber');
        if (!/(?=.*[@$!%*#?&])/.test(value)) return t('register.errors.passwordSpecial');
        return null;
      },
      confirmPassword: (value, values) =>
        value === values.password ? null : t('register.errors.passwordMismatch'),
      phoneNumber: (value) => {
        if (!value) return t('register.errors.phoneNumberRequired');
        if (value.length !== 17) return t('register.errors.invalidPhoneNumberLength');
        if (!value.startsWith('+90')) return t('register.errors.invalidPhoneNumberPrefix');
        return /^\+90\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/.test(value)
          ? null
          : t('register.errors.invalidPhoneNumber');
      },
      agreeToTerms: (value) =>
        value ? null : t('register.errors.agreeToTerms'),
    },
  });

  // Rate limiting check
  const checkRateLimit = () => {
    const MAX_ATTEMPTS = 10;
    const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes

    if (lastAttemptTime && Date.now() - lastAttemptTime < TIMEOUT_DURATION) {
      if (attemptCount >= MAX_ATTEMPTS) {
        const remainingTime = Math.ceil((TIMEOUT_DURATION - (Date.now() - lastAttemptTime)) / 1000 / 60);
        throw new Error(t('register.errors.tooManyAttempts', { minutes: remainingTime }));
      }
    } else {
      setAttemptCount(0);
    }
  };

  const handleSubmit = async (values, event) => {
    event.preventDefault();
    if (isSubmitting) return;

    try {
      checkRateLimit();
      if (!form.validate()) return; 
      setIsSubmitting(true);
      setAttemptCount(prev => prev + 1);
      setLastAttemptTime(Date.now());

      const sanitizedValues = sanitizeInput(values);
      const resultAction = await registerUser(sanitizedValues).unwrap();
      console.log(resultAction, "resultAction")
      if (resultAction?.user?._id) {
        console.log(resultAction, "resultAction")
        setUserIdForOtp(resultAction.user._id);
        setShowOtpModal(true);
        setOtpToken(resultAction.otpToken);
        
        try {
          await sendOTP({ userId: resultAction.user._id, otpToken: resultAction.otpToken }).unwrap();
          addNotification({
            id: Date.now(),
            message: t('register.otpSent'),
            type: 'success',
          });
        } catch (otpError) {
          addNotification({
            id: Date.now(),
            message: translateError(otpError, t),
            type: 'error',
          });
        }
      } else {
        throw new Error(t('register.errors.invalidResponse'));
      }
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: translateError(error, t),
        type: 'error',
      });

      if (error?.data?.code === 'EMAIL_TAKEN') {
        form.setFieldError('email', t('errors.EMAIL_TAKEN'));
      } else if (error?.data?.code === 'PHONE_TAKEN') {
        form.setFieldError('phoneNumber', t('errors.PHONE_TAKEN'));
      } else if (error?.data?.code === 'DISPOSABLE_EMAIL') {
        form.setFieldError('email', t('errors.DISPOSABLE_EMAIL'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Check if the device is iOS or Mac
  useEffect(() => {
    setIsAppleSupported(shouldShowAppleSignIn());
  }, []);

  const handleGoogleLogin = () => {
    window.location.href = `https://easyegitim.com/app/v1/auth/google`;
  };

  const handleAppleLogin = () => {
    window.location.href = `https://easyegitim.com/app/v1/auth/apple`;
  };

  return (
    <div className="w-full space-y-4">
      <div className="text-center space-y-2">
        <h2 className="text-2xl font-bold text-gray-900">
          {t('login.welcome')}
        </h2>
        <p className="text-sm text-gray-500">
          {t('login.createAccount')}
        </p>
      </div>

      <button
        onClick={handleGoogleLogin}
        className="w-full flex items-center justify-center gap-2 px-4 py-2.5 border border-gray-300 rounded-xl hover:bg-gray-50 transition-all"
      >
        <img
          src="https://static.easyegitim.com/g-logo.png"
          alt="Google Logo"
          className="w-4 h-4"
        />
        {t('login.signUpWithGoogle')}
      </button>

      {/* Apple Login Button - Only shown for iOS/Mac users */}
      {isAppleSupported && (
        <button
          onClick={handleAppleLogin}
          className="w-full flex items-center justify-center gap-2 px-4 py-2.5 border border-gray-300 rounded-xl hover:bg-gray-50 transition-all"
        >
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5645 12.4595C17.5488 10.1304 19.3822 8.89189 19.4761 8.82609C18.3058 7.06899 16.4739 6.80634 15.825 6.78783C14.2168 6.6276 12.663 7.72464 11.8469 7.72464C11.0139 7.72464 9.74349 6.80634 8.39349 6.83255C6.64799 6.85876 5.01978 7.91304 4.1347 9.52174C2.31628 12.7944 3.67849 17.6609 5.41609 20.3768C6.28896 21.7043 7.30599 23.1913 8.65599 23.1399C9.9799 23.0822 10.4513 22.2406 12.033 22.2406C13.601 22.2406 14.0407 23.1399 15.4313 23.1072C16.8693 23.0822 17.7475 21.7623 18.5888 20.4232C19.5907 18.8913 20.0007 17.3855 20.0164 17.2841C19.9854 17.2725 17.5848 16.2937 17.5645 12.4595Z" fill="black"/>
            <path d="M15.0087 4.58782C15.7304 3.67722 16.2125 2.42028 16.0745 1.14502C15.0291 1.1897 13.7432 1.88741 12.9885 2.77029C12.3204 3.5435 11.7285 4.85288 11.8838 6.07107C13.0572 6.16521 14.253 5.48424 15.0087 4.58782Z" fill="black"/>
          </svg>
          {t('login.signUpWithApple', 'Sign up with Apple')}
        </button>
      )}

      <div className="relative py-2">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-200"></div>
        </div>
        <div className="relative flex justify-center text-xs">
          <span className="px-2 bg-white text-gray-500">
            {t('login.orContinueWith')}
          </span>
        </div>
      </div>

      <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-4">
        <div className="space-y-3">
          <Input
            label={t('register.name')}
            placeholder={t('register.placeholders.name')}
            error={form.errors.name}
            {...form.getInputProps('name')}
          />

          <Input
            label={t('register.email')}
            placeholder={t('register.placeholders.email')}
            error={form.errors.email}
            {...form.getInputProps('email')}
          />

          <PasswordInput
            label={t('register.password')}
            placeholder={t('register.placeholders.password')}
            error={form.errors.password}
            {...form.getInputProps('password')}
          />

          <PasswordInput
            label={t('register.confirmPassword')}
            placeholder={t('register.placeholders.confirmPassword')}
            error={form.errors.confirmPassword}
            {...form.getInputProps('confirmPassword')}
          />

          <InputMask
            mask="+\9\0 999 999 99 99"
            value={form.values.phoneNumber}
            onChange={(event) => form.setFieldValue('phoneNumber', event.target.value)}
          >
            {(inputProps) => (
              <Input
                {...inputProps}
                label={t('register.phoneNumber')}
                placeholder="+90 555 555 55 55"
                error={form.errors.phoneNumber}
              />
            )}
          </InputMask>
        </div>

        <div className="flex items-start py-1">
          <input
            type="checkbox"
            className="mt-0.5 h-4 w-4 rounded border-gray-300 text-black focus:ring-black"
            {...form.getInputProps('agreeToTerms', { type: 'checkbox' })}
          />
          <div className="ml-2 text-xs">
            <p className="text-gray-700">
              {t('register.agreeToTerms')}{' '}
              <button
                type="button"
                onClick={() => setOpenedGizlilikMetni(true)}
                className="text-blue-600 hover:text-blue-500 font-medium"
              >
                {t('register.privacyPolicy')}
              </button>
              {' ' + t('register.and') + ' '}
              <button
                type="button"
                onClick={() => setOpenedUyelikSozlesmesi(true)}
                className="text-blue-600 hover:text-blue-500 font-medium"
              >
                {t('register.uyelikSozlesmesi')}
              </button>
            </p>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full py-2.5 px-4 bg-[#40286c] text-white rounded-xl font-medium hover:bg-[#40286c]/90 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c] disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              <span>{t('register.registering')}</span>
            </div>
          ) : (
            t('register.registerButton')
          )}
        </button>
      </form>

      <p className="text-center text-xs text-gray-600">
        {t('register.alreadyHaveAccount')}{' '}
        <Link
          to="/auth/login"
          className="font-medium text-blue-600 hover:text-blue-500"
        >
          {t('register.login')}
        </Link>
      </p>

      {/* Modals */}
      <Modal
        opened={openedAydinlatmaMetni}
        onClose={() => setOpenedAydinlatmaMetni(false)}
        title={t('register.customerAydinlatmaMetni')}
      >
        <AydinlatmaMetni />
      </Modal>

      <Modal
        opened={openedGizlilikMetni}
        onClose={() => setOpenedGizlilikMetni(false)}
        title={t('register.privacyPolicy')}
      >
        <GizlilikMetni />
      </Modal>

      <Modal
        opened={openedUyelikSozlesmesi}
        onClose={() => setOpenedUyelikSozlesmesi(false)}
        title={t('register.uyelikSozlesmesi')}
      >
        <UyelikSozlesmesi />
      </Modal>

      {showOtpModal && (
        <OTPVerification
          userId={userIdForOtp}
          otpToken={otpToken}
          onSuccess={async () => {
            setShowOtpModal(false);
            // Delay navigation if desired; after OTP verification, you can mark the user as verified in your state.
            await new Promise((resolve) => setTimeout(resolve, 3000));
            navigate('/tools/virtualTeacher');
          }}
          opened={showOtpModal}
          onClose={() => setShowOtpModal(false)}
        />
      )}
    </div>
  );
};

export default Register;
