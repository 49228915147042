// src/components/VirtualTeacher/VTChat.jsx

import React, { useRef, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import {
  FileText,
  Copy,
  Download,
  ChevronDown,
  ChevronRight,
  Languages,
  Book,
  Calculator,
  History
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { Modal } from '@mantine/core';
import loadingAnimation from '../assets/chatLoading.json';
import { fixFileName } from '../../utils/fileUtils';
// --- Begin Markdown & CodeBlock Setup ---

// Prism is used for syntax highlighting in code blocks.
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';

// For math rendering
import ReactKatex from 'react-katex';
import 'katex/dist/katex.min.css';
import Lottie from 'lottie-react';

// --- 1. Define CodeBlock BEFORE Markdown components ---
const CodeBlock = ({ language, value, inline, ...props }) => {
  if (inline) {
    return <code className="inline-code">{value}</code>;
  }
  const lines = value.split('\n');
  const languageClass = language ? `language-${language}` : '';
  return (
    <div className="code-block-wrapper" style={{ position: 'relative', margin: '1em 0' }}>
      {language && (
        <div className="code-language-indicator" style={{ position: 'absolute', top: 4, right: 8, fontSize: '0.75em', opacity: 0.6 }}>
          {language}
        </div>
      )}
      <div className="code-block-content" style={{ display: 'flex' }}>
        <div className="line-numbers" style={{ textAlign: 'right', paddingRight: '0.5em', userSelect: 'none', color: '#888' }}>
          {lines.map((_, i) => (
            <span key={i} className="line-number">{i + 1}</span>
          ))}
        </div>
        <pre className={`${languageClass} code-block`} style={{ margin: 0, overflowX: 'auto' }}>
          <code className={languageClass}>{value}</code>
        </pre>
      </div>
    </div>
  );
};

// --- 2. (Optional) Create a SafeKatex component for error handling ---
const SafeKatex = ({ children, display = false }) => {
  try {
    return (
      <ReactKatex
        throwOnError={false}
        display={display}
        settings={{
          strict: false,
          trust: true,
          macros: {
            "\\f": "f",
            "\\dx": "\\,dx",
            "\\implies": "\\quad\\Rightarrow\\quad",
            "\\quad": "\\;\\;",
            "\\Adim": "\\text{Adım}"
          },
          minRuleThickness: 0.04,
          maxSize: 20,
          maxExpand: 1000
        }}
      >
        {children}
      </ReactKatex>
    );
  } catch (error) {
    console.error('KaTeX rendering error:', error);
    return <code>{children}</code>;
  }
};const MarkdownComponents = {
  code({ node, inline, className, children, ...props }) {
    // The className is usually like "language-math" when math is intended.
    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : '';
    if (language === 'math') {
      return (
        <div className="math-block">
          <ReactKatex>{children}</ReactKatex>
        </div>
      );
    }
    return (
      <CodeBlock
        language={language}
        value={children.join('')}
        inline={inline}
        {...props}
      />
    );
  },
  p: (props) => <p>{props.children}</p>,
  h1: ({ children }) => (
    <h1 style={{ margin: '1em 0 0.5em', fontSize: '1.5em', color: '#2c3e50' }}>
      {children}
    </h1>
  ),
  h2: ({ children }) => <h2 style={{ margin: '0.8em 0 0.4em' }}>{children}</h2>,
  li: ({ children }) => (
    <li
      style={{
        marginBottom: '0.5em',
        lineHeight: '1.6',
        listStyleType: 'none',
        position: 'relative',
        paddingLeft: '2em'
      }}
    >
      {children}
    </li>
  ),
  h3: ({ children }) => (
    <h3
      style={{
        fontSize: '1.2em',
        color: '#2c3e50',
        marginTop: '1.5rem',
        marginBottom: '0.75rem',
        fontWeight: 600
      }}
    >
      {children}
    </h3>
  )
};

// --- 4. (If needed) Create a CustomMarkdownComponents object ---
// Note: If you rely on file-link logic (as in Chat.jsx) and need access to the messages,
// you might consider moving this definition inside the VTChat component so that `messages` is in scope.
const CustomMarkdownComponents = {
  ...MarkdownComponents,
  a: ({ node, children, href, ...props }) => (
    <a
      href={href || "#"}
      style={{
        color: '#228be6',
        textDecoration: 'none',
        cursor: href ? 'pointer' : 'default'
      }}
      {...props}
    >
      {children}
    </a>
  )
};

// -------------------------
// EmptyState Component (unchanged)

const EmptyState = ({ onTemplateClick, t }) => {
  const categories = [
    {
      title: t('vtchat.emptyState.title'),
      icon: <Languages className="w-7 h-7" />,
      question: t('vtchat.emptyState.questions.language'),
      bgColor: "bg-blue-50",
      iconColor: "text-blue-600"
    },
    {
      title: t('vtchat.emptyState.biology'),
      icon: <Book className="w-7 h-7" />,
      question: t('vtchat.emptyState.questions.biology'),
      bgColor: "bg-green-50",
      iconColor: "text-green-600"
    },
    {
      title: t('vtchat.emptyState.math'),
      icon: <Calculator className="w-7 h-7" />,
      question: t('vtchat.emptyState.questions.math'),
      bgColor: "bg-purple-50",
      iconColor: "text-purple-600"
    },
    {
      title: t('vtchat.emptyState.history'),
      icon: <History className="w-7 h-7" />,
      question: t('vtchat.emptyState.questions.history'),
      bgColor: "bg-orange-50",
      iconColor: "text-orange-600"
    }
  ];

  return (
    <div className="flex flex-col items-center justify-center h-full p-4 max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-3">
        {t('vtchat.emptyState.howCanIHelp')}
      </h2>
      <p className="text-gray-500 mb-8 text-center">
        {t('vtchat.emptyState.subtitle')}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        {categories.map((cat, idx) => (
          <div
            key={idx}
            className={`flex items-center p-4 ${cat.bgColor} rounded-xl cursor-pointer hover:scale-102 transition-all duration-200 border border-transparent hover:border-gray-200`}
            onClick={() => onTemplateClick(cat.question)}
          >
            <div className={`${cat.iconColor} mr-4`}>
              {cat.icon}
            </div>
            <div className="flex flex-col">
              <span className="text-lg font-medium text-gray-800">{cat.title}</span>
              <span className="text-sm text-gray-500 mt-1">{cat.question}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// -------------------------
// FileAttachment Component (unchanged)

const FileAttachment = ({ files, t }) => {
  const [isExpanded, setIsExpanded] = useState(false);


  if (!files?.length) return null;

  return (
    <div className="mt-2 text-sm text-gray-500">
      <button
        className="flex items-center gap-1 hover:text-gray-700"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >
        {isExpanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
        <span>{files.length} {t('chat.attachedFiles')}</span>
      </button>

      {isExpanded && (
        <div className="mt-2 ml-4 space-y-1">
          {files.map(file => (
            <div key={file.name || file} className="flex items-center gap-2 text-gray-600">
              <FileText className="w-4 h-4" />
              <span style={{ fontSize: '12px' }}>{fixFileName(file.name || file)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// -------------------------
// ChatMessage Component with enhanced markdown rendering

const ChatMessage = ({ message, onCopy, onImageDownload, isMobile, onThumbnailClick, t }) => {
  const isUser = message.sender === 'user';

  console.log(message, "message")
  // For image messages, build an array of image URLs.
  let imageUrls = [];
  if (message.questionType === 'img') {
    if (message.localImageUrls && message.localImageUrls.length > 0) {
      imageUrls = [...message.localImageUrls];
    } else if (message.files && message.files.length > 0) {
      imageUrls = message.files
        .map(file => file.s3URL?.urls?.large || message.url)
        .filter(Boolean);
    }
  }

  // Use Prism to highlight code after rendering.
  useEffect(() => {
    Prism.highlightAll();
  }, [message.text]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`flex w-full items-start mb-4 ${isUser ? 'justify-end' : 'justify-start'}`}
    >
      {!isUser && !isMobile && (
        <div className="flex-shrink-0 mr-3">
          <img
            src="https://static.easyegitim.com/mascot.webp"
            alt="Assistant"
            className="w-8 h-8 rounded-full shadow-lg"
          />
        

        </div>

      )}

      <div className={`chat__message relative max-w-3xl ${isUser ? 'bg-purple-900 text-white' : 'bg-white'} p-4 rounded-lg shadow-sm`}>
      

        <div className="chat__message-content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={CustomMarkdownComponents}
          >
            {message.text}
          </ReactMarkdown>
          {!isUser && message.isLoading && (
        
        <Lottie animationData={loadingAnimation} loop={true}  style={{ width: 100, height: 40 }}  />
   
  )}
        </div>
        {imageUrls.length > 0 && (
          <div
            className="chat-image-grid"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              marginBottom: '12px'
            }}
          >
            {imageUrls.map((url, idx) => (
              <div
                key={idx}
                style={{
                  width: '100px',
                  height: '100px',
                  overflow: 'hidden',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
                onClick={() => onThumbnailClick(url)}
              >
                <img
                  src={url}
                  alt={`Uploaded content ${idx}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
        )}

        {!isUser && !message.isLoading && (
          <button
            onClick={() => onCopy(message.text)}
            className="absolute top-2 right-2 p-1.5 rounded-full hover:bg-gray-100 transition-colors opacity-0 group-hover:opacity-100"
          >
            <Copy className="w-4 h-4 text-gray-500" />
          </button>
        )}

        <FileAttachment files={message.files} t={t} />
      </div>

      {isUser && !isMobile && (
        <div className="flex-shrink-0 ml-3">
          <img
            src="https://static.easyegitim.com/easy_user_avatar.png"
            alt="User"
            className="w-8 h-8 rounded-full shadow-lg"
          />
        </div>
      )}
    </motion.div>
  );
};

// -------------------------
// VTChat Component

const VTChat = ({ messages, loading, onCopy, onImageDownload, t, onTemplateClick, scrollContainerRef  }) => {
  console.log(messages, "messages", loading, "loading")
  const bottomRef = useRef(null);
  const isMobile = window.innerWidth < 768;

  // State for modal that shows larger image preview
  const [openedImageModal, setOpenedImageModal] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);

  const handleThumbnailClick = (url) => {
    setCurrentImageUrl(url);
    setOpenedImageModal(true);
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const displayMessages = useMemo(() => {
    const msgs = [...messages];
    if (loading) {
      msgs.push({
        id: 'loading-message',
        sender: 'assistant',
        isLoading: true,
        text: ''
      });
    }
    return msgs;
  }, [messages, loading]);

  return (
    <>
      <div className="relative h-full bg-white">
        <div
          ref={scrollContainerRef}
          className="absolute inset-0 overflow-y-auto flex flex-col p-0 md:p-6 scroll-smooth thinScroll-container"
        >
          <div className="flex-1 flex flex-col justify-start">
            {messages.length === 0 && !loading ? (
              <EmptyState onTemplateClick={onTemplateClick} t={t} />
            ) : (
              displayMessages.map(message => (
                <ChatMessage
                key={message.id || message.created_at}  // now uses message.id (if exists) before falling back to created_at
                  message={message}
                  onCopy={onCopy}
                  onImageDownload={onImageDownload}
                  isMobile={isMobile}
                  onThumbnailClick={handleThumbnailClick}
                  t={t}
                />
              ))
            )}

          </div>
          <div ref={bottomRef} />
        </div>
      </div>

      <Modal
        opened={openedImageModal}
        onClose={() => setOpenedImageModal(false)}
        centered
        withCloseButton
        size="xl"
        title="Image Preview"
      >
        {currentImageUrl && (
          <img src={currentImageUrl} alt="Large preview" style={{ width: '100%', height: 'auto' }} />
        )}
      </Modal>
    </>
  );
};

export default VTChat;
