import React from 'react';
import * as Sentry from "@sentry/react";
import { useEffect } from 'react';
import { AlertTriangle, RefreshCw, Home, ArrowLeft, Bug } from 'lucide-react';

const CustomErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errorInfo, setErrorInfo] = React.useState(null);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const handleError = (error, errorInfo) => {
    setError(error);
    setErrorInfo(errorInfo);
    setHasError(true);
    
    Sentry.withScope((scope) => {
      scope.setTag("error_boundary", "true");
      scope.setExtras({
        errorInfo,
        location: window.location.href,
        timestamp: new Date().toISOString()
      });
      Sentry.captureException(error);
    });
  };

  const handleReset = () => {
    setError(null);
    setErrorInfo(null);
    setHasError(false);
  };

  const goBack = () => {
    window.history.back();
  };

  const goHome = () => {
    window.location.href = '/';
  };

  if (hasError) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg w-full space-y-8 bg-white rounded-2xl shadow-xl p-8">
          <div className="text-center">
            <div className="relative inline-block">
              <div className="absolute inset-0 bg-red-100 rounded-full animate-ping opacity-75"></div>
              <AlertTriangle className="relative mx-auto h-16 w-16 text-red-500" />
            </div>
            
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Bir Hata Oluştu
            </h2>
            
            <p className="mt-2 text-base text-gray-600">
              Beklenmedik bir sorunla karşılaştık. Teknik ekibimiz bilgilendirildi.
            </p>
            
            <p className="mt-1 text-sm text-gray-500">
              Lütfen birkaç dakika içinde tekrar deneyiniz.
            </p>

            {error &&  import.meta.env.MODE === 'development' && (
              <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex items-center mb-2">
                  <Bug className="h-4 w-4 text-gray-500 mr-2" />
                  <span className="text-sm font-medium text-gray-700">Hata Detayları (Geliştirici Modu)</span>
                </div>
                <p className="text-sm text-gray-600 text-left font-mono break-all">
                  {error.toString()}
                </p>
                {errorInfo && (
                  <pre className="mt-2 text-xs text-gray-500 text-left overflow-auto max-h-32">
                    {JSON.stringify(errorInfo, null, 2)}
                  </pre>
                )}
              </div>
            )}
          </div>

          <div className="mt-8 space-y-3">
            <button
              onClick={handleReset}
              className="w-full flex items-center justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Tekrar Dene
            </button>

            <div className="grid grid-cols-2 gap-3">
              <button
                onClick={goBack}
                className="flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
              >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Geri Dön
              </button>

              <button
                onClick={goHome}
                className="flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
              >
                <Home className="h-4 w-4 mr-2" />
                Ana Sayfa
              </button>
            </div>

            <button
              onClick={() => window.location.reload()}
              className="w-full flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Sayfayı Yenile
            </button>
          </div>

          <div className="mt-6 text-center">
            <p className="text-xs text-gray-500">
              Hata Kodu: {error ? error.name || 'Unknown' : 'Unknown'}
              <br />
              Bu sorun devam ederse, lütfen destek ekibimizle iletişime geçin:
              <br />
              <a href="mailto:support@easyegitim.com" className="text-blue-600 hover:text-blue-500">
                support@easyegitim.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return children;
};

export default CustomErrorBoundary;