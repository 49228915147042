import { useEffect, useState, useRef } from "react";
import {
  Grid,
  Group,
  Button,
  Stack,
  Textarea,
  FileButton,
  Text,
  Modal
} from "@mantine/core";
import { useForm } from "@mantine/form";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import TokenCounter from "../../utils/tokenCounter";

import Selector from "../../components/common/Selector";

import Chat from "../../components/common/Chat";
import CourseCreationModal from "../../components/common/CourseCreationModal";
import { languageOptions, getAssistantOptions } from "../../utils/constants";
import { useGetAnswerMutation } from '../../redux/api/questionAnswerApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { appendToHistory,  clearSelectedHistoryId } from '../../redux/slices/toolHistoriesSlice';
import { useFetchHistoryDetailsQuery } from '../../redux/api/toolHistoriesApiSlice';
import { useFetchLessonsQuery } from '../../redux/api/lessonApiSlice';
import { useNotifications } from '../../context/NotificationContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes, faArrowCircleUp, faBook  } from "@fortawesome/free-solid-svg-icons";
import { setTokens } from '../../redux/slices/authSlice';
import { encode } from 'gpt-tokenizer'; // Ensure your tokenizer is ready
import { useMediaQuery } from '@mantine/hooks';
import * as Sentry from "@sentry/react";
import LessonSelectorModal from "../../components/common/LessonSelectorModal";
import { 
  Send,
  Book, 
  FileText, 
  Camera, 
  Upload, 
  X, 
 
  GraduationCap,
  FileUp,
  Bot,
  LanguagesIcon,
  Biohazard
} from 'lucide-react';
import StyledButton from "../../components/common/StyledButton";
import SingleStepTutorial from "../../components/common/Tutorial";
import { detectToolUsage, defaultToolKeywords, toolDisplayNames } from '../../components/common/toolDetector';
import { fixFileName } from '../../utils/fileUtils';

// Add this function after the other imports
const calculateQuestionTokens = (question = "", selectedLesson = null, hasImage = false, documentCount = 0) => {
  console.log(question, selectedLesson, hasImage, documentCount, `calculateQuestionTokens`)
  // Start with a base cost of 0
  let cost = 0;

  // If a valid lesson is selected, set the base cost to 20
  if (selectedLesson && selectedLesson._id !== 'noLesson' && selectedLesson.id !== 'noLesson') {
    cost = 20;
  }

  // Always add image cost if there's an image uploaded
  if (hasImage) {
    cost += 5;
  }
  
  // Add 5 tokens for each document
  if (documentCount > 0) {
    cost += documentCount * 5;
  }

  return cost;
};

function QuestionAnswer() {

  const LAST_LESSON_KEY = 'lastSelectedLessonQuestionAnswer';
  const currentTool = useSelector(state => state.toolHistories.currentTool);
  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);

  const { addNotification } = useNotifications();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getAnswer, { isLoading, error }] = useGetAnswerMutation();
  const selectedHistoryId = useSelector(state => state.toolHistories.selectedHistoryId);
  const [questionMode, setQuestionMode] = useState("ask");
  const { data: historyDetails, isError } = useFetchHistoryDetailsQuery(
    { serviceType: 'questionAnswer', id: selectedHistoryId },
    { skip: !selectedHistoryId || currentTool !== 'questionAnswer' || questionMode === 'next' }
  );
  const { data: lessons, error: lessonsError, isLoading: lessonsLoading } = useFetchLessonsQuery();
  const assistantOptions = getAssistantOptions(t);
  const lessonsForUser = lessons?.map((lesson) => ({ _id: lesson._id, courseName: lesson.courseName })) || [];
  const [tokenCount, setTokenCount] = useState(0); // Track the calculated token count
  const [messages, setMessages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [images, setImages] = useState([]);

  const [isCourseCreationModalVisible, setIsCourseCreationModalVisible] = useState(false);
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(null);
  const resetRef = useRef(null);
  const resetRefSecond = useRef(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  
  const form = useForm({
    initialValues: {
      selectedAssistant: assistantOptions[1].value,
      selectedLanguage: languageOptions[0].value,
      question: "",
      course: localStorage.getItem(LAST_LESSON_KEY) || "noLesson",
    },
    validate: {
      question: (value) => {
        if (!value && images.length === 0) return t("validation.question.required");
        if (value && value.length < 2) return t("validation.question.tooShort");
        if (value && value.length > 100000) return t("validation.question.tooLong");
        return null;
      },
      selectedAssistant: (value, values) => {
        if (!values.course && !value) return t("validation.selectedAssistant.required");
        return null;
      },
      selectedLanguage: (value) => value ? null : t("validation.selectedLanguage.required"),
   
    },
  });

  const [isLessonModalOpen, setIsLessonModalOpen] = useState(false);

// Add this function to handle lesson selection
const handleLessonSelect = (value) => {
  setSelectedLesson(value);
  
  // Store both the ID and the model type
  form.setFieldValue("course", value._id);
  localStorage.setItem(LAST_LESSON_KEY, value._id);
  localStorage.setItem(`${LAST_LESSON_KEY}_model`, value.model);
  localStorage.setItem(`${LAST_LESSON_KEY}course`, value.courseName);
};
const tutorialSeen = localStorage.getItem(`tutorial_questionAnswer`);

  useEffect(() => {
    if (!tutorialSeen) {
      setShowTutorial(true);
    }

    if (historyDetails && questionMode !== 'next') {
 
      form.setFieldValue('selectedAssistant', historyDetails.assistantType);
      form.setFieldValue('selectedLanguage', historyDetails.language);
  
      if (historyDetails.lesson && lessonsForUser) {
        const lesson = lessonsForUser.find((lesson) => lesson.value === historyDetails.lesson);
        if (lesson) form.setFieldValue('course', lesson.value);
      }
  
      const formMessages = [
        { sender: "user", text: historyDetails.question, files: historyDetails.files, questionType: historyDetails.questionType },
        ...historyDetails.result
      ];
  
      setMessages(formMessages);
    } else {
      setQuestionMode('ask');
    }
  }, [ selectedHistoryId, historyDetails, questionMode, t, dispatch]);
  

  useEffect(() => {
  
    if (localStorage.getItem(LAST_LESSON_KEY)) {
      
    setSelectedLesson(
      {
        id: localStorage.getItem(LAST_LESSON_KEY),
        courseName: localStorage.getItem(`${LAST_LESSON_KEY}course`)
      }
    );
    }
    if (!selectedHistoryId) {
  
      setMessages([]);
      form.reset();
      return;
    }
   
  }, [selectedHistoryId]);

  const handleSubmit = async (values) => {
    try {
      if (!values.course) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t("validation.course.required"),
          title: t("selectLessonFirst"),
          icon: <FontAwesomeIcon icon={faBook} />,
          autoClose: 5000
        });
        setIsLessonModalOpen(true);
        return;
      }
      if (tokenCount > tokenBalance) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.tokenBalance.insufficient'),
        });
        setNotEnoughTokens(true);
        setTimeout(() => {
          setNotEnoughTokens(false);
        }, 3000);
        return;
      }
      let detectedTool = detectToolUsage(values.question, defaultToolKeywords);
    if (detectedTool) {
      // If the detected tool is one of these keys, override it to use Virtual Teacher.
      if (["note", "lesson", "exam"].includes(detectedTool)) {
        detectedTool = "virtualTeacher";
      }
      
      // Determine the current language (assuming your i18n setup provides this, e.g., via a "lang" key).
      const currentLang = t("lang"); // e.g., "en" or "tr"
      const toolDisplayName =
        toolDisplayNames[detectedTool] && toolDisplayNames[detectedTool][currentLang]
          ? toolDisplayNames[detectedTool][currentLang]
          : detectedTool;
      
      addNotification({
        id: new Date().getTime(),
        type: 'warning',
        message: t('notifications.differentToolDetected.message', { tool: toolDisplayName }),
        title: t('notifications.differentToolDetected.title'),
        autoClose: 5000
      });
      return; // Stop further processing if a tool-specific keyword is detected.
    }

      // Clear any existing messages before making the request
      setMessages([]);
      
      const formData = new FormData();
      formData.append('question', DOMPurify.sanitize(values.question));
      formData.append('selectedAssistant', DOMPurify.sanitize(values.selectedAssistant));
      formData.append('selectedLanguage', DOMPurify.sanitize(values.selectedLanguage));
      formData.append('course', DOMPurify.sanitize(values.course));

      let frontEndQuestion = { sender: "user", text: `${values.question}` };
      if (documents.length > 0) {
        frontEndQuestion.files = [];
        frontEndQuestion.questionType = "file";
      }

      documents.forEach((file) => {
        frontEndQuestion.files.push({ name: fixFileName(file.name) });
        formData.append('files', file);
      });
      
      if (images.length > 0) {
        const localUrl = URL.createObjectURL(images[0]);
        frontEndQuestion.localImageUrl = localUrl;
        formData.append('files', images[0]);
        formData.append('type', 'img');
        frontEndQuestion.questionType = "img";
      }

      // Add the user's message first
      setMessages([frontEndQuestion]);

      // Make the API call
      const result = await getAnswer(formData).unwrap();
      
      // Only update state if the request was successful
      if (result) {
        form.setFieldValue("question", "");
        dispatch(appendToHistory({ tool: 'questionAnswer', entry: result.usageData }));
        dispatch(setTokens({ tokenBalance: result.tokenBalance }));
        setMessages((prev) => [...prev, ...result.result]);
        setQuestionMode("next");
        
        // Clear files after successful submission
/*         setDocuments([]); */
        setImages([]);
        resetRef.current?.();
        resetRefSecond.current?.();
      }

    } catch (error) {
      // Don't show error notification on mobile if it's a network error
      if (!isMobile || (error?.status !== 'FETCH_ERROR' && error?.status !== 'PARSING_ERROR')) {
        Sentry.captureException(error);
        console.error("Error in handleSubmit:", error);
        
        const errorMessage = error?.data?.message || t('validationError');
        let notificationMessage = t('validationError');
        
        try {
          const parsedError = JSON.parse(errorMessage);
          if (parsedError.status === 'failed' && parsedError.last_error?.code === 'file_error') {
            notificationMessage = t('fileError', { message: parsedError.last_error.message });
          }
        } catch (parseError) {
          Sentry.captureException(parseError);
        }
          // istegiinz islenirken hata olustu
       /*  addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: notificationMessage,
        }); */
      }

      // Reset states on error
      setDocuments([]);
      setImages([]);
      setMessages([]);
      form.reset();
    }
  };



  const showCourseCreationModal = () => {
    setIsCourseCreationModalVisible(true);
  };

  const handleSetFiles = (files, type) => {
    if (!files || (Array.isArray(files) && files.length === 0)) {
      return; // Do nothing if no files were selected
    }
    if (type === "img") {
      setImages(files ? [files] : []); // Ensure it's always an array
      
      // Create a notification message
      const fileNames = fixFileName(files.name);
      
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: `${t('successfulyaddeddocuments')} ${fileNames}`,
      });
    } else {
      setDocuments(files);
      
      // Create a notification message that joins all file names
      const fileNames = files.map(file => fixFileName(file.name)).join(', ');
      
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: `${t('successfulyaddeddocuments')} ${fileNames}`,
      });
    }
  };
  


  const handleRemoveFile = (file, type) => {
    if (type === "img") {
      setImages([]);
    } else {
      setDocuments(documents.filter((doc) => doc !== file));
    }
  };



  useEffect(() => {
    const newTokenCount = calculateQuestionTokens(
      form.values.question,
      selectedLesson,
      images.length > 0,
      documents.length // Pass the document count
    );
    console.log(form.values.question, selectedLesson, images.length, documents.length, newTokenCount, `newTokenCount`)
    setTokenCount(newTokenCount);
  }, [selectedHistoryId, selectedLesson, images, documents]); // Add documents to dependency array
 
 
  const userLessons = [
    { _id: 'noLesson', courseName: t('tumdersler'), model: 'uzman' },

    ...lessonsForUser
  ];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      form.onSubmit((values) => handleSubmit(values))();
    }
  };

  const tutorialSteps = [
    {
      elementId: ['language-selector', 'course-selector'],
      title: t('tutorial.questionAnswer.language.title'),
      content: t('tutorial.questionAnswer.language.content'),
      position: 'center'
    },
    {
      title: t('tutorial.questionAnswer.courseDescription.title'),
      content: t('tutorial.questionAnswer.courseDescription.content'),
      position: 'center'
    },
    // Only show these steps on desktop
    ...(!isMobile ? [
      {
        elementId: 'lesson-creator',
        title: t('tutorial.questionAnswer.createCourse.title'),
        content: t('tutorial.questionAnswer.createCourse.content'),
        position: 'right'
      },
      {
        title: t('tutorial.questionAnswer.createCourseDescription.title'),
        content: t('tutorial.questionAnswer.createCourseDescription.content'),
        position: 'right'
      }
    ] : []),
    {
      elementId: ['image-uploader', 'question', 'submit'],
      title: t('tutorial.questionAnswer.askWithDocument.title'),
      content: t('tutorial.questionAnswer.askWithDocument.content'),
      position: 'right'
    },
  ];
  console.log(form.values.course , 'formvaluescourse',  selectedLesson?.courseName)

  // Add handler for tutorial navigation
  const handleTutorialNavigation = (step) => {
    setCurrentTutorialStep(step);
    
    // Handle modal states based on tutorial step
    if (step === 2) { // Course selector step
      setIsLessonModalOpen(true);
    } else if (step === 4) { // Course creation step
      setIsCourseCreationModalVisible(true);
    } else {
      // Close modals when not on their respective steps
      setIsLessonModalOpen(false);
      setIsCourseCreationModalVisible(false);
    }
  };

  // Add useEffect to handle initial lesson selection
  useEffect(() => {
    const savedLessonId = localStorage.getItem(LAST_LESSON_KEY);
    if (savedLessonId) {
      const savedLesson = userLessons.find(lesson => lesson._id === savedLessonId);
      if (savedLesson) {
        setSelectedLesson(savedLesson);
      } else {
        // If saved lesson not found in userLessons, default to noLesson
        const defaultLesson = userLessons[0]; // noLesson
        setSelectedLesson(defaultLesson);
        form.setFieldValue("course", defaultLesson._id);
        localStorage.setItem(LAST_LESSON_KEY, defaultLesson._id);
        localStorage.setItem(`${LAST_LESSON_KEY}_model`, defaultLesson.model);
        localStorage.setItem(`${LAST_LESSON_KEY}course`, defaultLesson.courseName);
      }
    }
  }, []);

  const resetChat = () => {
    setMessages([]);

    setImages([]);
    form.reset();
    setTokenCount(0); 
  };



  useEffect(() => {
    // Add the global event listener
    const handleNewChat = () => {
      resetChat();
    };
    window.addEventListener('newChat', handleNewChat);
    
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('newChat', handleNewChat);
    };
  }, []); // empty dependency array to register once

  console.log(images, documents, 'imagesdocuments')
  return (
    <>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values), (errors) => console.log(errors))}>
        <Grid>
     { isMobile  ?   
            null
        :    <Grid.Col span={12} style={{ borderBottom: "1px solid #ccc" }}>
            <Group grow>
          
              <Selector
                icon={<LanguagesIcon size={12}/> }
                label={t("language")}
                placeholder={t("Select")}
                data={languageOptions}
                {...form.getInputProps("selectedLanguage")}
                description={t('languageDescription')}
                required
                id="language-selector"
                styles={{
                  description: {
                    whiteSpace: 'nowrap',
                  },
                }}
              />
              
              <Stack gap="4">
                <label className="m_8fdc1311 mantine-InputWrapper-label mantine-Select-label ">
                  <Book size={12} style={{ marginRight: '8px' }} />
                 {t('ders')} <span className="m_78a94662 mantine-InputWrapper-required mantine-Select-required" style={{marginLeft: '4px'}}> *</span>
                </label>
                <p className="m_fe47ce59 mantine-InputWrapper-description mantine-Select-description ">{t('dersseciniz')}</p>
              
                <StyledButton
  onClick={() => setIsLessonModalOpen(true)}
  variant={form.values.course ?  'primary' : 'default'}
  id="course-selector"

  
>
  {form.values.course 
    ? selectedLesson?.courseName || t('tumdersler')
    : t("selectLesson")
  }
</StyledButton>
</Stack>
       
            </Group>
          </Grid.Col>  } 
        
          {isMobile ? null : (
            <Grid.Col span={2} style={{ borderRight: "1px solid #ccc", }}>
     
         <Stack>
              <StyledButton   icon={<GraduationCap size={16} />}   onClick={showCourseCreationModal} id="lesson-creator" >
                 
                {t("dersOlustur")}
              </StyledButton>

              <FileButton
                className="button button__tool"
                onChange={(files) => handleSetFiles(files, "doc")}
                resetRef={resetRef}
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                multiple
                id="document-uploader"
                
                disabled={images.length > 0}
                style={{ opacity: images.length ? 0.5 : 1 }}
              >
                {(props) => (
                  <div style={{ position: 'relative' }}>
                    <StyledButton 
                      {...props} 
                      style={{ 
                        opacity: images.length > 0 ? 0.5 : 1,
                        width: "100%"
                      }}  
                      size="md"
                      icon={<FileUp size={16} />}
                    >
                      {t("uploadDocuments")}
                    </StyledButton>
                    {documents.length > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          background: '#40286C',
                          color: 'white',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '12px',
                          zIndex: 1,
                        }}
                      >
                        {documents.length}
                      </div>
                    )}
                  </div>
                )}
              </FileButton>

              {/* Image upload button with count and tooltip */}
              <div style={{ position: 'relative', '&:hover .file-names-tooltip': { display: 'block !important' } }} className="file-button-container">
                <FileButton
                  className="button button__tool"
                  onChange={(files) => handleSetFiles(files, "img")}
                  resetRef={resetRefSecond}
                  accept="image/*"
                  id="image-uploader"
                  disabled={documents.length > 0}
                >
                  {(props) => (
                    <StyledButton {...props} style={{ opacity: documents.length > 0 ? 0.5 : 1, width: "100%" }} icon={<Camera size={16} />}>
                      {t("uploadImage")}
                      {images.length > 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            top: -8,
                            right: -8,
                            background: '#40286C',
                            color: 'white',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {images.length}
                        </div>
                      )}
                    </StyledButton>
                  )}
                </FileButton>
                {images.length > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '100%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'rgba(0, 0, 0, 0.8)',
                      color: 'white',
                      padding: '8px',
                      borderRadius: '4px',
                      fontSize: '12px',
                      whiteSpace: 'pre-line',
                      display: 'none',
                      zIndex: 1000,
                      marginBottom: '8px',
                      minWidth: '200px',
                      maxWidth: '300px',
                      wordWrap: 'break-word'
                    }}
                    className="file-names-tooltip"
                  >
                    {documents.map((file, index) => `${index + 1}) ${fixFileName(file.name)}`).join('\n')}
                  </div>
                )}
              </div>

              {/* Display Uploaded Images */}
              {images.length > 0 && (
                <div className="image-preview-container">
                  <Text style={{marginBottom: '10px', fontWeight: 700}}>{t("uploadedImages")}</Text>
                  <div className="image-preview-list">
                    {images.map((image, index) => (
                      <div key={index} className="image-card">
                        <img src={URL.createObjectURL(image)} alt="Uploaded" className="image-thumbnail" />
                        <button className="remove-image" onClick={() => handleRemoveFile(image, "img")}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Stack>

 
          </Grid.Col>
          )}
     {isMobile ? null :     <Grid.Col span={isMobile ? 12 : 10}>
            {messages ? <Chat messages={messages} loading={isLoading}  form={form}/> : null}
          </Grid.Col>}
          <Grid.Col span={12} style={{ borderTop: "1px solid #ccc" }} className="questionArea">
           
              {isMobile ? (
           
             <div className="flex flex-col h-full">
              <div className="fixed top-0 left-0 right-0 z-10">
                <div className="bg-gray-900 text-white p-3 flex items-center justify-center shadow-lg">
                  <span className="text-sm font-medium truncate">
                    {form.values.course 
                      ? selectedLesson?.courseName || t('tumdersler')
                      : t("selectLesson")
                    }
                  </span>
                </div>
                
                <div className="bg-white border-b border-gray-200 flex items-center justify-between p-2">
                  <select
                    {...form.getInputProps("selectedLanguage")}
                    className="px-2 py-1.5 text-sm rounded-lg border border-gray-200 bg-white 
                      focus:ring-1 focus:ring-primary focus:border-primary
                      appearance-none cursor-pointer w-24"
                      id="language-selector"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23666666'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 4px center',
                      backgroundSize: '16px',
                    }}
                  >
                    {languageOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <StyledButton   icon={<GraduationCap size={16} />}   onClick={showCourseCreationModal} id="lesson-creator" variant="default" size="sm">
                 
                 {t("dersOlustur")}
               </StyledButton>
                  <span
                  id="course-selector"
                    onClick={() => setIsLessonModalOpen(true)}
                    className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm
                      ${form.values.course 
                        ? ' bg-[#40286C] text-white' 
                        : 'bg-gray-100 text-gray-700'
                      }
                      transition-colors duration-200`}
                  >
                    <Book size={16} />
                    <span className="truncate max-w-[100px]">
                      {form.values.course 
                        ? selectedLesson?.courseName || t('tumdersler')
                        : t("selectLesson")
                      }
                    </span>
                    {!form.values.course && <span className="text-red-500">*</span>}
                  </span>
                </div>
              </div>

              <div className="flex-1 pt-28 pb-24">
               <Chat messages={messages} loading={isLoading} form={form}/>
              </div>

              <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg">
                <div className="flex items-end gap-2 p-3">
                  <div className="flex gap-2">
                    <FileButton
                      accept="application/pdf,application/msword,..."
                      onChange={(files) => handleSetFiles(files, "doc")}
                      disabled={images.length > 0}
                      id="document-uploader"
                    >
                      {(props) => (
                        <span
                          {...props}
                          className={`p-2 rounded-full hover:bg-gray-100 active:scale-95 transition-transform ${
                            images.length > 0 ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          <FileUp size={18} />
                        </span>
                      )}
                    </FileButton>

<FileButton
  accept="image/*"
  onChange={(files) => handleSetFiles(files, "img")}
  disabled={documents.length > 0}
  id="image-uploader"
>
  {(props) => (
    <span
      {...props}
      className={`p-2 rounded-full hover:bg-gray-100 active:scale-95 transition-transform relative ${
        documents.length > 0 ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <Camera size={18} />
      {images.length > 0 && (
        <span className="absolute -top-2 -right-2 bg-[#40286C] text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
          1
        </span>
      )}
    </span>
  )}
</FileButton>
                  </div>

                  <Textarea
                    className="flex-grow rounded-2xl border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary"
                    disabled={isLoading}
                    placeholder={questionMode === "ask" ? t("askQuestion") : t("askNextQuestion")}
                    {...form.getInputProps("question")}
                    minRows={1}
                    maxRows={3}
                    autosize
                    onKeyDown={handleKeyDown}
                    id="question"
                  />

                  <button
                    type="submit"
                    disabled={isLoading || (!form.values.question && images.length === 0)}
                    className={`p-2 rounded-full bg-primary text-white 
                      ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-dark active:scale-95'}
                      transition-all duration-200`}
                    id="submit"
                  >
                    <Send size={18} />
                  </button>
                </div>
              </div>
            </div>
              ) : (
                // Structure for larger screens
               
                <Grid>
                  <Grid.Col span={8}>
                    <Textarea
                      disabled={isLoading}
                      placeholder={questionMode === "ask" ? t("askQuestion") :  t("askNextQuestion")}
                      {...form.getInputProps("question")}
                      minRows={1}
                      maxRows={3}
                      autosize
                      id="question"
                      style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'}}
                      onKeyDown={handleKeyDown}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Group grow>
                      <StyledButton
                      variant='primary'
                        id="submit"
                        icon={<Send size={16} />}
                        type="submit"
                        disabled={isLoading || (!form.values.question && images.length === 0)}
                      >
                        {questionMode === "ask" ? t("askQuestion") : t("askNextQuestion")}
                      </StyledButton>
                      <TokenCounter 
                    isError={notEnoughTokens}
        tokenCount={tokenCount}
        tokenBalance={tokenBalance}
      />
                    </Group>
                  </Grid.Col>
                </Grid>
     
              )}
           
          </Grid.Col>
        </Grid>
      </form>
      <SingleStepTutorial
        isVisible={showTutorial}
        onClose={() => {
          setShowTutorial(false);
          setIsLessonModalOpen(false);
          setIsCourseCreationModalVisible(false);
        }}
        toolType="questionAnswer"
        t={t}
        steps={tutorialSteps}
        currentStep={currentTutorialStep}
        onStepChange={handleTutorialNavigation}
      />
      <CourseCreationModal t={t} opened={isCourseCreationModalVisible} setOpened={setIsCourseCreationModalVisible} />
      <LessonSelectorModal
  opened={isLessonModalOpen}
  onClose={() => setIsLessonModalOpen(false)}
  userLessons={userLessons}
  selectedLesson={form.values.course}
  onLessonSelect={handleLessonSelect}
  t={t}
/>
    </>
  );
}

export default QuestionAnswer;