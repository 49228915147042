import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { NotificationProvider } from '../context/NotificationContext';
import { AuthProvider } from '../context/authContext'; // Adjust the import path
import store from '../redux/store';
import AppRoutes from '../routes'; // Automatically imports from index.js
import { TutorialProvider } from '../context/TutorialContext';
import i18n from '../i18n';

const GA_MEASUREMENT_ID = 'G-NY6N49R75Z';
const GTM_ID = 'GTM-TCDQD3T5';

function App() {
  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', GA_MEASUREMENT_ID);

      // Inject Google Tag Manager script
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `;
      document.head.appendChild(gtmScript);

      // Inject Google Tag Manager noscript
      const gtmNoscript = document.createElement('noscript');
      gtmNoscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.appendChild(gtmNoscript);
    }

    // Inject Clarity script
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "p59vuqo28k");
  }, []);

  const basename = import.meta.env.MODE === 'production' ? '/app' : '/';

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <BrowserRouter basename={basename}>
        <Provider store={store}>
          <NotificationProvider>
            <I18nextProvider i18n={i18n}>
              <AuthProvider>
                <TutorialProvider>
                  <AppRoutes />
                </TutorialProvider>
              </AuthProvider>
            </I18nextProvider>
          </NotificationProvider>
        </Provider>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;