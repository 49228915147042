import { useState, useEffect, useRef } from "react";
import {
  Grid,
  Group,
  Button,
  Stack,
  ScrollArea,
  TextInput,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import TokenCounter from "../../utils/tokenCounter";
import Loading from "../../components/common/Loading"; // Adjust the import path as necessary
import Selector from "../../components/common/Selector"; // Adjust the import path as necessary
import InputOutput from "../../components/common/InputOutput";
import ErrorDisplay from "../../components/common/Notification"; // Adjust the import path as needed

import { sanitizeInput } from "../../utils/sanitizeInput";
import { languageOptions, getAssistantOptions } from "../../utils/constants";
import { useSummarizerTextMutation } from '../../redux/api/summarizerApiSlice';
import { useNotifications } from '../../context/NotificationContext'; 
import { useDispatch, useSelector } from 'react-redux';
import { appendToHistory  } from '../../redux/slices/toolHistoriesSlice';
import { useFetchHistoryDetailsQuery } from '../../redux/api/toolHistoriesApiSlice';
import { encode } from 'gpt-tokenizer'; // Ensure your tokenizer is ready
import { setTokens } from '../../redux/slices/authSlice';
import mammoth from "mammoth"; // For handling DOCX files
import * as pdfjsLib from "pdfjs-dist"; // For handling PDF files
import "pdfjs-dist/build/pdf.worker.mjs";

import { useMediaQuery } from '@mantine/hooks';
import * as Sentry from "@sentry/react";
import { Bot, LanguagesIcon, Upload, Send, Ruler } from 'lucide-react'
import StyledButton from "../../components/common/StyledButton";
import SingleStepTutorial from "../../components/common/Tutorial";
// eslint-disable-next-line react/prop-types
function ReadyPrompts({ handleReadyPromptClick, t }) {
  return (
    <Group>
      <StyledButton
       
     
        onClick={() => handleReadyPromptClick(t("summarizer.readyPrompt1"))}
      >
        {t("summarizer.placeholder.readyPrompt1")}
      </StyledButton>
      <StyledButton
       
     
        onClick={() => handleReadyPromptClick(t("summarizer.readyPrompt2"))}
      >
        {t("summarizer.placeholder.readyPrompt2")}
      </StyledButton>
      <StyledButton
       
     
        onClick={() => handleReadyPromptClick(t("summarizer.readyPrompt3"))}
      >
        {t("summarizer.placeholder.readyPrompt3")}
      </StyledButton>
      <StyledButton
       
     
        onClick={() => handleReadyPromptClick(t("summarizer.readyPrompt4"))}
      >
        {t("summarizer.placeholder.readyPrompt4")}
      </StyledButton>
      <StyledButton
       
     
        onClick={() => handleReadyPromptClick(t("summarizer.readyPrompt5"))}
      >
        {t("summarizer.placeholder.readyPrompt5")}
      </StyledButton>
      
    </Group>
  );
}

function SummarizerTool() {
  const currentTool = useSelector(state => state.toolHistories.currentTool);
  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);
  const { addNotification } = useNotifications();
  const [summarizeText, { isLoading, error }] = useSummarizerTextMutation();
  const dispatch = useDispatch();
  const selectedHistoryId = useSelector(state => state.toolHistories.selectedHistoryId);
  
  const { data: historyDetails } = useFetchHistoryDetailsQuery({
    serviceType: 'summarizer',
    id: selectedHistoryId,
  }, {
    skip: !selectedHistoryId || currentTool !== 'summarizer',
  });
  const { t } = useTranslation();
  const assistantOptions = getAssistantOptions(t);

  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const [tokenCount, setTokenCount] = useState(0); // Track the calculated token count
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)
  const fileInputRef = useRef();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  
  const form = useForm({
    initialValues: {
      selectedAssistant: assistantOptions[1].value,
      selectedLanguage: languageOptions[0].value,
      length: 100,
      userPrompt: "",
    },
    validate: {
      userPrompt: (value) => {
        if (!value) return t("validation.userPrompt.required");
        if (value.length > 1000) return t("validation.userPrompt.tooLong");
        
        // Updated regex to allow Turkish characters, hyphen (-), and slash (/)
        const turkishRegex = /^[a-zA-Z0-9 çÇğĞıİöÖşŞüÜ\-\/]+$/;
        if (!turkishRegex.test(value)) {
          return t("validation.userPrompt.invalidCharacters");
        }
        return null;
      },
      selectedAssistant: (value) => (value === null ? t("validation.selectedAssistant.required") : null),
      selectedLanguage: (value) => (value === null ? t("validation.selectedLanguage.required") : null),
      length: (value) => {
        if (value < 50 || value > 25000) return t("validation.length.range");
        return null;
      },
    },
  });
  const tutorialSeen = localStorage.getItem(`tutorial_summarizer`);

  useEffect(() => {
    if (!tutorialSeen) {
      setShowTutorial(true);
    }
    if (historyDetails) {
      if (!selectedHistoryId) {
        form.reset();
        setOutputText('');
        setInputText('');
        return;
      }
      setOutputText(historyDetails.result);
      setInputText(historyDetails.userInput);
      form.setFieldValue("userPrompt", historyDetails.userPrompt);
      form.setFieldValue("selectedAssistant", historyDetails.assistantType);
      form.setFieldValue("selectedLanguage", historyDetails.language);
    }
  }, [selectedHistoryId, historyDetails]);

  const handleSubmit = async (values) => {
    try {
      if (!inputText) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.inputText.required'),
        });
        return;
      }
  
      if (inputText.length < 50) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.inputText.tooShort'),
        });
        return;
      }
  
      if (inputText.length > 1000000) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.inputText.tooLong'),
        });
        return;
      }

      if (tokenCount > tokenBalance) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.tokenBalance.insufficient'),
        });
        setNotEnoughTokens(true);
        setTimeout(() => {
          setNotEnoughTokens(false);
        }
        , 3000)
        return;
      }

      const sanitizedInputText = DOMPurify.sanitize(inputText);
      const sanitizedValues = sanitizeInput(values);

      const summarizeData = {
        userInput: sanitizedInputText,
        ...sanitizedValues,
      };

      const result = await summarizeText(summarizeData).unwrap();

      dispatch(appendToHistory({ tool: 'summarizer', entry: result.usageData }));
      setOutputText(result.result);
      dispatch(setTokens({ tokenBalance: result.tokenBalance }));
  
    } catch (error) {
      Sentry.captureException(error); // Capture any unexpected errors during submit
      console.error("Error in handleSubmit:", error);
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('general.error'),
      });
    }
  };

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const fileType = file.type;

        if (fileType === "application/pdf") {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const pdfData = new Uint8Array(e.target.result);
            const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
            let text = '';
            for (let i = 1; i <= pdf.numPages; i++) {
              const page = await pdf.getPage(i);
              const content = await page.getTextContent();
              const pageText = content.items.map(item => item.str).join(' ');
              text += pageText + '\n';
            }
            setInputText(text);
          };
          reader.readAsArrayBuffer(file);
        } else if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const arrayBuffer = e.target.result;
            const result = await mammoth.extractRawText({ arrayBuffer });
            setInputText(result.value);
          };
          reader.readAsArrayBuffer(file);
        } else if (fileType === "text/plain") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const sanitizedInput = DOMPurify.sanitize(e.target.result);
            setInputText(sanitizedInput);
          };
          reader.readAsText(file);
        } else {
          addNotification({
            id: new Date().getTime(),
            type: "error",
            message: t("validation.file.invalidType"),
          });
        }
      }
    } catch (e) {
      Sentry.captureException(e); // Capture any unexpected errors during file upload
      console.error("Error in handleFileUpload:", e);
      addNotification({
        id: new Date().getTime(),
        type: "error",
        message: t("general.error"),
      });
    }
  };

  const handleReadyPromptClick = (prompt) => {
    form.setFieldValue("userPrompt", prompt);
  };

  const summarizerFormula = (normalTokenCount, selectedAssistant) => {
    let tokenFormula = 0;

   
      if (selectedAssistant === assistantOptions[1].value) {
        tokenFormula = (3 * normalTokenCount) / 1000;
      } else if (selectedAssistant === assistantOptions[0].value) {
        tokenFormula = (3 * normalTokenCount) / 40;
      }
  

  
    // Round up the result and ensure at least 1 token is charged
    return Math.max(Math.ceil(tokenFormula), 1); 
  };
  const calculateTokens = () => {
    if (!inputText || inputText.trim() === "") {
      setTokenCount(0);
      return;
    }

    const normalTokenCount = encode(inputText).length;

    const newTokenCount = summarizerFormula(
      normalTokenCount,
      form.values.selectedAssistant
    );
  
    setTokenCount(newTokenCount);
  };

  useEffect(() => {
    calculateTokens();
  }, [inputText, form.values.selectedAssistant]);

  const tutorialSteps = [
    {
      elementId: ['language-selector', 'length-selector', 'upload-file', 'userprompt'],
      title: t('tutorial.summarizer.allSteps.title'),
      content: t('tutorial.summarizer.allSteps.content'),
      position: 'center'
    }
  ];



  return (
    <form
      onSubmit={form.onSubmit(
        (values) => handleSubmit(values),
        (errors) => console.log(errors)
      )}
      className="flex flex-col h-[calc(100vh-64px)]" // Adjust based on your header height
    >
      <div className="flex flex-col h-full gap-4">
        {/* Selector Section */}
        <div className="flex-none border-b border-gray-200 pb-4">
          {isLoading && <Loading />}
          <div className="flex flex-wrap gap-4">
            <div className="flex flex-wrap w-full gap-4 md:flex-row">
              {/* Selectors will be 2+1 on mobile, but stay in one row on desktop */}
          
              <Selector
                icon={<LanguagesIcon size={12}/>}
                label={t("language")}
                placeholder={t("Select")}
                data={languageOptions}
                defaultValue={languageOptions[0]}
                description={t('languageDescription')}
                required
                {...form.getInputProps("selectedLanguage")}
                id="language-selector"
                className="flex-1 min-w-[calc(50%-8px)] md:min-w-0"
              />
              
              <NumberInput
                label={
                  <div className="flex items-center gap-2">
                    <Ruler size={12}/>
                    <span>{t("length")}</span>
                  </div>
                }
                hideControls
                {...form.getInputProps("length")}
                description={t("lengthDescription")}
                id='length-selector'
                className="flex-1 min-w-full md:min-w-0"
              />
            </div>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex flex-1 min-h-0 gap-4">
          {/* File Upload Section - Hidden on Mobile */}
          {!isMobile && (
            <div className="w-[200px] flex-none border-r border-gray-200 pr-4">
              <div className="flex flex-col gap-4">
                <StyledButton 
                  onClick={() => fileInputRef.current.click()} 
                  id="upload-file" 
                  icon={<Upload />}
                  className="w-full"
                >
                  {t('uploadFile')}
                </StyledButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          )}

          {/* Input/Output Section */}
          <div className="flex-1 min-h-0">
            <InputOutput
              inputText={inputText}
              setInputText={setInputText}
              outputText={outputText}
              loading={isLoading}
              getInputProps={form.getInputProps}
              fieldName="inputText"
              t={t}
            />
          </div>
        </div>

        {/* Bottom Controls Section */}
        <div className="flex-none border-t border-gray-200 pt-4">
          {isMobile ? (
            <div className="flex flex-col gap-4">
              <TextInput
                disabled={isLoading}
                placeholder={t("userPrompt")}
                {...form.getInputProps("userPrompt")}
                  id="userprompt"
                styles={{
                  input: {
                    fontSize: '16px',
                  },
                }}
              />
              <div className="flex gap-4">
                <StyledButton
                  icon={<Send />}
                  variant="primary"
                  type="submit"
                  disabled={isLoading || !form.values.userPrompt || !inputText}
                  className="flex-1"
                >
                  {t("summarize")}
                </StyledButton>
                <TokenCounter 
                  isError={notEnoughTokens}
                  tokenCount={tokenCount}
                  tokenBalance={tokenBalance}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <ReadyPrompts handleReadyPromptClick={handleReadyPromptClick} t={t} />
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-8">
                  <TextInput
                    disabled={isLoading}
                    placeholder={t("userPrompt")}
                    {...form.getInputProps("userPrompt")}
                    className="shadow-sm"
                    id="userprompt"
                  />
                </div>
                <div className="col-span-4 ">
                  <div className="flex gap-4 items-center">
                    <StyledButton
                      icon={<Send />}
                      variant="primary"
                      type="submit"
                      disabled={isLoading || !form.values.userPrompt || !inputText}
                      className="flex-1"
                    >
                      {t("summarize")}
                    </StyledButton>
                    <TokenCounter 
                      isError={notEnoughTokens}
                      tokenCount={tokenCount}
                      tokenBalance={tokenBalance}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <SingleStepTutorial
        isVisible={showTutorial}
        onClose={() => {
          setShowTutorial(false);
          localStorage.setItem('tutorial_summarizer', 'true');
        }}
        toolType="summarizer"
        t={t}
        steps={tutorialSteps}
        currentStep={0}
        onStepChange={() => {}}
        showAllSteps={true}
      />
      
      <ErrorDisplay error={error} />
    </form>
  );
}

export default SummarizerTool;
