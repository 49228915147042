import { useState } from 'react';

function usePromptInput(onSavePrompt) {
  const [showPromptInput, setShowPromptInput] = useState(false);
  const [promptInputPosition, setPromptInputPosition] = useState({ top: 0, left: 0 });

  const handleAddPromptClick = (e) => {
    if (showPromptInput) {
        // If the prompt input is already shown, hide it
        setShowPromptInput(false);
      } else {
    const { clientX, clientY } = e;
    setPromptInputPosition({  x: clientX, y: clientY });
    setShowPromptInput(true);
      }
  };

  const handleSavePrompt = (prompt) => {
    onSavePrompt(prompt);
    setShowPromptInput(false);
  };

  const handleClosePromptInput = () => {
    setShowPromptInput(false);
  };

  return {
    showPromptInput,
    promptInputPosition,
    handleAddPromptClick,
    handleSavePrompt,
    handleClosePromptInput,
  };
}

export default usePromptInput;