import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import { ToolsRoutes } from './toolRoutes';
import { SettingsRoutes } from './settingRoutes';
import { PaymentRoutes } from './paymentRoutes';
import { LessonRoutes } from './lessonRoutes';
import { TokenVerificationRoutes } from './tokenVerificationRoutes';
import { AuthRoutes } from './authRoutes';
import { useAuth } from '../context/authContext';
import SkeletonLayout from '../components/common/SkeletonLoadingLayout';

const AppRoutes = () => {
  const { isAuthenticated, isAuthenticating } = useAuth(); // Use isAuthenticating
  const location = useLocation();

  // Wait until authentication process is completed before making routing decisions
  if (isAuthenticating) {
    return <SkeletonLayout />;
  }

  const isProtectedRoute = (path) => {
    return ['/tools', '/settings', '/payments', '/lessons'].some(route => path.startsWith(route));
  };


  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/tools/virtualTeacher" replace />
          ) : (
            <Navigate to="/auth/login" replace />
          )
        }
      />

<Route path="/verify/*" element={<TokenVerificationRoutes />} />

{/* Regular auth routes - only for non-authenticated users */}
<Route 
  path="/auth/*" 
  element={
    isAuthenticated ? (
      <Navigate to="/tools/virtualTeacher" replace />
    ) : (
      <AuthRoutes />
    )
  } 
/>

      {/* Protected routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="tools/*" element={<ToolsRoutes />} />
        <Route path="settings/*" element={<SettingsRoutes />} />
        <Route path="payments/*" element={<PaymentRoutes />} />
        <Route path="lessons/*" element={<LessonRoutes />} />
      </Route>

      {/* Catch-all route */}
      <Route
        path="*"
        element={
          isAuthenticated ? (
            isProtectedRoute(location.pathname) ? (
              <Navigate to={location.pathname} replace />
            ) : (
              <Navigate to="/tools/virtualTeacher" replace />
            )
          ) : (
            <Navigate to="/auth/login" state={{ from: location }} replace />
          )
        }
      />
    </Routes>
  );
};

export default AppRoutes;
