import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Package, Calendar, Coins, X, Loader } from 'lucide-react';
import { useFetchUserSubscriptionQuery, useCancelSubscriptionMutation } from '../../redux/api/transactionApiSlice';
import { useNotifications } from '../../context/NotificationContext';

// Sample plansData (kept as is)
const plansData = [
  {
    id: 'basic',
    buttonId: 'plans-button-1',
    title: 'subscriptionpackages.title.bronzePackage',
    price: {
      monthly: { TRY: 32, USD: 1 },
      yearly: { TRY: 768, USD: 10 },
      discounted: { TRY: 230, USD: 20 },
    },
    features: ['subscriptionpackages.feature.2000Coins', 'subscriptionpackages.feature.2Lessons', 'subscriptionpackages.feature.20MBFileUpload'],
  },
  {
    id: 'pro',
    buttonId: 'plans-button-2',
    title: 'subscriptionpackages.title.silverPackage',
    price: {
      monthly: { TRY: 170, USD: 5 },
      yearly: { TRY: 4080, USD: 50 },
      discounted: { TRY: 1224, USD: 70 },
    },
    features: ['subscriptionpackages.feature.12000Coins', 'subscriptionpackages.feature.3Lessons', 'subscriptionpackages.feature.30MBFileUpload'],
  },
  {
    id: 'premium',
    buttonId: 'plans-button-3',
    title: 'subscriptionpackages.title.goldPackage',
    price: {
      monthly: { TRY: 544, USD: 16 },
      yearly: { TRY: 13056, USD: 384 },
      discounted: { TRY: 3917, USD: 115 },
    },
    features: ['subscriptionpackages.feature.50000Coins', 'subscriptionpackages.feature.4Lessons', 'subscriptionpackages.feature.40MBFileUpload'],
  },
  {
    id: 'premium2',
    buttonId: 'plans-button-4',
    title: 'subscriptionpackages.title.platinumPackage',
    price: {
      monthly: { TRY: 1020, USD: 30 },
      yearly: { TRY: 24480, USD: 720 },
      discounted: { TRY: 7344, USD: 215 },
    },
    features: [
      'subscriptionpackages.feature.unlimited40miniUsage',
      'subscriptionpackages.feature.30000Coins',
      'subscriptionpackages.feature.5Lessons',
      'subscriptionpackages.feature.50MBFileUpload',
      'subscriptionpackages.feature.humanizerAPI',
    ],
  },
];

const getPackageTitle = (packageId, plansData) => {
  const packageInfo = plansData.find((plan) => plan.id === packageId);
  return packageInfo ? packageInfo.title : 'subscriptionpackages.title.free';
};

const SubscriptionInformation = () => {
  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  
  const { data: subscription, isLoading, error } = useFetchUserSubscriptionQuery();
  const [cancelSubscription, { isLoading: isCancelling }] = useCancelSubscriptionMutation();
  
  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription().unwrap();
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: t('subscriptionInformation.cancelSuccess'),
      });
    } catch (error) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('subscriptionInformation.cancelError'),
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader className="w-6 h-6 animate-spin text-gray-600" />
        <span className="ml-2 text-gray-600">{t('loading')}</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
        {t('error')}
      </div>
    );
  }

  if (!subscription) {
    return (
      <div className="flex flex-col items-center justify-center p-8 text-gray-500">
        <Package className="w-12 h-12 mb-4" />
        <p className="text-center">{t('subscriptionInformation.noSubscription')}</p>
      </div>
    );
  }

  const packageTitle = getPackageTitle(subscription.packageId, plansData);

  return (
    <div className="bg-white rounded-lg p-6">
      <h4 className="text-xl font-semibold mb-2 text-gray-900">
        {t('subscriptionInformation.title')}
      </h4>
      <p className="text-sm text-gray-600 mb-6">
        {t('subscriptionInformation.description')}
      </p>
      
      <div className="space-y-6">
        <div className="flex items-start space-x-4">
          <Package  />
          <div>
            <h3 className="font-medium text-gray-900">
              {t('subscriptionInformation.package')}
            </h3>
            <p className="text-gray-600">{t(packageTitle)}</p>
          </div>
        </div>

        <div className="flex items-start space-x-4">
          <Calendar  />
          <div>
            <h3 className="font-medium text-gray-900">
              {t('subscriptionInformation.renewalDate')}
            </h3>
            <p className="text-gray-600">
              {subscription.subscriptionRenewalDate 
                ? new Date(subscription.subscriptionRenewalDate).toLocaleDateString() 
                : '-'}
            </p>
          </div>
        </div>

        <div className="flex items-start space-x-4">
          <Coins  />
          <div>
            <h3 className="font-medium text-gray-900">
              {t('subscriptionInformation.tokenBalance')}
            </h3>
            <p className="text-gray-600">{tokenBalance}</p>
          </div>
        </div>
      </div>

      {subscription.paymentPeriod === 'monthly' && (
        <button
          onClick={handleCancelSubscription}
          disabled={isCancelling}
          className="mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-red-300 text-red-600 rounded-md hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          {isCancelling ? (
            <>
              <Loader className="w-4 h-4 mr-2 animate-spin" />
              <span>{t('subscriptionInformation.cancelling')}</span>
            </>
          ) : (
            <>
              <X className="w-4 h-4 mr-2" />
              <span>{t('subscriptionInformation.cancelSubscription')}</span>
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default SubscriptionInformation;