import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Grid, Textarea, Button } from '@mantine/core';
import { useNotifications } from '../../context/NotificationContext';
import { GraduationCap, PencilLine, Copy } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function InputOutput({ inputText, setInputText, outputText, loading, getInputProps, fieldName, t, className = '' }) {
  const inputProps = getInputProps(fieldName);
  const [splitSize, setSplitSize] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const { addNotification } = useNotifications();

  const startResizing = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsDragging(false);
  }, []);

  const resize = useCallback(
    (e) => {
      if (isDragging && containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const newSplitSize = ((e.clientX - rect.left) / rect.width) * 100;
        setSplitSize(Math.max(10, Math.min(90, newSplitSize)));
      }
    },
    [isDragging]
  );

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResizing);
    }
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [isDragging, resize, stopResizing]);

  // Function to copy the output text to the clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(outputText)
      .then(() => {
        addNotification({
          id: new Date().getTime(),
          type: 'success',
          message: t('Copied to clipboard!'),
        });
      })
      .catch(() => {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('Failed to copy!'),
        });
      });
  };

  return (
    <div className="input-output-wrapper">
      <div ref={containerRef} className={`input-output-container ${className}`}>
        {/* Input Section */}
        <div
          className="input-section relative flex-grow overflow-hidden"
          style={{ flex: window.innerWidth > 768 ? splitSize : '100%' }}
        >
          {!inputText && (
            <div className="placeholder-text rounded-lg p-8 flex flex-col justify-center items-center">
              <PencilLine className="h-12 w-12" />  
              <div>{t('inputPlaceholder')}</div>
            </div>
          )}
          <Textarea
            className="textarea-content"
            disabled={loading}
            variant="unstyled"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            styles={{
              input: {
                fontSize: '16px',
                height: '100%',
                maxHeight: '400px',
                overflowY: 'auto',
              },
            }}
          />
        </div>

        {/* Resizer (desktop only) */}
        <div
          onMouseDown={startResizing}
          className="resizer w-1 cursor-col-resize hover:bg-gray-300 active:bg-gray-400 transition-colors hidden md:block"
        />

        {/* Output Section with Copy Button */}
        <div
          className="output-section relative flex-grow overflow-hidden"
          style={{ flex: window.innerWidth > 768 ? (100 - splitSize) : '100%' }}
        >
          {!outputText ? (
            <div className="placeholder-text flex flex-col justify-center items-center">
              <GraduationCap className="h-12 w-12" />
              <div>{t('outputPlaceholder')}</div>
            </div>
          ) : (
            <>
          
              <div className="prose max-h-[70vh] md:max-h-[600px] overflow-y-auto prose-headings:text-base md:prose-headings:text-lg" style={{ userSelect: 'text' }}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {outputText}
                </ReactMarkdown>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InputOutput;
