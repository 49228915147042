import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { SendIcon, ChevronRight } from 'lucide-react';
import { useFetchMoreHistoryQuery, useDeleteHistoryEntryMutation } from '../../redux/api/toolHistoriesApiSlice';
import { setSelectedHistoryId, clearSelectedHistoryId } from '../../redux/slices/toolHistoriesSlice';

const InfiniteScrollHistory = ({ 
  toolType = 'articleForger', 
  initialHistories
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [historyData, setHistoryData] = useState(initialHistories || []);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(false);
  const selectedId = useSelector((state) => state.toolHistories.selectedHistoryId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  useEffect(() => {
    setHistoryData(initialHistories || []);
    setHasMore(initialHistories?.length === 20);
  }, [initialHistories]);

  const { data, isLoading } = useFetchMoreHistoryQuery(
    { toolType, page },
    { skip: !hasMore || !page }
  );

 
  const [deleteHistoryEntry] = useDeleteHistoryEntryMutation();

/*   useEffect(() => {
    if (data?.results && data.results.length > 0) {
      setHistoryData((prev) => [...prev, ...data.results]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(data.page < data.totalPages);
    } else {
      setHasMore(false);
    }
  }, [data]); */

  const handleDeleteClick = (id, event) => {
    event.stopPropagation();
    setItemToDelete(id);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteHistoryEntry({ id, toolType }).unwrap();
      dispatch(clearSelectedHistoryId());
    } catch (error) {
      console.error('Failed to delete history entry:', error);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50/30">
      <div className="sticky top-0 z-10  backdrop-blur-sm border-b border-wh-100 p-2 sm:p-4">
        <button
          onClick={() => {
            dispatch(clearSelectedHistoryId());
            window.dispatchEvent(new Event('newChat'));
          }}
          className="w-full group relative overflow-hidden rounded-md bg-[#40286C] text-white p-2 sm:p-2.5 shadow-sm transition-all duration-300 hover:shadow-lg hover:scale-[1.02]"
        >
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium tracking-wide">
              {t('historybar.createNew')}
            </span>
            <SendIcon className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
          </div>
          <div className="absolute inset-0 bg-white/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
        </button>
      </div>

      <div id="scrollableDiv" className="flex-1 overflow-auto thinScroll-container">
        <InfiniteScroll
          dataLength={historyData.length}
          next={() => setPage((prevPage) => prevPage + 1)}
          hasMore={hasMore}
          loader={
            <div className="flex items-center justify-center py-4 text-gray-500">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-400" />
            </div>
          }
          scrollableTarget="scrollableDiv"
          className="space-y-1 sm:space-y-2 p-2 sm:p-4"
        >
          {historyData.map((item) => (
            <div
              key={item.id}
              onClick={() => dispatch(setSelectedHistoryId(item.id))}
              onMouseEnter={() => setHoveredItemId(item.id)}
              onMouseLeave={() => setHoveredItemId(null)}
              className={`
                relative p-2 sm:p-2 sm:p-3 lg:p-3 transition-all duration-200 cursor-pointer rounded-md 
                ${selectedId === item.id 
                  ? 'bg-[#40286C] text-white shadow-lg' 
                  : 'bg-white hover:bg-white/80 hover:shadow-md'}
              `}
            >
              <div className="flex items-center justify-between gap-3">
                <div className="flex-1 min-w-0">
                  <p className={`text-sm font-medium truncate transition-all duration-200
                    ${selectedId === item.id ? 'text-white' : 'text-gray-900'}
                  
                  `}>
                    {item.inputSummary.length > 20 
                      ? `${item.inputSummary.substring(0, 20)}...` 
                      : item.inputSummary}
                  </p>
                </div>
                
                <div className="flex items-center gap-2" style={{position: 'absolute', right: '10px'}}>
                  {(selectedId === item.id || hoveredItemId === item.id) && (
                    <button
                      onClick={(e) => handleDeleteClick(item.id, e)}
                      className=" rounded-full transition-all duration-200 hover:bg-black/10"
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className={`w-3.5 h-3.5 transition-colors duration-200 ${
                          selectedId === item.id 
                            ? 'text-white/70 hover:text-white' 
                            : 'text-gray-400 hover:text-red-500'
                        }`}
                      />
                    </button>
                  )}
                  <ChevronRight className={`w-3.5 h-3.5 transition-all duration-200
                    ${selectedId === item.id ? 'text-white opacity-100' : 'opacity-0'}
                    ${hoveredItemId === item.id ? 'opacity-50' : ''}
                  `} />
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 max-w-sm w-full mx-4 shadow-xl">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              {t('historybar.deleteItem')}
            </h3>
            <p className="text-gray-600 mb-6">
              {t('historybar.deleteMessage')}
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
              >
                {t('common.cancel')}
              </button>
              <button
                onClick={() => {
                  if (itemToDelete !== null) {
                    handleDelete(itemToDelete);
                  }
                  setIsModalOpen(false);
                  setItemToDelete(null);
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-lg transition-colors"
              >
                {t('common.delete')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfiniteScrollHistory;