/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, 
  faCheckCircle, 
  faGraduationCap,
  faSort,
  faSortUp,
  faSortDown,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useFetchGeneralLessonsQuery } from '../../redux/api/lessonApiSlice';
import { Loader } from '@mantine/core';
import StyledButton from "./StyledButton";
const LessonCard = ({ lesson, isSelected, onSelect }) => (
  <div
    onClick={() => {

      onSelect(lesson)
    }}
    className={`
      cursor-pointer transition-all duration-200 border-b border-slate-100 last:border-b-0
      ${isSelected ? '[background-color:#563a8d]' : 'bg-slate-50/50 hover:bg-slate-100/70'}
    `}
  >
    <div className="p-1 flex items-center gap-3">
      <div className={`
        w-10 h-8 rounded-[3px] flex items-center justify-center transition-all duration-200
        ${isSelected ? '[background-color:#563a8d]' : 'bg-white hover:bg-slate-100'}
      `}>
        <FontAwesomeIcon 
          icon={isSelected ? faCheckCircle : faGraduationCap}
          className={`w-4 h-4 ${isSelected ? 'text-white' : 'text-slate-600'}`}
        />
      </div>
      <span className={`flex-1 text-sm transition-colors duration-200 ${isSelected ? 'text-white font-medium' : 'text-slate-600'}`}>
        {lesson.courseName}
      </span>
    </div>
  </div>
);

const FilterableGeneralLessons = ({ selectedLesson, onLessonSelect, onClose, activeTab, setActiveTab }) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ 
    universityName: '', 
    schoolName: '',
    courseName: '' 
  });
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setFilters({ 
      universityName: '', 
      schoolName: '',
      courseName: '',
      type: activeTab
    });
    setPage(1);
  };

  const { data: generalLessonsData, isLoading } = useFetchGeneralLessonsQuery({
    page,
    limit: 10,
    ...filters,
    type: activeTab
  });

  const handleFilter = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setPage(1);
  };

  return (
    <div className="space-y-4">
      {/* Tabs */}
      <div className="grid grid-cols-2 border rounded-[3px] overflow-hidden">
        <button
          onClick={() => handleTabChange('university')}
          className={`py-2.5 text-sm font-medium transition-colors ${
            activeTab === 'university' 
              ? 'bg-[#563a8d] text-white' 
              : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
          }`}
        >
          {t('Üniversite Dersleri')}
        </button>
        <button
          onClick={() => handleTabChange('highschool')}
          className={`py-2.5 text-sm font-medium transition-colors ${
            activeTab === 'highschool' 
              ? 'bg-[#563a8d] text-white' 
              : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
          }`}
        >
          {t('Lise Dersleri')}
        </button>
      </div>

      {/* Filters */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {Object.entries({
          ...(activeTab === 'university' 
            ? { universityName: t('university') }
            : { schoolName: t('highschool') }
          ),
          courseName: t('course')
        }).map(([key, label]) => (
          <div key={key} className="relative">
            <input
              type="text"
              placeholder={language === 'tr' ? `${label} ${t('filterby')}` : `${t('filterby')} ${label}`}
              value={filters[key] || ''}
              onChange={(e) => handleFilter(key, e.target.value)}
              className="w-full px-8 py-1.5 text-sm border rounded-[3px] focus:outline-none focus:ring-2 focus:ring-[#563a8d]/20"
            />
            <FontAwesomeIcon 
              icon={faSearch} 
              className="absolute left-2.5 top-2.5 text-slate-400 w-3 h-3"
            />
          </div>
        ))}
      </div>

      {/* Results List */}
      <div className="h-[300px] overflow-y-auto rounded-[3px] border border-slate-200 bg-white">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader size="sm" />
          </div>
        ) : generalLessonsData?.lessons?.length === 0 ? (
          <div className="flex justify-center items-center h-full text-slate-500">
            {t('noLessonsFound')}
          </div>
        ) : (
          generalLessonsData?.lessons?.map(lesson => (
            <div
              key={lesson._id}
              onClick={() => onLessonSelect(lesson)}
              className={`
                cursor-pointer transition-all duration-200 border-b border-slate-100 last:border-b-0 
                ${selectedLesson === lesson._id ? 'bg-[#563a8d]' : 'bg-slate-50/50 hover:bg-slate-100/70'}
              `}
            >
              <div className="p-3 grid grid-cols-2 gap-2">
                <span className={`text-sm ${selectedLesson === lesson._id ? 'text-white' : 'text-slate-600'}`}>
                  {lesson.universityName || lesson.schoolName}
                </span>
                <span className={`text-sm ${selectedLesson === lesson._id ? 'text-white' : 'text-slate-600'}`}>
                  {lesson.courseName}
                </span>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Pagination and Actions */}
      <div className="flex justify-between items-center">
        <div className="flex-1 flex justify-center gap-2">
          {Array.from({ length: generalLessonsData?.totalPages || 1 }, (_, i) => (
            <button
              key={i}
              onClick={() => setPage(i + 1)}
              className={`px-3 py-1 rounded-[3px] text-sm transition-colors duration-200 
                ${page === i + 1 
                  ? 'bg-[#563a8d] text-white' 
                  : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
                }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <div className="flex gap-2">
          <button onClick={onClose} className="px-4 py-1.5 text-sm rounded-[3px] bg-gray-100 hover:bg-gray-200 text-gray-700">
            {t('İptal')}
          </button>
          <button
            onClick={onClose}
            disabled={!selectedLesson}
            className="px-4 py-1.5 text-sm rounded-[3px] bg-[#563a8d] text-white hover:bg-[#563a8d]/90 disabled:opacity-50"
          >
            {t('Ders Seç')}
          </button>
        </div>
      </div>
    </div>
  );
};

const LessonSelectorModal = ({
  opened,
  onClose,
  userLessons,
  selectedLesson,
  onLessonSelect,
  t
}) => {
  const [activeTab, setActiveTab] = useState('university');

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="xl"
      padding="md"
      style={{borderRadius: '3px'}}
      radius="[3px]"
     /*  classNames={{
        header: 'p-4 border-b border-slate-200',
        body: 'p-4',
        close: 'hover:bg-slate-100 rounded-[3px] w-8 h-8'
      }} */
      id="lesson-selector-modal"
   /*    title={
        <div className="flex items-center justify-between w-full">
          <h2 className="text-xl font-semibold text-slate-900">
            {t("selectLesson")}
          </h2>
        </div>
      } */
    >
      <div className="space-y-4">
        <div id="personal-lessons">
          <h3 className="text-sm font-medium text-slate-900 mb-2">
            {t("personalLessons")}
          </h3>
          <div className=" overflow-y-auto rounded-[3px] border border-slate-200 bg-white">
            {userLessons?.map(lesson => (
              <LessonCard 
                key={lesson.id} 
                lesson={lesson} 
                isSelected={lesson._id === selectedLesson}
                onSelect={onLessonSelect}
              />
            ))}
          </div>
        </div>

        <div id="general-lessons">
          <h3 className="text-sm font-medium text-slate-900 mb-2">
            {activeTab === 'university' ? t('lessonSelector.universityLessons') : t('lessonSelector.highschoolLessons')}
          </h3>
          <FilterableGeneralLessons 
            selectedLesson={selectedLesson}
            onLessonSelect={onLessonSelect}
            onClose={onClose}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

      
      </div>
    </Modal>
  );
};

export default LessonSelectorModal;