import { Routes, Route } from 'react-router-dom';
import EmailVerification from '../pages/auth/VerifyEmail';
import VerifyChangeEmail from '../pages/auth/VerifyChangeEmail';
import VerifyForgotPassword from '../pages/auth/VerifyForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import SecureAccount from '../pages/auth/SecureAccount';

export const TokenVerificationRoutes = () => {
  return (
    <Routes>
      <Route path="verify-email/:token" element={<EmailVerification />} />
      <Route path="verify-change-email/:token" element={<VerifyChangeEmail />} />
      <Route path="forgot-password/:token" element={<VerifyForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="secure-account/:token" element={<SecureAccount />} />
    </Routes>
  );
};