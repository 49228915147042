import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const paymentApiSlice = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    initiatePayment: builder.mutation({
      query: (paymentData) => ({
        url: '/payments/initiate-payment',
        method: 'POST',
        body: paymentData,
      }),
    }),
    verifyPayment: builder.query({
      query: (paymentId) => `/payments/verify/${paymentId}`,
    }),
    
    fetchAddresses: builder.query({
      query: () => '/payments/addresses',
    }),
    saveAddress: builder.mutation({
      query: (addressData) => ({
        url: '/payments/addresses',
        method: 'POST',
        body: addressData,
      }),
    }),
    updateAddress: builder.mutation({
      query: ({ addressId, addressData }) => ({
        url: `/payments/addresses/${addressId}`,
        method: 'PATCH',
        body: addressData,
      }),
    }),
    deleteAddress: builder.mutation({
      query: (addressId) => ({
        url: `/payments/addresses/${addressId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useInitiatePaymentMutation,
  useVerifyPaymentQuery,
  useFetchAddressesQuery,
  useSaveAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation, // New hook for updating addresses
} = paymentApiSlice;
