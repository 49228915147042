import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { useNotifications } from '../../context/NotificationContext';
import { sanitizeInput } from '../../utils/sanitizeInput';
import { Input } from '../../components/common/Input';
import useAxios from '../../hooks/useAxios';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const { loading, error, sendRequest } = useAxios();
  const [method, setMethod] = useState('email');

  const form = useForm({
    initialValues: {
      contact: '',
    },
    validate: {
      contact: (value) => {
        if (method === 'email') {
          return /^\S+@\S+\.\S+$/.test(value) ? null : t('forgotPassword.errors.invalidEmail');
        } else {
          if (!value) return t('register.errors.phoneNumberRequired');
          if (value.length !== 17) return t('register.errors.invalidPhoneNumberLength');
          return /^\+90\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/.test(value) ? null : t('register.errors.invalidPhoneNumber');
        }
      },
    },
  });

  const handleSubmit = async (values) => {
    const sanitizedData = sanitizeInput(values.contact);
    try {
      await sendRequest({
        url: `/auth/forgot-password/${method}`,
        method: "POST",
        data: { contact: sanitizedData }
      });
      form.reset();
      addNotification({ 
        id: Date.now(), 
        message: t('forgotPassword.notifications.emailSent'), 
        type: 'success' 
      });
    } catch (error) {
      addNotification({ 
        id: Date.now(), 
        message: t('forgotPassword.notifications.emailSent'), 
        type: 'success' 
      });
    }
  };

  return (
    <div className="w-full max-w-md space-y-8">
      <div className="text-center space-y-3">
        <h2 className="text-3xl font-bold text-gray-900">
          {t('forgotPassword.title')}
        </h2>
        <p className="text-gray-500 max-w-sm mx-auto">
          {t('forgotPassword.instructions')}
        </p>
      </div>

      <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-6">
        <div className="bg-gray-50 p-4 rounded-xl space-y-4">
          <div className="space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                className="w-4 h-4 text-[#40286c] focus:ring-[#40286c] border-gray-300"
                checked={method === 'email'}
                onChange={() => setMethod('email')}
              />
              <span className="text-sm text-gray-700">{t('forgotPassword.emailOption')}</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                className="w-4 h-4 text-[#40286c] focus:ring-[#40286c] border-gray-300"
                checked={method === 'sms'}
                onChange={() => setMethod('sms')}
              />
              <span className="text-sm text-gray-700">{t('forgotPassword.smsOption')}</span>
            </label>
          </div>
        </div>

        <Input
          label={method === 'email' ? t('forgotPassword.emailLabel') : t('forgotPassword.phoneNumber')}
          placeholder={method === 'email' ? t('forgotPassword.emailPlaceholder') : '+90 555 555 55 55'}
          error={form.errors.contact}
          {...form.getInputProps('contact')}
        />
        
        <button
          type="submit"
          disabled={loading}
          className="w-full py-3 px-4 bg-[#40286c] text-white rounded-xl font-medium hover:bg-[#332056] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c]"
        >
          {loading ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              <span>{t('forgotPassword.sending')}</span>
            </div>
          ) : (
            t('forgotPassword.submitButton')
          )}
        </button>
      </form>

      <p className="text-center text-sm text-gray-600">
        <Link 
          to="/auth/login"
          className="font-medium text-blue-600 hover:text-blue-500"
        >
          {t('login.goBack')}
        </Link>
      </p>
    </div>
  );
};

export default ForgotPassword;
