import { useState, useEffect } from 'react';
import { useForm } from '@mantine/form';
import { Stack, Button, TextInput, Box, Title, Text, Grid } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNotifications } from '../../../context/NotificationContext';
import { 
  useSendUpdatePhoneVerificationMutation,
  useVerifyUpdatePhoneMutation,
  useAddPhoneMutation 
} from '../../../redux/api/authApiSlice';
import DOMPurify from 'dompurify';

const ChangePhoneNumber = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const { addNotification } = useNotifications();
  const [phoneStep, setPhoneStep] = useState(1);
  const [phoneToken, setPhoneToken] = useState('');

  const [sendUpdatePhoneVerification] = useSendUpdatePhoneVerificationMutation();
  const [verifyUpdatePhone] = useVerifyUpdatePhoneMutation();
  const [addPhone] = useAddPhoneMutation();

  const isAddingNewPhone = !user?.phoneNumber;

  const form = useForm({
    initialValues: {
      currentPhone: '',
      newPhone: '',
    },
    validate: {
      newPhone: (value) => {
        if (!value) return t('validation.required');
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        return phoneRegex.test(value) ? null : t('validation.newPhone');
      },
    },
  });

  useEffect(() => {
    if (user) {
      form.setValues({
        currentPhone: user.phoneNumber || '',
      });
    }
  }, [user]);

  const handleSendPhoneCode = async () => {
    try {
      if (isAddingNewPhone) {
        await addPhone({ newPhone: form.values.newPhone }).unwrap();
      } else {
        await sendUpdatePhoneVerification({ newPhone: form.values.newPhone }).unwrap();
      }
      setPhoneStep(2);
      addNotification({ 
        type: 'success', 
        message: t('settings.form.notifications.phoneCodeSent') 
      });
    } catch (err) {
      addNotification({ 
        type: 'error', 
        message: err.data?.message || t('settings.form.notifications.phoneCodeSendFail') 
      });
    }
  };

  const handleVerifyPhoneCode = async () => {
    try {
      await verifyUpdatePhone({ token: phoneToken }).unwrap();
      addNotification({ type: 'success', message: t('settings.form.notifications.phoneUpdateSuccess') });
      form.setValues({
        currentPhone: form.values.newPhone,
        newPhone: '',
      });
      setPhoneStep(1);
      setPhoneToken('');
    } catch (err) {
      if (err.data?.message === 'Token has expired') {
        addNotification({ type: 'error', message: t('settings.form.notifications.tokenExpired') });
      } else {
        addNotification({ type: 'error', message: err.data?.message || t('settings.form.notifications.phoneUpdateFail') });
      }
    }
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900">
        {isAddingNewPhone 
          ? t('settings.form.addPhoneNumber')
          : t('settings.form.changePhoneNumber')}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {!isAddingNewPhone && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {t('settings.form.currentPhoneNumber')}
            </label>
            <input
              type="tel"
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-gray-500"
              value={form.values.currentPhone}
              disabled
            />
          </div>
        )}
        <div className={`space-y-4 ${isAddingNewPhone ? 'md:col-span-2' : ''}`}>
          {phoneStep === 1 ? (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.form.newPhoneNumber')}
                </label>
                <input
                  type="tel"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={t('settings.form.enterNewPhoneNumber')}
                  {...form.getInputProps('newPhone')}
                />
              </div>
              <button
                type="button"
                onClick={handleSendPhoneCode}
                className="w-full px-4 py-2 bg-[#40286C] text-white rounded-lg hover:bg-[#3e2866] transition-colors"
              >
                {t('settings.form.sendCode')}
              </button>
            </>
          ) : (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.form.enterVerificationCode')}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={phoneToken}
                  onChange={(e) => setPhoneToken(e.currentTarget.value)}
                />
              </div>
              <button
                type="button"
                onClick={handleVerifyPhoneCode}
                className="w-full px-4 py-2 bg-[#40286C] text-white rounded-lg hover:bg-[#3e2866] transition-colors"
              >
                {t('settings.form.verifyCode')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ChangePhoneNumber;
