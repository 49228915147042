export const languageOptions = [
  { value: "tr", label: "Türkçe" },
  { value: "en", label: "English" },
  { value: "de", label: "Deutsch" },
  { value: "fr", label: "Français" },
];

export const getAssistantOptions = (t) => [
    { value: "profesor", label: t("assistant.profesor") },
    { value: "uzman", label: t("assistant.uzman") },
];