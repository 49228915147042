const AydinlatmaMetni = () => {
  return (
    <div><p ><strong><span>Easy Eğitim ve Teknoloji İşletmeleri</span></strong></p>
    <p ><strong><span>Aydınlatma Metni&nbsp;</span></strong></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmeleri b&uuml;nyesindeki kişisel veriler, Easy Eğitim ve Teknoloji İşletmeleri g&uuml;vencesi altındadır. Easy Eğitim ve Teknoloji İşletmeleri olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (&ldquo;Kanun&rdquo;) ve ilgili mevzuat uyarınca, kişisel verilerin g&uuml;venli şekilde saklanmasını, hukuka uygun olarak işlenmesini sağlamak i&ccedil;in gerekli teknik ve idari &ouml;nlemleri almaktayız.</span></p>
    <p ><span>Bu doğrultuda, Kanun uyarınca kişisel verilerinizin işlenmesi, muhafaza edilmesi ve aktarılması kapsamında sizleri bilgilendirmek amacıyla işbu Kanun ve ilgili mevzuat kapsamında Aydınlatma Metni d&uuml;zenlenmiştir.</span></p>
    <p ><strong><span>1. Kişisel Verilerin Hangi Ama&ccedil;la İşlenebileceği</span></strong></p>
    <p ><span>Kişisel verileriniz, Kanun&rsquo;a uygun olarak, Easy Eğitim ve Teknoloji İşletmeleri tarafından sağlanan hizmet ve Easy Eğitim ve Teknoloji İşletmeleri Şirketinin ticari faaliyetlerine bağlı olarak değişkenlik g&ouml;sterebilme ile birlikte; otomatik ya da otomatik olmayan yollarla, Easy Eğitim ve Teknoloji İşletmeleri birimleri, internet sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla s&ouml;zl&uuml;, yazılı ya da elektronik olarak toplanabilecektir. &Ccedil;ağrı merkezlerimizi veya internet sayfamızı kullandığınızda veya internet sitemizi, sosyal medya mecralarını ziyaret ettiğinizde, kişisel verileriniz oluşturularak ve g&uuml;ncellenerek işlenebilecektir.</span></p>
    <p ><span>Kişisel veriler, Kanun&rsquo;un 5. ve 6. Maddelerinde belirtilen şartlara ve ilgili t&uuml;m mevzuata uygun olarak, Easy Eğitim ve Teknoloji İşletmeleri tarafından;</span></p>
    <p ><span>Yasal d&uuml;zenlemelerin gerektirdiği veya zorunlu kıldığı şekilde hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin yerine getirilmesini sağlamak,</span></p>
    <p ><span>Etkin bir m&uuml;şteri hizmetinin sunulabilmesi,</span></p>
    <p ><span>Hizmet ve tekliflerin sunulması,</span></p>
    <p ><span>Her t&uuml;rl&uuml; promosyon, kampanya, &ccedil;ekilişler hakkında bilgi verilmesi,</span></p>
    <p ><span>Her t&uuml;rl&uuml; pazarlama ve reklam faaliyetlerinin y&uuml;r&uuml;t&uuml;lebilmesi,</span></p>
    <p ><span>Ziyaret&ccedil;i profillerinin belirlenebilmesi,</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmelerinin ticari g&uuml;venilirliğinin sağlanabilmesi,</span></p>
    <p ><span>İstek, talep ve şikayetlerin cevaplanarak &ccedil;&ouml;z&uuml;mlenmesinin sağlanması,</span></p>
    <p ><span>S&ouml;zleşme kapsamında ve hizmet standartları &ccedil;er&ccedil;evesinde M&uuml;şteri&rsquo;lere ve Ziyaret&ccedil;iler&rsquo;e destek hizmetinin sağlanması,</span></p>
    <p ><span>Pazar araştırmaları ve istatistiksel &ccedil;alışmalar yapılabilmesi,</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmeleri ile iş ilişkisi i&ccedil;inde bulunan kişiler ile irtibat sağlanması,</span></p>
    <p ><span>Pazarlama, uyum y&ouml;netimi, satıcı/tedarik&ccedil;i y&ouml;netimi,</span></p>
    <p ><span>Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin planlanması, denetimi ve icrası,</span></p>
    <p ><span>Bilgi teknolojileri altyapısının oluşturulması ve y&ouml;netilmesi,</span></p>
    <p ><span>&Ccedil;alışanların Veri Sahibi bilgilerine erişim yetkilerinin planlanması ve icrası,</span></p>
    <p ><span>Faturalandırma da dahil, finans ve/veya muhasebe işlemlerinin takibi,</span></p>
    <p ><span>Hukuk işlerinin takibi,</span></p>
    <p ><span>Kurumsal iletişim faaliyetlerinin planlanması ve icrası,</span></p>
    <p ><span>Verilerin doğru ve g&uuml;ncel olmasının sağlanması amacıyla işlenebilecektir.</span></p>
    <p ><span>Operasyonel faaliyetlerinin yerine getirilmesi i&ccedil;in yazılım hizmetleri ve diğer dış kaynak hizmetlerinin sağlanması,</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmeleri ana s&ouml;zleşmelerinde belirtilen ticari faaliyetlerin mevzuata ve ilgili işletmepolitikalarına uygun olarak yerine getirilmesi i&ccedil;in ilgili birimler tarafından gerekli &ccedil;alışmaların yapılması ve bu doğrultuda faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi,</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmeleri kısa, orta, uzun vadeli ticari politikalarının tespiti, planlanması ve uygulanması.</span></p>
    <p ><strong><span>2. Kişisel Verilerin Aktarılması ve Aktarım Ama&ccedil;ları</span></strong></p>
    <p ><span>&nbsp;Kişisel verileriniz; Kanun tarafından &ouml;ng&ouml;r&uuml;len temel ilkelere uygun olarak ve Kanun&rsquo;un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve ama&ccedil;larına uygun olarak, Easy Eğitim ve Teknoloji İşletmelerinin meşru ve hukuka uygun kişisel veri işleme ama&ccedil;ları doğrultusunda, Easy Eğitim ve Teknoloji İşletmeleri tarafından aşağıda yer alan ama&ccedil;larla;</span></p>
    <p ><span>Kısa, orta ve uzun vadede ticari ve iş stratejilerini belirlenmesi, planlanması ve uygulanması</span></p>
    <p ><span>İş ilişkisi i&ccedil;erisinde olunan kişilerin hukuki ve ticari g&uuml;venliğinin temini Easy Eğitim ve Teknoloji İşletmeleri tarafından y&uuml;r&uuml;t&uuml;len iletişime y&ouml;nelik idari operasyonlar, Easy Eğitim ve Teknoloji İşletmelerine ait lokasyonların fiziksel g&uuml;venliğini ve denetimini sağlamak, iş ortağı/m&uuml;şteri/tedarik&ccedil;i (yetkili veya &ccedil;alışanları) değerlendirme s&uuml;re&ccedil;leri, itibar araştırma s&uuml;re&ccedil;leri, hukuki uyum s&uuml;reci, denetim, mali işler vb.),</span></p>
    <p ><span>İnsan kaynakları politikalarının y&uuml;r&uuml;t&uuml;lmesi temini ama&ccedil;larıyla Easy Eğitim ve Teknoloji İşletmeleri yetkililerine, iş ortaklarımıza, tedarik&ccedil;ilerimize, hissedarlarımıza, kanunen yetkili kurum ve kuruluşlara aktarılabilecektir.</span></p>
    <p ><span>Kişisel verilerinizin Easy Eğitim ve Teknoloji İşletmeleri tarafından aktarılması konusunda detaylı bilgilere,</span><a href="https://easyegitim.com/"><u><span style={{color: "rgb(17, 85, 204)"}}>&nbsp;https://easyegitim.com/</span></u></a><span>&nbsp;internet adresinden ulaşabileceğiniz Gizlilik ve Kişisel Verilerin Korunması İlkeleri başlığı altında yer verilmiştir.</span></p>
    <p ><strong><span>&nbsp;3. Yurtdışına Aktarım</span></strong></p>
    <p ><span>&nbsp;Kanun&rsquo;un 9. Maddesi uyarınca,Easy Eğitim ve Teknoloji İşletmelerinin meşru ve hukuka uygun kişisel veri işleme ama&ccedil;ları doğrultusunda kişisel verileriniz, Easy Eğitim ve Teknoloji İşletmeleri tarafından sunulan &uuml;r&uuml;n ve hizmetlerden sizleri faydalandırmak i&ccedil;in gerekli &ccedil;alışmaların iş birimlerimiz tarafından yapılması, Easy Eğitim ve Teknoloji İşletmelerinin, Easy Eğitim ve Teknoloji İşletmeleri ile iş ilişkisi i&ccedil;erisinde olan kişilerin hukuki ve ticari g&uuml;venliğinin temini (Şirketimiz tarafından y&uuml;r&uuml;t&uuml;len iletişime y&ouml;nelik idari operasyonlar, Easy Eğitim ve Teknoloji İşletmelerine ait lokasyonların fiziksel g&uuml;venliğini ve denetimini sağlamak, iş ortağı/m&uuml;şteri/tedarik&ccedil;i (yetkili veya &ccedil;alışanları) değerlendirme s&uuml;re&ccedil;leri, itibar araştırma s&uuml;re&ccedil;leri, hukuki uyum s&uuml;reci, denetim, mali işler v.b.), Easy Eğitim ve Teknoloji İşletmelerinin ticari ve iş stratejilerinin belirlenmesi ve uygulanması ile Easy Eğitim ve Teknoloji İşletmelerinin insan kaynakları politikalarının y&uuml;r&uuml;t&uuml;lmesinin temini ama&ccedil;larıyla, a&ccedil;ık rızanız var ise veya, a&ccedil;ık rızanız olmamasına rağmen, Kanun&rsquo;da d&uuml;zenlenen h&uuml;k&uuml;mler &ccedil;er&ccedil;evesinde, Yeterli korumaya sahip veya yeterli korumayı taahh&uuml;t eden veri sorumlusunun bulunduğu yabancı &uuml;lkelere aktarabilecektir.</span></p>
    <p ><strong><span>4. Kişisel Verilerin Toplanma Y&ouml;ntemi ve Hukuki Sebebi</span></strong></p>
    <p ><span>&nbsp;Kişisel verileriniz, hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerin gerektirdiği s&uuml;reyle ya da ilgili mevzuatta izin verilen s&uuml;reyle mevzuata uygun koşullarda saklanmaktadır. Kişisel verileriniz Kanun&rsquo;un 5. ve 6. maddelerinde belirtilen kişisel veri işlenme şartları ve ama&ccedil;ları kapsamında bu metnin 1. ve 2. maddelerinde belirtilen ama&ccedil;larla toplanabilecek, işlenebilecek, aktarılabilecek ve saklanabilecektir.</span></p>
    <p ><span>Kişisel verileriniz, Easy Eğitim ve Teknoloji İşletmeleri tarafından farklı yollardan ( Easy Eğitim ve Teknoloji İşletmeleri merkezi, şubeleri, acenteleri, satış ofisleri veya diğer alt y&uuml;klenicileri veya iş ortakları ile iletişime ge&ccedil;ebileceğiniz ofis ve diğer fiziki ortamlar, &ccedil;ağrı merkezleri, internet siteleri ve benzeri elektronik işlem platformları, sosyal medya veya diğer kamuya a&ccedil;ık mecralar aracılığıyla, d&uuml;zenleyecekleri eğitim, seminer ve benzeri ortamlara katılmanızla, tahkikat y&ouml;ntemiyle veya diğer grup şirketleri veya anlaşmalı oldukları diğer kişi ve kuruluşlar kanalıyla s&ouml;zl&uuml;, yazılı, ses veya g&ouml;r&uuml;nt&uuml; kaydı veya diğer fiziksel veya elektronik ortamda vb. ) elde edilebilir.</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmeleri b&uuml;nyesindeki şirketlerin faaliyetlerinin, Easy Eğitim ve Teknoloji İşletmeleri ilke, hedef ve stratejilerine uygun olarak y&uuml;r&uuml;t&uuml;lmesi, Easy Eğitim ve Teknoloji İşletmelerinin hak ve menfaatleri ile itibarının korunması amacıyla, Easy Eğitim ve Teknoloji İşletmeleri b&uuml;nyesindeki şirketlerden biri tarafından toplanan ve işlenmekte olan kişisel veriler, yine Easy Eğitim ve Teknoloji İşletmeleri b&uuml;nyesindeki diğer şirketlere aktarılabilir ve bu şirketler tarafından da işlenebilir.</span></p>
    <p ><strong><span>&nbsp;5. Kişisel Veri Sahibi Olarak Kanun&rsquo;un 11. Maddesinde Sayılan Haklarınız</span></strong></p>
    <p ><span>&nbsp;Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, aşağıda d&uuml;zenlenen y&ouml;ntemlerle Easy Eğitim ve Teknoloji İşletmelerine iletmeniz durumunda, Easy Eğitim ve Teknoloji İşletmeleri talebinizi niteliğine g&ouml;re en kısa s&uuml;rede ve en ge&ccedil; 30 (otuz) g&uuml;n i&ccedil;inde &uuml;cretsiz olarak sonu&ccedil;landıracaktır. Ancak, Kişisel Verileri Koruma Kurulunca bir &uuml;cret &ouml;ng&ouml;r&uuml;lmesi halinde, belirlenen tarifedeki &uuml;cret alınabilir.</span></p>
    <p ><span>Bu kapsamda kişisel veri sahipleri, Easy Eğitim ve Teknoloji İşletmelerine başvurarak aşağıdaki haklarını kullanabilir:</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmelerinin veri sahibinin kişisel verilerini işleyip işlemediğini &ouml;ğrenme,</span></p>
    <p ><span>Eğer Easy Eğitim ve Teknoloji İşletmeleri nezdinde kişisel veriler işleniyorsa, bu veri işleme faaliyeti hakkında bilgi talep etme,</span></p>
    <p ><span>Eğer Easy Eğitim ve Teknoloji İşletmeleri nezdinde kişisel veriler işleniyorsa, kişisel veri işleme faaliyetinin amacını ve işlenme amacına uygun kullanılıp kullanmadığını &ouml;ğrenme,</span></p>
    <p ><span>Eğer kişisel veriler yurti&ccedil;inde veya yurtdışında &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarılıyorsa, bu &uuml;&ccedil;&uuml;nc&uuml; kişiler hakkında bilgi talep etme,</span></p>
    <p ><span>Kişisel verilerin eksik veya yanlış işlenmiş olması halinde, bunların d&uuml;zeltilmesini talep etme,</span></p>
    <p ><span>Kişisel verilerin Easy Eğitim ve Teknoloji İşletmeleri nezdinde eksik veya yanlış işlenmiş olması halinde, kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bu durumun bildirilmesini talep etme,</span></p>
    <p ><span>Kişisel verilerin Kanun ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde kişisel verilerin silinmesini, yok edilmesini veya anonim hale getirilmesini isteme,</span></p>
    <p ><span>Kişisel verilerin işlenmesini gerektiren sebepler ortadan kalktıysa, bu durumun kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini talep etme,</span></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmeleri tarafından işlenen kişisel verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi ve bu analiz neticesinde ilgili kişinin aleyhine bir sonu&ccedil; doğduğunu d&uuml;ş&uuml;nd&uuml;ğ&uuml; sonu&ccedil;ların ortaya &ccedil;ıkması halinde, bu sonuca itiraz etme,</span></p>
    <p ><span>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması halinde zararın giderilmesini talep etme.</span></p>
    <p ><span>Kanun&rsquo;un 13(f.1) maddesi gereğince, yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı olarak veya Kişisel Verileri Koruma Kurulunun belirlediği diğer y&ouml;ntemlerle Easy Eğitim ve Teknoloji İşletmelerine iletebilirsiniz. Bu &ccedil;er&ccedil;evede Easy Eğitim ve Teknoloji İşletmelerine Kanun&rsquo;un 11. Maddesi kapsamında yapacağınız başvurularda yazılı olarak başvurunuzu ileteceğiniz kanallar ve usuller aşağıda a&ccedil;ıklanmaktadır.</span></p>
    <p ><span>Kanun&rsquo;un 11. maddesinde belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza y&ouml;nelik a&ccedil;ıklamalarınızı i&ccedil;eren talebinizi, kimliğinizi tespit edici belgeler ile birlikte, https://easy.vedubox.net/ adresindeki 6698 Sayılı Kişisel Verilerin Korunması Kanunu Kapsamında Kişisel Veri Sahibi Başvuru Formu&rsquo;nu eksiksiz olarak doldurarak;</span></p>
    <p ><span>Islak imzalı bir n&uuml;shasını &ldquo;Nispetiye Mah. Tuğcular Sk. &Ouml;zgen Apt. No:15 D:11 &nbsp;Beşiktaş, İstanbul&rdquo; adresine şahsen elden teslim edebilir,</span></p>
    <p ><span>noter vasıtasıyla veya iadeli taahh&uuml;tl&uuml; posta yoluyla yukarıdaki adresimize g&ouml;nderebilir,</span></p>
    <p ><span>G&uuml;venli Elektronik imza ile imzalayarak veya m&uuml;şteriye ait Easy&rsquo;de kayıtlı e-posta ile mail@easyegitim.com adresine iletebilirsiniz.</span></p>
    <p ><strong><span>6. Değişiklikler</span></strong></p>
    <p ><span>Easy Eğitim ve Teknoloji İşletmelerinin, Kişisel Verilerin Korunması Kanunu&rsquo;nda olabilecek değişiklikler ve Kişisel Verileri Koruma Kurulu tarafından belirlenecek y&ouml;ntemler nedeni ile bu aydınlatma bildiriminde değişiklik yapma hakkı saklıdır.</span></p>
 7. Pazarlama, segmentasyon, analiz çalışmaları ve e-bülten süreçleri kapsamında, Kimlik Bilgilerimin (ad, soyadı, doğum tarihi, cinsiyet), lletişim Bilgilerimin (e-posta adresi, cep telefonu numarası), Pazarlama Bilgilerimin (Internet Siteleri ve Mobil Uygulamalar kullanım/event detayları, elektronik ticari ve sair iletişimlere yaklaşım/aksiyonlar, sipariş bilgisi, geçmiş alışveriş bilgileri, favorilerim, son incelenen ürünler, takip ettiklerim, karşılaştırma listesine eklenen ürünler, puanlarım (kazanılan/bekleyen puanlar) anketlere verilen cevaplar) ve Diğer Bilgilerimin (beden ölçüsü, spor tecrübesi bilgileri) genel veya tarafıma özel kişiselleştirilmiş kampanyalar, avantajlar, promosyonların oluşturulması ve sağlanması, segmentasyon, raporlama, profilleme, pazarlama ve analiz çalışmalarııı yapııması, kampanya, yarışma, çekiliş, anket ve diğer etkinliklerin düzenlenmesi, kampanya, tanıtım ve promosyon gibi çeşitli pazarlama aktivitelerine ilişkin bilgilendirmelerin e-postayla tarafınıza gönderilmesi ve e-posta yoluyla ürünlere ilişkin güncelleme ve tanıtımların yapııması çerçevesinde e-bülten süreçlerinin yürütülmesi kapsamında firma, ürün ve hizmetlere bağllıık süreçlerinin yürütülmesi, iş süreçlerinin iyileştirilmesine yönelik önerilerin alınması ve değerlendirilmesi, müşteri ilişkileri yönetimi süreçlerinin yürütülmesi, müşteri memnuniyetine yönelik aktivitelerin yürütülmesi, reklam, kampanya ve promosyon süreçlerinin yürütülmesine ürün ve hizmetlerin pazarlama süreçlerinin yürütülmesi amaçlarıyla işlenmesine açık rıza veriyorum.
Easy Eğitim ve Telnoloj İşletmeleri tarafından, genel veya bana özel kişiselleştirilmiş kampanyalar, avantajlar, promosyonlar, reklamlar, bilgilendirmeler, anketler ve pazarlama faaliyetleri kapsamında benimle ticari iletişim kurulması, belirli bir süre ile alışveriş yapmamış olmam halinde tarafımdan geri bildirim alınması iç̧in benimle iletişime geçilmesi, tarafıma hediye ürün sunulması ve hediye ürüne ilişkin geri bildirimlerimin alınması ile kampanya, tanıtım ve promosyon gibi çeşitli pazarlama aktivitelerine ilişkin bilgilendirmelerin e-postayla tarafıııza gönderilmesi ve e-posta yoluyla ürünlere ilişkin güncelleme ve tanıtımların yapılması çerçevesinde e-bülten süreçlerinin yürütülmesi kapsamında tarafımla elektronik ticari iletişim kurulmasına izin ve bu amaçlarla lletişim Bilgilerimin (e-posta adresi, telefon numarası) işlenmesine açık rıza veriyorum.
    </div>
  )
}

export default AydinlatmaMetni