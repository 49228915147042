import React from 'react';
import { Modal } from '@mantine/core';
import { AlertTriangle, CheckCircle, XCircle, Info } from 'lucide-react';
import StyledButton from './StyledButton';

const ConfirmationModal = ({ 
  isOpen, 
  title, 
  message, 
  onConfirm, 
  onCancel, 
  t,
  variant = 'default',
  confirmText,
  cancelText,
  icon,
  size = 'md',
  loading = false
}) => {
  const variants = {
    default: {
      icon: Info,
      confirmButtonVariant: 'primary',
      iconColor: 'text-blue-500',
      headerBg: 'bg-blue-50',
    },
    danger: {
      icon: AlertTriangle,
      confirmButtonVariant: 'danger',
      iconColor: 'text-red-500',
      headerBg: 'bg-red-50',
    },
    success: {
      icon: CheckCircle,
      confirmButtonVariant: 'success',
      iconColor: 'text-green-500',
      headerBg: 'bg-green-50',
    },
    warning: {
      icon: AlertTriangle,
      confirmButtonVariant: 'warning',
      iconColor: 'text-amber-500',
      headerBg: 'bg-amber-50',
    }
  };

  const currentVariant = variants[variant];
  const IconComponent = icon || currentVariant.icon;

  return (
    <Modal
      opened={isOpen}
      onClose={onCancel}
      radius="lg"
      padding={0}
      size={size}
      classNames={{
        header: 'hidden',
        body: 'p-0',
      }}
    >
      <div className="overflow-hidden rounded-lg">
        {/* Header */}
        <div className={`px-6 py-4 ${currentVariant.headerBg} border-b border-gray-200`}>
          <div className="flex items-center gap-3">
            <IconComponent className={`w-6 h-6 ${currentVariant.iconColor}`} />
            <h3 className="text-lg font-semibold text-gray-900">
              {title}
            </h3>
          </div>
        </div>

        {/* Content */}
        <div className="px-6 py-4 bg-white">
          <p className="text-gray-600">
            {message}
          </p>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
          <div className="flex justify-end gap-3">
            <StyledButton
              variant="ghost"
              onClick={onCancel}
              disabled={loading}
            >
              {cancelText || t('cancel')}
            </StyledButton>
            <StyledButton
              variant={currentVariant.confirmButtonVariant}
              onClick={onConfirm}
              loading={loading}
            >
              {confirmText || t('confirm')}
            </StyledButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;