import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/authApiSlice";
import { paraphraserApiSlice } from './api/paraphraserApiSlice';
import { humanizerApiSlice } from './api/humanizerApiSlice';
import { summarizerApiSlice } from "./api/summarizerApiSlice";
import { questionAnswerApiSlice } from "./api/questionAnswerApiSlice";
import { articleForgerApiSlice } from "./api/articleForgerApiSlice";
import { virtualTeacherApiSlice } from "./api/virtualTeacherApiSlice";
import { toolHistoriesApiSlice } from "./api/toolHistoriesApiSlice";
import { lessonApiSlice } from './api/lessonApiSlice';
import { paymentApiSlice } from './api/paymentApiSlice';
import  { transactionApiSlice } from './api/transactionApiSlice';
import apiMiddleware from './middleware/apiMiddleware';
import authReducer from "./slices/authSlice";
import toolHistoriesReducer from './slices/toolHistoriesSlice'
import * as Sentry from "@sentry/react";

const sentryMiddleware = () => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    Sentry.captureException(error);
    throw error; // Rethrow to avoid unexpected state issues
  }
};

export default configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [paraphraserApiSlice.reducerPath]: paraphraserApiSlice.reducer,
    [summarizerApiSlice.reducerPath]: summarizerApiSlice.reducer,
    [questionAnswerApiSlice.reducerPath]: questionAnswerApiSlice.reducer,
    [articleForgerApiSlice.reducerPath]: articleForgerApiSlice.reducer,
    [virtualTeacherApiSlice.reducerPath]: virtualTeacherApiSlice.reducer,
    [toolHistoriesApiSlice.reducerPath]: toolHistoriesApiSlice.reducer,
    [lessonApiSlice.reducerPath]: lessonApiSlice.reducer,
    [paymentApiSlice.reducerPath]: paymentApiSlice.reducer,
    [transactionApiSlice.reducerPath]: transactionApiSlice.reducer,
    [humanizerApiSlice.reducerPath]: humanizerApiSlice.reducer,
    toolHistories: toolHistoriesReducer, // Add the toolHistoriesApiSlice reducer
    auth: authReducer,
  
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddleware, sentryMiddleware),
  devTools: true,
});
