const UyelikSozlesmesi = () => {
      return (
        <div>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kullanıcı Sözleşmesi</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>1. Sözleşmenin Tarafları</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>1.1 Etiler Mah. Tuğcular Sk. No:15 D:9 34337 Beşiktaş, İstanbul adresine mukim Easy Eğitim ve Teknoloji İşletmeleri (“Easy”) ile diğer tarafta tüketici (“Kullanıcı”) arasında, Kullanıcı’nin Sözleşme’yi okuduğu ve onay verdiğini dijital ortamda kabulü ile yürürlüğe girmiştir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>1.2. Üye, Sözleşme hükümlerinin tamamını okuduğunu, anladığını, onayladığını ve easyegitim.com adresinde yer alan “Gizlilik ve Kişisel Verileri Korunması Politikası” metninin işbu Sözleşme’nin ayrılmaz bir parçası ve eki olduğunu kabul, beyan ve taahhüt eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Hizmetler:</span></strong><span style={{fontSize: '11px'}}>&nbsp;EASY tarafından easyegitim.com üzerinden kullanıcılara sunulan hizmetleri ifade eder. Bu hizmetlere Akademik Danışmanlık, Soru Çözüm Desteği, ve EASY tarafından geliştirilmiş Dijital Asistan dahildir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>EASY:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Eğitim ve öğretim amaçlı teknoloji geliştiricisi olarak kurulmuş olan easyegitim.com uzantılı internet sitesini ifade eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>2. Sözleşmenin Konusu</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>2.1 Bu sözleşme Easy ile sözleşmeyi okuyup “Kayıt Ol” butonuna basarak kabul eden Easy hizmetinden yararlanmak isteyen kişi arasında aşağıda detayları açıklanan Online Eğitim Hizmeti için imzalanmıştır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>2.2 Kullanıcı bu hizmet sözleşmesini okuyup kabul ettikten sonra bu hizmetten yararlanabilir ve sadece bu anlaşmada geçen maddelerde belirtilmiş haklara sahiptir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>2.3 Bu hizmeti ilk defa kullanmadan önce Kullanım Sözleşmesinin tamamını dikkatle okuyunuz. Kullanıcı bu hizmeti ilk kullanımından veya çalıştırmasından itibaren burada geçen anlaşma koşullarından haberdar kabul edilir ve bu metni okuduğu, anladığı ve buradaki koşullarla bağlı olduğunu kabul etmiş sayılır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>2.4 İş bu sözleşmeyi kabul etmekle birlikte kullanıcı fiil ehliyetine sahip olduğunu kabul eder. Kullanıcı 18 yaşından küçük olması halinde Easy Uygulamasından velisinin onayı ve vasıtasıyla faydalanabileceğini kabul eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>2.5 Kullanıcı bu hükümleri kabul etmemesi halinde hizmetten yararlanamaz, özel olarak hazırlanmış videoları izleyemez, görselleri göremez.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>3. Kullanıcı Hak ve Yükümlülükleri</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.1 Bu hizmetle ilgili tüm fikri ve sınai haklar Easy’e ait olup, eserin izinsiz olarak kullanılması, çoğaltılması, yayılması, satışa sunulması ve benzeri durumlarda ilgili kişi veya kişiler hakkında hukuki ve cezai yaptırımlara başvurulacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.2</span><span style={{fontSize: '11px'}}>&nbsp;İ</span><span style={{fontSize: '11px'}}>nternet sitesi içindeki sunumların, yazılımların, grafik kullanıcı arabirimlerinin, görsellerin, seslerin ve videoların kullanıcı tarafından aşağıda belirtilen hükümlere uygun olarak kullanılmasını şart koşar.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.3 Kullanıcı münhasıran eserin kullanma hakkına sahiptir. "Kullanım Hakkı", ürünün kullanılmasını, saklanmasını, yüklenmesini, çalıştırılmasını ve görüntülenmesini kullanıcının paketi satın aldığı süre boyunca içermektedir. Kullanım hakkı tanınan kullanıcı eserde değişiklik yapamaz, eseri çoğaltamaz, eseri yayamaz, başkalarının kullanımına sunamaz, eserden yararlanarak yeni bir eser oluşturamaz, eserin lisans ya da kontrol özelliklerinden herhangi birini devre dışı bırakamaz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.4 Bu ürün ister bireysel, ister kurumsal olarak satın alınmış olunsun, Easy tarafından tek bir gerçek kişi üzerine kaydedilir ve sadece o kişi tarafından belirtilen süre içinde kullanılabilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.5 Ürün hakkında yeterli bilgiye ve sistem gereksinimlerine sahip olduğuna inanan kullanıcı, telefon numarası, e-posta adresi, adı ve soyadı, üniversite ve bölüm bilgileri ile easyegitim.com internet&nbsp;</span><span style={{fontSize: '11px'}}>sitesinden hesabını oluşturur. Telefon numarası veya e-posta, ve telefon numarasına SMS olarak gönderilen giriş kodu ile hesabına giriş yapar.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.6 Üye, sözleşme kapsamında kendisine daha önce başka üyelerce seçilmemiş, Easy tarafından belirlenen uzantılara sahip e-posta adresi ve telefon numarası belirler. E-posta ve telefon numarası seçimi, SMS ile gönderilen giriş kodlarının korunması tamamıyla üyenin sorumluluğundadır. SMS kodu alamayan üye giriş ekranında belirlenen süre geçtikten sonra yeni bir giriş kodu gönderilmesini isteyebilir. Telefon numarası seçimi ve SMS giriş kodlarının korunması tamamıyla kullanıcının sorumluluğundadır. Easy, kullanıcı tarafından SMS giriş kodlarının yanlış kullanımından, gönderici veya alıcı telekom şirketlerinin SMS giriş kodunu üyeye iletememesinden doğacak problemlerden kesinlikle sorumlu değildir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.7 Kullanıcının üyeliği gerektiren servislere bağlanabilmesi için telefon numarasını veya e-posta adresini ve telefon numarasına gönderilecek SMS giriş kodunu girmesi gereklidir. Bu işlem üye girişi yapmak şeklinde tanımlanır. Üyenin faydalanacağı uygulamalar, bedelini ödediği paketlere bağlı olarak giriş yaptıktan sonra üyeye gösterilecektir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.8 Kullanıcı olma kişiseldir. Başkasına devredilemez ve kullandırılamaz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.9 Kullanıcı, Easy Eğitim Platformu hizmetlerinden yararlandığı sırada, üyelik formunda yer alan bilgilerin doğru olduğunu ve bu bilgilerin gerekli olduğu (telefonuna SMS kodu için erişememe gibi) durumlarda bilginin hatalı veya eksik olmasından doğacak zararlardan dolayı sorumluluğun kendisine ait olduğunu, bu hallerde üyeliğinin sona erebileceğini kabul eder. Kullanıcı üyeliğin devamı sürecinde Easy Uygulamaları tarafından istenilen gerekli bilgileri eksiksiz ve doğru olarak vermekle yükümlüdür.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.10 Kullanıcı bilgilerindeki üniversite, adres, telefon vb. gibi her türlü değişikliklerde Easy’e bilgi vermek zorundadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>3.11 Sisteme giriş yapan kullanıcı satın almak istediği hizmeti seçer, yönergeleri takip ederek ödeme işlemini gerçekleştirir ve işletmetarafından kullanıma sunulmuş olan içeriklere erişim hakkını satın alır. Kullanıcı almış olduğu hizmeti sadece kendi kullanabilir. Bu hizmeti başkalarının kullanımına açamaz, kiraya veremez.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>4. Telif Hakları ve Kullanım Kısıtlamaları</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>4.1 Sunumlar, görseller, metinler, videolar, ses kayıtları ve konuşmaların bütün telif hakları Easy’e aittir. Kullanıcı; sunumları, görselleri, metinleri, videoları ses kayıtlarını ya da konuşmaları Easy’den izin almadan kullanamaz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>4.2 Özel olarak hazırlanmış olan videoların veya sağlanan materyallerin toplu halde izinsiz gösterimleri veya kullanımı olduğu takdirde gösterimi yapan organizasyon, şirket, kişi veya kurum; faaliyete katılan her bir kişi için gerekli olan kullanım bedelini Easy’e ödemekle yükümlüdür. Söz konusu gösterim için şifre bilgilerini veren ya da paylaşan kullanıcı doğacak olan hukuki ve cezai müeyyidelerden sorumludur.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>4.3 Özel olarak hazırlanmış olan videoları veya içinden alınmış görsel malzemeyi, sunumları, sesleri ve ilgili olabilecek her türlü medyayı aynı veya alıntılanmış şekilde herhangi bir medya şeklinde çoğaltmak, kopyalamak, yayınlamak ya da dağıtmak kesinlikle yasaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>4.4 Kullanıcı Easy Eğitim platformu</span><span style={{fontSize: '11px'}}>&nbsp;</span><span style={{fontSize: '11px'}}>internet sitesine ilişkin hiçbir yazılım veya içeriği kopyalayamaz, uyarlayamaz, değiştiremez.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>5. Sözleşme Bedeli ve Ödeme</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>5.1 Sözleşme bedeli tüm vergiler dahil,&nbsp;</span><span style={{fontSize: '11px'}}>internet sitesinde yazılı olarak belirtilen paket ücretidir. İnternet sayfasında gösterilen şekilde gerekli ödeme işleminin tam olarak yapılması şarttır. Bu konuda kullanıcı tarafından yapılabilecek hatalar nedeni ile üyelikte ve ödemede çıkabilecek aksaklıklardan Easy sorumlu değildir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>5.2 İnternet üzerinden kredi kartı ile yapılan ödemelerin bedelleri üyenin kredi kartından otomatik olarak çekilir. Ödeme işlemi onaylandıktan sonra satın alınan hizmet paketleri kullanıma açılır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>5.3 Satın alınan paketin kullanım süresi&nbsp;</span><strong><span style={{fontSize: '11px'}}>4 (dört) aydır.&nbsp;</span></strong><span style={{fontSize: '11px'}}>Dört ay içerisinde kullanılmayan haklar geçerliliğini yitirir, kullanılamaz ve gelecek seneye devredilemez.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>5.4 Easy paket ücretlerinde indirim veya artırım uygulamasını döneme göre değiştirme hakkına sahiptir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>5.5 Kullanıcı üyelerin satın aldıkları ürünün satış, teslim ve iadesi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir. Easy tarafından internet sitesinde satılan tüm ürünler Mesafeli Sözleşmeler Yönetmeliği’nin 15 maddesinin “ğ” bendinde sayılan Cayma Hakkının Kullanılamadığı Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallardan olduğu için hiçbir şekilde iade söz konusu olamaz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>6. Özel Hükümler</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>6.1 Kullanıcı verilerinin, Easy’nin ihmali görülmeden yetkisiz kişilerce okunması nedeni ile meydana gelebilecek zararlardan ötürü Easy’nin sorumlu olmayacağını kabul eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>6.2 Kullanıcı, kurallara aykırı davrandığı takdirde Easy gerekli müdahalelerde bulunma, üyeyi servis dışına çıkarma ve üyeliğe son verme hakkına sahip olduğunu kabul eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>6.3 Kullanıcı, hizmetin kullanımı ile ilgili internetten kaynaklanan sorunlardan Easy’i sorumlu tutmayacağını kabul eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>6.4 Kullanıcı site geneline zarar verecek veya Easy’i başka sitelerle davalık duruma getirecek zarar verici iş ve eylemlerde bulunmayacağını taahhüt eder. Bulunulduğu takdirde bundan doğacak zararlardan kullanıcı sorumlu olacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>7. Uygulanacak Hükümler ve Yetkili Merci</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>7.1 Bu sözleşmeyle ilgili olarak çıkabilecek ihtilaflarda öncelikle bu sözleşmedeki hükümler ve bu sözleşmede hüküm bulunmayan hallerde ise Türk Kanunları uygulanacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0) rgb(0, 0, 0) rgb(229, 231, 235)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>7.2 İşbu sözleşmenin uygulanmasından doğabilecek her türlü uyuşmazlıkların çözümünde Beşiktaş mahkeme ve icra müdürlüklerinin yetkili olduğu kararlaştırılmıştır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.5e0flmnh7tkv" /><strong><span style={{fontSize: '11px'}}>8.&nbsp;</span></strong><strong><span style={{fontSize: '11px'}}>Gizlilik ve Kişisel Verileri Koruma Politikası</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.yqutb8lxf6ug" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>1. Amaç ve Kapsam</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy (Easy Eğitim ve Teknoloji İşletmeleri) olarak kişisel verilerinizin güvenliği hususuna azami hassasiyet göstermekteyiz. Kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK)’na uygun olarak işlenmekte ve muhafaza edilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bu web sitesini ziyaret etmeniz ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmanız sırasında, size ve talep ettiğiniz hizmetlere ilişkin olarak elde ettiğimiz bilgilerin ne şekilde kullanılacağı ve korunacağı, işbu "Gizlilik Politikası"nda belirtilen şartlara tabidir. Bu web sitesini ziyaret etmekle ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmayı talep etmekle işbu "Gizlilik Politikası"nda belirtilen şartları kabul etmektesiniz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.21y5h2jgbydd" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>2. Tanımlar</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Easy:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Nispetiye Mah. Tuğcular Sk. No:15 D:9 34337 Beşiktaş, İstanbul adresine mukim Easy Eğitim ve Teknoloji İşletmeleri</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Açık Rıza:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Anonim Hâle Getirme:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Başvuru Formu:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel veri sahiplerinin haklarını kullanmak için yapacakları başvuruyu içeren “6698 Sayılı Kişisel Verilerin Korunması Kanunu Gereğince İlgili Kişi (Veri Sahibi) Tarafından Veri Sorumlusuna Yapılacak Başvurulara İlişkin Başvuru Formu”.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Çalışan:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Easy personeli.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Çalışan Adayı:</span></strong><span style={{fontSize: '11px'}}>Easy’e herhangi bir yolla iş başvurusunda bulunmuş ya da özgeçmiş ve ilgili bilgilerini şirketimizin incelemesine açmış olan gerçek kişiler</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>İmha:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>İş Ortağı:</span></strong><span style={{fontSize: '11px'}}>Easy’nin ticari faaliyetlerini yürütürken, birlikte muhtelif projeler yürütmek, hizmet almak gibi amaçlarla iş ortaklığı kurduğu taraflar. &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kanun/KVKK:</span></strong><span style={{fontSize: '11px'}}>&nbsp;6698 Sayılı Kişisel Verilerin Korunması Kanunu</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kişisel Veri:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kişisel Verilerin İşlenmesi:</span></strong><span style={{fontSize: '11px'}}>Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kişisel Verilerin Silinmesi:</span></strong><span style={{fontSize: '11px'}}>Kişisel verilerin silinmesi, kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kişisel Verilerin Yok Edilmesi:</span></strong><span style={{fontSize: '11px'}}>Kişisel verilerin yok edilmesi, kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kişisel Verilerin Anonim Hale Getirilmesi:</span></strong><span style={{fontSize: '11px'}}>Kişisel verilerin anonim hale getirilmesi, kişisel verilerin başka verilerle eşleştirilirse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kurul:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel Verileri Koruma Kurulu</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kurum:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel Verileri Koruma Kurumu</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Müşteri:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Easy ile herhangi bir sözleşmesel ilişkisi olup olmadığına bakılmaksızın Easy iş birimlerinin yürüttüğü operasyonlar kapsamında Easy iş ilişkileri üzerinden kişisel verileri elde edilen gerçek kişiler.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Özel Nitelikli Kişisel Veri:</span></strong><span style={{fontSize: '11px'}}>Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik veriler.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Periyodik İmha:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kanunda yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla resen gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Politika:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Easy tarafından, Kanun uyarınca hazırlanan, işbu kişisel verilerin korunması ve işlenmesi politikası.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>işletmeHissedarı:</span></strong><span style={{fontSize: '11px'}}>Easy’nin hissedarı gerçek kişiler.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>işletmeYetkilisi:</span></strong><span style={{fontSize: '11px'}}>İşletme adına imza yetkisine sahip gerçek kişiler . &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Tedarikçi:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Easy’nin ticari faaliyetlerini yürütürken, &nbsp;Easy’nin emir ve talimatlarına uygun olarak, sözleşme temelli olarak, &nbsp;Easy’e hizmet sunan taraflar.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Veri İşleyen:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Veri Kayıt Sistemi:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Veri Sorumlusu:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Veri Sahibi/İlgili Kişi:</span></strong><span style={{fontSize: '11px'}}>Kişisel verisi işlenen gerçek kişi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Yönetmelik:</span></strong><span style={{fontSize: '11px'}}>&nbsp;28.10.2017 Tarihli Resmi Gazete’de yayımlanan “Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik”.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Ziyaretçi:</span></strong><span style={{fontSize: '11px'}}>&nbsp;Easy’nin faaliyet gösterdiği fiziksel yerleşkelere çeşitli amaçlarla girmiş olan veya internet sitelerimizi ziyaret eden gerçek kişiler.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.yct4g0vdu23u" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3. KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN USUL VE ESASLAR</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.78gyr43nzbz0" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.1. GENEL İLKELER</span></strong>&nbsp;</p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Anayasa’nın m. 20/III kişisel verilerin ancak kanunda öngörülen hallerde veya kişinin açık rızasıyla işlenebileceği belirtilerek kişisel verilerin korunması güvence altına alınmıştır. Kişisel veri sahiplerine tanınan bu hak doğrultusunda Easy, kişisel verileri ilgili mevzuatta belirtilen ilkeler doğrultusunda veya kişinin açık rızasının bulunduğu durumlarda aşağıdaki ilkelere uygun olarak işlemektedir:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.xkoy2u3ou8ro" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.1.1. Hukuka ve Dürüstlük Kuralına Uygun İşleme</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, Kişisel Verilerin işlenmesinde, tüm hukuki düzenlemeler ve dürüstlük kuralına uygun hareket etmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.wr650nad6c0a" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.1.2. Kişisel Verilerin Doğru ve Gerektiğinde Güncel Olmasını Sağlama</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından işlenen Kişisel Verilerin doğru ve güncel duruma uygun olması için gerekli tedbirler alınmakta ve işlenmekte olan verilerin gerçek durumu yansıtmasını sağlamak amacıyla bilgilendirmelerde bulunularak Veri Sahipleri’ne gerekli imkânlar tanınmaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.r2cb9y92qrs8" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.1.3. Belirli, Açık ve Meşru Amaçlarla İşleme</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, meşru ve hukuka uygun olan kişisel veri işleme amacını açık ve kesin olarak belirlemektedir. Easy, yürütmekte olduğu ticari faaliyet ile bağlantılı ve bunlar için gerekli olan kadar işlemektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.34xdj9yplg7q" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.1.4. İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olma</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, Kişisel Verileri belirlenen amaçların gerçekleştirilebilmesine elverişli bir biçimde işlemekte ve amacın gerçekleştirilmesi ile ilgili olmayan veya ihtiyaç duyulmayan Kişisel Verilerin işlenmesinden kaçınmaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.fcush3xrzy2j" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.1.5. İlgili Mevzuatta Öngörülen veya İşlendikleri Amaç İçin Gerekli Olan Süre Kadar Muhafaza Etme</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından işlenen kişisel veriler, ancak ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilmektedir. Bu kapsamda, Easy, ilgili mevzuatta verilerin saklanması için öngörülen bir süre varsa bu süreye uymakta; böyle bir süre yoksa verileri, ancak işlendikleri amaç için gerekli olan süre kadar muhafaza etmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.p0aitwgfi9ii" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.2. KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından elde edilen Kişisel Verileriniz, aşağıda açıklanan kapsamlar dahilinde işlenebilecektir:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde hukuki yükümlülüklerimizin yerine getirilmesini sağlamak,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Operasyonel faaliyetlerinin yerine getirilmesi için yazılım hizmetleri ve diğer dış kaynak hizmetlerinin sağlanması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından sunulan hizmet ürün ve projelerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek önerilmesi, ürün, hizmet ve projeler hakkında bilgi verilmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Etkin bir müşteri hizmetinin sunulabilmesi,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Hizmet ve tekliflerin sunulması,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Her türlü promosyon, kampanya, çekilişler hakkında bilgi verilmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Her türlü pazarlama ve reklam faaliyetlerinin yürütülebilmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Ziyaretçi profillerinin belirlenebilmesi,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin ticari güvenilirliğinin sağlanabilmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>İstek, talep ve şikayetlerin cevaplanarak çözümlenmesinin sağlanması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Sözleşme kapsamında ve hizmet standartları çerçevesinde Müşteri’lere ve Ziyaretçiler’e destek hizmetinin sağlanması, &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Pazar araştırmaları ve istatistiksel çalışmalar yapılabilmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy ile iş ilişkisi içinde bulunan kişiler ile irtibat sağlanması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Pazarlama, uyum yönetimi, satıcı/tedarikçi yönetimi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bilgi teknolojileri altyapısının oluşturulması ve yönetilmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Çalışanların Veri Sahibi bilgilerine erişim yetkilerinin planlanması ve icrası,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Faturalandırma da dahil, finans ve/veya muhasebe işlemlerinin takibi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Hukuk işlerinin takibi,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kurumsal iletişim faaliyetlerinin planlanması ve icrası,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Verilerin doğru ve güncel olmasının sağlanması.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy ana sözleşmesinde belirtilen ticari faaliyetlerin mevzuata ve Easy politikalarına uygun olarak yerine getirilmesi için ilgili birimler tarafından gerekli çalışmaların yapılması ve bu doğrultuda faaliyetlerin yürütülmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin kısa, orta, uzun vadeli ticari politikalarının tespiti, planlanması ve uygulanması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.pvsq3kstrnx4" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.3. KİŞİSEL VERİLERİN İŞLENME ŞARTLARI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanun ile kişisel verilerin işlenme koşulları düzenlenmiş olup, Easy tarafından kişisel veriler aşağıda belirtilen söz konusu koşullara uygun olarak işlenmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanun'da sayılan istisnalar dışında, Easy ancak veri sahiplerinin açık rızasını temin etmek suretiyle kişisel veri işlemektedir. Kanun’da sayılan aşağıdaki hallerin varlığı durumunda ise, veri sahibinin açık rızası olmasa dahi kişisel veriler işlenebilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanunlarda açıkça öngörülmesi,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri sahibinin kendisi tarafından alenileştirilmiş olması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.7cxqi84lf25a" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.4. KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN ÖNLEMLER</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, KVK Kanunu’nun 12. maddesine uygun olarak, işlemekte olduğu kişisel verilerin hukuka aykırı olarak işlenmesini önlemek, verilere hukuka aykırı olarak erişilmesini önlemek ve verilerin muhafazasını sağlamak için uygun güvenlik düzeyini sağlamaya yönelik gerekli teknik ve idari tedbirleri almakta, bu kapsamda gerekli denetimleri yapmak veya yaptırmaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bu kapsamda Easy tarafından gerekli (a) idari ve (b) teknik tedbirler alınmalı, (c) işletmebünyesinde denetim sistemi kurulmalı ve (d) kişisel verilerin kanuni olmayan yollarla ifşası durumunda KVK Kanunu’nda öngörülen tedbirler alınmalıdır. Easy, kişisel verilerin hukuka uygun işlenmesini sağlamak için, teknolojik imkânlar ve uygulama maliyetine göre teknik ve idari tedbirler almaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.1etid73yhdle" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.4.1. Kişisel Verilerin Hukuka Uygun İşlenmesini Sağlamak ve Kişisel Verilere Hukuka Aykırı Erişilmesini Önlemek ve Kişisel Verilerin Güvenli Ortamda Saklanması için Alınan Teknik Tedbirler</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel verilerin korunmasına ilişkin olarak, teknolojinin imkân verdiği ölçüde teknik önlemler alınmalı ve alınan önlemler periyodik olarak güncellenmeli ve yenilenmelidir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Teknik konularda, uzman personel istihdam edilmelidir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Alınan önlemlerin uygulanmasına yönelik düzenli aralıklarla denetim yapılmalıdır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Güvenliği temin edecek yazılım ve donanımlar kurulmalıdır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından işlenmekte olan kişisel verilere erişim yetkisi, belirlenen işleme amacı doğrultusunda ilgili işletmeçalışanı ile sınırlandırılmalıdır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.5x41x0gtjx9w" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.4.2. Kişisel Verilerin Hukuka Uygun İşlenmesini Sağlamak ve Kişisel Verilere Hukuka Aykırı Erişilmesini Önlemek ve Kişisel Verilerin Güvenli Ortamda Saklanması için Alınan İdari Tedbirler</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy kişisel verilerin korunması hukukuna ilişkin olarak çalışanlarını bilgilendirmekte ve eğitmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından kişisel verilerin hukuka uygun olarak aktarıldığı kişiler ile akdedilen sözleşmelere; kişisel verilerin aktarıldığı kişilerin, kişisel verilerin korunması amacıyla gerekli güvenlik tedbirlerini alacağına ve kendi kuruluşlarında bu tedbirlere uyulmasını sağlayacağına ilişkin hükümler eklenmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından gerçekleştirilen süreçler detaylı olarak incelenmeli, süreç kapsamında yürütülen kişisel veri işleme faaliyetleri her birim özelinde tespit edilmelidir. Bu kapsamda, yürütülen veri işleme faaliyetlerinin KVK Kanunu'nda öngörülen kişisel veri işleme şartlarına uygunluğunun sağlanması için atılması gereken adımlar belirlenmelidir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, işletmeyapılarına göre hukuksal uyum gerekliliklerinin sağlanması için yerine getirilmesi gereken uygulamaları tespit etmeli, bu uygulamaların denetimini ve sürekliliğini sağlamak için gerekli idari tedbirler, işletmeiçi politikalar ve eğitimler düzenlemelidir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy ile çalışanlar arasındaki hukuki ilişkiyi yöneten sözleşme ve belgelere, Easy’nin talimatları ve kanunla getirilen istisnalar dışında, kişisel verileri işlememe, ifşa etmeme ve kullanmama yükümlülüğü getiren kayıtlar konulmakta ve bu konuda çalışanların farkındalığı yaratılmakta ve denetimler yürütülmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Çalışanlar, öğrendikleri kişisel verileri KVK Kanunu hükümlerine aykırı olarak başkasına açıklayamayacağı ve işleme amacı dışında kullanamayacağı ve bu yükümlülüğün görevden ayrılmalarından sonra da devam edeceği konusunda bilgilendirilmekte ve bu doğrultuda kendilerinden gerekli taahhütler alınmaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.tz5ocu8wi8mh" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.4.3. Kişisel Verilerin Korunmasına İlişkin Alınan Tedbirlerin Denetim Faaliyetleri</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, KVK Kanunu’nun 12. Maddesine uygun olarak, kişisel verilerin korunması ve güvenliğinin sağlanması kapsamında alınan teknik ve idari tedbirlerin gerekli denetimlerini yapmakta veya yaptırmaktadır. Bu denetim sonuçları Easy’nin iç işleyişi kapsamında konu ile ilgili birime raporlanmakta ve alınan tedbirlerin iyileştirilmesi için gerekli faaliyetler yürütülmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.aynwzqf5xltj" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.5. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENME ŞARTLARI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi açısından korunmasının çeşitli açılardan daha kritik önem teşkil ettiğine inanılan Özel Nitelikli Kişisel Verilerin işlenmesinde, Easy tarafından özel hassasiyet gösterilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanun’un 6. maddesinde, hukuka aykırı olarak işlendiğinde kişilerin mağduriyetine veya ayrımcılığa sebep olma riski taşıyan bir takım kişisel veriler “özel nitelikli” olarak belirlenmiştir. Bu veriler; ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik verilerdir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Özel Nitelikli Kişisel Veriler, Easy tarafından, Kanun’a uygun bir şekilde, Kurul’ca belirlenecek yeterli önlemlerin alınması kaydıyla, aşağıdaki şartların varlığı halinde işlenmektedir: &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin açık rızası var ise veya</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin açık rızası yok ise;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>(i) Veri Sahibi’nin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler, kanunlarda öngörülen hallerde, &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>(ii) Veri Sahibi’nin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel verileri ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından işlenmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.t6acjzown5pr" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.6. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN ÖNLEMLER</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, Kanun’un 6. Maddesinde yer alan, Özel Nitelikli Kişisel Verilerin işlenmesinde, Kurul’un 31.01.2018 Tarihli ve 2018/10 Numaralı kararı uyarınca, veri sorumlusu sıfatıyla, aşağıda belirtilen önlemleri almaktadır:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.cuj1wlsk2lhw" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.6.1. Özel nitelikli kişisel verilerin işlenmesi süreçlerinde yer alan Çalışan’lara yönelik,</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanun ve buna bağlı yönetmelikler ile Özel Nitelikli Kişisel Veri güvenliği konularında düzenli olarak eğitimler verilmektedir,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Gizlilik sözleşmelerinin yapılmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Verilere erişim yetkisine sahip kullanıcıların, yetki kapsamları ve süreleri net olarak tanımlanmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Periyodik olarak yetki kontrolleri gerçekleştirilmektedir,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Görev değişikliği olan ya da işten ayrılan Çalışanlar’ın bu alandaki yetkileri derhal kaldırılmaktadır. Bu kapsamda, Veri Sorumlusu tarafından kendisine tahsis edilen envanteri iade almaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.w9uwxvney3t3" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.6.2. Özel Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği ortamlar, elektronik ortam ise,</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veriler, kriptografik yöntemler kullanılarak muhafaza edilmektedir,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kriptografik anahtarlar güvenli ve farklı ortamlarda tutulmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veriler üzerinde gerçekleştirilen tüm hareketlerin işlem kayıtları güvenli olarak loglanmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin bulunduğu ortamlara ait güvenlik güncellemelerinin sürekli takip edilmekte, gerekli güvenlik testleri düzenli olarak yapılmakta/yaptırılmakta, test sonuçları kayıt altına alınmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilere bir yazılım aracılığı ile erişiliyor ise bu yazılıma ait kullanıcı yetkilendirmeleri yapılmakta, bu yazılımların güvenlik testleri düzenli olarak yapılmakta/yaptırılmakta, test sonuçları kayıt altına alınmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilere uzaktan erişim gerekiyorsa en az iki kademeli kimlik doğrulama sisteminin sağlanmaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.owhnomxfg5t8" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.6.3. Özel Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği ortamlar, fiziksel ortam ise;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Özel Nitelikli Kişisel Verilerin bulunduğu ortamın niteliğine göre yeterli güvenlik önlemleri (elektrik kaçağı, yangın, su baskını, hırsızlık vb. durumlara karşı) alınmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bu ortamların fiziksel güvenliğinin sağlanarak yetkisiz giriş çıkışlar engellenmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.w98yqj1nkb77" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.6.4. Özel Nitelikli Kişisel Veriler aktarılacaksa,</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin e-posta yoluyla aktarılması gerekiyorsa şifreli olarak kurumsal e-posta adresiyle veya Kayıtlı Elektronik Posta (KEP) hesabı kullanılarak aktarılmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Taşınabilir Bellek, CD, DVD gibi ortamlar yoluyla aktarılması gerekiyorsa kriptografik yöntemlerle şifrelenmekte ve kriptografik anahtar farklı ortamda tutulmaktadır,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Farklı fiziksel ortamlardaki sunucular arasında aktarma gerçekleştiriliyorsa, sunucular arasında VPN kurularak veya sFTP yöntemiyle veri aktarımı gerçekleştirilmektedir,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin kağıt ortamı yoluyla aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler alınmakta ve evrak "Gizli ” formatta gönderilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Yukarıda belirtilen önlemlerin yanı sıra Kişisel Verileri Koruma Kurumunun internet sitesinde yayımlanan Kişisel Veri Güvenliği Rehberinde belirtilen uygun güvenlik düzeyini temin etmeye yönelik teknik ve idari tedbirler de dikkate alınmalıdır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.rupif0xcz22y" /><strong><span style={{fontSize: '11px'}}>3.7. İNTERNET SİTESİ ZİYARETÇİ VERİLERİNİN İŞLENMESİ</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy sahibi olduğu internet sitelerinde; bu siteleri ziyaret eden kişilerin sitelerdeki ziyaretlerini ziyaret amaçlarıyla uygun bir şekilde gerçekleştirmelerini temin etmek; kendilerine özelleştirilmiş içerikler gösterebilmek ve çevrimiçi reklamcılık faaliyetlerinde bulunabilmek maksadıyla teknik vasıtalarla (Kurabiyeler-cookie gibi) site içerisindeki internet hareketlerini kaydedilmektedir (“Çerez Politikası”). &nbsp;Easy’nin yapmış olduğu bu faaliyetlere ilişkin kişisel verilerin korunması ve işlenmesine ilişkin detaylı açıklamalar Politika metni içerisinde yer almaktadır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.6zj8ywj8qno9" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.8. KİŞİSEL VERİLERİN AKTARILMASI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, hukuka uygun olarak elde etmiş olduğu Kişisel Verileri, veri işleme amaçları doğrultusunda, gerekli güvenlik önlemlerini alarak, Veri Sahibi’nin Kişisel Verileri’ni ve/veya Özel Nitelikli Kişisel Verileri’ni üçüncü kişilere aktarabilmektedir. Bu doğrultuda, Easy, Kişisel verileri, işbu Politika’da belirtilen işleme şartlarından ve aşağıda belirtilen şartlardan birinin varlığı halinde üçüncü kişilere aktarabilecektir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin açık rızası var ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanunlarda Kişisel Veri’nin aktarılacağına ilişkin açık bir düzenleme var ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün korunması için zorunlu ise ve Veri Sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin aktarılması gerekli ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’un hukuki yükümlülüğünü yerine getirmesi için kişisel veri aktarımı zorunlu ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veriler, Veri Sahibi tarafından alenileştirilmiş ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veri aktarımı bir hakkın tesisi, kullanılması veya korunması için zorunlu ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Easy’nin meşru menfaatleri için kişisel veri aktarımı zorunlu ise.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.1oun1jpyysvg" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.9. KİŞİSEL VERİLERİN YURTDIŞINA AKTARILMASI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, meşru ve hukuka uygun Kişisel Veri işleme amaçları doğrultusunda Veri Sahibi’nin kişisel verilerini, açık rızası var ise veya, Veri Sahibi’nin açık rızası yok ise, aşağıdaki hallerden birinin varlığı durumunda, Kişisel verileri Yeterli korumaya sahip veya yeterli korumayı taahhüt eden veri sorumlusunun bulunduğu yabancı ülkelere aktarabilecektir:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanunlarda Kişisel Verinin aktarılacağına ilişkin açık bir düzenleme var ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün korunması için zorunlu ise ve Veri Sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin aktarılması gerekli ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin hukuki yükümlülüğünü yerine getirmesi için kişisel veri aktarımı zorunlu ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veriler, Veri Sahibi tarafından alenileştirilmiş ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veri aktarımı bir hakkın tesisi, kullanılması veya korunması için zorunlu ise,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Easy’nin meşru menfaatleri için kişisel veri aktarımı zorunlu ise.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.fvuaksjkom8e" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>3.10. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN YURTDIŞINA AKTARILMASI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, gerekli özeni göstererek, gerekli güvenlik tedbirlerini ve Kurul tarafından öngörülen yeterli önlemleri alarak, meşru ve hukuka uygun Kişisel Veri işleme amaçları doğrultusunda, Veri Sahibi’nin Özel Nitelikli Kişisel Veriler’ini aşağıdaki durumlarda yeterli korumaya sahip veya yeterli korumayı taahhüt eden veri sorumlusunun bulunduğu yabancı ülkelere aktarabilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin açık rızası var ise veya</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin açık rızası yok ise;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>(i) Veri Sahibi’nin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler, kanunlarda öngörülen hallerde, &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>(ii) Veri Sahibi’nin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel verileri ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından işlenmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.cdvxfnr1oqq2" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>4. KİŞİSEL VERİLERİN SAKLANMA SÜRELERİNE İLİŞKİN ESASLAR</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel veriler, Easy tarafından ilgili mevzuatta öngörülen süreler boyunca ve hukuki yükümlülükleri doğrultusunda saklanmaktadır. Kişisel verilerin ne kadar süre boyunca saklanması gerektiğine ilişkin mevzuatta bir süre düzenlenmemişse, kişisel veri Easy’nin o veriyi işlerken yürüttüğü faaliyet ile bağlantılı olarak Easy’nin uygulamaları ile ticari teamüller doğrultusunda işlenmesini gerektiren süre kadar işlenmekte daha sonra silinmekte, yok edilmekte veya anonim hale getirilmektedir. İşlenme amacı sona ermiş kişisel veriler ile kişisel veri sahipleri tarafından silinmesi/anonimleştirilmesi talep edilmiş olan kişisel veriler, ilgili mevzuat ve Easy’nin belirlediği saklama sürelerinin de sonuna gelinmişse; yalnızca olası hukuki uyuşmazlıklarda delil teşkil etmesi veya kişisel veriye bağlı ilgili hakkın ileri sürülebilmesi veya savunmanın tesis edilmesi amacıyla saklanabilmektedir. Easy kişisel verilerin saklanma sürelerini belirlerken ilgili mevzuatta öngörülen zamanaşımı sürelerini esas almaktadır. Bu amaç doğrultusunda saklanan kişisel verilere yalnızca ilgili hukuki uyuşmazlıkta kullanılması gerektiği zaman sınırlı kişiler tarafından erişilmekte olup bu amaç dışında başka bir amaçla erişilmemektedir. Bu sürenin sonunda da kişisel veriler silinmekte, yok edilmekte veya anonim hale getirilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.y4x2qbcjhssr" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>5. KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE ANONİM HALE GETİRİLMESİ</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veriler, Kanunu’nun 7. Maddesinde ve Türk Ceza Kanunu’nun 138. maddesinde ve düzenlendiği üzere ilgili kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde, re’sen veya kişisel veri sahibinin talebi üzerine, Easy tarafından silinir, yok edilir veya anonim hâle getirilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.c41qd6cjpod8" /><strong><span style={{fontSize: '11px'}}>8.6</span></strong><strong><span style={{fontSize: '11px'}}>6. YÜKÜMLÜLÜKLER VE HAKLAR</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.72ohlbeisfgh" /><strong><span style={{fontSize: '11px'}}>6.1.&nbsp;</span></strong><span style={{fontSize: '11px'}}>EASY</span><strong><span style={{fontSize: '11px'}}>’nin AYDINLATMA VE BİLGİLENDİRME YÜKÜMLÜLÜĞÜ</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy, Kanun’un 10. maddesine uygun olarak, kişisel verilerin elde edilmesi sırasında Veri Sahiplerini aydınlatmaktadır. Bu kapsamda Easy;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sorumlusu ve varsa temsilcisinin kimliği,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin hangi amaçla işleneceği,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>İşlenen Kişisel Verilerin kimlere ve hangi amaçla aktarılabileceği,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Veri toplamanın yöntemi ve hukuki sebebi ile</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahibi’nin sahip olduğu hakları konusunda Veri Sahibi’ne bilgi vermektedir. &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Anayasa’nın 20. maddesinde herkesin, kendisiyle ilgili kişisel veriler hakkında bilgilendirilme hakkına sahip olduğu ortaya konulmuştur. Bu doğrultuda Kanun’un 11. maddesinde kişisel veri sahibinin hakları arasında “bilgi talep etme” de sayılmıştır. Easy bu kapsamda, Anayasa’nın 20. ve Kanun’un 11.&nbsp;</span><span style={{fontSize: '11px'}}>maddelerine uygun olarak Veri Sahibi’nin bilgi talep etmesi durumunda gerekli bilgilendirmeyi yapmaktadır. Bu konu ile ilgili ayrıntılı bilgiye Politika’nın Veri Sahibi’nin Hakları bölümünden ve Aydınlatma metninden ulaşabilirsiniz. &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.d3gxwsqbi6kc" /><strong><span style={{fontSize: '11px'}}>8.7</span></strong><strong><span style={{fontSize: '11px'}}>6.2. VERİ SAHİBİ’NİN HAKLARI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahipleri, aşağıdaki haklara sahiptirler:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin Veri Sahibi’nin kişisel verilerini işleyip işlemediğini öğrenme,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Eğer Easy nezdinde Kişisel Veriler işleniyorsa, bu veri işleme faaliyeti hakkında bilgi talep etme,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Eğer Easy nezdinde Kişisel Veriler işleniyorsa, kişisel veri işleme faaliyetinin amacını ve işlenme amacına uygun kullanılıp kullanmadığını öğrenme,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Eğer Kişisel Veriler yurtiçinde veya yurtdışında üçüncü kişilere aktarılıyorsa, bu üçüncü kişiler hakkında bilgi talep etme,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin eksik veya yanlış işlenmiş olması halinde, bunların düzeltilmesini talep etme,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin Easy nezdinde eksik veya yanlış işlenmiş olması halinde, Kişisel Verilerin aktarıldığı üçüncü kişilere bu durumun bildirilmesini talep etme,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde Kişisel Verilerin silinmesini, yok edilmesini veya anonim hale getirilmesini istiyorum.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin işlenmesini gerektiren sebepler ortadan kalktıysa, bu durumun Kişisel Verilerin aktarıldığı üçüncü kişilere bildirilmesini talep etme, &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından işlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi ve bu analiz neticesinde ilgili kişinin (Veri Sahibi) aleyhine bir sonuç doğduğunu düşündüğü sonuçların ortaya çıkması halinde, bu sonuca itiraz etme.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması halinde zararın giderilmesini talep etme.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Veri Sahipleri, Kanun’un uygulanmasına ilişkin taleplerini, “6698 Sayılı Kişisel Verilerin Korunması Kanunu Gereğince İlgili Kişi (Veri Sahibi) Tarafından Veri Sorumlusuna Yapılacak Başvurulara İlişkin Başvuru Formu”nu, yazılı olarak ve Kurul’un belirleyeceği diğer yöntemler ile Easy’e iletebilirler. Forma ulaşmak için tıklayınız.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.u4iywm7058k0" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>6.3. İSTİSNALAR</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kanun’un 28. Maddesi uyarınca, aşağıdaki haller Kanun kapsamı dışında tutulduğundan, Veri Sahipleri, yukarıda belirtilen haklarını ileri süremezler:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel verilerin, üçüncü kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak kaydıyla gerçek kişiler tarafından tamamen kendisiyle veya aynı konutta yaşayan aile fertleriyle ilgili faaliyetler kapsamında işlenmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları veya infaz mercileri tarafından işlenmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin Aydınlatma Yükümlülüğü, Kanun’un 28/2 maddesi uyarınca aşağıdaki hâllerde uygulanmaz:</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>İlgili kişinin kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli olması.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak Devletin ekonomik ve mali çıkarlarının korunması için gerekli olması.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.l6h3d7ng6h5i" /><strong><span style={{fontSize: '11px'}}>8.</span></strong><strong><span style={{fontSize: '11px'}}>7. YETKİ VE SORUMLULUK</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kişisel Verilerin Korunması ve İşlenmesi Politikası, şirket yetkilisinin onayı ile yürürlüğe girmektedir. Ayrıca, Kişisel Verilerin Korunması ve İşlenmesi Politikası’nda değişiklik yapılması da yine şirket yetkilisinin onayı ile gerçekleşmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tarafından Kanun’a uygunluğun sağlanması, korunması ve sürdürülmesi, bu konuyla ilgili olan departmanlar arasında koordinasyonun sağlanması, yürütülen işletmefaaliyetlerinin Kanun’a uygunluğunun tespiti konularından, şirket yetkilisi tarafından bu konuda usulünce yetkilendirilmiş şirket yetkilisi üyesi sorumludur.&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>8. KİŞİSEL VERİLERİN KATEGORİZASYONU</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy nezdinde; Easy’nin meşru ve hukuka uygun kişisel veri işleme amaçları doğrultusunda, Kanun’un 5. maddesinde belirtilen kişisel veri işleme şartlarından bir veya birkaçına dayalı ve sınırlı olarak, başta kişisel verilerin işlenmesine ilişkin 4. maddede belirtilen ilkeler olmak üzere Kanun’da belirtilen genel ilkelere ve Kanun’da düzenlenen bütün yükümlülüklere uyularak ve Politika kapsamındaki süjelerle (Müşteriler, Ziyaretçi, Çalışan Adayı, işletmeYetkilileri, Tedarikçiler, İş Ortakları vb.) aşağıda belirtilen kategorilerdeki kişisel veriler, Kanun’un 10. maddesi uyarınca ilgili kişiler bilgilendirilmek suretiyle işlenmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Kimlik Bilgisi&nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; kişinin kimliğine dair bilgilerin bulunduğu verilerdir;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Ad-soyad, T.C. kimlik numarası, uyruk bilgisi, anne adı-baba adı, doğum yeri, doğum tarihi, cinsiyet gibi bilgileri içeren ehliyet, nüfus cüzdanı ve pasaport gibi belgeler ile vergi numarası, SGK numarası, imza bilgisi, taşıt plakası v.b. bilgiler;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>İletişim Bilgisi &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; telefon numarası, adres, e-mail adresi, faks numarası, IP adresi gibi bilgiler</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Lokasyon Verisi &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; kişisel veri sahibinin Easy iş birimleri tarafından yürütülen operasyonlar çerçevesinde, Easy’nin ürün ve hizmetlerinin kullanımı sırasında veya işbirliği içerisinde olduğumuz kurumların çalışanlarının Easy’nin araçlarını kullanırken bulunduğu yerin konumunu tespit eden bilgiler; GPS lokasyonu, seyahat verileri v.b.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Aile Bireyleri ve Yakın Bilgisi &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy’nin iş birimleri tarafından yürütülen operasyonlar çerçevesinde, Easy’nin sunduğu ürün ve hizmetlerle ilgili veya Easy’nin ve kişisel veri sahibinin hukuki ve diğer menfaatlerini korumak amacıyla kişisel veri sahibinin aile bireyleri (örn. eş, anne, baba, çocuk), yakınları ve acil durumlarda ulaşılabilecek diğer kişiler hakkındaki bilgiler &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Fiziksel Mekan Güvenlik Bilgisi &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; &nbsp;fiziksel mekana girişte, fiziksel mekanın içerisinde kalış sırasında alınan kayıtlar ve belgelere ilişkin kişisel veriler; kamera kayıtları, parmak izi kayıtları ve güvenlik noktasında alınan kayıtlar v.b.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Finansal Bilgi&nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy’nin kişisel veri sahibi ile kurmuş olduğu hukuki ilişkinin tipine göre yaratılan her türlü finansal sonucu gösteren bilgi, belge ve kayıtlara ilişkin işlenen kişisel veriler ile banka hesap numarası, IBAN numarası, kredi kartı bilgisi, finansal profil, malvarlığı verisi, gelir bilgisi gibi veriler</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Görsel/İşitsel Bilgi &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; fotoğraf ve kamera kayıtları (Fiziksel Mekan Güvenlik Bilgisi kapsamında giren kayıtlar hariç), ses kayıtları ile kişisel veri içeren belgelerin kopyası niteliğindeki belgelerde yer alan veriler</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Özlük Bilgisi&nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy ile çalışma ilişkisi içerisinde olan gerçek kişilerin özlük haklarının oluşmasına temel olacak bilgilerin elde edilmesine yönelik işlenen her türlü kişisel veri</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Özel Nitelikli Kişisel Veri &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; KVK Kanunu’nun 6. maddesinde belirtilen veriler (örn. kan grubu da dahil sağlık verileri)</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Talep/Şikâyet Yönetimi Bilgisi</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy’a yöneltilmiş olan her türlü talep veya şikayetin alınması ve değerlendirilmesine ilişkin kişisel veriler Easy, Politika’da düzenlenmiş, hususlara ve Kanun’un 8. ve 9. maddelerine uygun olarak, Politika ile yönetilen veri sahiplerinin kişisel verilerini aşağıda sıralanan kişi kategorilerine aktarılabilir: &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy iş ortaklarına,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy tedarikçilerine,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy Şirketler Grubu bünyesindeki şirketlere,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy hissedarlarına,&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy işletmeyetkililerine, &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Hukuken yetkili kamu kurum ve kuruluşlarına&nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Hukuken yetkili özel hukuk kişilerine &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Aktarımda bulunulan yukarıda belirtilen kişilerin kapsamı ve veri aktarım amaçları aşağıda belirtilmektedir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.dtc1xwz6hxcb" /><strong><span style={{fontSize: '11px'}}>Veri Aktarımı Yapılabilecek Kişiler / Tanımı / Veri Aktarım Amacı</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>İş Ortağı &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin ticari faaliyetlerini yürütürken bizzat veya Grup Şirketleri ile birlikte muhtelif projeler yürütmek, hizmet almak gibi amaçlarla iş ortaklığı kurduğu tarafları tanımlamaktadır. / İş ortaklığının kurulma amaçlarının yerine getirilmesini temin etmek amacıyla sınırlı olarak.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Tedarikçi &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin ticari faaliyetlerini yürütürken Easy’nin emir ve talimatlarına uygun olarak sözleşme temelli olarak Easy’e hizmet sunan tarafları tanımlamaktadır. / Easy’nin tedarikçiden dış kaynaklı olarak temin ettiği ve Easy’nin ticari faaliyetlerini yerine getirmek için gerekli hizmetlerin Easy’e sunulmasını sağlamak amacıyla sınırlı olarak. &nbsp;</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Hissedarlar &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy’nin hissedarı gerçek kişiler / İlgili mevzuat hükümlerine göre Easy’nin şirketler hukuku, etkinlik yönetimi ve kurumsal iletişim süreçleri kapsamında yürüttüğü faaliyetlerin amaçlarıyla sınırlı olarak.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>işletmeYetkilileri&nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Easy şirket yetkilisi üyeleri ve diğer yetkili gerçek kişiler / İlgili mevzuat hükümlerine göre Easy’nin ticari faaliyetlerine ilişkin stratejilerin tasarlanması, en üst düzeyde yönetiminin sağlanması ve denetim amaçlarıyla sınırlı olarak.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Hukuken Yetkili Kamu Kurum ve Kuruluşları &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>İlgili mevzuat hükümlerine göre Easy’den bilgi ve belge almaya yetkili kamu kurum ve kuruluşları / İlgili kamu kurum ve kuruluşlarının hukuki yetkisi dahilinde talep ettiği amaçla sınırlı olarak.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>Hukuken Yetkili Özel Hukuk Kişileri &nbsp;</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>İlgili mevzuat hükümlerine göre Easy’den bilgi ve belge almaya yetkili özel hukuk kişileri / İlgili özel hukuk kişilerinin hukuki yetkisi dahilinde talep ettiği amaçla sınırlı olarak.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}>&nbsp;</p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.s72tlocad5qo" /><a name="_heading=h.nz0nka8427la" /><a name="_heading=h.oljj7vy8ztlc" /><a name="_heading=h.opawo3omc4vt" /><strong><span style={{fontSize: '11px'}}>8.8</span></strong><strong><span style={{fontSize: '11px'}}>. BEYAN VE TAAHHÜTLER</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, gerçek kişi olması halinde 18 (onsekiz) yaşından büyük olduğunu ve sözleşme yapmak için gereken fiil ehliyetine sahip olduğunu veya 18 yaşından küçük olması halinde işbu Sözleşmeyi&nbsp;</span><span style={{fontSize: '11px'}}>akdetmek için velisinin onayının bulunduğunu, tüzel kişilik ise Sözleşme’nin kabulünün yetkili kişilerce yapıldığını kabul ve taahhüt eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı’nın EASY sistemine kayıt olabilmesi, Hizmetler’den yararlanabilmesi ve EASY üzerinden soru çözüm desteği alabilmesi, işbu Sözleşme hükümlerinin Kullanıcı tarafından kabul ve taahhüt edilmesi şartına bağlıdır. Kullanıcı’nın EASY sistemine kayıt olması ve/veya Hizmetler’den faydalanması ve/veya EASY üzerinden soru çözüm desteği alması halinde Kullanıcı işbu Sözleşme hükümlerini peşinen kabul ve taahhüt etmiş olur ve buradaki hükümler Kullanıcı açısından bağlayıcı hale gelir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, EASY’e kayıt sırasında beyan ettiği bilgilerin ve sunduğu belgelerin doğru, güncel ve geçerli olduğunu ve bu beyanların yanıltıcı, hatalı veya eksik olması halinde tüm sorumluluğun kendisine ait olduğunu kabul ve beyan eder. Kullanıcı, EASY’e kayıt olurken sisteme yüklediği bilgi veya belgelerde bir değişiklik olması halinde söz konusu güncellemeleri sistem üzerinden derhal yapmakla yükümlüdür. İlgili güncellemelerin Kullanıcı tarafından zamanında yapılmaması nedeniyle doğabilecek hak kayıpları ve buna bağlı sorumluluklar Kullanıcı’ya ait olacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY tarafından izin verilmedikçe, Kullanıcı easyegitim.com uzantılı internet sitesinin üzerinde sadece bir hesaba sahip olabilecektir. Kullanıcı, easyegitim.com üzerinden oluşturacağı hesabın bizzat kendisi tarafından kullanılacağını, başkaları nam ve hesabına hesap açmayacağını ve/veya hesabının üçüncü kişilerce kullanılmasına izin vermeyeceğini beyan ve taahhüt eder. Kullanıcı tarafından oluşturulan hesap üzerinden yapılan her türlü işlemin sorumluluğu Kullanıcı’ya aittir. EASY, dilediği zaman Kullanıcı’nın hesabını kapatmaya ve/veya erişimi kısmen veya tamamen engellemeye yetkilidir. Kullanıcı bu doğrultuda herhangi bir itiraz veya talep hakkına sahip olmayacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY tarafından sunulan hizmetleri üyelik sistemi ve abonelik modeliyle işlemekte olup EASY, kullanıcılar tarafından oluşturulan içeriklerle ilgili hiçbir sorumluluk kabul etmemektedir. Kullanıcı, Hizmetlerin kendisi tarafından kullanımına, soruların içeriğine ve soru çözüm desteğine dair süreçlere ilişkin her türlü sorumluluğun kendisine ait olduğunu, EASY’de bulunan içeriklere dair hiçbir sorumluluğu bulunmadığını ve herhangi bir içeriği önceden tarama, izleme, düzenleme veya kaldırmaya dair bir yükümlülüğü bulunmadığını kabul ve beyan eder. Kullanıcı, EASY üzerinden paylaştığı herhangi bir bilgi, belge ve/veya sorunun ve bunların EASY üzerinden paylaşılmasının ve kullanılmasının, herhangi bir üçüncü kişinin fikri mülkiyet hakları, gizlilik hakları, kişilik hakları ve telif hakları dâhil, ancak bunlarla sınırlı olmamak kaydıyla, herhangi bir hakkını ihlal etmeyeceğini ve bu kapsamda oluşacak herhangi bir ihlal nedeniyle herhangi bir kullanıcının, Eğitmenin ve/veya ilişkili üçüncü kişinin ve/veya EASY’in zarar görmesi halinde her türlü hukuki, mali ve cezai sorumluluğun kendisine ait olacağını ve bu doğrultuda EASY’in maruz kalabileceği hukuki ve mali yaptırımların kendisi tarafından tazmin edileceğini kabul, beyan ve taahhüt eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, EASY hizmetlerini kullanırken kendisine yüklenen tüm sorumlulukları yerine getirmeyi, EASY tarafından talep edilen raporlama, bildirim, uyarı, sistem girişi ve benzeri yükümlülüklere eksiksiz şekilde uymayı kabul ve taahhüt eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, EASY aracılığıyla kullandığı hesap üzerinden yapılan işlemlerde ve yönelttiği sorularda tüm yasa, yönetmelik ve düzenlemelere uygun davranmayı, herhangi bir suretle kişilik haklarını zedeleyici, ayrımcılığa yol açan, cinsellik, çıplaklık, şiddet, hakaret, taciz, tehdit veya yasaya aykırı eylem ve söylemler içeren, pornografik veya müstehcen unsurlar taşıyan veya benzeri şekilde aykırılık oluşturan içeriklere yer vermeyeceğini, bu durumda her türlü sorumluluğun kendisine ait olacağını kabul, beyan ve taahhüt eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, herhangi bir zararlı yazılım veya başka zarar verici etkeni kullanarak EASY sunucularını veya ona bağlı ağları engelleyecek veya kesintiye uğratacak eylemlerde bulunmayacağını ve EASY’i kullanan diğer kullanıcıların tarayıcılarında veya cihazlarında gösterilme ya da görüntülenme şeklini herhangi bir&nbsp;</span><span style={{fontSize: '11px'}}>şekilde değiştirmeyeceğini veya bunları engelleyen içerikler ya da kodlar eklemeyeceğini beyan ve taahhüt eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, işbu Sözleşme’nin koşullarını dilediği zaman tek taraflı olarak değiştirmek hakkına sahiptir. İlgili değişiklikler Kullanıcı’ya easyegitim.com üzerinden bildirilecek olup, yapılan sözleşme değişiklikleri bildirimin yapılmasıyla birlikte Kullanıcı açısından bağlayıcı hale gelecektir. Kullanıcı, söz konusu güncellemeler yürürlüğe girdiği tarihten sonra EASY sistemine kullanıcı girişi yapmasının ve/veya Hizmetleri kullanmasının, yapılan sözleşme değişikliklerini kabul ettiği anlamına geleceğini kabul ve beyan eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, bünyesinde reklam veya tanıtımlara yer verebilir. Kullanıcı, söz konusu reklamlara ve tanıtımlara ana veya ara sayfalarda ve/veya kendisi tarafından yüklenen soru ve içeriklerin yanında yer verilebileceğini bilir ve bunu kabul eder. Söz konusu reklam ve tanıtımların içerik, konu, yer ve şekillerinin belirlenmesinde EASY münhasıran yetkilidir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, zaman zaman site ve yazılımın kullanımına ilişkin kılavuz, yönerge, yönetmelik, bilgilendirme metni ve benzeri belge ve kurallar yayımlayabilir. Söz konusu belge ve kurallar yayımlandığı tarih itibariyle işbu Sözleşme’nin ayrılmaz bir parçası ve ekini oluşturacak ve Kullanıcı açısından bağlayıcı olacaktır. Kullanıcı, söz konusu belge ve kuralları sistem üzerinden takip etmekle ve bunlardan haberdar olmakla yükümlüdür.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.5euuebjwt07c" /><strong><span style={{fontSize: '11px'}}>8</span></strong><strong><span style={{fontSize: '11px'}}>.</span></strong><strong><span style={{fontSize: '11px'}}>9</span></strong><strong><span style={{fontSize: '11px'}}>&nbsp;</span></strong>EASY KULLANIMINA İLİŞKİN KURALLAR</p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, Kullanıcı tarafından easyegitim.com üzerinden yöneltilen soruların çözümlenmesi amacıyla Kullanıcı ile Eğitmen ve Tutorları easyegitim.com uzantılı internet sitesi aracılığıyla bir araya getirerek Kullanıcı’ya hizmet sunar.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, zorunlu olarak ad, soyad, telefon ve e-posta bilgilerini sisteme girerek kaydolur ve Kullanıcı adına bir hesap oluşturulur. Kullanıcı, tercihe bağlı olarak promosyon kodu, üniversite, bölüm ve adres bilgisini de sisteme kaydedebilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, EASY sistemine kaydolduktan sonra kendisine tanımlanan şifre ile sisteme giriş yaparak Eğitmenler tarafından çözümlenmesini talep ettiği soruları EASY sistemine yükleyebilir, anasayfada yazan dersler ve alt konulara tıklayarak soru görüntüleme ve arama yapabilir ve bekleyen soruları görüntüleyebilir. Kullanıcı, EASY sistemine kaydolduktan sonra kendisine tanımlanan şifre ile sisteme giriş yaparak dersi ile ilgili videoları izleyebilir ve yazılım ürünü olan dijital asistanımıza erişebilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı öğrenciler belirli bir ücret karşılığında EASY üzerinden soru sorar. Her bir soru çözümlenmek üzere sisteme yüklenebilir. Sorulan sorunun hangi akademik bölümün konusuna girdiğine göre soruların ücret değeri değişebilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, sistem içerisinde mevcut olan dilediği derslerden sorularını cevaplanmak üzere sisteme yükler. Kullanıcı dilerse, daha yüksek ücret ödeyerek dersin çözümlü kurs videolarına erişebilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, sorduğu soruya ilişkin Eğitmen tarafından sunulan çözümü beğendiği takdirde Eğitmen’i puanlar. Kullanıcı, sorduğu soruya ilişkin Eğitmen tarafından sunulan çözümü eksik ya da hatalı bulduğu takdirde ise itiraz formunu doldurarak Eğitmen’e düzeltme için yollar. Kullanıcı, düzeltilmiş çözümü de beğenmediği takdirde ilgili sorunun incelenmesi için EASY ekibine ulaşır. EASY ekibinin yapacağı değerlendirme sonucunda; Kullanıcı’nın itirazı yerinde bulunursa, Kullanıcı’ya ilgili soruyu sormak için harcadığı ücret iade edilir. Yapılan değerlendirme sonucunda Kullanıcı’nın itiraz haksız bulunursa, ilgili ücret tahsil edilerek Kullanıcı talebi reddedilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı’nın sorduğu sorulara Eğitmenler tarafından verilen cevaplara/çözümlere sürekli olarak haksız bir şekilde itiraz ettiğinin tespit edilmesi halinde Kullanıcı hesabı EASY tarafından incelenerek, EASY Hizmetlerini usulüne ve/veya Sözleşme koşullarına uygun olarak kullanmadığı gerekçesiyle Kullanıcı EASY tarafından easyegitim.com sisteminden ihraç edilebilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, EASY sistemi üzerinden beğendiği soruları kaydedip daha sonra bakmak için saklayabilir veya tercih ettiği akademik bölümlere tıklayarak daha önce çözülmüş soruları ücretsiz olarak inceleyip gezebilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>easyegitim.com sisteminde yer alan Marketplace içerisinde üyelik ve hizmet paketleri mevcuttur. Kullanıcı, dilediği zaman Marketplace üzerinden dilediği üyelik ve hizmet paketini satın alarak EASY hizmetlerinden faydalanabilir. Marketplace üzerinden yapılacak söz konusu alışveriş İyzico platformu üzerinden gerçekleştirilir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, Eğitmen tarafından gönderilen çözümün uygunsuz bir içeriğe sahip olduğunu tespit ederse durumu gecikmeksizin raporlayarak ilgili birime bildirir. Kullanıcı, yönelttiği sorunun düşük fotoğraf kalitesi nedeniyle çözüme uygun olmadığının kendisine bildirilmesi üzerine sorunun doğru fotoğraf kalitesiyle sisteme yüklenmesinden sorumludur.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>5.11. EASY hiçbir suretle sınav ve/veya akademik değerlendirme sorularının cevaplanması/çözümlenmesi amacıyla kullanılamaz. EASY, easyegitim.com’un bu şekilde hukuka ve hakkaniyete aykırı olarak kullanılmasını yasaklar ve bu kurala uymadığı tespit edilen Kullanıcıları derhal EASY sisteminden ihraç ederek, ilgili Kullanıcı’nın ileride yeni bir hesap açmasını engeller. Kullanıcı tarafından sınav esnasında yöneltildiği anlaşılan veya herhangi bir eğitim kurumunun öğrencinin akademik değerlendirmesinin yapılabilmesi için sorduğu belli olan veya kötü niyetli kullanım amacı taşıdığı tespit edilebilen sorular Eğitmenler tarafından yanıtlamaz ve derhal raporlanarak ilgili birime bildirilir. Kullanıcı’nın işbu maddede belirtilen yasak ve kurallara uymaması ve yasaklanmış nitelikteki soruları sisteme yüklemesi nedeniyle EASY’nin, Eğitmenlerinin ve/veya herhangi bir üçüncü kişinin zarar görmesi ve/veya herhangi bir kurum veya kuruluşun yaptırımına maruz kalması halinde Kullanıcı bundan kaynaklı maddi, hukuki ve cezai tüm sorumluluğu üstlenecek ve bundan kaynaklı doğacak her türlü zararı tazmin edecektir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, sisteme yüklediği bilgi veya belgenin bir soru niteliği taşımaması ve/veya uygunsuz bir içerik içermesi halinde bir çözümleme yapılmayacağını ve Eğitmenlerin zamanını haksız suretle almasından dolayı ilgili hizmetin karşılığı ücretin hesabından düşüleceğini kabul eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, kendisi tarafından sisteme yüklenen soru ve içeriklerin EASY’e kayıtlı Eğitmenler ve/veya EASY tarafından Eğitim Hizmetleri kapsamında kullanımı için erişim ve kullanım izni verdiğini kabul ve beyan eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, easyegitim.com üzerindeki ödeme sistemini, ücretleri, ücret hesaplamalarını veya ücretlendirmeye esas diğer sistemleri dilediği zaman dilediği şekilde değiştirme veya kaldırma ve/veya daha önce ücretsiz olarak sunulan bir hizmeti ücrete tabi kılma hak ve yetkisine sahiptir. Söz konusu değişiklik, geçmiş dönemdeki kazanılmış haklara uygulanmamakla birlikte, yapıldığı tarihten itibaren gerçekleştirilecek işlemlere doğrudan uygulanacak olup, Kullanıcı’nın bu doğrultuda herhangi bir talep hakkı olmayacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, zaman zaman Kullanıcı’nın yaralanması için belirli koşullarla belirli kampanyalar yapabilir. Kullanıcı söz konusu kampanyalardan yararlanırken kampanya koşullarına uyacağını ve tamamen Zenotech’in takdirinde olmak üzere, bu koşullara uymadığının ve/veya hileli/yasa dışı eylemlerde bulunduğunun tespit edilmesi halinde kampanyadan kaynaklı kazançlarının alıkonulacağını, kısıtlanacağını veya ilerideki kazançlarından mahsup edileceğini kabul ve beyan eder. Kullanıcı, kendisi&nbsp;</span><span style={{fontSize: '11px'}}>faydalanmadan önce kampanyanın kaldırılması veya değiştirilmesi halinde herhangi bir talep hakkına sahip olmayacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, gerekli gördüğü hallerde Kullanıcı’nın hesabını askıya alabilir. Bu süreçte Kullanıcı hesaba giriş yapılabilir fakat hizmetlerimizden faydalanamaz..</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı’nın Hizmetler’in kullanımına ilişkin ihtiyaç duyacağı her türlü tesis, ekipman, donanım, yazılım, veri ağı erişimi ve benzeri kaynağın sağlanması Kullanıcı’nın sorumluluğundadır. EASY tarafından sistem üzerinde yapılacak güncelleme ve yenilikler nedeniyle Kullanıcı’nın mevcut tesis, ekipman, donanım, yazılım, veri ağı erişimi ve benzeri kaynağının kullanılmaz hale gelmesinden EASY sorumlu tutulamaz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY’e kayıtlı Eğitmenler, EASY’in değerlendirme kriterlerini karşılayan herhangi bir şahıs olabilir. EASY, Eğitmen seçiminde titizlikle davranmak ve easyegitim.com’a kayıtlı Eğitmenlerin EASY tarafından belirlenen kriterlere uygunluğunu ve yeterliliğini garanti etmekle birlikte, Eğitmenler tarafından sunulan çözüm desteğinin doğruluğu veya yeterliliğine ilişkin bir garanti vermemektedir. Bu kapsamdaki her türlü sorumluluk Eğitmenlere aittir. Kullanıcı bu hususu peşinen kabul eder ve easyegitim.com üzerinden Eğitmenlere yöneltilen sorulara verilen cevapların/çözümlerin kendisi tarafından kullanımından kaynaklı oluşabilecek herhangi bir zarara ve sorumluluğa ilişkin EASY’in bir yükümlülüğünü bulunmayacağını kabul ve beyan eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY, Kullanıcıların yönelttiği sorulara en kısa sürede en kaliteli çözümlerin sunulmasını amaçlamakla birlikte, Kullanıcılar tarafından sisteme yüklenen soruların ne kadar sürede ve hangi yeterlilikte çözüleceğiyle ilgili bir garanti vermez. Bu kapsamdaki her türlü sorumluluk Eğitmenlere aittir,</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><strong><span style={{fontSize: '11px'}}>8.10</span></strong><strong><span style={{fontSize: '11px'}}>&nbsp;</span></strong>FİKRİ MÜLKİYET HAKLARI</p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, Kullanıcı tarafından EASY üzerinden yöneltilen sorulara ve bunlarla ilgili ortaya çıkabilecek her türlü çözüm, rapor, doküman, buluş, faydalı model, patent, marka, tasarım dahil ancak bunlarla sınırlı olmamak üzere, her türlü ürün ve esere ilişkin Fikir ve Sanat Eserleri Kanunu ve ilgili diğer mevzuattan doğan işleme, yayma, çoğaltma, temsil, umuma iletim, tasarım, üretim, satış ve değişiklik yapma hakkı da dahil her türlü mali hakkını EASY’e işbu Sözleşme ile devrettiğini, bunların üzerinde kabul ve beyan eder. EASY, söz konusu soruları, çözümleri ve buna bağlı eser ve ürünleri EASY üzerinde veya başka bir platformda yayımlamak, kullanmak, çoğaltmak, dağıtmak, devretmek, alt lisans/uluslararası lisans vermek ve/veya başka kullanıcılarla ücret karşılığı paylaşmak da dahil olmak üzere her türlü kullanım hakkına ücretsiz olarak sahiptir. Kullanıcı bu doğrultuda hiçbir talep hakkı bulunmayacağını peşinen kabul eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY’e, Hizmetler’e ve bunlarla ilgili yazılımlara ilişkin her türlü fikri mülkiyet hakkı ve lisans hakkı EASY’e aittir ve Kullanıcı, easyegitim.com’a veya Hizmetlere ilişkin hiçbir yazılımı veya içeriği kopyalamayacak, değiştirmeyecek, uyarlamayacak, çoğaltmayacak, yayınlamayacak, dağıtmayacak, satmayacak, lisans vermeyecek, işbu Sözleşme’nin amacı haricinde bir amaçla kullanmayacak veya başka suretle ihlal etmeyecektir. EASY’nin Kullanıcı’nın easyegitim.com sistemine giriş yapmasına veya Hizmetler’den yaralanmasına izin vermesi, hiçbir şekilde EASY’e, Hizmetler’e ve bunlarla ilgili yazılımlara dair lisans verildiği anlamına gelmez, hiçbir Sözleşme maddesi bu şekilde yorumlanamaz.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı, Hizmetler’den faydalanırken EASY’e ait lisanslı veya lisanssız her herhangi bir içeriğe dahil olan telif hakkı, ticari marka, hizmet markası veya diğer mülkiyet hakları bildirilerini kaldırmayacak, değiştirmeyecek veya gizlemeyecektir.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><a name="_heading=h.64fg9090sokz" /><strong><span style={{fontSize: '11px'}}>8.11</span></strong><strong><span style={{fontSize: '11px'}}>. HİZMETLERİN SONA ERDİRİLMESİ VE CAYMA HAKKI</span></strong></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>EASY tarafından sunulan Hizmetler, kullanıcı öğrencileri dijital içeriklere ve yazılımlara erişim ve kullanımı sağlamaktan ibarettir. EASY bu Hizmetlerin devamlılığını sağlamakla yükümlü olmadığı gibi&nbsp;</span><span style={{fontSize: '11px'}}>dilediği zaman bu Hizmetleri herhangi bir bildirimde bulunmadan tek taraflı olarak sona erdirme ve/veya EASY’e veya Hizmetler’e erişimi engelleme hakkına sahiptir. Hizmetlerin EASY tarafından kısmen veya tamamen durdurulması veya sona erdirilmesi halinde Kullanıcı, sona erdirme tarihinde paketinde kullanmadığı miktarın iadesi haricinde, herhangi bir tazminat veya ödeme talep etme hakkına sahip olmayacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Kullanıcı’nın Hizmetler’in kullanımına ilişkin cayma hakkını kullanmak istemesi halinde; Kullanıcı’nın talebi üzerine Kullanıcı’nın easyegitim.com bünyesinde oluşturulan hesabı EASY tarafından kapatılacaktır. Kullanıcı söz konusu kapatma talebini mail@easyegitim.com adresine e-posta göndermek suretiyle EASY’e iletecektir. Söz konusu talebin usulüne uygun şekilde yapılmamış olması halinde EASY ilgili hesabın kapatılmaması ve/veya kullanımına ilişkin herhangi bir sorumluluk kabul etmez. Kullanıcı’nın hesabı bu şekilde kapatılarak sistemden silinse bile, Kullanıcı’nın kişisel verileri ve daha önce Kullanıcı’nın easyegitim.com’e yüklemiş olduğu sorular ile çözümleri Kişisel Verileri Koruma Kanunu kapsamında sistemde depolanmaya ve kullanılmaya devam eder.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>Sözleşme’nin herhangi bir hükmünün veya easyegitim.com kullanımına dair oluşturulmuş olan kurallardan herhangi birinin Kullanıcı tarafından ihlal edilmesi halinde EASY, tamamen kendi takdirine bağlı olarak, Kullanıcı’nın hesabının kapatılmasına ve/veya Kullanıcı’nın Hizmetlerden faydalanma hakkının kısmen veya tamamen bildirimsiz şekilde tek taraflı olarak sona erdirilmesine karar verebilir. Hizmetlerin EASY tarafından sona erdirilmesi veya Kullanıcı’nın hesabının EASY tarafından kapatılması halinde Kullanıcı herhangi bir tazminat veya ödeme talep etme hakkına sahip olmayacak, EASY’nin söz konusu sona erdirmeden kaynaklı zararlarının tazminini talep etme hakkı saklı olacaktır.</span></p>
        <p style={{textAlign: 'justify', color: 'rgb(0, 0, 0)', fontSize: '12px'}}><span style={{fontSize: '11px'}}>İşbu Sözleşme kapsamında herhangi bir nedenle Kullanıcı’nın hesabının kapatılması ve/veya Hizmetlerin sona erdirilmesi halinde işbu Sözleşme kapsamında Kullanıcı’ya tanınan tüm hak ve yetkiler otomatik olarak derhal sona erer.</span></p>
        <p><br /></p>
      </div>
      );
    }

    export default UyelikSozlesmesi;