import { Routes, Route } from 'react-router-dom';
import AuthLayout from '../components/layout/AuthLayout';
import Login from '../pages/auth/Login'; // Adjust the path as necessary
import GoogleCallback from '../pages/auth/GoogleCallback';
import AppleCallback from '../pages/auth/AppleCallback';
import Register from '../pages/auth/Register';
import ForgotPassword from '../pages/auth/ForgotPassword';


// Define a component to group all auth related routes
// phone verify


// 2fa ne kadar uzun surer bakicam

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="google/callback" element={<GoogleCallback />} />
        <Route path="apple/callback" element={<AppleCallback />} />
        <Route path="forgot-password" element={<ForgotPassword />} />

      </Route>
    </Routes>
  );
};

