import { Container } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentFailure = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen  py-12">
      <Container size="xs">
        <div className="bg-white rounded-2xl shadow-2xl p-8 relative overflow-hidden">
          {/* Error Icon */}
          <div className="flex justify-center mb-6">
            <div className="w-20 h-20 bg-red-100 rounded-full flex items-center justify-center">
              <svg className="w-12 h-12 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>

          {/* Title and Message */}
          <h2 className="text-2xl font-bold text-center text-red-600 mb-4">
            {t('paymentFailure.title')}
          </h2>
          <p className="text-gray-600 text-center mb-8">
            {t('paymentFailure.message')}
          </p>

          {/* Action Button */}
          <Link 
            to="/payments/subscription"
            className="block w-full bg-[#40286C] hover:bg-[#503485] text-white text-center py-4 rounded-xl font-semibold transition-colors duration-200"
          >
            {t('paymentFailure.backToSubscription')}
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default PaymentFailure;
