import React, { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { useAddLessonMutation, useUpdateLessonMutation } from '../../redux/api/lessonApiSlice';
import { useNotifications } from '../../context/NotificationContext';
import { useTranslation } from 'react-i18next';

import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';
import LoadingAnimation from './Loading'; // Import the LoadingAnimation component
import { Building2, GraduationCap, BookOpen, AlertTriangle, Upload, X, MessageSquare, Lightbulb } from 'lucide-react'
import { fixFileName } from '../../utils/fileUtils';

const LessonForm = ({ selectedLesson, isCreatingNew, setIsCreatingNew }) => {
  console.log(selectedLesson, 'selectedLesson')
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  const [addLesson, { isLoading: isAdding }] = useAddLessonMutation();
  const [updateLesson, { isLoading: isUpdating }] = useUpdateLessonMutation();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [fileError, setFileError] = useState(''); // State to handle file errors


  const answerOptions = [
    { value: 'math', label: t('lessonForm.mathAnswerType') },
    { value: 'text', label: t('lessonForm.textAnswerType') },
  ];

  const fileUploadLimit = user?.permissions?.fileUploadLimit || 10; // in MB

  const form = useForm({
    initialValues: {
      universityName: '',
      courseName: '',
      facultyName: '',
      answerType: answerOptions[0]?.value || '',
      existingFiles: [],
      newFiles: [],
    },
    validate: {
      universityName: (value) => {
        if (!value) return t('validation.lessonForm.required');
        if (value.length < 3) return t('validation.lessonForm.minLength', { min: 3 });
        if (value.length > 50) return t('validation.lessonForm.maxLength', { max: 50 });
        if (!/^[\p{L}\s]*$/u.test(value)) return t('validation.lessonForm.invalidCharacters');
        return null;
      },
      courseName: (value) => {
        if (!value) return t('validation.lessonForm.required');
        if (value.length < 5) return t('validation.lessonForm.minLength', { min: 5 });
        if (value.length > 100) return t('validation.lessonForm.maxLength', { max: 100 });
        return null;
      },
      facultyName: (value) => {
        if (!value) return t('validation.lessonForm.required');
        if (value.length < 3) return t('validation.lessonForm.minLength', { min: 3 });
        if (value.length > 100) return t('validation.lessonForm.maxLength', { max: 100 });
        if (!/^[\p{L}\s]*$/u.test(value)) return t('validation.lessonForm.invalidCharacters');
        return null;
      },
      answerType: (value) => (!value ? t('validation.lessonForm.required') : null),
    },
  });

  useEffect(() => {
    if (selectedLesson && !isCreatingNew) {
      form.setValues({
        universityName: selectedLesson.universityName || '',
        courseName: selectedLesson.courseName || '',
        facultyName: selectedLesson.facultyName || '',
        answerType: selectedLesson.answerType || '',
        existingFiles: selectedLesson.files ? selectedLesson.files.map((file) => file.id) : [],
        newFiles: [],
      });
      console.log(selectedLesson, 'se')
      setSelectedFiles(selectedLesson.files ? selectedLesson.files.map((file) => ({ id: file.id, name: file.name })) : []);
    } else {
      console.log('formResetWorks')
      form.reset();
      setSelectedFiles([]);
    }
  }, [selectedLesson, isCreatingNew]);

  const handleFileChange = (event) => {
    console.log(event, 'event', event.target,' fdfd')
    const newFilesArray = Array.from(event.target.files);
    event.target.value = null;
    let totalFileSizeInMB = totalFileSize;

    const newValidatedFiles = newFilesArray.filter((file) => {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
      totalFileSizeInMB += fileSizeInMB;

      if (totalFileSizeInMB > fileUploadLimit) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('lessonForm.exceedsFileLimit', { fileUploadLimit }),
        });
        return false;
      }

      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ];
      if (!allowedTypes.includes(file.type)) {
        setFileError(t('lessonForm.invalidFileType', { fileName: file.name }));
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('lessonForm.invalidFileType', { fileName: file.name }),
        });
        return false;
      }

      return true;
    });

    setTotalFileSize(totalFileSizeInMB); // Update total file size
    form.setFieldValue('newFiles', [...form.values.newFiles, ...newValidatedFiles]);
    setSelectedFiles([...selectedFiles, ...newValidatedFiles.map((file) => ({ id: null, name: file.name }))]);
  };

  const handleRemoveFile = (fileName) => {
    // Check if the file is in 'newFiles'
    const newFiles = form.values.newFiles;
    const fileInNewFiles = newFiles.find((file) => file.name === fileName);
    if (fileInNewFiles) {
      form.setFieldValue('newFiles', newFiles.filter((file) => file.name !== fileName));
    } else {
      // If not in 'newFiles', remove from 'existingFiles'
      const fileIndex = selectedFiles.findIndex((file) => file.name === fileName);
      if (fileIndex > -1) {
        const fileId = selectedFiles[fileIndex].id;
        form.setFieldValue('existingFiles', form.values.existingFiles.filter((id) => id !== fileId));
      }
    }
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
  };
console.log(user, 'user')
  const handleSubmit = async (values) => {
    if (values.existingFiles.length === 0 && values.newFiles.length === 0) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('validation.lessonForm.filesRequired'),
      });
      return;
    }
  
    const sanitizedValues = {
      universityName: DOMPurify.sanitize(values.universityName),
      courseName: DOMPurify.sanitize(values.courseName),
      facultyName: DOMPurify.sanitize(values.facultyName),
      answerType: DOMPurify.sanitize(values.answerType),
      existingFiles: values.existingFiles,
      newFiles: values.newFiles,
    };
  
    const formData = new FormData();
    formData.append('universityName', sanitizedValues.universityName);
    formData.append('courseName', sanitizedValues.courseName);
    formData.append('facultyName', sanitizedValues.facultyName);
    formData.append('answerType', sanitizedValues.answerType);
  
    sanitizedValues.existingFiles.forEach((fileId) => {
      formData.append('existingFiles', fileId);
    });
  
    sanitizedValues.newFiles.forEach((file) => {
      formData.append('newFiles', file);
    });
  
    try {
      if (isCreatingNew) {
        await addLesson(formData).unwrap();
        addNotification({
          id: Date.now(),
          type: 'success',
          message: t('lessonForm.lessonAdded')
        });
      } else {
        formData.append('lessonId', selectedLesson._id);
        await updateLesson(formData).unwrap();
        addNotification({
          id: Date.now(),
          type: 'success',
          message: t('lessonForm.lessonUpdated')
        });
      }
      form.reset();
      setSelectedFiles([]);
      setIsCreatingNew(true);
    }  catch (error) {
      console.log(error, 'error')
      const errorObj = error.data;
      if (errorObj?.error === 'SUBSCRIPTION_LIMIT_REACHED') {
        const limit = errorObj?.details?.limit || 0;
        const packageName = user?.permissions?.packageName || 'Ücretsiz';
        addNotification({
          id: Date.now(),
          type: 'error',
          message: t('lessonForm.maxLessonsReached', { 
            lessonLimit: limit,
            packageName: packageName 
          }),
          autoClose: 5000
        });
      } else if (error.status === 413) {
        const fileUploadLimit = user?.permissions?.fileUploadLimit || 5;
        addNotification({
          id: Date.now(),
          type: 'error',
          message: t('lessonForm.fileLimitExceeded', { 
            fileUploadLimit: fileUploadLimit 
          })
        });
      } else {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: t('lessonForm.errorOccurred')
        });
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const files = e.dataTransfer.files;
    const event = { target: { files } };
    handleFileChange(event);
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-2xl shadow-lg ">
      {(isAdding || isUpdating) && <LoadingAnimation type="default" />}
      
      <div className="mb-8 bg-gray-50 p-6 rounded-xl">
        <h1 className="text-2xl font-bold text-[#40286C] mb-6 text-center">
          {isCreatingNew ? t('lessonForm.createCourse') : t('lessonForm.editCourse')}
        </h1>
        
        <div className="space-y-4  ">

          <div className="space-y-4">
            <p className="text-gray-600">
              Notlarınızı, kitaplarınızı ve sunumlarınızı yükleyerek kendinize kurs oluşturabilirsiniz.
            </p>
            
            <p className="text-gray-600">
              Sanal Öğretmen ve Soru&Cevap aracımız üzerinden oluşturduğunuz kursu seçerek dersinize özel eğitilmiş asistanınızla konuşabilirsiniz.
            </p>
            
            <p className="text-gray-600">
              Notlarınızı, kitaplarınızı ve sunumlarınızı kolayca yükleyin ve onlarla konuşma fırsatını yakalayın. Her zaman doğru cevapları alın. Daha hızlı öğrenin.
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-4">
        <div className="space-y-4">
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('lessonForm.universityName')}
            </label>
            <div className="relative">
              <Building2 className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#40286C] focus:border-transparent outline-none transition-all"
                placeholder={t('lessonForm.enterUniversityName')}
                {...form.getInputProps('universityName')}
              />
            </div>
            {form.errors.universityName && (
              <span className="text-sm text-red-500">{form.errors.universityName}</span>
            )}
          </div>

          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('lessonForm.facultyName')}
            </label>
            <div className="relative">
              <GraduationCap className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#40286C] focus:border-transparent outline-none transition-all"
                placeholder={t('lessonForm.enterFacultyName')}
                {...form.getInputProps('facultyName')}
              />
            </div>
            {form.errors.facultyName && (
              <span className="text-sm text-red-500">{form.errors.facultyName}</span>
            )}
          </div>

          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('lessonForm.courseName')}
            </label>
            <div className="relative">
              <BookOpen className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#40286C] focus:border-transparent outline-none transition-all"
                placeholder={t('lessonForm.enterCourseName')}
                {...form.getInputProps('courseName')}
              />
            </div>
            {form.errors.courseName && (
              <span className="text-sm text-red-500">{form.errors.courseName}</span>
            )}
          </div>
        </div>

        <div className="bg-amber-50 text-amber-900 border border-amber-200 rounded-lg p-3 flex items-center gap-2 text-sm">
          <AlertTriangle className="h-4 w-4 flex-shrink-0" />
          <span>{t('lessonForm.allowedFileTypes')}</span>
        </div>

        <div className="mt-4">
          <div 
            className="border-2 border-dashed border-gray-300 rounded-lg p-6 transition-colors hover:border-[#40286C]"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {selectedFiles.length === 0 ? (
              <div className="flex flex-col items-center justify-center text-center">
                <Upload className="h-6 w-6 text-gray-400 mb-2" />
                <h3 className="text-sm font-medium">{t('fileUpload.dragAndDrop')}</h3>
                <p className="text-xs text-gray-500 mb-3">{t('fileUpload.allowedFileTypes')}</p>
                <button
                  type="button" 
                  onClick={() => document.getElementById('fileUpload').click()}
                  className="px-4 py-1.5 bg-[#40286C] text-white rounded-lg hover:bg-[#2c1d49] transition-colors text-sm"
                >
                  {t('fileUpload.addFiles')}
                </button>
                <input
                  type="file"
                  id="fileUpload"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                  accept=".pdf,.doc,.docx,.ppt,.pptx"
                />
              </div>
            ) : (
              <div className="space-y-3 sm:space-y-4">
                <div className="flex flex-col items-center justify-center text-center mb-2 sm:mb-4">
                  <button
                    type="button" 
                    onClick={() => document.getElementById('fileUpload').click()}
                    className="px-4 sm:px-6 py-1.5 sm:py-2  bg-[#40286C] text-white rounded-lg hover:bg-gray-200 transition-colors text-sm sm:text-base"
                  >
                    {t('fileUpload.addFiles')}
                  </button>
                  <input
                    type="file"
                    id="fileUpload"
                    multiple
                    onChange={handleFileChange}
                    className="hidden"
                    accept=".pdf,.doc,.docx,.ppt,.pptx"
                  />
                </div>
                
                <ul className="space-y-1.5 sm:space-y-2">
                  {selectedFiles.map((file, index) => (
                    <li 
                      key={index} 
                      className="flex justify-between items-center p-2 sm:p-3 bg-white rounded-lg border border-gray-200"
                    >
                      <div className="flex items-center space-x-2 sm:space-x-3">
                        <div className="text-[10px] sm:text-xs text-gray-500 ">
                          {file.size ? (
                            `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                          ) : ''}
                        </div>
                        <span className="text-xs sm:text-sm text-gray-700 truncate max-w-[150px] sm:max-w-none">{fixFileName(file.name)}</span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(file.name)}
                        className="text-red-500 hover:text-red-700 "
                      >
                        <X className="h-3 w-3 sm:h-4 sm:w-4" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <button
          type="submit"
          className="w-full px-4 py-2.5 bg-[#40286C] text-white rounded-lg hover:bg-[#2c1d49] transition-all transform hover:scale-[1.02] disabled:opacity-70 disabled:hover:scale-100 font-medium"
          disabled={isAdding || isUpdating}
        >
          {isCreatingNew ? t('lessonForm.create') : t('lessonForm.save')}
        </button>
      </form>
    </div>
  );
};

export default LessonForm;