import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useVerifyUpdateEmailMutation } from '../../redux/api/authApiSlice';
import { useNotifications } from '../../context/NotificationContext';
import { useTranslation } from 'react-i18next';

const VerifyChangeEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [verifyUpdateEmail] = useVerifyUpdateEmailMutation();
  const [isLoading, setIsLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const hasRequested = useRef(false); // Track if the request has been made

  useEffect(() => {
    console.log("useEffect called"); // Log when useEffect is called
    if (hasRequested.current) {
      console.log("Request already made, returning early"); // Log if request already made
      return;
    }

    if (token) {
      hasRequested.current = true; // Set flag to true
      console.log("Sending verification request with token:", token); // Log token being sent
      verifyUpdateEmail({ token })
        .unwrap()
        .then(() => {
          console.log("Verification successful"); // Log success
          addNotification({ type: 'success', message: t('verificationPage.verify.successNotification') });
          setVerificationStatus('success');
        })
        .catch((error) => {
          console.log("Verification failed:", error); // Log error
          const errorMessage = error.data?.message || t('verificationPage.verify.errorNotification');
          
          // Check for the specific "No refresh token available" error
          if (error.status === 401 && error.data === 'No refresh token available') {
            addNotification({ type: 'error', message: t('verificationPage.verify.loginRequired') });
            setVerificationStatus('login-required');
          } else if (errorMessage.includes('Token has expired')) {
            addNotification({ type: 'error', message: t('verificationPage.verify.tokenExpired') });
            setVerificationStatus('error');
          } else {
            addNotification({ type: 'error', message: errorMessage });
            setVerificationStatus('error');
          }
        })
        .finally(() => {
          console.log("Setting isLoading to false"); // Log when loading is done
          setIsLoading(false);
        });
    } else {
      setVerificationStatus('missing');
      setIsLoading(false);
    }
  }, [token, verifyUpdateEmail, addNotification, t]);

  const handleGoToAccount = () => {
    navigate('/tools/virtualTeacher');
  };

  const handleGoToLogin = () => {
    navigate('/auth/login');
  };

  return (
    <div className="max-w-md mx-auto mt-12 p-6 bg-white rounded-lg shadow-md">
      {isLoading ? (
        <div className="flex justify-center items-center py-10">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="text-center">
          {verificationStatus === 'success' && (
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mt-4">{t('verificationPage.verify.successTitle')}</h2>
              <p className="text-gray-600 mt-2">
                {t('verificationPage.verify.successMessage')}
              </p>
              <button 
                onClick={handleGoToAccount} 
                className="mt-6 px-6 py-2 bg-green-600 text-white font-medium rounded-md hover:bg-green-700 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {t('verificationPage.verify.goToAccount')}
              </button>
            </div>
          )}
          {verificationStatus === 'login-required' && (
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mt-4">{t('verificationPage.verify.loginRequiredTitle')}</h2>
              <p className="text-gray-600 mt-2">
                {t('verificationPage.verify.loginRequiredMessage')}
              </p>
              <button 
                onClick={handleGoToLogin} 
                className="mt-6 px-6 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                {t('verificationPage.verify.goToLogin')}
              </button>
            </div>
          )}
          {verificationStatus === 'error' && (
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mt-4">{t('verificationPage.verify.errorTitle')}</h2>
              <p className="text-gray-600 mt-2">
                {t('verificationPage.verify.errorMessage')}
              </p>
              <button 
                onClick={handleGoToAccount} 
                className="mt-6 px-6 py-2 bg-red-600 text-white font-medium rounded-md hover:bg-red-700 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                {t('verificationPage.verify.goToAccount')}
              </button>
            </div>
          )}
          {verificationStatus === 'missing' && (
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mt-4">{t('verificationPage.verify.missingTitle')}</h2>
              <p className="text-gray-600 mt-2">
                {t('verificationPage.verify.missingMessage')}
              </p>
              <button 
                onClick={handleGoToAccount} 
                className="mt-6 px-6 py-2 bg-red-600 text-white font-medium rounded-md hover:bg-red-700 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                {t('verificationPage.verify.goToAccount')}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerifyChangeEmail;
