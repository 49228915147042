import React from 'react';
import { Modal, Button, Group, Stack, Radio, Checkbox, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faBrain, faRunning } from '@fortawesome/free-solid-svg-icons';

const Originalizer = ({ formState, setFormState, isOpen, title, onConfirm, onCancel, t }) => {
  
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = () => {
    onConfirm(formState);
    onCancel(); // Close the modal
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onCancel}
      title={t("originalizer.title")}
      size="lg"
    >
      {/* Shealth Mode Selection */}
      <Stack    gap="xs">
        <Text fw={600}>{t("originalizer.shealthMode")}</Text>
        {["Low", "Medium", "High"].map((mode) => (
          <Radio
            key={mode}
            value={mode}
            checked={formState.shealthMode === mode}
            label={t(`originalizer.${mode.toLowerCase()}`)}
            name="shealthMode"
            onChange={handleChange}
            color="#03045E"
          />
        ))}
      </Stack>

      {/* Writing Tone Selection */}
      <Stack   gap="xs" mt="md">
        <Text  fw={600}>{t("originalizer.writingTone")}</Text>
        {["Standard", "Casual", "Professional", "Academic"].map((tone) => (
          <Radio
            key={tone}
            value={tone}
            checked={formState.writingTone === tone}
            label={t(`originalizer.${tone.toLowerCase()}`)}
            name="writingTone"
            onChange={handleChange}
            color="#03045E"
          />
        ))}
      </Stack>

      {/* Checkboxes */}
      <Stack mt="md" gap="xs">
      <Text  fw={600}>{t("originalizer.specialFeatures")}</Text>
      <Group >
  <FontAwesomeIcon icon={faRunning} style={{ color: formState.intihalOrani ? 'green' : 'inherit' }} />
  <Checkbox
   color="#03045E"
    label={t("originalizer.intihalOrani")}
    name="intihalOrani"
    checked={formState.intihalOrani}
    onChange={handleChange}
  />
</Group>

<Group>
  <FontAwesomeIcon icon={faBrain} style={{ color: formState.yapayZekaOrani ? 'green' : 'inherit' }} />
  <Checkbox
   color="#03045E"
    label={t("originalizer.yapayZekaOrani")}
    name="yapayZekaOrani"
    checked={formState.yapayZekaOrani}
    onChange={handleChange}
  />
</Group>

<Group>
  <FontAwesomeIcon icon={faPen} style={{ color: formState.writingFeedback ? 'green' : 'inherit' }} />
  <Checkbox
   color="#03045E"
    label={t("originalizer.writingFeedback")}
    name="writingFeedback"
    checked={formState.writingFeedback}
    onChange={handleChange}
  />
</Group>

<Group>
  <FontAwesomeIcon icon={faEye} style={{ color: formState.intihalRaporu ? 'green' : 'inherit' }} />
  <Checkbox
   color="#03045E"
    label={t("originalizer.intihalRaporu")}
    name="intihalRaporu"
    checked={formState.intihalRaporu}
    onChange={handleChange}
  />
</Group>
   

      </Stack>
     

      {/* Action Buttons */}
      <Group position="right" mt="md">
        <Button variant="default" onClick={() => onCancel()}>{t("originalizer.cancel")}</Button>
        <Button onClick={handleSubmit}  color="#03045E">{t("originalizer.confirm")}</Button>
      </Group>
    </Modal>
  );
};

export default Originalizer;
