import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/tr'; // Turkish
import 'dayjs/locale/en'; // English

dayjs.extend(localizedFormat);

const TransactionList = ({ usageHistory, totalCount,  onLoadMore, isLoading }) => {
  const { t, i18n } = useTranslation();
  const viewport = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    // Set Day.js locale based on i18n language
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  const handleScroll = useCallback(() => {
    const current = viewport.current;
    if (current) {
      setScrolled(current.scrollTop > 0);
      setShowScrollTop(current.scrollTop > 400);

      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollHeight - scrollTop <= clientHeight * 1.2) {
        onLoadMore();
      }
    }
  }, [onLoadMore]);

  const scrollToTop = () => {
    viewport.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getTotalTokens = () => {
    return usageHistory.reduce((sum, item) => sum + item.amount, 0);
  };

  const getToolIcon = (toolType) => {
    const cleanToolType = toolType.toLowerCase().replace(/\s/g, '').replace("&", "");
    console.log(toolType, cleanToolType, "toolType")
    const toolIcons = {
      questionanswer: "M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z",
      virtualteacher: "M21 11.5v-1c0-.83-.67-1.5-1.5-1.5H17v-2c0-.83-.67-1.5-1.5-1.5h-1V4h-1.5v1.5h-2V4H9.5v1.5h-1C7.67 5.5 7 6.17 7 7v2H4.5C3.67 9 3 9.67 3 10.5v1H1.5V13H3v1c0 .83.67 1.5 1.5 1.5H7v2c0 .83.67 1.5 1.5 1.5h1V20h1.5v-1.5h2V20H14.5v-1.5h1c.83 0 1.5-.67 1.5-1.5v-2h2.5c.83 0 1.5-.67 1.5-1.5v-1H22.5V11.5H21zm-4 2c0 .83-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5v5z",
      articleforger: 'M21 11.5v-1c0-.83-.67-1.5-1.5-1.5H17v-2c0-.83-.67-1.5-1.5-1.5h-1V4h-1.5v1.5h-2V4H9.5v1.5h-1C7.67 5.5 7 6.17 7 7v2H4.5C3.67 9 3 9.67 3 10.5v1H1.5V13H3v1c0 .83.67 1.5 1.5 1.5H7v2c0 .83.67 1.5 1.5 1.5h1V20h1.5v-1.5h2V20H14.5v-1.5h1c.83 0 1.5-.67 1.5-1.5v-2h2.5c.83 0 1.5-.67 1.5-1.5v-1H22.5V11.5H21zm-4 2c0 .83-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5v5z',
      summarizer: 'M12 2C6.48 2 2 4.69 2 8v8c0 3.31 4.48 6 10 6s10-2.69 10-6V8c0-3.31-4.48-6-10-6zm0 2c4.42 0 8 1.79 8 4s-3.58 4-8 4-8-1.79-8-4 3.58-4 8-4zm0 16c-4.42 0-8-1.79-8-4v-6.11c1.37 1.19 4.24 2.11 8 2.11s6.63-.92 8-2.11V18c0 2.21-3.58 4-8 4z',
      paraphraser: 'M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z',
      default: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z',
    };

    return toolIcons[cleanToolType] || toolIcons.default;
  };

  useEffect(() => {
    const current = viewport.current;
    if (current) {
      current.addEventListener('scroll', handleScroll);
      return () => current.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  if (!isLoading && usageHistory.length === 0) {
    return (
      <div className="container mx-auto mt-8 max-w-2xl">
        <div className="bg-white rounded-lg shadow-md p-8 text-center">
          <svg className="w-16 h-16 mx-auto text-blue-500 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h2 className="text-2xl font-semibold mb-2">{t('transactions.noTransactionsTitle')}</h2>
          <p className="text-gray-600">{t('transactions.noTransactionsMessage')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8 max-w-4xl">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h2 className="text-xl font-semibold">{t('transactions.title')}</h2>
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
    {totalCount} {t('transactions.entries')}
  </span>
            </div>
            <div className="bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded-full flex items-center">
              <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {getTotalTokens()}
            </div>
          </div>
        </div>

        <div ref={viewport} className="overflow-auto max-h-[600px]">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className={`bg-gray-50 ${scrolled ? 'shadow' : ''}`}>
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('transactions.table.toolName')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('transactions.table.date')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('transactions.table.tokens')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {usageHistory.map((item) => (
                <tr key={item._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <svg className="w-5 h-5 text-gray-400 mr-2" viewBox="0 0 24 24" fill="currentColor">
                        <path d={getToolIcon(item.toolType)} />
                      </svg>
                      <div className="text-sm font-medium text-gray-900">{item.toolType}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{dayjs(item.createdAt).format('LL')}</div>
                    <div className="text-sm text-gray-500">{dayjs(item.createdAt).format('LT')}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      item.amount > 0 ? 'bg-green-100 text-green-800' : 
                      item.amount <= -5 ? 'bg-red-100 text-red-800' : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {`${item.amount > 0 ? '+' : ''}${item.amount}`}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
          {isLoading && (
            <div className="flex justify-center items-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          )}
        </div>
      </div>

      {showScrollTop && (
        <button
          className="fixed right-8 bottom-8 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300"
          onClick={scrollToTop}
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default TransactionList;
