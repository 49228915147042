import { Box, Title, Text } from '@mantine/core';
import { useTranslation } from "react-i18next";
import ChangeEmail from './changeEmailPhone/changeEmail';
import ChangePhoneNumber from './changeEmailPhone/changePhone';

const EmailPhoneSettings = () => {
  const { t } = useTranslation();
  
  return (
    <div className="bg-white rounded-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-900 mb-2">
        {t('settings.form.changeEmailPhone')}
      </h2>
      <p className="text-gray-600 mb-6">
        {t('settings.form.changeEmailPhoneDescription')}
      </p>
      <div className="space-y-8">
        <ChangeEmail />
        <div className="border-t border-gray-200 pt-8">
          <ChangePhoneNumber />
        </div>
      </div>
    </div>
  );
};
export default EmailPhoneSettings;
