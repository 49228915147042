
import { Modal } from '@mantine/core';
import LessonForm from './LessonForm';

// eslint-disable-next-line react/prop-types
function CourseCreationModal({ t, opened, setOpened }) {
   


    return (
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
           
            size="lg"
            classNames={{
                content: 'rounded-lg thinScroll-container',
                header: 'pb-4 border-b border-slate-200',
                body: 'pt-6',
                close: 'hover:bg-slate-100 rounded-lg w-8 h-8'
              }}
        >
            <LessonForm
                t={t}
                isCreatingNew={true}
            />
        </Modal>
    );
}

export default CourseCreationModal;