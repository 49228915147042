import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccountInformation from '../../components/settings/AccountInformation';
import ChangePassword from '../../components/settings/ChangePassword';
import ContactPreference from '../../components/settings/ContactPreference';
import ChangeEmailPhone from '../../components/settings/ChangeEmailPhone';
import SubscriptionInformation from '../../components/settings/SubscriptionInformation';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('account');
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const tabs = [
    { id: 'account', label: t('settings.tab.account') },
    { id: 'subscription', label: t('settings.tab.subscription') },
    { id: 'password', label: t('settings.tab.changePassword') },
    { id: 'emailphone', label: t('settings.tab.changeEmailPhone') },
    { id: 'contact', label: t('settings.tab.contactPreferences') }
  ];

  const TabButton = ({ id, label, isActive }) => (
    <button
      onClick={() => setActiveTab(id)}
      className={`
        relative whitespace-nowrap px-3 py-2.5 text-sm 
        transition-colors duration-200 flex-shrink-0
        ${isActive ? 
          'text-black font-medium border-b-2 border-black' : 
          'text-gray-500 hover:text-gray-800'
        }
      `}
    >
      {label}
    </button>
  );

  return (
    <div className="max-w-7xl mx-auto px-3 py-4 sm:px-6 sm:py-6">
      <div className="bg-white rounded-xl shadow-sm px-3 py-4 sm:px-4">
        {/* Header */}
        <div className="mb-4 sm:mb-6">
          <h1 className="text-xl sm:text-2xl font-bold text-black mb-1">
            {t('settings.title')}
          </h1>
          <p className="text-gray-500 text-sm">
          {t('settings.subtitle')}
          </p>
        </div>

        {/* Navigation */}
        <div className="border-b border-gray-200 overflow-x-auto -mx-3 sm:mx-0">
          <nav className="flex space-x-4 min-w-max px-3 sm:px-0">
            {tabs.map((tab) => (
              <TabButton
                key={tab.id}
                id={tab.id}
                label={tab.label}
                isActive={activeTab === tab.id}
              />
            ))}
          </nav>
        </div>

        {/* Content */}
        <div className="py-4">
          {activeTab === 'account' && <AccountInformation />}
          {activeTab === 'subscription' && <SubscriptionInformation user={user} />}
          {activeTab === 'password' && <ChangePassword />}
          {activeTab === 'emailphone' && <ChangeEmailPhone />}
          {activeTab === 'contact' && (
            <ContactPreference userContactPreferences={user.contactPreferences} />
          )}
        </div>
      </div>
    </div>
  );
};

const MainLayout = () => {
  return (
    <div className=" min-h-screen">
      <Settings />
    </div>
  );
};

export default MainLayout;