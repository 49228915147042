import { useState } from 'react';
import { useForm } from '@mantine/form';
import { Button, PasswordInput, Box, Alert, Text, Title, Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../context/NotificationContext';
import { useChangePasswordMutation } from '../../redux/api/authApiSlice';
import DOMPurify from 'dompurify';
import StyledButton from '../common/StyledButton';

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const ChangePassword = () => {
  const { t } = useTranslation();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { addNotification } = useNotifications();

  const form = useForm({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    },
    validate: {
      currentPassword: (value) => (value ? null : t('settings.form.validation.currentPassword')),
      newPassword: (value) => 
        passwordRegex.test(value) 
          ? null 
          : t('settings.form.validation.newPassword'),
      newPasswordConfirm: (value, values) => 
        value !== values.newPassword 
          ? t('settings.form.validation.newPasswordConfirm') 
          : null
    },
  });

  const handleSubmit = async (values) => {
    // Log values for debugging
    console.log('Form values:', values);

    const sanitizedCurrentPassword = DOMPurify.sanitize(values.currentPassword);
    const sanitizedNewPassword = DOMPurify.sanitize(values.newPassword);
    const sanitizedNewPasswordConfirm = DOMPurify.sanitize(values.newPasswordConfirm);

    if (sanitizedNewPassword !== sanitizedNewPasswordConfirm) {
      addNotification({
        type: 'error',
        message: t('settings.form.validation.passwordMismatch'),
      });
      return;
    }

    try {
      console.log(sanitizedCurrentPassword, sanitizedNewPassword, sanitizedNewPasswordConfirm, "12213123123");
      await changePassword({
        currentPassword: sanitizedCurrentPassword,
        newPassword: sanitizedNewPassword,
        newPasswordConfirm: sanitizedNewPasswordConfirm,
      }).unwrap();
      addNotification({
        type: 'success',
        message: t('settings.form.notifications.passwordUpdateSuccess'),
      });
      form.reset();
    } catch (err) {
      addNotification({
        type: 'error',
        message: err.data?.message || t('settings.form.notifications.passwordUpdateFail'),
      });
    }
  };

  
  return (
    <div className="bg-white rounded-lg  p-6">
      <h2 className="text-2xl font-semibold text-gray-900 mb-2">
        {t('settings.form.changePassword')}
      </h2>
      <p className="text-gray-600 mb-6">
        {t('settings.form.passwordDescription')}
      </p>
      <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            {t('settings.form.currentPassword')}
          </label>
          <input
            type="password"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder={t('settings.form.enterCurrentPassword')}
            {...form.getInputProps('currentPassword')}
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {t('settings.form.newPassword')}
            </label>
            <input
              type="password"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('settings.form.enterNewPassword')}
              {...form.getInputProps('newPassword')}
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {t('settings.form.newPasswordConfirm')}
            </label>
            <input
              type="password"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('settings.form.enterNewPasswordConfirm')}
              {...form.getInputProps('newPasswordConfirm')}
            />
          </div>
        </div>
        
        <div className="p-4 bg-red-50 text-red-800 rounded-lg">
          <p className="text-sm">
            {t('settings.form.passwordSecurityDescription')}
          </p>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-[#40286C] text-white rounded-lg hover:bg-[#3e2866] transition-colors"
            disabled={isLoading}
          >
            {isLoading ? t('loading') : t('settings.form.update')}
          </button>
        </div>
      </form>
    </div>
  );
};


export default ChangePassword;
