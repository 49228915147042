import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Title, Text, Button, Loader, Modal } from '@mantine/core';
import useAxios from '../../hooks/useAxios';
import { useNotifications } from '../../context/NotificationContext';
import { translateError } from '../../utils/translateError';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { loading, error, sendRequest } = useAxios();
  const { addNotification } = useNotifications();
  const [initialLoad, setInitialLoad] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  
  const { token } = useParams();
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      token: '',
      email: ''
    },
    validate: {
      token: (value) => {
        return value ? null : t('verifyEmail.missingTokenError');
      },
      email: (value) => {
        return /^\S+@\S+$/.test(value) ? null : t('verifyEmail.invalidEmail');
      }
    }
  });
  
  useEffect(() => {
    // Check if the token is not present
    if (!token) {
      setErrorMessage(t('verifyEmail.missingTokenError'));
      setInitialLoad(false);
      return;
    }
    verifyEmailToken(token);
  }, [token]);

  const verifyEmailToken = async (token) => {
    try {
      const response = await sendRequest(
        {
          url: `/auth/verify-email/${token}`,
          method: "GET",
        },
        (data) => {
          form.setFieldValue("token", "", { shouldValidate: false });
          setVerified(true);
          setInitialLoad(false);
          if (data.alreadyVerified) {
            addNotification({
              id: Date.now().toString(),
              message: t('verifyEmail.alreadyVerified'),
              type: 'info'
            });
          } else {
            setTimeout(() => {
              navigate('/auth/login');
            }, 5000);
          }
        }
      );
      
      // If we get here and initialLoad is still true, it means the success callback wasn't called
      if (initialLoad) {
        setInitialLoad(false);
        setErrorMessage(t('verifyEmail.tokenExpired'));
      }
    } catch (err) {
      console.error("Verification error:", err);
      const errorMessage = err?.response?.data?.code === "EMAIL_VERIFICATION_FAILED" 
        ? t('verifyEmail.tokenExpired') 
        : translateError(err, t);
      
      addNotification({
        id: Date.now().toString(),
        message: errorMessage,
        type: 'error'
      });
      setErrorMessage(errorMessage);
      setInitialLoad(false);
    }
  };

  const handleResendVerification = async () => {
    if (!form.values.email || !/^\S+@\S+$/.test(form.values.email)) {
      form.setFieldError('email', t('verifyEmail.invalidEmail'));
      return;
    }

    setResendLoading(true);
    try {
      await sendRequest(
        {
          url: '/auth/resend-verification-email',
          method: 'POST',
          data: { email: form.values.email }
        },
        (data) => {
          addNotification({
            id: Date.now().toString(),
            message: t('verifyEmail.resendSuccess'),
            type: 'success'
          });
          form.setFieldValue('email', '');
        }
      );
    } catch (err) {
      addNotification({
        id: Date.now().toString(),
        message: translateError(err, t),
        type: 'error'
      });
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 px-4 sm:px-6">
      <div className="w-full max-w-md mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-5 sm:p-8">
          {loading || initialLoad ? (
            <div className="flex flex-col justify-center items-center py-8 sm:py-12">
              <Loader size="xl" color="#40286C" />
              <p className="mt-4 text-gray-600">{t('verifyEmail.loading')}</p>
            </div>
          ) : verified ? (
            <div className="text-center">
              <div className="flex justify-center mb-4 sm:mb-6">
                <div className="w-16 h-16 sm:w-20 sm:h-20 bg-green-100 rounded-full flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 sm:h-10 sm:w-10 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800">{t('verifyEmail.successTitle')}</h2>
              <p className="text-gray-600 mb-6 sm:mb-8 text-sm sm:text-base">{t('verifyEmail.successMessage')}</p>
              <button 
                className="w-full py-2.5 sm:py-3 px-4 bg-[#40286C] text-white rounded-lg hover:bg-opacity-90 transition-colors font-medium text-sm sm:text-base"
                onClick={() => navigate('/auth/login')}
              >
                {t('verifyEmail.loginButton')}
              </button>
            </div>
          ) : (
            <div className="text-center">
              <div className="flex justify-center mb-4 sm:mb-6">
                <div className="w-16 h-16 sm:w-20 sm:h-20 bg-red-100 rounded-full flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 sm:h-10 sm:w-10 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800">{errorMessage || t('verifyEmail.errorTitle')}</h2>
              
              {errorMessage && errorMessage.includes(t('verifyEmail.tokenExpired')) && (
                <div className="mt-6 sm:mt-8 bg-gray-50 p-4 sm:p-6 rounded-lg border border-gray-200">
                  <div className="flex justify-center mb-3 sm:mb-4">
                    <div className="w-10 h-10 sm:w-12 sm:h-12 bg-[#40286C] bg-opacity-10 rounded-full flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 sm:h-6 sm:w-6 text-[#40286C]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                    </div>
                  </div>
                  <p className="text-gray-700 mb-4 sm:mb-6 font-medium text-sm sm:text-base">{t('verifyEmail.resendInstructions')}</p>
                  <div className="space-y-3 sm:space-y-4">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                        </svg>
                      </div>
                      <input
                        type="email"
                     
                        className={`w-full pl-9 sm:pl-10 pr-3 sm:pr-4 py-2.5 sm:py-3 border rounded-lg focus:ring-2 focus:ring-[#40286C] focus:border-[#40286C] outline-none transition-colors text-sm sm:text-base ${
                          form.errors.email ? 'border-red-500 bg-red-50' : 'border-gray-300'
                        }`}
                        value={form.values.email}
                        onChange={(e) => form.setFieldValue('email', e.target.value)}
                      />
                    </div>
                    {form.errors.email && (
                      <p className="text-red-500 text-xs sm:text-sm text-left">{form.errors.email}</p>
                    )}
                    <button
                      className="w-full py-2.5 sm:py-3 px-4 bg-[#40286C] text-white rounded-lg hover:bg-opacity-90 transition-colors font-medium disabled:bg-opacity-70 flex items-center justify-center text-sm sm:text-base"
                      onClick={handleResendVerification}
                      disabled={resendLoading}
                    >
                      {resendLoading ? (
                        <>
                          <Loader size="sm" color="white" className="mr-2" />
                          {t('verifyEmail.sending')}
                        </>
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                          </svg>
                          {t('verifyEmail.resendButton')}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
              
              <button 
                className="w-full py-2.5 sm:py-3 px-4 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors font-medium mt-4 sm:mt-6 text-sm sm:text-base"
                onClick={() => navigate('/auth/login')}
              >
                {t('verifyEmail.loginButton')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
