import { SendIcon, Send } from 'lucide-react';
import { AppShell } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import Sidebar from './Sidebar';
import { Grid, Textarea, Group } from '@mantine/core';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading.json';

const SkeletonHistory = () => {
  // Create an array of 10 items for skeleton loading
  const skeletonItems = Array(10).fill(null);

  return (
    <div className="flex flex-col h-screen bg-gray-50/30">
      {/* Keep the original button unchanged */}
      <div className="sticky top-0 z-10 backdrop-blur-sm border-b border-wh-100 p-4">
        <button className="w-full group relative overflow-hidden rounded-md bg-[#40286C] text-white p-3.5 shadow-sm transition-all duration-300 hover:shadow-lg hover:scale-[1.02]">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium tracking-wide">
              Yeni Oluştur
            </span>
            <SendIcon className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
          </div>
          <div className="absolute inset-0 bg-white/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
        </button>
      </div>

      {/* Skeleton loading list */}
      <div className="flex-1 overflow-auto">
        <div className="space-y-2 p-4">
          {skeletonItems.map((_, index) => (
            <div
              key={index}
              className="relative p-3.5 bg-white rounded-md"
            >
              <div className="flex items-center justify-between gap-3">
                <div className="flex-1 min-w-0">
                  {/* Skeleton text animation */}
                  <div className="h-4 bg-gray-200 rounded animate-pulse w-3/4"></div>
                </div>
                <div className="flex items-center gap-2">
                  {/* Skeleton button placeholder */}
                  <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



const ContentSkeleton = () => {
    return (
      <div className="max-w-6xl mx-auto px-4 py-6">
        {/* Top selection boxes */}
        <div className="grid grid-cols-3 gap-4 mb-8 border-b border-gray-200 pb-4">
          <div className="space-y-1">
            <div className="h-5 w-24 bg-gray-200 rounded animate-pulse mb-1" />
            <div className="h-10 bg-gray-100 rounded animate-pulse" />
          </div>
          <div className="space-y-1">
            <div className="h-5 w-24 bg-gray-200 rounded animate-pulse mb-1" />
            <div className="h-10 bg-gray-100 rounded animate-pulse" />
          </div>
          <div className="space-y-1">
            <div className="h-5 w-24 bg-gray-200 rounded animate-pulse mb-1" />
            <div className="h-10 bg-[#40286C] rounded animate-pulse" />
          </div>
        </div>
  
        {/* Main content */}
        <div className="relative">
          <div className="w-48 space-y-2 mb-12 border-r border-gray-200 pr-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="h-10 bg-gray-100 rounded animate-pulse p-2" />
            ))}
          </div>
        </div>
  
        {/* Fixed bottom input and button */}
        <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 p-4 z-50">
          <div className="mx-auto max-w-6xl">
            <Grid>
              <Grid.Col span={8}>
                <Textarea
                  minRows={1}
                  maxRows={3}
                  autosize
                  id="question"
                  style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Group grow>
                <button
                  disabled={true}
                  className="opacity-50 cursor-not-allowed inline-flex items-center justify-center gap-2 font-medium rounded-lg transition-all duration-200 shadow-sm focus:ring-2 focus:ring-offset-2 bg-[#40286C] text-white hover:bg-[#563a8d]  py-2.5 text-sm flex gap-3"
                >
                  Soru Sor
                  <Send size={16} />
                </button>
              
                </Group>
              </Grid.Col>
            </Grid>
          </div>
        </div>
      </div>
    );
  };
  


const SkeletonLayout = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isToolsRoute = location.pathname.startsWith('/tools');

  return (
    <AppShell
      header={{ height: isMobile ? 38 : 0 }}
      navbar={{
        width: 250,
        breakpoint: 'md',
      }}
      padding="xs"
      aside={isToolsRoute && !isMobile ? {
        width: 250,
        breakpoint: 'md',
     
      } : undefined}
    >
        {isMobile ? (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <Lottie
              animationData={loadingAnimation}
              loop
              style={{ width: 150, height: 150 }}
            />
          </div>
        ) : (
          <>  
        <AppShell.Header style={{ border: 'unset', background: 'unset' }}>
          {isMobile && (
            <div className="px-4 py-2">
              <div className="w-6 h-6 bg-gray-300 rounded animate-pulse" />
            </div>
          )}
        </AppShell.Header>

        <AppShell.Navbar p={0} className="bg-gray-50 border-r border-gray-200">
          <Sidebar isMobile={isMobile} />
        </AppShell.Navbar>
    
        <AppShell.Aside 
          p="md" 
          width={{ base: 250 }} 
          style={{ 
            backgroundColor: '#f9f9fa',
            marginTop: 0,
            zIndex: 1000
          }} 
          hiddenBreakpoint="sm" 
          id="toolHistory"
        >   
            <SkeletonHistory />
        </AppShell.Aside>

        <AppShell.Main>
          <ContentSkeleton />
        </AppShell.Main>
        </>
         )}
    </AppShell>
  );
};

export default SkeletonLayout;