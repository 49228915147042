import React, { useState } from 'react';
import { useFetchLessonsQuery, useDeleteLessonMutation } from '../../redux/api/lessonApiSlice';
import LessonForm from '../../components/common/LessonForm';
import ConfirmationModal from '../../components/common/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { PlusCircle, Trash2, Book, Loader2 } from 'lucide-react';
import { useNotifications } from '../../context/NotificationContext';

const Lessons = () => {
  const { t } = useTranslation();
  const { data: lessons, error, isLoading } = useFetchLessonsQuery();
  const [deleteLesson] = useDeleteLessonMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lessonIdToDelete, setLessonIdToDelete] = useState(null);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(true);
  const { addNotification } = useNotifications();

  const handleCreateNew = () => {
    setSelectedLessonId(null);
    setIsCreatingNew(true);
  };
console.log(lessons)
  const handleDeleteLesson = async (lessonId) => {
    try {
      await deleteLesson(lessonId).unwrap();
      setSelectedLessonId(null);
      setIsCreatingNew(true);
      addNotification({
        id: Date.now(),
        type: 'success',
        title: t('lessonForm.deleteSuccess.title'),
        message: t('lessonForm.deleteSuccess.message'),
        autoClose: 5000
      });
    } catch (error) {
      console.error('Failed to delete lesson:', error);
      addNotification({
        id: Date.now(),
        type: 'error',
        title: t('lessonForm.deleteError.title'),
        message: t('lessonForm.deleteError.message'),
        autoClose: 5000
      });
    }
  };

  const openDeleteModal = (lessonId) => {
    setIsModalOpen(true);
    setLessonIdToDelete(lessonId);
  };

  const confirmDelete = async () => {
    if (lessonIdToDelete) {
      await handleDeleteLesson(lessonIdToDelete);
      setIsModalOpen(false);
      setLessonIdToDelete(null);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setLessonIdToDelete(null);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-red-500">
        Hata: {error.toString()}
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row h-screen ">
      {/* Sidebar */}
   

      {/* Main Content */}
      <div className="flex-1 overflow-auto thinScroll-container">
        <div className="max-w-4xl mx-auto py-6 px-4 ">
          <LessonForm
            selectedLesson={lessons?.find((lesson) => lesson._id === selectedLessonId)}
            isCreatingNew={isCreatingNew}
            setIsCreatingNew={setIsCreatingNew}
          />
        </div>
      </div>
      <div className="w-80 bg-white border-l border-gray-200 flex flex-col">
        {/* Create New Button */}
        <div className="p-4 border-b border-gray-200">
          <button
            onClick={handleCreateNew}
            className="w-full flex items-center justify-center gap-2 bg-[#40286C] text-white px-4 py-2 rounded-lg hover:bg-[#40286C]/90 transition-colors"
            id="create-lesson-btn"
          >
            <PlusCircle className="h-5 w-5" />
            <span>{t("lessonForm.createCourse")}</span>
          </button>
        </div>

        {/* Lessons List */}
        <div className="flex-1 overflow-y-auto" id="lessons-list">
          {lessons?.map((lesson) => (
            <div
              key={lesson._id}
              onClick={() => {
                setSelectedLessonId(lesson._id);
                setIsCreatingNew(false);
              }}
              className={`
                flex items-center justify-between px-4 py-3 cursor-pointer
                hover:bg-[#40286C]/5 transition-colors
                ${selectedLessonId === lesson._id ? "bg-[#40286C]/10" : ""}
                border-b border-gray-100
              `}
            >
              <div className="flex items-center gap-3">
                <Book className="h-5 w-5 text-[#40286C]" />
                <span className="text-sm font-medium text-gray-700 truncate">
                  {lesson.courseName}
                </span>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  openDeleteModal(lesson._id);
                }}
                className="p-1 hover:bg-[#40286C]/10 rounded-full transition-colors"
              >
                <Trash2 className="h-4 w-4 text-gray-400 hover:text-red-500" />
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        title={t('lessonForm.deleteLesson')}
        message={t('lessonForm.deleteCourseConfirmation')}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        t={t}
      />
    </div>
  );
};

export default Lessons;