import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Check } from 'lucide-react';
// Pricing data
const plansData = [
  {
    id: 'basic',
    buttonId: 'plans-button-1',
    name: 'Freshman',
    title: 'subscriptionpackages.title.bronzePackage',
    price: {
      monthly: { TRY: 32, USD: 1 },
      yearly: { TRY: 768, USD: 10 },
      discounted: { TRY: 230, USD: 20 }
    },
    features: [
      'subscriptionpackages.feature.docprofesor',
      'subscriptionpackages.feature.2000Coins',
      'subscriptionpackages.feature.2Lessons',
      'subscriptionpackages.feature.20MBFileUpload',
      'subscriptionpackages.feature.aiDetectorAPI',
      'subscriptionpackages.feature.humanizerAPI',
    ],
    gradient: "bg-white border border-gray-300 text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:ring-gray-300",
        type: 'subscription'
  },
  {
    id: 'pro',
    buttonId: 'plans-button-2',
    name: 'Sophomore',
    title: 'subscriptionpackages.title.silverPackage',
    price: {
      monthly: { TRY: 170, USD: 5 },
      yearly: { TRY: 4080, USD: 50 },
      discounted: { TRY: 1224, USD: 70 }
    },
    features: [
      'subscriptionpackages.feature.docprofesor',
      'subscriptionpackages.feature.12000Coins',
      'subscriptionpackages.feature.3Lessons',
      'subscriptionpackages.feature.30MBFileUpload',
      'subscriptionpackages.feature.aiDetectorAPI',
      'subscriptionpackages.feature.humanizerAPI',
    ],
    gradient: "bg-white border border-gray-300 text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:ring-gray-300",
        type: 'subscription'
  },
  {
    id: 'premium',
    buttonId: 'plans-button-3',
    name: 'Junior',
    title: 'subscriptionpackages.title.goldPackage',
    price: {
      monthly: { TRY: 544, USD: 16 },
      yearly: { TRY: 13056, USD: 384 },
      discounted: { TRY: 3917, USD: 115 }
    },
    features: [
      'subscriptionpackages.feature.docprofesor',
      'subscriptionpackages.feature.50000Coins',
      'subscriptionpackages.feature.4Lessons',
      'subscriptionpackages.feature.40MBFileUpload',
      'subscriptionpackages.feature.aiDetectorAPI',
      'subscriptionpackages.feature.humanizerAPI',
    ],
    gradient: "bg-gradient-to-r from-[#40286C] to-black text-white hover:from-[#40286C]/90 hover:to-gray-800 focus:ring-[#40286C]",
    popular: true,
        type: 'subscription'
  },
  {
    id: 'premium2',
    buttonId: 'plans-button-4',
    name: 'Senior',
    title: 'subscriptionpackages.title.platinumPackage',
    price: {
      monthly: { TRY: 1020, USD: 30 },
      yearly: { TRY: 24480, USD: 720 },
      discounted: { TRY: 7344, USD: 215 }
    },
    features: [
      'subscriptionpackages.feature.unlimited40miniUsage',
      'subscriptionpackages.feature.30000Coins',
      'subscriptionpackages.feature.5Lessons',
      'subscriptionpackages.feature.50MBFileUpload',
      'subscriptionpackages.feature.aiDetectorAPI',
      'subscriptionpackages.feature.humanizerAPI',
    ],
    gradient: "bg-white border border-gray-300 text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:ring-gray-300",
    type: 'subscription'
  },
];

const coinPackagesData = [
  {
    id: 'silver',
    buttonId: 'plans-button-5',
    name: 'Small',
    title: 'subscriptionpackages.title.smallPortion',
    price: { TRY: 170, USD: 5 },
    coins: 15000,
    gradient: "bg-white border border-gray-300 text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:ring-gray-300",
     type: 'coin'
  },
  {
    id: 'gold',
    buttonId: 'plans-button-6',
    name: 'Medium',
    title: 'subscriptionpackages.title.mediumPortion',
    price: { TRY: 340, USD: 10 },
    coins: 25000,
    gradient: "bg-white border border-gray-300 text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:ring-gray-300",
     type: 'coin'
  },
  {
    id: 'platinum',
    buttonId: 'plans-button-7',
    name: 'Large',
    title: 'subscriptionpackages.title.largePortion',
    price: { TRY: 680, USD: 20 },
    coins: 60000,
   
    gradient: "bg-gradient-to-r from-[#40286C] to-black text-white hover:from-[#40286C]/90 hover:to-gray-800 focus:ring-[#40286C]",
    type: 'coin'
  },
  {
    id: 'platinum2',
    buttonId: 'plans-button-8',
    name: 'Double',
    title: 'subscriptionpackages.title.doubleCoin',
    price: { TRY: 1360, USD: 40 },
    coins: 100000,
    popular: true,
    gradient: "bg-gradient-to-r from-[#40286C] to-black text-white hover:from-[#40286C]/90 hover:to-gray-800 focus:ring-[#40286C]",
     type: 'coin'
  }
];

const BillingToggle = ({ billingCycle, setBillingCycle }) => {
  const { t } = useTranslation();
  
  return (
    <div className="mt-12 flex justify-center gap-4">
      {["monthly", "yearly", "tokens"].map((cycle) => (
        <button
          key={cycle}
          onClick={() => setBillingCycle(cycle)}
          className={`rounded-full px-6 py-2 text-sm font-medium transition-colors
            ${billingCycle === cycle 
              ? "bg-[#40286C] text-primary-foreground" 
              : "bg-white text-gray-500 hover:bg-gray-50"}`}
        >
          {t(`subscriptionpackages.${cycle}`)}
        </button>
      ))}
    </div>
  );
};

const PricingCard = ({ plan, billingCycle, currency, onSelect, userPackage }) => {
  const { t } = useTranslation();
  const isCurrentPackage = userPackage === plan.id;
  
  const getPrice = () => {
    if (billingCycle === "yearly") {
      return `${plan.price.discounted[currency]}${currency === 'TRY' ? '₺' : '$'}`;
    }
    if (billingCycle === "tokens") {
      return `${plan.price[currency]}${currency === 'TRY' ? '₺' : '$'}`;
    }
    return `${plan.price.monthly[currency]}${currency === 'TRY' ? '₺' : '$'}`;
  };

  return (
    <div className={`relative rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200 
      transition-all hover:scale-105 ${plan.popular ? "ring-2 ring-[#40286C]" : ""}`}>
      {plan.popular && (
        <span className="absolute -top-2 right-4 rounded-full bg-[#40286C] px-3 py-1 text-xs font-semibold text-primary-foreground">
          {t("subscriptionpackages.popular")}
        </span>
      )}
      <h3 className="text-lg font-semibold text-gray-900">{t(plan.title)}</h3>
      <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900">{getPrice()}</p>
      <p className="mt-1 text-sm text-gray-500">
        {billingCycle === "yearly" ? t("subscriptionpackages.perYear") 
          : billingCycle === "tokens" ? t("subscriptionpackages.oneTime") 
          : t("subscriptionpackages.perMonth")}
      </p>
      
      <ul className="mt-8 space-y-4">
        {plan.coins ? (
          <li className="flex items-center gap-3">
            <Check className="h-5 w-5 text-green-500" />
            <span className="text-sm text-gray-700">
              {plan.coins.toLocaleString()} {t('subscriptionpackages.coins')}
            </span>
          </li>
        ) : (
          plan.features.map((feature) => (
            <li key={feature} className="flex items-center gap-3">
              <Check className="h-5 w-5 text-green-500" />
              <span className="text-sm text-gray-700">{t(feature)}</span>
            </li>
          ))
        )}
      </ul>
      
      <button
        onClick={() => onSelect(plan)}
        disabled={isCurrentPackage}
        className={`mt-8 w-full rounded-xl bg-gradient-to-r py-3 text-sm font-semibold 
           transition-all hover:opacity-90 ${plan.gradient} 
          ${isCurrentPackage ? "opacity-50 cursor-not-allowed" : ""}`}
      >
        {t("subscriptionpackages.choose")}
      </button>
    </div>
  );
};

const SubscriptionPackages = () => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [currency, setCurrency] = useState("TRY");
  console.log(user)
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabs = queryParams.get('tab');
    if (tabs === 'token') {
      
      setBillingCycle('tokens');
    } 
  }, [location.search]);
  const handlePayment = (plan) => {
    const price = billingCycle === "monthly" 
      ? plan.price.monthly[currency]
      : billingCycle === "yearly" 
        ? plan.price.discounted[currency]
        : plan.price[currency];

    navigate("/payments/payment", {
      state: {
        selectedPackage: { ...plan, price, currency },
        paymentPeriod: billingCycle,
        currency,
      },
    });
  };

  return (
    <div className="min-h-screen">
      <div className="mx-auto max-w-7xl px-4 py-4">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            {t("subscriptionpackages.plansAndPricing")}
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            {t("subscriptionpackages.plansAndPricingSubtitle")}
          </p>
        </div>

        <BillingToggle billingCycle={billingCycle} setBillingCycle={setBillingCycle} />

        <div className="mt-12 grid gap-8 lg:grid-cols-4">
          {(billingCycle === "tokens" ? coinPackagesData : plansData).map((plan) => (
            <PricingCard
              key={plan.id}
              plan={plan}
              billingCycle={billingCycle}
              currency={currency}
              onSelect={handlePayment}
              userPackage={user?.permissions?.packageId}
            />
          ))}
        </div>

        <div className="mt-12 text-center text-sm text-gray-500">
          <p>{t("subscriptionpackages.note1")}</p>
          <p>{t("subscriptionpackages.note2")}</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPackages;