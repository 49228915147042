import { createContext, useContext, useState, useCallback } from 'react';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

// eslint-disable-next-line react/prop-types
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((notification) => {
    setNotifications((currentNotifications) => [...currentNotifications, notification]);
    // Optionally, remove the notification after a delay
    setTimeout(() => {
      setNotifications((currentNotifications) => currentNotifications.slice(1));
    }, 6000); // Adjust time as needed
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((currentNotifications) => currentNotifications.filter((n) => n.id !== id));
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
