import { useRef, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/chatLoading.json';
import { ScrollArea, Text, Avatar, Box, Tooltip, Grid, Button, Stack } from '@mantine/core';
import { faCopy, faDownload, faChevronDown, faChevronRight  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNotifications } from '../../context/NotificationContext';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';
import 'katex/dist/katex.min.css';
import { 
  BookText, // For Language
  PenLine, // For Essay
  Calculator, // For Math
  BookOpen,
  Biohazard
} from 'lucide-react'; // Using Lucide icons for consistency
import ReactKatex from 'react-katex';
import { motion } from "framer-motion";
import { fixFileName } from '../../utils/fileUtils';

const shortenFileName = (fileName) => {
  try {
    // Use the imported fixFileName function to decode the filename
    const decodedName = fixFileName(fileName);
    
    // Extract short name for display
    const fileExtension = decodedName.split('.').pop();
    const baseName = decodedName.substring(0, decodedName.lastIndexOf('.'));
    const shortName =
      baseName.length > 10 ? `${baseName.slice(0, 10)}...` : baseName;
    return `${shortName}.${fileExtension}`;
  } catch (error) {
    console.error('Error shortening file name:', fileName);
    return fileName; // Fallback to original name
  }
};

const CodeBlock = ({ language, value, inline, ...props }) => {
  if (inline) {
    return (
      <code className="inline-code">
        {value}
      </code>
    );
  }

  const lines = value.split('\n');
  const languageClass = language ? `language-${language}` : '';

  return (
    <div className="code-block-wrapper">
      {language && (
        <div className="code-language-indicator">
          {language}
        </div>
      )}
      <div className="code-block-content">
        <div className="line-numbers">
          {lines.map((_, i) => (
            <span key={i} className="line-number">{i + 1}</span>
          ))}
        </div>
        <pre className={`${languageClass} code-block`}>
          <code className={languageClass}>
            {value}
          </code>
        </pre>
      </div>
    </div>
  );
};

// Update the SafeKatex component with better error handling
const SafeKatex = ({ children, display = false }) => {
  try {
    return (
      <ReactKatex
        throwOnError={false}
        display={display}
        settings={{
          strict: false,
          trust: true,
          macros: {
            "\\f": "f",
            "\\dx": "\\,dx",
            "\\implies": "\\quad\\Rightarrow\\quad",
            "\\quad": "\\;\\;",
            "\\Adim": "\\text{Adım}"
          },
          minRuleThickness: 0.04,
          maxSize: 20,
          maxExpand: 1000,
          strict: false
        }}
      >
        {children}
      </ReactKatex>
    );
  } catch (error) {
    console.error('KaTeX rendering error:', error);
    return <code>{children}</code>;
  }
};

const MarkdownComponents = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : '';
    

    if (language === 'math') {
      return (
        <div className="math-block">
          <ReactKatex>{children}</ReactKatex>
        </div>
      );
    }
    
    return (
      <CodeBlock
        language={language}
        value={children}
        inline={inline}
        {...props}
      />
    );
  },
  p: (props) => <p>{props.children}</p>,
  h1: ({ children }) => (
    <h1 style={{ 
      margin: '1em 0 0.5em',
      fontSize: '1.5em',
      color: '#2c3e50'
    }}>
      {children}
    </h1>
  ),
  h2: ({ children }) => <h2 style={{ margin: '0.8em 0 0.4em' }}>{children}</h2>,
  li: ({ children }) => (
    <li style={{
      marginBottom: '0.5em',
      lineHeight: '1.6',
      listStyleType: 'none',
      position: 'relative',
      paddingLeft: '2em'
    }}>
      {children}
    </li>
  ),
  h3: ({ children }) => (
    <h3 style={{
      fontSize: '1.2em',
      color: '#2c3e50',
      marginTop: '1.5rem',
      marginBottom: '0.75rem',
      fontWeight: 600
    }}>
      {children}
    </h3>
  )
};

function Chat({ form, messages, loading }) {
  console.log(messages, 'messages')
  const { t } = useTranslation();
  const messagesEndRef = useRef(null);
  const chatRef = useRef(null);
  const { addNotification } = useNotifications();
  const [showFiles, setShowFiles] = useState(false);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    Prism.highlightAll();
  }, [messages]);

  const isImageUrl = (url) => {
    if (!url) return false;
    
    try {
      if (url.startsWith('blob:')) return true;
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname.toLowerCase();
      const imageExtensions = /\.(jpeg|jpg|gif|png|webp|avif|tiff|bmp)$/;
      return imageExtensions.test(pathname);
    } catch (error) {
      const lowercaseUrl = url.toLowerCase();
      return (
        lowercaseUrl.includes('.jpeg') ||
        lowercaseUrl.includes('.jpg') ||
        lowercaseUrl.includes('.gif') ||
        lowercaseUrl.includes('.png') ||
        lowercaseUrl.includes('.webp') ||
        lowercaseUrl.includes('.avif') ||
        lowercaseUrl.includes('.tiff') ||
        lowercaseUrl.includes('.bmp') ||
        url.startsWith('blob:')
      );
    }
  };

  const processMessageContent = (text) => {
    if (!text) return { mainContent: '', footnotes: '' };
    const parts = text.split(/(?={{\s*\[\d+\])/);
    const mainContent = parts[0];
    const footnotesMatch = text.match(/{{[^}]+}}/g);
    const footnotes = footnotesMatch ? footnotesMatch.join('\n') : '';
    return { mainContent, footnotes };
  };

  const processAnnotatedText = (text) => {
    const footnoteRegex = /\{\{\s*\[(\d+)\]:\s*(.*?)\s*\}\}/g;
    let match;
    const elements = [];
    let lastIndex = 0;

    while ((match = footnoteRegex.exec(text)) !== null) {
      if (lastIndex < match.index) {
        elements.push(
          <ReactMarkdown 
            key={`text-${elements.length}`} 
            remarkPlugins={[remarkGfm, remarkMath]} 
            rehypePlugins={[rehypeKatex]}
            components={MarkdownComponents}
          >
            {text.substring(lastIndex, match.index)}
          </ReactMarkdown>
        );
      }

      elements.push(
        <Tooltip 
          key={elements.length} 
          label={match[2]} 
          inline
        >
          <span style={{ 
            textDecoration: 'underline', 
            cursor: 'pointer', 
            fontWeight: 700, 
            color: '#d4d4d4' 
          }}>
            [{match[1]}]
          </span>
        </Tooltip>
      );

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      elements.push(
        <ReactMarkdown 
          key={`text-end`} 
          remarkPlugins={[remarkGfm, remarkMath]} 
          rehypePlugins={[rehypeKatex]}
          components={MarkdownComponents}
        >
          {text.substring(lastIndex)}
        </ReactMarkdown>
      );
    }

    return elements;
  };

  const handleImageDownload = async (imageUrl) => {
    try {
      const proxyUrl = `/results/download?url=${encodeURIComponent(imageUrl)}`;
      const response = await fetch(proxyUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileName = imageUrl.split('/').pop().split('?')[0];
      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
  
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: t('chat.downloadSuccess') || 'Image download started',
      });
    } catch (error) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('chat.downloadError') || 'Image download failed',
      });
      console.error('Error downloading image:', error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        addNotification({
          id: new Date().getTime(),
          type: 'success',
          message: t('chat.copySuccess'),
        });
      })
      .catch((err) => {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('chat.copyError'),
        });
        console.error('Could not copy text: ', err);
      });
  };


  const CustomMarkdownComponents = {
    ...MarkdownComponents,
    a: ({ node, children, href, ...props }) => {
      // For file messages, create a direct link
      const messageWithFile = messages.find(msg => 
        msg.type === 'file' && msg.text && msg.url && (
          msg.text.includes(href) || 
          msg.text.includes(String(children))
        )
      );
      
      if (messageWithFile) {
        return (
          <a
            href={messageWithFile.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#228be6',
              textDecoration: 'none',
              cursor: 'pointer',
              fontWeight: 500
            }}
            {...props}
          >
            {children}
          </a>
        );
      }
      
      // Return regular link for non-file URLs
      return (
        <a 
          href={href || "#"} 
          style={{
            color: '#228be6',
            textDecoration: 'none',
            cursor: href ? 'pointer' : 'default'
          }}
          {...props}
        >
          {children}
        </a>
      );
    }
  };
  const isMobile = useMediaQuery('(max-width: 768px)');
  const EmptyState = () => {
    const handleTemplateClick = (type) => {
      form.setFieldValue('question', t(`chat.templates.${type}`));
    };

    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-200px)]">
        <div className="flex flex-col items-center justify-center w-full max-w-2xl mx-auto px-4">
          <h2 className="text-2xl font-semibold text-gray-800 mb-8">
            {t('chat.howCanIHelpYou')}
          </h2>
          
          <div className="grid grid-cols-2 gap-4 w-full max-w-lg">
            {/* Language Button */}
            <button 
              onClick={() => handleTemplateClick('language')}
              className="group p-6 rounded-lg   border border-purple-200 hover:border-blue-500 transition-all duration-200 flex flex-col items-center justify-center gap-3"
            >
              <div className="p-3 rounded-lg group-hover:bg-blue-50 transition-colors duration-200">
                <BookText className="w-6 h-6 text-purple-900 group-hover:text-blue-500 transition-colors duration-200" />
              </div>
              <span className="font-medium text-purple-900 group-hover:text-blue-500 transition-colors duration-200">
                {t('chat.language')}
              </span>
            </button>
  
            {/* Essay Button */}
            <button 
              onClick={() => handleTemplateClick('essay')}
              className="group p-6 rounded-lg  border border-purple-200 hover:border-pink-500 transition-all duration-200 flex flex-col items-center justify-center gap-3"
            >
              <div className="p-3 rounded-lg group-hover:bg-pink-50 transition-colors duration-200">
                <Biohazard className="w-6 h-6 text-purple-900 group-hover:text-pink-500 transition-colors duration-200" />
              </div>
              <span className="font-medium text-purple-900 group-hover:text-pink-500 transition-colors duration-200">
                {t('chat.essay')}
              </span>
            </button>
  
            {/* Math Button */}
            <button 
              onClick={() => handleTemplateClick('math')}
              className="group p-6 rounded-lg  border border-purple-200 hover:border-violet-500 transition-all duration-200 flex flex-col items-center justify-center gap-3"
            >
              <div className="p-3 rounded-lg group-hover:bg-violet-50 transition-colors duration-200">
                <Calculator className="w-6 h-6 text-purple-900 group-hover:text-violet-500 transition-colors duration-200" />
              </div>
              <span className="font-medium text-purple-900 group-hover:text-violet-500 transition-colors duration-200">
                {t('chat.math')}
              </span>
            </button>
  
            {/* History Button */}
            <button 
              onClick={() => handleTemplateClick('history')}
              className="group p-6 rounded-lg border border-purple-200 hover:border-emerald-500 transition-all duration-200 flex flex-col items-center justify-center gap-3"
            >
              <div className="p-3 rounded-lg group-hover:bg-emerald-50 transition-colors duration-200">
                <BookOpen className="w-6 h-6 text-purple-900 group-hover:text-emerald-500 transition-colors duration-200" />
              </div>
              <span className="font-medium text-purple-900 group-hover:text-emerald-500 transition-colors duration-200">
                {t('chat.history')}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Add styles for math content


  const getImageUrl = (message) => {
    // If message has s3URL with urls, return the large url
    if (message.files?.[0]?.s3URL?.urls?.large) {
      return message.files[0].s3URL.urls.large;
    }
    // Fallback to other possible image sources
    return message.localImageUrl || message.url;
  };

  return (
    <ScrollArea style={{ overflowX: 'hidden' }} className="scrollArea chat" ref={chatRef}>
      {messages.length === 0 && !loading ? (
        <EmptyState />
      ) : (
        <Box className="chat__question">
          {messages.map((message, index) => {
            // Get image URL if message is type 'img' or has files with s3URL
            const imageUrl = message.questionType === 'img' ? getImageUrl(message) : null;
            const textWithoutUrl = message.text;
            const { mainContent, footnotes } = processMessageContent(textWithoutUrl);
            
            return (
              <div key={index} style={{ 
                display: 'flex', 
                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                marginBottom: '1rem'
              }}>
                {message.sender !== 'user' && !isMobile && (
                  <Avatar 
                    src="https://static.easyegitim.com/mascot.webp" 
                    style={{ 
                      alignSelf: 'flex-start', 
                      marginRight: '.75rem', 
                      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px' 
                    }} 
                  />
                )}
                <div className={`chat__message ${message.sender === 'user' ? 'chat__message--user' : ''}`}>
                  {imageUrl && (
                    <div className="chat-image-container" style={{
                      maxWidth: '300px',
                      margin: '0 auto 12px auto'
                    }}>
                      <img
                        src={imageUrl}
                        alt={t('chat.imageAlt')}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
                        onClick={() => window.open(imageUrl, '_blank')}
                      />
                      <FontAwesomeIcon
                        icon={faDownload}
                        onClick={() => handleImageDownload(imageUrl)}
                        style={{
                          position: 'absolute',
                          bottom: '8px',
                          right: '8px',
                          background: 'rgba(0,0,0,0.6)',
                          color: 'white',
                          padding: '8px',
                          borderRadius: '50%',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  )}
                  <div className="chat__message-content">
                    <ReactMarkdown 
                      remarkPlugins={[remarkGfm, remarkMath]} 
                      rehypePlugins={[rehypeKatex]}
                      components={CustomMarkdownComponents}
                    >
                      {mainContent}
                    </ReactMarkdown>
                    {footnotes && (
                      <div style={{ marginTop: '1rem', fontSize: '0.9em', color: '#858585' }}>
                        {processAnnotatedText(footnotes)}
                      </div>
                    )}
                    {message.sender !== 'user' && isMobile && (
                      <FontAwesomeIcon 
                        icon={faCopy} 
                        style={{cursor: 'pointer'}} 
                        className="chat__question--copy" 
                        onClick={() => copyToClipboard(textWithoutUrl)}
                      />
                    )}
                  </div>
               
{message.questionType === 'file' && message.files && message.files.length > 0 && (
  <>
    {isMobile ? (
      // Mobile view - Collapsible section
      <Box mt={8}>
        <Text 
          size="sm" 
          fw={700}
          onClick={() => setShowFiles((prev) => !prev)}
          style={{ 
            cursor: 'pointer',
            color: '#011627',
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}
        >
          <FontAwesomeIcon 
            icon={showFiles ? faChevronDown : faChevronRight} 
            size="xs"
          />
          {t('showAttachedFiles')} ({message.files.length})
        </Text>
        
        {showFiles && (
          <Box 
            mt={4} 
            pl={12} 
            style={{ 
              borderLeft: '2px solid #eee'
            }}
          >
            {message.files.map((file) => (
              <Text 
                key={file.name} 
                size="xs" 
                color="dimmed" 
                mb={4}
              >
                {shortenFileName(file.name)}
              </Text>
            ))}
          </Box>
        )}
      </Box>
    ) : (
      // Desktop view - Hover tooltip
      <Text size="sm" fw={700}>
        <Tooltip
  label={
    <div style={{ textAlign: 'left' }}>
      {message.files.map((file, index) => (
        <div key={file.name} style={{ padding: '2px 0' }}>
          [{index}] {shortenFileName(file.name)}
        </div>
      ))}
    </div>
  }
  position="bottom"
  multiline
  width={300}
  withArrow
  styles={{
    tooltip: {
      backgroundColor: '#011627',
      color: '#fff',
    },
    arrow: {
      backgroundColor: '#011627'
    }
  }}
>
  <span style={{ 
    color: '#011627', 
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'color 0.2s ease',
  }}>
    [{t('showAttachedFiles')}]
  </span>
</Tooltip>
      </Text>
    )}
  </>
)}
                </div>
                {message.sender === 'user' && !isMobile && (
                  <Avatar
                    src="https://static.easyegitim.com/easy_user_avatar.png"
                    style={{ 
                      alignSelf: 'flex-start', 
                      marginLeft: '.75rem', 
                      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px' 
                    }}
                  />
                )}
              </div>
            );
          })}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
              <Avatar 
                src="https://static.easyegitim.com/mascot.webp" 
                style={{ 
                  alignSelf: 'flex-start', 
                  marginRight: '.75rem', 
                  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px' 
                }} 
              />
              <div className="chat__message">
                <Lottie 
                  animationData={loadingAnimation} 
                  loop 
                  style={{ width: 50, height: 50 }} 
                />
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </Box>
      )}
    </ScrollArea>
  );
}

export default Chat;