import { useState, useEffect } from 'react';
import { Button, Modal, Text, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../context/NotificationContext';
import OtpInput from 'react-otp-input';
import { useVerifyOTPMutation, useSendOTPMutation } from '../../redux/api/authApiSlice';
import DOMPurify from "dompurify";
import { translateError } from '../../utils/translateError';

const OTPVerification = ({ userId, otpToken, onSuccess, opened, onClose }) => {
  const { t } = useTranslation();
  const [otpCode, setOtpCode] = useState('');
  const [timer, setTimer] = useState(120);
  const [isError, setIsError] = useState(false);
  const { addNotification } = useNotifications();
  const [sendOTP, { isLoading: isSendingOTP }] = useSendOTPMutation();
  const [verifyOTP, { isLoading: isVerifyingOTP }] = useVerifyOTPMutation();

  useEffect(() => {
    console.log('OTPVerification component mounted');
    return () => {
      console.log('OTPVerification component unmounting');
    };
  }, []);

  useEffect(() => {
    if (otpCode.length === 6) {
      console.log('OTP code complete, submitting...');
      handleSubmit();
    }
  }, [otpCode]);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleSubmit = async () => {
    if (isVerifyingOTP) return;
    try {
      const sanitizedOtpCode = DOMPurify.sanitize(otpCode);
      console.log(otpToken, "otpToken")
      await verifyOTP({ userId, code: sanitizedOtpCode, otpToken }).unwrap();
      console.log("OTP verified successfully");
      addNotification({
        id: Date.now(),
        message: t('otpVerification.successMessage'),
        type: 'success',
      });
      setIsError(false);
      onSuccess();
      onClose();
    } catch (error) {
      addNotification({
        id: Date.now(),
        message: translateError(error, t),
        type: 'error'
      });
      setIsError(true);
      setTimeout(() => setIsError(false), 2000);
    }
  };

  const handleResend = async () => {
    setTimer(120);
    try {
      await sendOTP({ userId }).unwrap();
      addNotification({ 
        id: Date.now(), 
        message: t('otpVerification.resendSuccessMessage'), 
        type: 'success' 
      });
    } catch (error) {
      addNotification({ 
        id: Date.now(), 
        message: translateError(error, t), 
        type: 'error' 
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        console.log('OTP Modal closing');
        onClose();
      }}
      title={t('otpVerification.title')}
      closeOnClickOutside={false}
      centered
    >
      <Text size="sm" align="center" mb="md">
        {t('otpVerification.instructions')}
      </Text>
      <form onSubmit={(e) => e.preventDefault()}>
        <Group justify="center" mb="md">
          <OtpInput
            value={otpCode}
            onChange={(value) => {
              console.log('OTP input changed:', value);
              setOtpCode(value);
            }}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
            hasErrored={isError}
            inputStyle={{
              width: '3rem',
              height: '3rem',
              margin: '0 0.25rem',
              fontSize: '1rem',
              borderRadius: 8,
              border: `1px solid ${isError ? 'red' : 'rgba(0,0,0,0.3)'}`,
              animation: isError ? 'shake 0.5s ease-in-out' : 'none',
            }}
          />
        </Group>
        <Group mt="md" justify="flex-end">
          {timer > 0 ? (
            <Text color="dimmed" size="sm">
              {t('otpVerification.resendTimer', { timer })}
            </Text>
          ) : (
            <Button variant="subtle" onClick={handleResend} disabled={isSendingOTP}>
              {isSendingOTP ? t('otpVerification.sending') : t('otpVerification.resendButton')}
            </Button>
          )}
        </Group>
      </form>
    </Modal>
  );
};

export default OTPVerification;
