import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Text, Anchor } from '@mantine/core';
import { Input, PasswordInput } from '../../components/common/Input';
import { useForm } from '@mantine/form';
import OTPVerification from '../../components/common/OTPVerification';
import { sanitizeInput } from '../../utils/sanitizeInput';
import { useNavigate, Link } from "react-router-dom";
import { useNotifications } from '../../context/NotificationContext';
import { useLoginUserMutation, useSendOTPMutation } from '../../redux/api/authApiSlice';
import { translateError } from '../../utils/translateError';
import { shouldShowAppleSignIn } from '../../utils/platformDetect';


const Login = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [userIdForOtp, setUserIdForOtp] = useState('');
  const [loginUser] = useLoginUserMutation();
  const [sendOTP] = useSendOTPMutation();
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState(null);
  const [otpToken, setOtpToken] = useState('');
  console.log(otpToken, "otpTokenState")
  // Constants for rate limiting
  const MAX_LOGIN_ATTEMPTS = 5;
  const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
  const MIN_ATTEMPT_INTERVAL = 1000; // Minimum 1 second between attempts
  const [isAppleSupported, setIsAppleSupported] = useState(false);

  // Check if the device is iOS or Mac
  useEffect(() => {
    setIsAppleSupported(shouldShowAppleSignIn());
  }, []);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => {
        if (!value) return t('login.errors.required');
        if (!/^\S+@\S+\.\S+$/.test(value)) return t('login.errors.invalidEmail');
        // Check for common spam patterns
        if (value.includes('script') || value.includes('SELECT') || value.includes('DROP')) {
          return t('login.errors.invalidCharacters');
        }
        // Prevent extremely long emails
        if (value.length > 100) return t('login.errors.emailTooLong');
        return null;
      },
      password: (value) => {
        if (!value) return t('login.errors.required');
        if (value.length < 6) return t('login.errors.shortPassword');
        if (value.length > 100) return t('login.errors.passwordTooLong');
        // Check for common injection patterns
        if (value.includes('<') || value.includes('>') || value.includes('script')) {
          return t('login.errors.invalidCharacters');
        }
        return null;
      },
    },
  });

  const checkRateLimit = () => {
    const now = Date.now();

    // Check if user is in lockout period
    if (loginAttempts >= MAX_LOGIN_ATTEMPTS) {
      const timeSinceFirstAttempt = now - lastAttemptTime;
      if (timeSinceFirstAttempt < LOCKOUT_DURATION) {
        const remainingTime = Math.ceil((LOCKOUT_DURATION - timeSinceFirstAttempt) / 60000);
        throw new Error(t('login.tooManyAttempts', { minutes: remainingTime }));
      } else {
        // Reset attempts after lockout period
        setLoginAttempts(0);
      }
    }

    // Prevent rapid-fire attempts
    if (lastAttemptTime && (now - lastAttemptTime) < MIN_ATTEMPT_INTERVAL) {
      throw new Error(t('login.tooFast'));
    }
  };

  const handleLogin = async (values) => {
    try {
      checkRateLimit();
      setLoginAttempts(prev => prev + 1);
      setLastAttemptTime(Date.now());

      const sanitizedValues = sanitizeInput(values);
      
      if (sanitizedValues.email !== values.email || sanitizedValues.password !== values.password) {
        throw new Error(t('login.invalidInput'));
      }

      const response = await loginUser(sanitizedValues).unwrap();
      
      if (!response.user.isVerified) {
        try {
          setShowOtpModal(true);
  
          setUserIdForOtp(response.user._id);
          setOtpToken(response.otpToken);
          console.log(response.otpToken, "response.otpToken")
          console.log({userId: response.user._id, otpToken: response.otpToken }, "otpTokenObject")
          await sendOTP({ userId: response.user._id, otpToken: response.otpToken }).unwrap();
        } catch (otpError) {
          addNotification({
            id: Date.now(),
            message: translateError(otpError, t),
            type: 'error'
          });
          return;
        }
        addNotification({
          id: Date.now(),
          message: t('login.verificationRequired'),
          type: 'warning'
        });
      } else {
        setLoginAttempts(0);
        if (values.remember) {
          localStorage.setItem('remember', 'true');
        } else {
          localStorage.removeItem('remember');
        }
        navigate('/tools/virtualTeacher');
      }
    } catch (error) {
      console.error('Login failed:', error);
      addNotification({
        id: Date.now(),
        message: translateError(error, t),
        type: 'error'
      });

      // Handle specific field errors based on error codes
      if (error?.data?.code === 'INVALID_CREDENTIALS') {
        form.setFieldError('email', t('errors.INVALID_CREDENTIALS'));
        form.setFieldError('password', t('errors.INVALID_CREDENTIALS'));
      } else if (error?.data?.code === 'ACCOUNT_BLOCKED') {
        form.setFieldError('email', t('errors.ACCOUNT_BLOCKED', {
          time: error.data.blockExpiresAt,
          reason: error.data.blockReason
        }));
      }
    }
  };
  
  const handleGoogleLogin = () => {
    window.location.href = `https://easyegitim.com/app/v1/auth/google`;
  };

  const handleAppleLogin = () => {
    window.location.href = `https://easyegitim.com/app/v1/auth/apple`;
  };

  return (
    <div className="w-full space-y-6">
      {/* Logo/Brand Section */}
      <div className="text-center space-y-3 mb-8">
        <Title order={2} className="text-2xl font-bold">
          {t('login.signIn')}
        </Title>
        <Text className="text-gray-500">
          {t('login.welcomeBack')}
        </Text>
      </div>

      {/* Social Login */}
      <button
        onClick={handleGoogleLogin}
        className="w-full flex items-center justify-center gap-2 px-6 py-3 border border-gray-300 rounded-xl hover:bg-gray-50 transition-all"
      >
        <img
          src="https://static.easyegitim.com/g-logo.png"
          alt="Google Logo"
          className="w-5 h-5"
        />
        {t('login.signInWithGoogle')}
      </button>

      {/* Apple Login Button - Only shown for iOS/Mac users */}
      {isAppleSupported && (
        <button
          onClick={handleAppleLogin}
          className="w-full flex items-center justify-center gap-2 px-6 py-3 border border-gray-300 rounded-xl hover:bg-gray-50 transition-all"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5645 12.4595C17.5488 10.1304 19.3822 8.89189 19.4761 8.82609C18.3058 7.06899 16.4739 6.80634 15.825 6.78783C14.2168 6.6276 12.663 7.72464 11.8469 7.72464C11.0139 7.72464 9.74349 6.80634 8.39349 6.83255C6.64799 6.85876 5.01978 7.91304 4.1347 9.52174C2.31628 12.7944 3.67849 17.6609 5.41609 20.3768C6.28896 21.7043 7.30599 23.1913 8.65599 23.1399C9.9799 23.0822 10.4513 22.2406 12.033 22.2406C13.601 22.2406 14.0407 23.1399 15.4313 23.1072C16.8693 23.0822 17.7475 21.7623 18.5888 20.4232C19.5907 18.8913 20.0007 17.3855 20.0164 17.2841C19.9854 17.2725 17.5848 16.2937 17.5645 12.4595Z" fill="black"/>
            <path d="M15.0087 4.58782C15.7304 3.67722 16.2125 2.42028 16.0745 1.14502C15.0291 1.1897 13.7432 1.88741 12.9885 2.77029C12.3204 3.5435 11.7285 4.85288 11.8838 6.07107C13.0572 6.16521 14.253 5.48424 15.0087 4.58782Z" fill="black"/>
          </svg>
          {t('login.signInWithApple', 'Sign in with Apple')}
        </button>
      )}

      {/* Divider */}
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-200"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">
            {t('login.orContinueWith')}
          </span>
        </div>
      </div>

      {/* Login Form */}
      <form onSubmit={form.onSubmit(handleLogin)} className="space-y-5">
        <Input
          label={t('login.email')}
          placeholder="mail@gmail.com"
          error={form.errors.email}
          {...form.getInputProps('email')}
        />

        <PasswordInput
          label={t('login.password')}
          placeholder="••••••••"
          error={form.errors.password}
          {...form.getInputProps('password')}
        />

        <div className="flex items-center justify-between">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-4 h-4 rounded border-gray-300 text-black focus:ring-black"
              {...form.getInputProps('remember', { type: 'checkbox' })}
            />
            <span className="text-sm text-gray-600">{t('login.rememberMe')}</span>
          </label>
          <Anchor 
            component={Link} 
            to="/auth/forgot-password"
            className="text-sm font-medium text-blue-600 hover:text-blue-500"
          >
            {t('login.forgotPassword')}
          </Anchor>
        </div>

        <button
          type="submit"
          className="w-full py-3 px-4 bg-[#40286c] text-white rounded-xl font-medium hover:bg-[#332056] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c]"
        >
          {t('login.signInButton')}
        </button>
      </form>

      {/* Sign Up Link */}
      <Text align="center" size="sm">
        {t('login.noAccount')}{' '}
        <Anchor 
          component={Link} 
          to="/auth/register"
          className="font-medium text-blue-600 hover:text-blue-500"
        >
          {t('login.signUp')}
        </Anchor>
      </Text>

      {/* OTP Modal */}
      {showOtpModal && (
        <OTPVerification
          userId={userIdForOtp}
          otpToken={otpToken}
          onSuccess={() => {
            setShowOtpModal(false);
            navigate('/tools/virtualTeacher');
          }}
          opened={showOtpModal}
          onClose={() => setShowOtpModal(false)}
        />
      )}
    </div>
  );
};

export default Login;