import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import SkeletonLayout from '../components/common/SkeletonLoadingLayout';

const ProtectedRoute = () => {
  const { isAuthenticated, isAuthenticating } = useAuth();
  const location = useLocation();

  if (isAuthenticating) {
    return <SkeletonLayout />;
  }

  if (!isAuthenticated && !isAuthenticating) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
