import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import PageTransition from '../components/common/PageTransition';
import ToolsLayout from '../components/layout/ToolsLayout';
import Summarizer from '../pages/tools/Summarizer';
import Paraphraser from '../pages/tools/Paraphraser';
import QuestionAnswer from '../pages/tools/QuestionAnswer';
import Forger from '../pages/tools/ArticleForger';
import Humanizer from '../pages/tools/Humanizer';
import VirtualTeacher from '../pages/tools/VirtualTeacher';

export const ToolsRoutes = () => {
  const location = useLocation();

  return (
/*     <AnimatePresence mode="wait"> */
      <Routes location={location} key={location.pathname}>
        <Route path="/*" element={<ToolsLayout />}>
          <Route 
            path="summarizer" 
            element={
              <PageTransition>
                <Summarizer />
              </PageTransition>
            } 
          />
          <Route 
            path="paraphraser" 
            element={
              <PageTransition>
                <Paraphraser />
              </PageTransition>
            } 
          />
          <Route 
            path="questionAnswer" 
            element={
              <PageTransition>
                <QuestionAnswer />
              </PageTransition>
            } 
          />
          <Route 
            path="articleForger" 
            element={
              <PageTransition>
                <Forger />
              </PageTransition>
            } 
          />
          <Route 
            path="virtualTeacher" 
            element={
              <PageTransition>
                <VirtualTeacher />
              </PageTransition>
            } 
          />
          <Route 
            path="humanizer" 
            element={
              <PageTransition>
                <Humanizer />
              </PageTransition>
            } 
          />
        </Route>
      </Routes>
  /*   </AnimatePresence> */
  );
};