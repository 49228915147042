import React, { useState, useEffect } from 'react';
import { Container, Title, Center, Loader, Alert, ScrollArea } from '@mantine/core';
import { useFetchTransactionHistoryQuery } from '../../redux/api/transactionApiSlice';
import { useTranslation } from 'react-i18next';
import TransactionList from "../../components/settings/TransactionList";

const Transactions = () => {
  const { t } = useTranslation();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const { data, isLoading, isError } = useFetchTransactionHistoryQuery({ skip, limit });
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(true); // Flag to stop fetching when all data is loaded

  useEffect(() => {
    if (data?.transactions) {
      setTransactions((prev) => [...prev, ...data.transactions]);
      if (transactions.length + data.transactions.length >= data.totalCount) {
        setHasMore(false); // Stop fetching when all data is loaded
      }
    }
  }, [data]);

  const loadMoreTransactions = () => {
    if (!isLoading && hasMore) {
      setSkip((prevSkip) => prevSkip + limit);
    }
  };

  return (
    <Container size="m" mt={20}>
      <Center style={{ marginBottom: '20px' }}>
        <Title order={2}>{t('transactions.title')}</Title>
      </Center>
      {isLoading && transactions.length === 0 ? (
        <Loader />
      ) : isError ? (
        <Alert title={t('transactions.errorTitle')} color="red">
          {t('transactions.errorMessage')}
        </Alert>
      ) : (
        <TransactionList
        usageHistory={transactions}
        onLoadMore={loadMoreTransactions}
        isLoading={isLoading}
        totalCount={data?.totalCount || 0} // Pass totalCount to TransactionList
      />
      
      )}
    </Container>
  );
};


export default Transactions;
