import { Outlet } from 'react-router-dom';
import { 
  AppShell, 
  Burger, 
  Group, 
  Modal, 
  Text, 
  Center, 
  Button 
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../context/NotificationContext';
import CustomNotification from '../common/Notification';
import PromotionalBanner from '../common/PromotionalBanner';
import { PencilLine } from 'lucide-react';
import { useState, useEffect } from 'react';
import ContactUsModal from '../common/ContactUsModal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Sidebar from '../common/Sidebar';
import { useDispatch } from 'react-redux';
import { clearSelectedHistoryId } from '../../redux/slices/toolHistoriesSlice';
import WhatsAppButton from '../common/WhatsAppButton';

const NotificationsContainer = ({ notifications }) => {
  if (!notifications?.length) return null;

  return (
    <div className="fixed bottom-4 left-1/2 -translate-x-1/2 z-50 flex flex-col items-center gap-2">
      <TransitionGroup className="flex flex-col gap-2">
        {notifications.map((notification) => (
          <CSSTransition
            key={notification.id}
            timeout={300}
            classNames="notification"
          >
            <div>
              <CustomNotification
                message={notification.message}
                type={notification.type}
              />
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

const BaseLayout = ({ children, historyContent }) => {
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const [showPromoBanner, setShowPromoBanner] = useState(true);
  const { t } = useTranslation();
  const { notifications } = useNotifications();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened] = useDisclosure(true);
  const [asideMobileOpened] = useDisclosure();
  const [asideDesktopOpened, { toggle: asideToggleDesktop }] = useDisclosure(true);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isToolsRoute = location.pathname.startsWith('/tools');
  const dispatch = useDispatch();
  const [showMobilePopup, setShowMobilePopup] = useState(false);

  useEffect(() => {
    const displayPopup = () => {
      const displayCount = Number(localStorage.getItem('popupDisplayCount') || 0);
      if (displayCount < 2 && isMobile) {
        setShowMobilePopup(true);
        localStorage.setItem('popupDisplayCount', String(displayCount + 1));
      }
    };

    displayPopup();
  }, [isMobile]);

  const handleCloseMobilePopup = () => {
    setShowMobilePopup(false);
  };

  const handleClosePromoBanner = () => {
    setShowPromoBanner(false);
  };

  const handleToggleMobile = () => {
    toggleMobile();
  };

  return (
    <>
      {isMobile && (
        <PromotionalBanner 
          isEnabled={showPromoBanner} 
          onClose={handleClosePromoBanner} 
        />
      )}
      
      {isMobile && (mobileOpened || asideMobileOpened) && (
        <div 
          className="dimmed-background" 
          onClick={handleToggleMobile}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100
          }}
        />
      )}

      <AppShell
        header={{ height: isMobile ? 38 : 0 }}
        navbar={{
          width: 250,
          breakpoint: 'md',
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
          style: { zIndex: 200 }
        }}
        aside={isToolsRoute && !isMobile ? {
          width: 250,
          breakpoint: 'md',
          collapsed: { desktop: !asideDesktopOpened, mobile: !asideMobileOpened }
        } : undefined}
        padding="xs"
      >
        <AppShell.Header style={{ border: 'unset', background: 'unset', zIndex: 96 }}>
          {isMobile ? (
            <div className="mobileheader" style={{ zIndex: 999, margin: ".5rem .25rem" }}>
              <Group h="100%" justify="space-between">
                <Burger 
                  opened={mobileOpened} 
                  onClick={handleToggleMobile} 
                  hiddenFrom="sm" 
                  lineSize={2} 
                  size="sm" 
                  color="#F3EFF5"
                />
                {isToolsRoute ? <PencilLine size={24} color="#ffffff" onClick={() => 
                  {
                    dispatch(clearSelectedHistoryId());
                    window.dispatchEvent(new Event('newChat'))}
                  }/> : <div></div>}
              </Group>
            </div>
          ) : (
            <Group h="100%" justify="space-between">
              <Burger 
                opened={mobileOpened} 
                onClick={handleToggleMobile} 
                hiddenFrom="sm" 
                size="sm" 
                aria-label="Open navigation" 
                id="mobile-nav"
              />
              {isToolsRoute && (
                <Burger
                  opened={asideDesktopOpened}
                  onClick={asideToggleDesktop}
                  visibleFrom="md"
                  id="aside-toggle"
                  lineSize={2}
                  size="md"
                  color="black"
                />
              )}
            </Group>
          )}
        </AppShell.Header>

        <AppShell.Navbar p={0} className="navbar">
          <Sidebar
            isMobile={isMobile}
            mobileOpened={mobileOpened}
            toggleMobile={handleToggleMobile}
            historyContent={historyContent}
            setIsContactUsModalOpen={setIsContactUsModalOpen}
          />
        </AppShell.Navbar>

        <AppShell.Main>
          {children || <Outlet />}
        </AppShell.Main>
      </AppShell>

      <NotificationsContainer notifications={notifications} />
      
      <ContactUsModal 
        isOpen={isContactUsModalOpen} 
        onClose={() => setIsContactUsModalOpen(false)} 
      />
      
      <Modal
        opened={showMobilePopup}
        onClose={handleCloseMobilePopup}
        centered
        size="sm"
        withCloseButton={false}
        overlayOpacity={0.6}
        styles={{
          modal: {
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            padding: '20px'
          }
        }}
      >
        <Text 
          weight={700} 
          align="center" 
          variant="gradient" 
          gradient={{ from: 'blue', to: 'cyan', deg: 244 }}
        >
          {t('baseLayout.mobilePopupTitle')}
        </Text>
        <Center mt="md">
          <Button 
            onClick={handleCloseMobilePopup}
            variant="gradient"
            gradient={{ from: 'blue', to: 'cyan', deg: 244 }}
          >
            {t('kapat')}
          </Button>
        </Center>
      </Modal>
      {!isMobile && <WhatsAppButton />}
    </>
  );
};

export default BaseLayout;