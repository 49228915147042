// sanitizeInput.js
import DOMPurify from 'dompurify';

/**
 * Sanitize input data to prevent XSS attacks.
 * @param {string | object} input - The input data to sanitize. Can be a string or an object containing strings.
 * @returns {string | object} - The sanitized input.
 */
export const sanitizeInput = (input) => {
  if (typeof input === 'string') {
    return DOMPurify.sanitize(input);
  } else if (typeof input === 'object' && input !== null) {
    const sanitizedObject = {};
    for (const key in input) {
      if (typeof input[key] === 'string') {
        sanitizedObject[key] = DOMPurify.sanitize(input[key]);
      } else {
        // For nested objects or arrays, you could recursively sanitize, but be mindful of performance and depth.
        sanitizedObject[key] = input[key]; // Or apply recursion for objects/arrays
      }
    }
    return sanitizedObject;
  }
  return input; // Return as is if it's not a string or plain object
};
