import { createSlice } from '@reduxjs/toolkit';


// Initial state for the auth slice
const initialState = {
  user: null,
  accessToken: localStorage.getItem('accessToken') || null,
  status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
      setCredentials: (state, action) => {
        console.log(action.payload, "action.payload")
        if (action.payload.user) {
          state.user = action.payload.user;
        }
         
          state.accessToken = action.payload.accessToken.token;
        console.log(state, "state", state.user, "stateuser")
          localStorage.setItem('accessToken', action.payload.accessToken.token); // Store the access token in localStorage
          state.status = 'succeeded';
          state.error = null;
      },
      verifyUser: (state) => {
        state.user.isVerified = true;
      },
      setTokens: (state, action) => {
        if (state.user && action.payload.tokenBalance) {
          state.user.tokenBalance = action.payload.tokenBalance; // Only update token balance
        }
      },
      logOut: (state) => {
        state.user = null;
        state.accessToken = null;
        state.status = 'idle';
        state.error = null;

        localStorage.removeItem('accessToken');
      },
      resetAuthState: () => initialState,
      validateSessionStart: (state) => {
        state.status = 'loading';
      },
      validateSessionFailure: (state) => {
        console.log("Session validation failed",)
        state.status = 'failed';
        state.error = 'Session validation failed';
        state.user = null;
        state.accessToken = null;
        localStorage.removeItem('accessToken');
      },
  },
  // No need for extraReducers as the apiSlice handles API interactions
});



export const { resetAuthState, verifyUser, setTokens, setCredentials, logOut } = authSlice.actions;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrenToken = (state) => state.auth.token;
export default authSlice.reducer;
