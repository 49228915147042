import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, verifyUser, logOut } from '../slices/authSlice'
// utility function to decode JWT and check expiration
const isTokenExpired = (token) => {
  if (!token) return true;

  // Split the token and get the payload part
  const payload = token.split('.')[1];
  
  // Decode the base64 string
  const decodedPayload = JSON.parse(atob(payload));
  
  // Get current timestamp (in seconds)
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Check if the token has expired
  return decodedPayload.exp < currentTimestamp;
};

let baseURL;

if (import.meta.env.MODE === 'development') {
    baseURL = 'http://localhost:3000/v1';
  } else {
    baseURL = import.meta.env.VITE_API_URL; // Access the environment variable here
  }
export const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
  
export const baseQueryWithReauth = async (args, api, extraOptions) => {
    const state = api.getState();
    console.log(args, "args")
    
    // Check if args.url exists before trying to use startsWith
    if (args.url && (
      args.url.startsWith('/auth/register') ||
      args.url.startsWith('/auth/login') ||
      args.url.startsWith('/auth/secure-account') ||
      args.url.startsWith('/verify/send-otp') ||  
      args.url.startsWith('/verify/verify-otp')
    )) {
      return baseQuery(args, api, extraOptions);
    }
    
    if (!state.auth.accessToken) {
      return { error: { status: 401, data: 'No refresh token available' } };
    }
    if (isTokenExpired(state.auth.accessToken)) {
      console.log("Access token is expired, attempting to refresh");
      const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions);
      if (refreshResult?.data) {
        console.log("Token refreshed successfully");
        api.dispatch(setCredentials(refreshResult.data));
      } else {
        console.log("Failed to refresh token, logging out");
        api.dispatch(logOut());
        return { error: { status: 401, data: 'Session expired. Please log in again.' } };
      }
    }
    let result = await baseQuery(args, api, extraOptions);
  
    if (result?.error?.status === 401) {
      const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions);
      if (refreshResult?.data) {
      
        api.dispatch(setCredentials(refreshResult.data));
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
      }
    }
  
    return result;
  };

  export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth, // Default to authenticated baseQuery
    endpoints: (builder) => ({
      // Unauthenticated endpoints
      registerUser: builder.mutation({
        query: (userData) => ({
          url: '/auth/register',
          method: 'POST',
          body: userData,
          baseQuery: baseQuery, // Use baseQuery for unauthenticated requests
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
 /* 
            dispatch(setCredentials(data)); */
          } catch (error) {
            console.log(error, "error")
            localStorage.removeItem('accessToken');
          }
        },
      }),
      loginUser: builder.mutation({
        query: (credentials) => ({
          url: '/auth/login',
          method: 'POST',
          body: credentials,
          baseQuery: baseQuery, // Use baseQuery for unauthenticated requests
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setCredentials(data));
          } catch (error) {
            console.log(error, "error")
            localStorage.removeItem('accessToken');
          }
        },
      }),
      handleGoogleCallback: builder.query({
        query: (params) => ({
          url: '/auth/google/callback',
          method: 'GET',
          params: params
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setCredentials(data));
          } catch (error) {
            console.error('Google callback error:', error);
            localStorage.removeItem('accessToken');
          }
        }
      }),
      handleAppleCallback: builder.query({
        query: (params) => ({
          url: '/auth/apple/callback',
          method: 'GET',
          params: params
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setCredentials(data));
          } catch (error) {
            console.error('Apple callback error:', error);
            localStorage.removeItem('accessToken');
          }
        }
      }),
      initiateAppleAuth: builder.mutation({
        query: () => ({
          url: '/auth/apple',
          method: 'GET',
          // No need for credentials here as it's a redirect
        })
      }),
      initiateGoogleAuth: builder.mutation({
        query: () => ({
          url: '/auth/google',
          method: 'GET',
          // No need for credentials here as it's a redirect
        })
      }),
      // Authenticated endpoints
      logout: builder.mutation({
        query: () => ({
          url: '/auth/logout',
          method: 'POST',
        }),
        async onQueryStarted(arg, { dispatch }) {
          dispatch(logOut()); // Dispatch logout action
          localStorage.removeItem('accessToken'); // Clear local storage
        },
      }),
      getUser: builder.query({
        query: () => '/auth/user',
  
      }),
      sendOTP: builder.mutation({
        query: ({ userId, otpToken }) => ({
          url: `/verify/send-otp/${userId}`,
          method: 'GET',
          headers: { 'X-OTP-Token': otpToken },
        }),
      }),
      verifyOTP: builder.mutation({
        query: ({ userId, code, otpToken }) => ({
          url: '/verify/verify-otp',
          method: 'POST',
          body: { userId, code },
          headers: { 'X-OTP-Token': otpToken },
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
           /*  await queryFulfilled;
            dispatch(verifyUser()); */
            
            const { data } = await queryFulfilled;
            console.log(data, "data")
            // Finalize authentication only after OTP verification is successful.
        
            dispatch(setCredentials(data));
            dispatch(verifyUser());
        
          } catch (error) {
            // Handle error
          }
        },
      }),
     
      updateContactPreferences: builder.mutation({
        query: ({ preferences }) => ({
          url: `/auth/update-contact-preferences`,
          method: 'PUT',
          body: preferences,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
          } catch (error) {
            // Handle error
          }
        },
      }),
      updateAccountInformation: builder.mutation({
        query: (accountData) => ({
          url: '/auth/update-account-info',
          method: 'PUT',
          body: accountData,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
          } catch (error) {
            // Handle error
          }
        },
      }),
      sendUpdateEmailVerification: builder.mutation({
        query: ({ newEmail }) => ({
          url: '/auth/initiate-email-change',
          method: 'POST',
          body: { newEmail },
        }),
      }),
      verifyUpdateEmail: builder.mutation({
        query: ({ token }) => ({
          url: `/verify/change-email/${token}`,
          method: 'GET',
        }),
      }),
      changePassword: builder.mutation({
        query: ({ currentPassword, newPassword, newPasswordConfirm }) => ({
          url: `/auth/change-password`,
          method: 'POST',
          body: { currentPassword, newPassword, newPasswordConfirm },
        }),
      }),
      secureAccount: builder.mutation({
        
        query: ({ token, newPassword, newPasswordConfirm }) => ({
          baseQuery: baseQuery, // Use baseQuery for unauthenticated requests
          url: '/auth/secure-account',
          method: 'POST',
          body: { token, newPassword, newPasswordConfirm },
        }),
      }),
      sendUpdatePhoneVerification: builder.mutation({
        query: ({ newPhone }) => ({
            url: '/auth/initiate-phone-change',
            method: 'POST',
            body: { newPhone },
        }),
    }),
    verifyUpdatePhone: builder.mutation({
        query: ({ token }) => ({
            url: `/verify/change-phone/${token}`,
            method: 'POST',
            body: { token },
        }),
    }),
    sendContactUs: builder.mutation({
      query: ({ subject, type, message }) => ({
        url: '/auth/contact-us',
        method: 'POST',
        body: { subject, type, message },
      }),
    }),
    addPhone: builder.mutation({
      query: (data) => ({
        url: '/auth/add-phone',
        method: 'POST',
        body: data,
      }),
    }),
    }),

    // contact us

   
  });
  
  export const {
    useRegisterUserMutation,
    useLoginUserMutation,
    useLogoutMutation,
    useGetUserQuery,
    useSendOTPMutation,
    useVerifyOTPMutation,
    useUpdateAccountInformationMutation,
    useUpdateContactPreferencesMutation,
    useChangePasswordMutation,
    useSendUpdateEmailVerificationMutation,
    useVerifyUpdateEmailMutation,
    useSecureAccountMutation,
    useSendUpdatePhoneVerificationMutation,
    useVerifyUpdatePhoneMutation,
    useSendContactUsMutation,
    useHandleGoogleCallbackQuery,
    useHandleAppleCallbackQuery,
    useInitiateAppleAuthMutation,
    useInitiateGoogleAuthMutation,
    useAddPhoneMutation,
  } = apiSlice;
  