import { useState, useCallback } from 'react';
import axios from '../utils/axios'; // Ensure axios is correctly imported

function useAxios() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async (config, onSuccess) => {
        setLoading(true);
        setError(null);
        const controller = new AbortController();

        try {
            const response = await axios({
                ...config,
                signal: controller.signal,
            });
            console.log(response)
            onSuccess(response.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }

        // Return the abort function to the caller, allowing them to cancel the request if needed
        return () => controller.abort();
    }, []);

    return { loading, error, sendRequest };
}

export default useAxios;