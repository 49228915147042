import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { motion, AnimatePresence } from 'framer-motion';
import { useNotifications } from '../../context/NotificationContext';
import { useChangePasswordMutation } from '../../redux/api/authApiSlice';
import { sanitizeInput } from '../../utils/sanitizeInput';
import { Input, PasswordInput } from '../../components/common/Input';

function PasswordRequirement({ meets, label }) {
  return (
    <div className={`flex items-center gap-2 text-sm ${meets ? 'text-green-600' : 'text-red-500'}`}>
      <span>{meets ? '✓' : '✗'}</span>
      <span>{label}</span>
    </div>
  );
}

const requirements = [
  { re: /[0-9]/, label: 'changePassword.requirements.number' },
  { re: /[a-z]/, label: 'changePassword.requirements.lowercase' },
  { re: /[A-Z]/, label: 'changePassword.requirements.uppercase' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'changePassword.requirements.special' },
];

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;
  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });
  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

const ResetPassword = () => {
  const { t } = useTranslation();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const [showRequirements, setShowRequirements] = useState(false);

  const form = useForm({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: {
      currentPassword: (value) => (value.length < 1 ? t('changePassword.currentPasswordRequired') : null),
      newPassword: (value) => (getStrength(value) < 50 ? t('changePassword.passwordTooWeak') : null),
      confirmNewPassword: (value, values) => (value !== values.newPassword ? t('changePassword.passwordMismatch') : null),
    },
  });

  const strength = getStrength(form.values.newPassword);

  const handleChangePassword = async (values) => {
    const sanitizedData = sanitizeInput(values);
    try {
      await changePassword(sanitizedData).unwrap();
      addNotification({ id: Date.now(), message: t('changePassword.success'), type: 'success' });
      form.reset();
      setTimeout(() => navigate('/tools/virtualTeacher'), 2000);
    } catch (error) {
      addNotification({ id: Date.now(), message: t('changePassword.error'), type: 'error' });
    }
  };

  return (
    <div className="w-full max-w-md space-y-8">
      <div className="text-center space-y-3">
        <h2 className="text-3xl font-bold text-gray-900">
          {t('changePassword.title')}
        </h2>
        <p className="text-gray-500 max-w-sm mx-auto">
          {t('changePassword.instructions')}
        </p>
      </div>

      <form onSubmit={form.onSubmit(handleChangePassword)} className="space-y-6">
        <div className="space-y-4">
          <PasswordInput
            label={t('changePassword.currentPassword')}
            placeholder={t('changePassword.currentPasswordPlaceholder')}
            error={form.errors.currentPassword}
            {...form.getInputProps('currentPassword')}
          />

          <div className="relative">
            <PasswordInput
              label={t('changePassword.newPassword')}
              placeholder={t('changePassword.newPasswordPlaceholder')}
              error={form.errors.newPassword}
              {...form.getInputProps('newPassword')}
              onFocus={() => setShowRequirements(true)}
              onBlur={() => setShowRequirements(false)}
            />
            
            <AnimatePresence>
              {showRequirements && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute z-10 w-full mt-2 p-4 bg-white rounded-xl shadow-lg border border-gray-200"
                >
                  {/* Password Strength Indicator */}
                  <div className="mb-3">
                    <div className="h-1 w-full bg-gray-200 rounded-full overflow-hidden">
                      <motion.div
                        className={`h-full transition-all duration-300 ${
                          strength > 80 ? 'bg-green-500' : 
                          strength > 50 ? 'bg-yellow-500' : 
                          'bg-red-500'
                        }`}
                        initial={{ width: 0 }}
                        animate={{ width: `${strength}%` }}
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <PasswordRequirement 
                      label={t('changePassword.minLength')} 
                      meets={form.values.newPassword.length > 5} 
                    />
                    {requirements.map((requirement, index) => (
                      <PasswordRequirement
                        key={index}
                        label={t(requirement.label)}
                        meets={requirement.re.test(form.values.newPassword)}
                      />
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <PasswordInput
            label={t('changePassword.confirmNewPassword')}
            placeholder={t('changePassword.confirmNewPasswordPlaceholder')}
            error={form.errors.confirmNewPassword}
            {...form.getInputProps('confirmNewPassword')}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full py-3 px-4 bg-[#40286c] text-white rounded-xl font-medium hover:bg-[#332056] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c]"
        >
          {isLoading ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              <span>{t('changePassword.processing')}</span>
            </div>
          ) : (
            t('changePassword.changeButton')
          )}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;