import { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Group,
  Button,
  Stack,
  Box,
  TextInput,
  Modal,
  Text,
  ActionIcon,
  FileButton,
  Paper,
  Title,
  Tooltip
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from 'framer-motion';

import TokenCounter from "../../utils/tokenCounter";  
import Selector from "../../components/common/Selector"; // Adjust the import path as necessary
import ErrorDisplay from "../../components/common/Notification"; // Adjust the import path as needed
import usePromptInput from "../../hooks/usePromptInput";
import { sanitizeInput } from "../../utils/sanitizeInput";
import { languageOptions, getAssistantOptions } from "../../utils/constants";

import ForgerOutlineCreator from "../../components/common/ForgerOutlineCreator";
import ForgerTextArea from '../../components/common/ForgerTextArea';

import { useDispatch, useSelector } from 'react-redux';
import  { useForgeArticleMutation, usePromptChainMutation } from '../../redux/api/articleForgerApiSlice';
import { useFetchHistoryDetailsQuery } from '../../redux/api/toolHistoriesApiSlice';
import { appendToHistory, clearSelectedHistoryId } from "../../redux/slices/toolHistoriesSlice";
// eslint-disable-next-line react/prop-types
import { useNotifications } from '../../context/NotificationContext'; 
import { setTokens } from '../../redux/slices/authSlice';
import { useMediaQuery } from '@mantine/hooks';
import StyledButton from "../../components/common/StyledButton";
import * as Sentry from "@sentry/react";
import { Send, Brain, Upload, FileText, Copy, Book, Ruler, LightbulbIcon, FileUp, ChevronRight, Bot, LanguagesIcon, Files, FileIcon, Wand2, Settings2, Sparkles, Blocks, X, Info } from 'lucide-react';
import SingleStepTutorial from "../../components/common/Tutorial";

import ArticleForgerWizard from './ArticleForgerWizard';
import { fixFileName } from '../../utils/fileUtils';

const FileList = ({ t, files, title, onDelete, brokenFiles = [] }) => {
  if (!files?.length) return null;

  return (
    <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4 shadow-lg border border-white/10">
      <div className="flex items-center gap-2 mb-3">
        <Files className="h-4 w-4 text-gray-600" />
        <h3 className="font-medium text-gray-700">{title}</h3>
      </div>
      <ul className="space-y-2">
        {files.map((file, index) => {
          const isBroken = brokenFiles.includes(file.name);
          return (
            <li 
              key={index} 
              className={`group relative flex items-center justify-between p-1 rounded-md
                ${isBroken 
                  ? 'bg-red-50 border border-red-100' 
                  : 'hover:bg-gray-50 border border-transparent hover:border-gray-100'
                } transition-all duration-200`}
            >
              <div className="flex items-center gap-2 min-w-0 flex-1">
                <FileIcon className={`h-3.5 w-3.5 flex-shrink-0 ${isBroken ? 'text-red-500' : 'text-gray-500'}`} />
                <div className="min-w-0 flex-1">
                  <Tooltip 
                    label={fixFileName(file.name)}
                    position="top"
                    disabled={file.name.length <= 20}
                  >
                    <span style={{fontSize: '14px'}} className={`block truncate ${isBroken ? 'text-red-600' : 'text-gray-700'}`}>
                      {fixFileName(file.name)}
                    </span>
                  </Tooltip>

                  {isBroken && (
                    <span className="text-xs text-red-500 block mt-0.5">
                      {t('errors.fileProcessing.broken')}
                    </span>
                  )}
                </div>
              </div>
              {onDelete && (
                <span
                  onClick={() => onDelete(index)}
                  className={`ml-2 p-1.5 rounded-full cursor-pointer
                    ${isBroken 
                      ? 'hover:bg-red-200 text-red-500' 
                      : 'hover:bg-gray-200 text-gray-500'
                    } transition-all duration-200`}
                  aria-label="Delete file"
                >
                  <X className="h-3.5 w-3.5" />
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const ConditionalFileAttachments = ({ t, attachedFiles, historyFiles, labels, selectedHistoryId, onDeleteFile, brokenFiles = [] }) => {
  if (selectedHistoryId) {
    return (
      <div className="space-y-3">
        {historyFiles?.length > 0 && (
          <FileList 
            files={historyFiles} 
            title={labels.usedFiles}
            brokenFiles={brokenFiles}
            t={t}
          />
        )}
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {attachedFiles?.length > 0 && (
        <FileList 
          files={attachedFiles} 
          title={labels.attachedFiles}
          onDelete={onDeleteFile}
          brokenFiles={brokenFiles}
          t={t}
        />
      )}
    </div>
  );
};

function ArticleForger() {
  const hasStartedRef = useRef(false); // Use ref instead of state
  const currentTool = useSelector(state => state.toolHistories.currentTool);

  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);
  const { addNotification } = useNotifications();
  const [forgeArticle, { isLoading: forgerLoading, error: forgerError }] = useForgeArticleMutation();
  const [promptChain, { isLoading: promptChainLoading, error: promptChainError }] = usePromptChainMutation();
  const [dots, setDots] = useState(''); // State to manage the dots animation
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)
  const [promptChainActive, setPromptChainActive] = useState(false); // State to control prompt chain activation
  
  const isLoading = forgerLoading || promptChainLoading;
  const error = forgerError || promptChainError;


  useEffect(() => {
    if (promptChainActive && isLoading) {
      const interval = setInterval(() => {
        setDots((prevDots) => {
          if (prevDots === '...') return '.';
          if (prevDots === '.') return '..';
          return '...';
        });
      }, 500); // Change every 500ms
    
      return () => clearInterval(interval); // Cleanup interval on component unmount or condition change
    } else {
      setDots(''); // Reset dots if either condition is false
    }
  }, [promptChainActive, isLoading]); // Dependency array includes `promptChainActive` and `isLoading`
  const dispatch = useDispatch();
  const selectedHistoryId = useSelector(state => state.toolHistories.selectedHistoryId);
  const { data: historyDetails, isError  } = useFetchHistoryDetailsQuery({
    serviceType: 'articleForger',
    id: selectedHistoryId,
  }, {
    skip: !selectedHistoryId || currentTool !== 'articleForger', // Skip the query if no ID or serviceType is selected
  });

  const { t } = useTranslation();
  const [brokenFiles, setBrokenFiles] = useState([]);
  const assistantOptions = getAssistantOptions(t);
  const [messages, setMessages] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [queue, setQueue] = useState(0);
  const [listLength, setListLength] = useState(null)

  // forger outline creator Modal
  const [isForgerOutlineModalVisible, setIsForgerOutlineModalVisible] = useState(false);

  
  
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  

  const toggleForgerOutlineModal = () => {
    setIsForgerOutlineModalVisible(!isForgerOutlineModalVisible);
  };

  // for prompt chain aktif olup olmamasi

  /// for prompt chain Modal
  const [showWarning, setShowWarning] = useState(false); // State to control warning modal visibility

  // confirmation modal for deletion

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

  const tutorialSeen = localStorage.getItem(`tutorial_articleForger`);
 
  //end mobile popup

  const [tokenCount, setTokenCount] = useState(0); // Track the calculated token count
  //

  // Apply these styles conditionally based on the screen width

  const form = useForm({
    initialValues: {
      selectedAssistant: 'uzman',
      selectedLanguage: 'tr',
      course: '',
      length: '',
      extraPrompt: "",
      topic: "",
      promptChain: false,
      threadId: false,
      queue: 0,
      inputs: [
        { id: "input1", inputLabel: "1", parentId: null, undeletable: true, done: false, value: "Giriş" },
        { id: "input2", inputLabel: "2", parentId: null, undeletable: true, done: false, value: "İçerik 1" },
        { id: "input3", inputLabel: "3", parentId: null, undeletable: true, done: false, value: "İçerik 2" },
        { id: "input4", inputLabel: "4", parentId: null, undeletable: true, done: false, value: "Sonuç" },
      ],
    },

    // Adding form validation rules
    validate: {
      topic: (value) => {
        if (!value) return t("validation.topic.required");
        if (value.length > 10000) return t("validation.topic.tooLong");
        // Add more custom validation as needed
        return null; // return null if the value passes validation
      },
      course: (value) => {
        if (!value) return t("validation.course.required");
        if (value.length > 200) return t("validation.course.tooLong");
        // Add more custom validation as needed
        return null; // return null if the value passes validation
      },
      length: (value) => {
        const number = Number(value); // Convert value to number for validation
        if (!value) return t("validation.lengthForger.required");
        if (isNaN(number)) return t("validation.lengthForger.mustBeNumber");
        if (number < 300 || number > 100000) return t("validation.lengthForger.range");
        return null; // return null if the value passes validation
      },

      selectedAssistant: (value) =>
        value === null ? t("validation.assistant.required") : null,
        selectedLanguage: (value) =>
        value === null ? t("validation.language.required") : null,
        
    },
  });
  useEffect(() => {
    try {
      if (!tutorialSeen) {
          setShowTutorial(true);
        }
      if (historyDetails) {
        if (!selectedHistoryId) {
          console.log('reset');
          form.reset();
          setMessages([]);
          setQueue(0);
          return;
        }
  
        form.setFieldValue("topic", historyDetails.topic);
        form.setFieldValue("selectedAssistant", historyDetails.assistantType);
        form.setFieldValue("selectedLanguage", historyDetails.language);
        form.setFieldValue("length", historyDetails.length);
        form.setFieldValue("course", historyDetails.course);
        form.setFieldValue('promptChain', historyDetails.promptChainActive);
        form.setFieldValue('threadId', historyDetails.threadId);
        form.setFieldValue('inputs', historyDetails.inputs);
        form.setFieldValue('queue', historyDetails.queue);
      setListLength(historyDetails.listLength);
        setQueue(historyDetails.queue);
        if (historyDetails.extraPrompt) form.setFieldValue("extraPrompt", historyDetails.extraPrompt);
        const assistantMsg = { sender: "assistant", text: historyDetails.result };
        setMessages(() => [assistantMsg]);
      }
    } catch (error) {
      Sentry.captureException(error); // Capture unexpected errors
      console.error("Error loading history details:", error);
    }
  }, [selectedHistoryId, historyDetails, dispatch]);
  
  const { 
    showPromptInput,
    promptInputPosition,
    handleAddPromptClick,
    handleSavePrompt,
    handleClosePromptInput,
  } = usePromptInput((prompt) => {
    form.setFieldValue("extraPrompt", prompt);
  });

  const handleSubmit = async (values) => {
    // Add start notification
    addNotification({
      id: new Date().getTime(),
      type: 'info',
      message: t('notifications.generation')
    });

    try {
      if (tokenCount > tokenBalance) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.tokenBalance.insufficient'),
        });
        setNotEnoughTokens(true);
        setTimeout(() => {
          setNotEnoughTokens(false);
        }
        , 3000)
        return;
      }
  
      const formData = new FormData();
      formData.append('topic', sanitizeInput(values.topic));
      formData.append('length', sanitizeInput(values.length));
      formData.append('course', sanitizeInput(values.course));
      formData.append('selectedAssistant', sanitizeInput(values.selectedAssistant));
      formData.append('selectedLanguage', sanitizeInput(values.selectedLanguage));
      formData.append('outline', JSON.stringify(values.inputs.map(sanitizeInput)));
      attachedFiles.forEach((file) => {
        formData.append('files', file);
      });
  
      const result = await forgeArticle(formData).unwrap();
      const assistantMsg = { sender: "assistant", text: result.result };
      setMessages((prev) => [...prev, assistantMsg]);
      form.setFieldValue("topic", "", { shouldValidate: false });
  
      dispatch(appendToHistory({ tool: 'articleForger', entry: result.usageData }));
      dispatch(setTokens({ tokenBalance: result.tokenBalance }));
      setAttachedFiles([]);
      setBrokenFiles([]);
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: t('notifications.generationComplete'),
      });
    } catch (error) {
      Sentry.captureException(error); // Capture unexpected errors
      console.error(error);
      if (error.data?.error === "FileUploadError") {
        let failedFiles = error.data.failedFiles;
        if (!failedFiles) {
          const match = error.data.message.match(/The following files failed to embed: (.+)/);
          failedFiles = match ? [match[1]] : [];
        }
        
        setBrokenFiles(failedFiles.map(name => fixFileName(name)));
        
        const errorMessage = failedFiles.length === 1
          ? t('errors.fileProcessing.single', { fileName: fixFileName(failedFiles[0]) })
          : t('errors.fileProcessing.multiple', { 
              fileNames: failedFiles.map(name => `"${fixFileName(name)}"`).join(', ')
            });

      
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: errorMessage
        });
      } else {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: t('errors.generation'),
        });
      }
    }
  };

  const startPromptChain = async (values, queue = 0, threadId = false) => {
    try {
      if (tokenCount > tokenBalance) {
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('validation.tokenBalance.insufficient'),
        });
        setNotEnoughTokens(true);
        setTimeout(() => {
          setNotEnoughTokens(false);
        }, 3000);
        return;
      }
  
      // Only show the academic mode notification on the first call
      if (queue === 0) {
        addNotification({
          id: new Date().getTime(),
          type: 'info',
          message: t('notifications.generation')
        });
    
        addNotification({
          id: new Date().getTime(),
          type: 'info',
          message: t('notifications.academicmodeactivated'),
        });
      }
  
      // If using the 'uzman' assistant branch (which might be a one‐step process)
      if (values.selectedAssistant === 'uzman') {
        const formData = new FormData();
        formData.append("topic", sanitizeInput(values.topic));
        formData.append("length", sanitizeInput(values.length));
        formData.append("course", sanitizeInput(values.course));
        formData.append("selectedAssistant", sanitizeInput(values.selectedAssistant));
        formData.append("selectedLanguage", sanitizeInput(values.selectedLanguage));
        formData.append("isPromptChain", true);
        formData.append("outline", JSON.stringify(values.inputs.map(sanitizeInput)));
        attachedFiles.forEach((file) => {
          formData.append("files", file);
        });
  
        const result = await forgeArticle(formData).unwrap();
        const assistantMsg = { sender: "assistant", text: result.result };
        setMessages((prev) => [...prev, assistantMsg]);
        form.setFieldValue("topic", "", { shouldValidate: false });
  
        dispatch(appendToHistory({ tool: "articleForger", entry: result.usageData }));
        dispatch(setTokens({ tokenBalance: result.tokenBalance }));
        setAttachedFiles([]);
        
        // Show final notification for the complete article
        addNotification({
          id: new Date().getTime(),
          type: 'success',
          message: t('notifications.generationcomplete'),
        });
        setPromptChainActive(true);
        return;
      }
  
      // Reset when starting a new chain if needed
      if (historyDetails?.status === "completed" && !hasStartedRef.current) {
        setQueue(0);
        setMessages([]);
        values.threadId = false;
        hasStartedRef.current = true;
      }
  
      setPromptChainActive(true);
      const formData = new FormData();
      formData.append("topic", sanitizeInput(values.topic));
      formData.append("length", sanitizeInput(values.length));
      formData.append("course", sanitizeInput(values.course));
      formData.append("selectedAssistant", sanitizeInput(values.selectedAssistant));
      formData.append("selectedLanguage", sanitizeInput(values.selectedLanguage));
      if (values.threadId) formData.append("queue", values.queue);
      else formData.append("queue", queue);
      if (values.threadId) formData.append("threadId", values.threadId);
      else formData.append("threadId", threadId);
      formData.append("outline", JSON.stringify(values.inputs.map(sanitizeInput)));
      
      if (queue === 0) {
        attachedFiles.forEach((file) => {
          formData.append("files", file);
        });
      }
      setListLength(values.inputs.length);
      const result = await promptChain(formData).unwrap();
      form.setFieldValue(
        "inputs",
        form.values.inputs.map((input, index) => {
          if (index === queue) {
            return { ...input, done: true };
          }
          return input;
        })
      );
      
      if (result.usageData)
        dispatch(appendToHistory({ tool: "articleForger", entry: result.usageData }));
      setQueue(result.queue);
      if (listLength === null) setListLength(result.listLength);
      const assistantMsg = { sender: "assistant", text: result.result };
      setMessages((prev) => [...prev, assistantMsg]);
      dispatch(setTokens({ tokenBalance: result.tokenBalance }));
  
      // If there are still sections remaining, continue the chain recursively
      if (result.listLength !== result.queue) {
        startPromptChain(values, result.queue, result.threadId);
      }
      // When the final section has been processed, notify the user
      if (result.listLength === result.queue) {
        setPromptChainActive(false);
        addNotification({
          id: new Date().getTime(),
          type: 'success',
          message: t('notifications.generationcomplete'),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      if (error.data?.error === "FileUploadError") {
        let failedFiles = error.data.failedFiles;
        if (!failedFiles) {
          const match = error.data.message.match(/The following files failed to embed: (.+)/);
          failedFiles = match ? [match[1]] : [];
        }
        
        setBrokenFiles(failedFiles.map(name => fixFileName(name)));
        
        const errorMessage = failedFiles.length === 1
          ? t('errors.fileProcessing.single', { fileName: fixFileName(failedFiles[0]) })
          : t('errors.fileProcessing.multiple', { 
              fileNames: failedFiles.map(name => `"${fixFileName(name)}"`).join(', ')
            });

      
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: errorMessage
        });
      } else {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: t('errors.generation'),
        });
      }
    }
  };
  // prompt input functions end

  const handleActivatePromptChain = () => {
    form.setFieldValue('promptChain', !form.values.promptChain);
    form.setFieldValue('selectedAssistant', !form.values.promptChain ? 'profesor' : 'uzman');
  };



  const handleSetFiles = (files) => {
    setAttachedFiles(files); // Store files in state
    addNotification({
      id: new Date().getTime(), // Unique identifier
      type: 'success',
      message: `${t('successfulyaddeddocuments')} ${files.map(file => file.name).join(', ')}`,
    });
  };
  
  function calculateFinalSum(y, x) {
    let result = 0;
    for (let n = 1; n <= y; n++) {
        result += n * 700;
    }
    return result + 2 * x;
  }

  const forgerFormula = (normalTokenCount) => {

    let tokenFormula = 0;

  
    if (!form.values.promptChain) {
  
        tokenFormula = (5 * normalTokenCount) / 1000;
    } else {
        
        tokenFormula = (5 * normalTokenCount) / 40;
    
    
    }

    if (attachedFiles.length > 0) tokenFormula = tokenFormula + ( 17000  / 40);
    // Round up the result and ensure at least 1 token is charged
    return Math.max(Math.ceil(tokenFormula), 1); 
  };


  useEffect(() => {
    const normalTokenCount = form.values.length;
    
    if (normalTokenCount === '') {
      setTokenCount(0); // Reset token count if no length is provided
      return;
    }
  
  
    const newTokenCount = forgerFormula(
      normalTokenCount,
    );
  
    setTokenCount(newTokenCount / 2);
  }, [form.values.inputs.length, form.values.length, form.values.topic.length,form.values.promptChain, attachedFiles]);
  
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Add new state for mode selection
  const [selectedMode, setSelectedMode] = useState(() => {
    // Initialize to 'advanced' if there's a selected history, otherwise null
    return selectedHistoryId ? 'advanced' : null;
  });

  // Update useEffect to set mode when history changes
  useEffect(() => {
    if (selectedHistoryId) {
      setSelectedMode('advanced');
    }
    console.log(selectedHistoryId, "selectedHistoryId")
    if (selectedHistoryId === null) {
      setAttachedFiles([]);
    }
  }, [selectedHistoryId]);

  // Add handler for wizard completion
  const handleWizardComplete = (wizardData) => {
    // Transfer wizard data to main form
    form.setValues({
      topic: wizardData.topic,
      selectedLanguage: wizardData.language,
      length: wizardData.wordCount,
      course: wizardData.course,
      inputs: wizardData.selectedTitles,
      selectedAssistant: 'uzman', // Default to expert for easy mode
      promptChain: wizardData.files.length > 0, // Enable prompt chain if files are attached
    });
    
    // Set attached files if any
    if (wizardData.files.length > 0) {
      setAttachedFiles(wizardData.files);
    }
    
    // Submit the form
    handleSubmit(form.values);
  };

  const handleDeleteFile = (index) => {
    setAttachedFiles(prev => prev.filter((_, i) => i !== index));
    addNotification({
      id: new Date().getTime(),
      type: 'success',
      message: t('fileDeletedSuccessfully'),
    });
  };
  const resetArticle = () => {
    form.reset();
    setMessages([]);
    setQueue(0);
    setAttachedFiles([]);
    setBrokenFiles([]);
    setPromptChainActive(false);

  };

  useEffect(() => {
    const handleNewArticle = () => {
      resetArticle();
    };

    window.addEventListener("newChat", handleNewArticle);
    
    return () => {
      window.removeEventListener("newChat", handleNewArticle);
    };
  }, []);

  const tutorialSteps = [
    {
      elementId: isMobile 
        ? ['course-input-mobile', 'length-input-mobile', 'language-selector-mobile']
        : ['course-input-desktop', 'length-input-desktop', 'language-selector-desktop'],
      title: t('tutorial.articleForger.first.title'),
      content: t('tutorial.articleForger.first.content'),
      position: 'center'
    },
    {
      elementId: ['document-uploader', 'promptChain', 'addPrompt'],
      title: t('tutorial.articleForger.second.title'),
      content: t('tutorial.articleForger.second.content'),
      position: 'center'
    },
    {
      elementId: ['suggestTitles', "addTitle", "plusTitle", "addOutline"],
      title: t('tutorial.articleForger.third.title'),
      content: t('tutorial.articleForger.third.content'),
      position: 'center'
    },
    // Only show these steps on desktop
    {
      elementId: isMobile ? ['submit-mobile'] : ['submit'],
      title: t('tutorial.articleForger.fourth.title'),
      content: t('tutorial.articleForger.fourth.content'),
      position: 'center'
    },
  ];

  
  const handleTutorialStepChange = (step) => {
    setCurrentTutorialStep(step);
  };
  

  // If no mode is selected, show mode selection screen
  if (!selectedMode) {
    return (
      <div className="flex items-center justify-center min-h-screen ">
        <Paper  className="w-full max-w-4xl mx-4 p-8 rounded-2xl ">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-[#40286C] mb-3">
              {t("selectMode")}
            </h2>
            <p className="text-gray-600">
              {t("selectModeDescription")}
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-6 ">
            {/* Easy Mode Card */}
            <button
              onClick={() => setSelectedMode('easy')}
              className="group relative bg-white p-6 rounded-xl shadow-sm border border-gray-200 
                       hover:shadow-md hover:border-blue-200 transition-all duration-300
                       flex flex-col items-center text-center"
            >
              <div className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
                <ChevronRight className="w-5 h-5 text-blue-500" />
              </div>
              
              <div className="mb-6 p-4 bg-blue-50 rounded-full">
                <Wand2 className="w-8 h-8 text-blue-500" />
              </div>
              
              <h3 className="text-xl font-semibold text-gray-800 mb-3 flex items-center gap-2">
                {t("easyMode")}
                <Sparkles className="w-4 h-4 text-yellow-500" />
              </h3>
              
              <p className="text-gray-600 mb-4">
                {t("easyModeDescription")}
              </p>
              
              <ul className="text-sm text-gray-500 text-left space-y-2">
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                  {t("easyModeFeature1")}
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                  {t("easyModeFeature2")}
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                  {t("easyModeFeature3")}
                </li>
              </ul>
            </button>

            {/* Advanced Mode Card */}
            <button
              onClick={() => setSelectedMode('advanced')}
              className="group relative bg-white p-6 rounded-xl shadow-sm border border-gray-200 
                       hover:shadow-md hover:border-purple-200 transition-all duration-300
                       flex flex-col items-center text-center"
            >
              <div className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
                <ChevronRight className="w-5 h-5 text-purple-500" />
              </div>
              
              <div className="mb-6 p-4 bg-purple-50 rounded-full">
                <Settings2 className="w-8 h-8 text-purple-500" />
              </div>
              
              <h3 className="text-xl font-semibold text-gray-800 mb-3 flex items-center gap-2">
                {t("advancedMode")}
                <Blocks className="w-4 h-4 text-purple-500" />
              </h3>
              
              <p className="text-gray-600 mb-4">
                {t("advancedModeDescription")}
              </p>
              
              <ul className="text-sm text-gray-500 text-left space-y-2">
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 bg-purple-500 rounded-full" />
                  {t("advancedModeFeature1")}
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 bg-purple-500 rounded-full" />
                  {t("advancedModeFeature2")}
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 bg-purple-500 rounded-full" />
                  {t("advancedModeFeature3")}
                </li>
              </ul>
            </button>
          </div>
        </Paper>
      </div>
    );
  }

  // Show wizard for easy mode
  if (selectedMode === 'easy') {
    return (
      <ArticleForgerWizard 
      tokenBalance={tokenBalance}
      t={t}
        onComplete={handleWizardComplete}
        onCancel={() => setSelectedMode(null)}
      />
    );
  }

  // Show existing interface for advanced mode
  return (
    <form onSubmit={form.onSubmit(
      (values) => 
        {
          if (listLength === queue) {
          setQueue(0)
          setListLength(null)
          form.values.threadId = false
          setMessages([])
          }
          form.values.promptChain ? startPromptChain(values) : handleSubmit(values)
        },
      (errors) => console.log(errors)
    )} className="flex flex-col"  style={{ height: '98vh' }} >
      {/* Top Section */}
      <div className="border-b border-gray-200 pb-2" >
        {/* Mobile Layout */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Mobile: First row with 2 columns */}
          <div className="grid grid-cols-2 gap-4 md:hidden">
            <Selector
              className="col-span-1"
              icon={<LanguagesIcon size={12}/>}
              label={t("language")}
              placeholder={t("Select")}
              data={languageOptions}
              description={t("languageDescription")}
              required
              {...form.getInputProps("selectedLanguage")}
              id="language-selector-mobile"
            />
            <TextInput 
              className="col-span-1"
              label={
                <label className="m_8fdc1311 mantine-InputWrapper-label mantine-Select-label" style={{marginRight: '4px'}}>
                  <Ruler size={12} style={{ marginRight: '8px' }}/>
                  {t("length")}
                </label>
              }
              type="number"
              placeholder={t("writeLength")}
              description={t("lengthDescription")}
              required
              {...form.getInputProps("length")}
              id="length-input-mobile"
            />
          </div>

          {/* Mobile: Second row full width */}
          <TextInput
            className="col-span-1 noErrorMargin md:hidden"
            label={
              <label className="m_8fdc1311 mantine-InputWrapper-label mantine-Select-label" style={{marginRight: '4px'}}>
                <Book size={12} style={{ marginRight: '8px' }}/>
                {t("course")}
              </label>
            }
            placeholder={t("writeCourse")}
            description={t("lessonDescription")}
            required
            {...form.getInputProps("course")}
            id="course-input-mobile"
          />

          {/* Desktop Layout (hidden on mobile) */}
          <div className="hidden md:grid md:grid-cols-3 md:gap-4 md:col-span-3">
            <Selector
              className="col-span-1 "
              icon={<LanguagesIcon size={12}/>}
              label={t("language")}
              placeholder={t("Select")}
              data={languageOptions}
              description={t("languageDescription")}
              required
              {...form.getInputProps("selectedLanguage")}
              id="language-selector-desktop"
            />
            <TextInput
              className="col-span-1 noErrorMargin "
              label={
                <label className="m_8fdc1311 mantine-InputWrapper-label mantine-Select-label" style={{marginRight: '4px'}}>
                  <Book size={12} style={{ marginRight: '8px' }}/>
                  {t("course")}
                </label>
              }
              placeholder={t("writeCourse")}
              description={t("lessonDescription")}
              required
              {...form.getInputProps("course")}
              id="course-input-desktop"
            />
            <TextInput 
              className="col-span-1 "
              label={
                <label className="m_8fdc1311 mantine-InputWrapper-label mantine-Select-label" style={{marginRight: '4px'}}>
                  <Ruler size={12} style={{ marginRight: '8px' }}/>
                  {t("length")}
                </label>
              }
              type="number"
              placeholder={t("writeLength")}
              description={t("lengthDescription")}
              required
              {...form.getInputProps("length")}
              id="length-input-desktop"
            />
          </div>
        </div>
      </div>

      {/* Main Content Area with Sidebar */}
      <div className="flex-1 flex overflow-hidden relative">
        {/* Sidebar - Hidden on mobile, stops at input area */}
        <div className="hidden md:block w-32 lg:w-60 border-r border-gray-200 p-4 space-y-4 absolute top-0 bottom-0 overflow-y-auto">
          <StyledButton 
            variant="default" 
            icon={<FileText />} 
            onClick={toggleForgerOutlineModal}
            fullWidth
            id="addOutline"
          >
            {t('addOutline')}
          </StyledButton>
          <StyledButton
            icon={<Brain />}
            id="promptChain"
            style={{
              background: form.values.promptChain ? '#6d9a54' : '',
              color: form.values.promptChain ? 'white': '',
            }}
            onClick={handleActivatePromptChain}
            fullWidth
          >
            {t("activatePromptChain")}
          </StyledButton>
          <FileButton
            className={`w-full `}
            onChange={handleSetFiles}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.*,.docx,.txt,.csv,.xls,.xlsx,.ppt,.pptx"
            multiple
            id="document-uploader"
          >
            {(props) => (
              <StyledButton {...props} icon={<Upload />} fullWidth variant="default">
                {t('uploadDocuments')}
              </StyledButton>
            )}
          </FileButton>
          <StyledButton 
            icon={<LightbulbIcon />}
            onClick={handleAddPromptClick}
            fullWidth
            id="addPrompt"
          >
            {t("addPrompt")}
          </StyledButton>
          <StyledButton
            icon={<Copy />}
            onClick={() => {
              const resultText = messages.length > 0 ? messages[messages.length - 1].text : '';
              if (resultText) {
                navigator.clipboard.writeText(resultText)
                  .then(() => {
                    addNotification({
                      type: 'success',
                      message: t('resultCopied'),
                    });
                  });
              }
            }}
            fullWidth
          >
            {t("copyResult")}
          </StyledButton>
          <StyledButton
            onClick={() => {
              setSelectedMode(null);
              dispatch(clearSelectedHistoryId());
            }}
            icon={<Wand2 size={16} />}
       
            fullWidth
          >
            {t("returnToModeSelection")}
          </StyledButton>
          <ConditionalFileAttachments 
            attachedFiles={attachedFiles}
            historyFiles={historyDetails?.files}
            labels={{
              attachedFiles: t('attachedFiles'),
              usedFiles: t('usedFiles')
            }}
            selectedHistoryId={selectedHistoryId}
            onDeleteFile={handleDeleteFile}
            brokenFiles={brokenFiles}
            t={t}
          />

        </div>

        {/* Main Content Area */}
        <div className="flex-1 flex flex-col md:ml-64">
          {/* ForgerTextArea - Stretches to fill available space */}
          <div className="flex-1 overflow-auto p-2 md:p-4">
            <ForgerTextArea 
              promptChain={form.values.promptChain}
              messages={messages} 
              loading={isLoading} 
              listLength={listLength} 
              queue={queue} 
              t={t}
            />
          </div>
        </div>
      </div>

      {/* Bottom Input Section - Full width, fixed at bottom */}
      <div className=" bg-white w-full">
      <div className="md:hidden bg-white px-2">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <StyledButton 
            variant="default" 
            icon={<FileText />}
            onClick={toggleForgerOutlineModal}
            fullWidth
          >
            {t('addOutline')}
          </StyledButton>
          <StyledButton
            icon={<Brain />}
            style={{
              background: form.values.promptChain ? '#6d9a54' : '',
              color: form.values.promptChain ? 'white': '',
            }}
            onClick={handleActivatePromptChain}
            fullWidth
          >
            {t("activatePromptChain")}
          </StyledButton>
          <FileButton
            className={`w-full `}
            onChange={handleSetFiles}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.*,.docx,.txt,.csv,.xls,.xlsx,.ppt,.pptx"
            multiple
           
          >
            {(props) => (
              <StyledButton {...props} icon={<Upload />} fullWidth variant="default">
                {t('uploadDocuments')}
              </StyledButton>
            )}
          </FileButton>
          <StyledButton
            icon={<Copy />}
            onClick={() => {
              const resultText = messages.length > 0 ? messages[messages.length - 1].text : '';
              if (resultText) {
                navigator.clipboard.writeText(resultText)
                  .then(() => {
                    addNotification({
                      type: 'success',
                      message: t('resultCopied'),
                    });
                  });
              }
            }}
            fullWidth
          >
            {t("copyResult")}
          </StyledButton>
        </div>
      </div>
        <div className="container mx-auto">
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:space-x-4">
            <div className="flex flex-row space-x-4 flex-1 pb-2">
              <TextInput
                disabled={isLoading}
                placeholder={t("entertopic")}
                {...form.getInputProps("topic")}
                className="flex-1"
                   id="question"
              />
              <div className="md:hidden">
                <StyledButton
                  variant="primary"
                  icon={<Send />}
                  type="submit"
                  disabled={isLoading || !form.values.topic || !form.values.course || !form.values.length}
                  className={`${isLoading ? 'opacity-30' : 'opacity-100'}`}
                   id="submit-mobile"
                >
                  {selectedHistoryId ? t('forgenextarticle') : t("forgearticle")}
                </StyledButton>
              </div>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <StyledButton
                variant="primary"
                id="submit"
                icon={<Send />}
                type="submit"
                disabled={isLoading || !form.values.topic || !form.values.course || !form.values.length}
                className={`${isLoading ? 'opacity-30' : 'opacity-100'}`}
              >
                {selectedHistoryId ? t('forgenextarticle') : t("forgearticle")}
              </StyledButton>
              <TokenCounter
                isError={notEnoughTokens}
                tokenCount={tokenCount}
                tokenBalance={tokenBalance}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Action Buttons */}


      {/* Modals */}
      <Modal opened={isForgerOutlineModalVisible} onClose={toggleForgerOutlineModal}>
        <ForgerOutlineCreator form={form} t={t} />
      </Modal>
      
      {/* Error Display */}
      <ErrorDisplay error={error} />

      {/* Add this Modal component with Framer Motion animations */}
      <AnimatePresence>
        {showPromptInput && (
          <motion.div 
            className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {/* Backdrop with blur */}
            <motion.div 
              className="absolute inset-0 bg-black/50 backdrop-blur-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleClosePromptInput}
            />
            
            {/* Modal Content */}
            <motion.div 
              className="relative bg-white rounded-xl shadow-xl w-full max-w-md"
              initial={{ scale: 0.9, y: 20, opacity: 0 }}
              animate={{ scale: 1, y: 0, opacity: 1 }}
              exit={{ scale: 0.9, y: 20, opacity: 0 }}
              transition={{ type: "spring", duration: 0.5 }}
            >
              <div className="p-6 space-y-6">
                {/* Header */}
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {t("customPrompt.title")}
                  </h3>
                  <span
                    onClick={handleClosePromptInput}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <X className="h-6 w-6" />
                  </span>
                </div>

                {/* Content */}
                <div className="space-y-4">
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("customPrompt.label")}
                      </label>
                      {/* Tailwind Tooltip */}
                      <div className="relative group">
                        <Info className="h-5 w-5 text-gray-400 cursor-help" />
                        <div className="absolute z-10 w-64 px-4 py-2 text-sm text-white bg-gray-900 rounded-lg 
                                      invisible opacity-0 group-hover:visible group-hover:opacity-100 
                                      transition-opacity duration-300 bottom-full right-0 mb-2">
                          {t("customPrompt.help")}
                          {/* Triangle */}
                          <div className="absolute bottom-0 right-4 w-3 h-3 -mb-1.5 
                                        transform rotate-45 bg-gray-900"></div>
                        </div>
                      </div>
                    </div>
                    <textarea
                      {...form.getInputProps('extraPrompt')}
                      className="w-full rounded-lg border border-gray-300 px-4 py-3 
                               focus:border-[#40286C] focus:ring-[#40286C] 
                               text-gray-900 text-sm min-h-[120px]
                               placeholder:text-gray-400"
                      placeholder={t("customPrompt.placeholder")}
                    />
                  </div>
                </div>

                {/* Footer */}
                <div className="flex justify-end space-x-3 pt-4">
                  <span
                    onClick={handleClosePromptInput}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white 
                             border border-gray-300 rounded-lg hover:bg-gray-50 
                             focus:outline-none focus:ring-2 focus:ring-offset-2 
                             focus:ring-[#40286C] transition-colors"
                  >
                    {t("common.cancel")}
                  </span>
                  <span
                    onClick={() => {
                      handleSavePrompt(form.values.extraPrompt);
                      handleClosePromptInput();
                    }}
                    className="px-4 py-2 text-sm font-medium text-white 
                             bg-[#40286C] rounded-lg hover:bg-[#40286C]/90 
                             focus:outline-none focus:ring-2 focus:ring-offset-2 
                             focus:ring-[#40286C] transition-colors"
                  >
                    {t("common.save")}
                  </span>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <SingleStepTutorial
        isVisible={showTutorial}
        onClose={() => {
          setShowTutorial(false);
          localStorage.setItem('tutorial_articleForger', 'true');
        }}
        toolType="articleForger"
        t={t}
        steps={tutorialSteps}
        currentStep={currentTutorialStep}
        onStepChange={handleTutorialStepChange}
      />
      
    </form>
  );
}

export default ArticleForger;
