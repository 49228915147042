import { Outlet } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationContext';
import CustomNotification from '../common/Notification';
import { useTranslation } from 'react-i18next';
import WhatsAppButton from '../common/WhatsAppButton';

const AuthLayout = ({ children }) => {
  const { notifications } = useNotifications();
  const { t } = useTranslation();

  // Define content using translations
  const content = {
    articleforger: {
      badge: t('landing.articleforger.badge'),
      title: t('landing.articleforger.title'),
      description: t('landing.articleforger.description'),
      buttonText: t('landing.articleforger.buttonText')
    },
    questionanswer: {
      badge: t('landing.questionanswer.badge'),
      title: t('landing.questionanswer.title'),
      description: t('landing.questionanswer.description'),
      buttonText: t('landing.questionanswer.buttonText')
    },
    humanizer: {
      badge: t('landing.humanizer.badge'),
      title: t('landing.humanizer.title'),
      description: t('landing.humanizer.description'),
      buttonText: t('landing.humanizer.buttonText')
    },
    virtualteacher: {
      badge: t('landing.virtualteacher.badge'),
      title: t('landing.virtualteacher.title'),
      description: t('landing.virtualteacher.description'),
      buttonText: t('landing.virtualteacher.buttonText')
    }
  };

  return (
    <div className="min-h-screen w-full flex " 
      style={{
        backgroundImage: 'url("https://static.easyegitim.com/bg8.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <div className="w-full flex items-center justify-center p-4 ">
         <div className="w-full max-w-[1200px] flex rounded-2xl overflow-hidden bg-white/10 backdrop-blur-md  " style={{boxShadow: 'rgb(255 255 255) 0px 0px 20px'}}>
          {/* Left Section */}
          <div className="w-full lg:w-1/2 p-4 flex items-center justify-center  bg-white p-8 shadow-lg">
            <div className="w-full max-w-[440px] sm:px-6">
              {children || <Outlet />}
            </div>
          </div>

          {/* Right Section */}
          <div className="hidden lg:flex w-1/2 p-8 items-center justify-center relative bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: 'url("https://static.easyegitim.com/auth2.jpg")',
            }}>
            <div className="absolute inset-0 bg-black/30 backdrop-blur-xs" />
            
            <div className="w-full max-w-lg grid grid-cols-2 gap-4 relative z-10">
              {Object.entries(content).map(([key, item]) => (
                <div key={key} className="bg-white/10 backdrop-blur-md rounded-xl p-4 shadow-lg border border-white/20 hover:bg-white/20 transition-all">
                  <span className={`text-[10px] font-semibold px-2 py-0.5 rounded-full 
                    ${key === 'articleforger' ? 'text-purple-200 bg-purple-500/20' :
                    key === 'questionanswer' ? 'text-blue-200 bg-blue-500/20' :
                    key === 'humanizer' ? 'text-green-200 bg-green-500/20' :
                    'text-orange-200 bg-orange-500/20'}`}>
                    {item.badge}
                  </span>
                  <h3 className="text-lg font-bold mt-2 text-white">{item.title}</h3>
                  <p className="text-xs text-white/80 mt-1 line-clamp-2">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="fixed top-5 right-5 z-50 flex flex-col items-end space-y-2">
        {notifications.map((notification) => (
          <CustomNotification 
            key={notification.id} 
            message={notification.message} 
            type={notification.type} 
          />
        ))}
      </div>

      <WhatsAppButton />
    </div>
  );
};

export default AuthLayout;