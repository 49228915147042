// src/redux/middleware/apiMiddleware.js
import { paraphraserApiSlice } from '../api/paraphraserApiSlice';
import { summarizerApiSlice } from '../api/summarizerApiSlice';
import { humanizerApiSlice } from '../api/humanizerApiSlice';
import { questionAnswerApiSlice } from '../api/questionAnswerApiSlice';
import { virtualTeacherApiSlice } from '../api/virtualTeacherApiSlice';
import { articleForgerApiSlice } from '../api/articleForgerApiSlice';
import { lessonApiSlice } from '../api/lessonApiSlice';
import { toolHistoriesApiSlice } from '../api/toolHistoriesApiSlice';
import { paymentApiSlice } from '../api/paymentApiSlice';
import { transactionApiSlice } from '../api/transactionApiSlice';
import { apiSlice } from '../api/authApiSlice';
// Import other API slices...

const apiMiddleware = [
  paraphraserApiSlice.middleware,
  summarizerApiSlice.middleware,
  questionAnswerApiSlice.middleware,
  articleForgerApiSlice.middleware,
  virtualTeacherApiSlice.middleware,
  toolHistoriesApiSlice.middleware,
  lessonApiSlice.middleware,
  paymentApiSlice.middleware,
  transactionApiSlice.middleware,
  humanizerApiSlice.middleware,

  apiSlice.middleware,
  // Add other API slice middleware here...
];

export default apiMiddleware;
