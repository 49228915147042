import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../redux/slices/authSlice';
import { useNotifications } from '../../context/NotificationContext';
import { useTranslation } from 'react-i18next';

const AppleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { t } = useTranslation();

  useEffect(() => {
    const handleAppleCallback = async () => {
      try {
        const params = new URLSearchParams(location.search);

        const accessToken = params.get('accessToken');
        const encodedUser = params.get('user');
        console.log("1")
        if (!accessToken || !encodedUser) {
          throw new Error(t('auth.missingAuthData', 'Authentication data is missing'));
        }

        const user = JSON.parse(decodeURIComponent(encodedUser));
        localStorage.setItem('accessToken', accessToken);

        // Save credentials to Redux store
        dispatch(setCredentials({
          user,
          accessToken: { token: accessToken }
        }));

        // Show success notification
        addNotification({
          id: Date.now(),
          message: t('auth.appleLoginSuccess', 'Successfully signed in with Apple'),
          type: 'success'
        });

        // Redirect to main application
        navigate('/tools/virtualTeacher');
      } catch (error) {
        console.error('Apple callback error:', error);
        addNotification({
          id: Date.now(),
          message: t('auth.appleLoginFailed', 'Apple sign-in could not be completed'),
          type: 'error'
        });
        navigate('/auth/login');
      }
    };

    handleAppleCallback();
  }, [location, navigate, dispatch, addNotification, t]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="text-center">
        <div className="mb-4">{t('auth.completingAppleSignIn', 'Completing Apple sign-in...')}</div>
        {/* You can add a loading animation here */}
      </div>
    </div>
  );
};

export default AppleCallback; 