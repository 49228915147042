import { useRef } from 'react';
import { ScrollArea, Tooltip } from '@mantine/core';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import ArticleGenerationLoading from './ArticleGenerationLoading';
import 'katex/dist/katex.min.css'; // for math rendering
import { fixFileName } from '../../utils/fileUtils';
import { FileIcon } from 'lucide-react';

// Add CSS for reference styling
const referenceStyles = `
  .reference-tag {
    font-weight: 700 !important;
    display: inline-block;
    padding: 2px 8px;
    margin: 0 4px;
    background-color: #533e7b1f;
    color: #533e7b;
    border-radius: 4px;
    cursor: pointer !important;
    transition: background-color 0.2s;
  }
  
  .reference-tag:hover {
    background-color: #533e7b33;
  }
  
  .references-line {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }
`;

/**
 * ForgerTextArea
 *
 * Renders messages in a scrollable area using ReactMarkdown,
 * replicating the "renderFinalStep" approach from ArticleForgerWizard.
 *
 * Props:
 *  - messages: Array of objects [{ sender: 'assistant'|'user', text: string }]
 *  - loading: boolean for loading state
 *  - listLength: total steps in the chain (if using promptChain)
 *  - queue: current step in the chain
 *  - placeholder: optional text to show if no messages
 */
// eslint-disable-next-line react/prop-types
function ForgerTextArea({ messages, loading, queue, listLength, placeholder, t, promptChain }) {
  const messagesEndRef = useRef(null);

  // Regular expression to match file references in the format {{ [n]: from filename.pdf }}
  const FILE_REFERENCE_REGEX = /\{\{\s*\[(\d+)\]:\s*from\s+(.*?)(?:\s+\}\}|\}\})/g;
  
  // Regular expression to match standalone reference numbers like [0], [1], etc.
  const REFERENCE_NUMBER_REGEX = /\[(\d+)\]/g;

  /**
   * Process text to extract file references and prepare for display
   */
  const processFootnotes = (text) => {
    if (!text) return { cleanedText: '', footnotes: {} };
    
    // Extract all file references and create a mapping
    const footnotes = {};
    let match;
    
    // Reset regex lastIndex to ensure we start from the beginning
    FILE_REFERENCE_REGEX.lastIndex = 0;
    
    // First, process the {{ [n]: from filename.pdf }} format
    while ((match = FILE_REFERENCE_REGEX.exec(text)) !== null) {
      const [, index, fileInfo] = match;
      if (!footnotes[index]) {
        // Apply decoding for Turkish characters
        footnotes[index] = fixFileName(fileInfo);
      }
    }
    
    // Replace all file references with [n] format
    let cleanedText = text.replace(FILE_REFERENCE_REGEX, (match, index) => `[${index}]`);
    
    // Also check for standalone [n] references that might already be in the text
    // This ensures we capture all references, even if they're not in the {{ }} format
    REFERENCE_NUMBER_REGEX.lastIndex = 0;
    while ((match = REFERENCE_NUMBER_REGEX.exec(cleanedText)) !== null) {
      const [, index] = match;
      // If we don't already have this reference and it's a number
      if (!footnotes[index] && !isNaN(parseInt(index))) {
        // Add a placeholder for standalone references
        footnotes[index] = `Reference ${parseInt(index) + 1}`;
      }
    }
    
    return { cleanedText, footnotes };
  };

  // Custom component to render reference numbers with tooltips
  // eslint-disable-next-line react/prop-types
  const ReferenceTag = ({ number, fileName }) => {
    return (
      <Tooltip
        label={
          <div className="p-1">
            <div className="font-semibold">{t('reference') || 'Reference'}:</div>
            <div className="flex items-center gap-1">
              <FileIcon className="w-4 h-4 text-gray-200" />
              <span>{fileName}</span>
            </div>
          </div>
        }
        position="top"
        withArrow
        multiline
        width={300}
        color="dark"
      >
        <span 
          className="reference-tag" 
          onClick={(e) => e.stopPropagation()}
          style={{ fontWeight: 700, color: '#533e7b' }}
        >
          [{number}]
        </span>
      </Tooltip>
    );
  };

  // Process paragraph text to replace reference numbers with interactive elements
  const processReferencesInParagraph = (children, footnotes) => {
    if (!footnotes || Object.keys(footnotes).length === 0) return children;
    
    // If children is not a string (could be a React element or array), return it as is
    if (typeof children !== 'string') return children;
    
    // Check if the paragraph ends with standalone references like [0] [1] [2]
    const endsWithReferences = children.match(/(\[\d+\]\s*)+$/);
    
    if (endsWithReferences) {
      // Split the text into content and references
      const contentPart = children.replace(/(\[\d+\]\s*)+$/, '');
      const referencesPart = endsWithReferences[0];
      
      // Extract all reference numbers
      const referenceNumbers = [];
      let refMatch;
      REFERENCE_NUMBER_REGEX.lastIndex = 0;
      
      while ((refMatch = REFERENCE_NUMBER_REGEX.exec(referencesPart)) !== null) {
        referenceNumbers.push(refMatch[1]);
      }
      
      // Create reference tags for each number
      const referenceTags = referenceNumbers.map((number, index) => (
        <ReferenceTag 
          key={`ref-${number}-${index}`} 
          number={number} 
          fileName={footnotes[number] || `Reference ${parseInt(number) + 1}`} 
        />
      ));
      
      // Return content followed by references in a styled div
      return (
        <>
          {contentPart}
          <div className="references-line">
            {referenceTags}
          </div>
        </>
      );
    }
    
    // For regular text with inline references, use the original approach
    const parts = children.split(/(\[\d+\])/g);
    
    if (parts.length <= 1) return children;
    
    return parts.map((part, index) => {
      // Reset regex lastIndex to ensure we start from the beginning
      REFERENCE_NUMBER_REGEX.lastIndex = 0;
      
      // Check if this part is a reference using the dedicated regex
      const match = REFERENCE_NUMBER_REGEX.exec(part);
      if (match && match[1] && footnotes[match[1]]) {
        return <ReferenceTag key={index} number={match[1]} fileName={footnotes[match[1]]} />;
      }
      return part;
    });
  };

  return (
    <ScrollArea
      className="relative w-full h-full"
      styles={{
        root: { height: '100%' },
        viewport: { height: '100%' },
      }}
    >
      {/* Add reference styles */}
      <style>{referenceStyles}</style>
      
      <div className="flex flex-col gap-6 p-4 h-full">
        {/* If no messages and not loading, show a placeholder */}
        {messages.length === 0 && !loading && (
          <div className="flex items-center justify-center text-gray-400 p-8" style={{ height: '100%' }}>
            <div className="flex flex-col items-center text-center">
              <svg
                className="w-16 h-16 mb-3 text-gray-300"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* Document base */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M8 7h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h4"
                />
                {/* Text lines */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M7 12h10M7 16h6"
                />
              </svg>
              <p className="text-lg">
                {placeholder || t('forgerTextArea.placeholder')}
              </p>
            </div>
          </div>
        )}

        {messages.map((message, index) => {
          const { cleanedText, footnotes } = processFootnotes(message.text);
          return (
            <div key={index} className="prose prose-base max-w-none text-gray-800">
              {/* We replicate the contentEditable style used in renderFinalStep */}
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                // The components object replicates what you had in renderFinalStep
                components={{
                  h1: ({ children }) => (
                    <h1 className="text-3xl font-bold mb-6">
                      {children}
                    </h1>
                  ),
                  h2: ({ children }) => (
                    <h2 className="text-2xl font-semibold mt-6 mb-4">
                      {children}
                    </h2>
                  ),
                  p: ({ children }) => (
                    <p className="mb-4 leading-relaxed">
                      {processReferencesInParagraph(children, footnotes)}
                    </p>
                  ),
                  strong: ({ children }) => (
                    <strong className="font-semibold">
                      {children}
                    </strong>
                  ),
                  em: ({ children }) => (
                    <em className="italic">
                      {children}
                    </em>
                  ),
                  li: ({ children }) => (
                    <li>
                      {processReferencesInParagraph(children, footnotes)}
                    </li>
                  ),
                  section: ({ children }) => (
                    <section>
                      {children}
                    </section>
                  ),
                  text: ({ children }) => {
                    return <>{children}</>;
                  },
                }}
              >
                {cleanedText}
              </ReactMarkdown>
            </div>
          );
        })}

        {/* Loading animation if in progress */}
        {loading && (
          <div className="flex items-center justify-center gap-2 pt-4">
               <ArticleGenerationLoading
              showProgress={promptChain}
              current={queue}
              total={listLength || 0}
            />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
    </ScrollArea>
  );
}

export default ForgerTextArea;
