import { Routes, Route } from 'react-router-dom';
import PaymentLayout from '../components/layout/PaymentLayout';
import SubscriptionPackages from '../pages/payment/SubscriptionPackages';
import PaymentSuccess from '../pages/payment/PaymentSuccess';
import PaymentFailure from '../pages/payment/PaymentFailure';
import Checkout from '../pages/payment/Checkout';
import Payment from '../pages/payment/Payment'; // Import the Payment component

export const PaymentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentLayout />}>
        <Route path="subscription" element={<SubscriptionPackages />} />
        <Route path="billingAddress" element={<Checkout />} />
        <Route path="payment" element={<Payment />} /> 
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route path="payment-failure" element={<PaymentFailure />} />

      </Route>
    </Routes>
  );
};
