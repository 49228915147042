import { useEffect } from 'react';
import { Box, Button, Text, TextInput, Select, Group, Title, Grid } from '@mantine/core';
import { DateInput } from '@mantine/dates'; // Adjust the import path as necessary
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useUpdateAccountInformationMutation } from '../../redux/api/authApiSlice'; // Adjust the path as necessary
import { useNotifications } from '../../context/NotificationContext'; // Adjust the import path as necessary  
import DOMPurify from 'dompurify'; // Ensure DOMPurify is installed and imported
import { useSelector } from 'react-redux';
import StyledButton from '../common/StyledButton';
const AccountInformation = () => {
    const { t } = useTranslation();
    const [updateAccountInformation, { isLoading }] = useUpdateAccountInformationMutation();
    const { addNotification } = useNotifications();
    const user = useSelector((state) => state.auth.user); // Fetch user from Redux state

    const form = useForm({
        initialValues: {
            name: '',
            surname: '',
            gender: '',
            birthDate: null,
        },
        validate: {
            name: (value) => {
                const sanitized = DOMPurify.sanitize(value).trim();
                if (sanitized.length < 2 || sanitized.length > 50) {
                    return t('validation.nameLength', { min: 2, max: 50 });
                }
                return null;
            },
            surname: (value) => {
                const sanitized = DOMPurify.sanitize(value).trim();
                if (sanitized.length < 2 || sanitized.length > 50) {
                    return t('validation.surnameLength', { min: 2, max: 50 });
                }
                return null;
            },
            gender: (value) => {
                return ['male', 'female', 'other'].includes(value) ? null : t('validation.gender');
            },
            birthDate: (value) => {
                return value && value instanceof Date && value.getTime() < Date.now() ? null : t('validation.birthDate');
            },
        }
    });

    useEffect(() => {
        if (user) {
            form.setValues({
                name: user.name || '',
                surname: user.surname || '',
                gender: user.gender || '',
                birthDate: user.birthDate ? new Date(user.birthDate) : null,
            });
        }
    }, [user]);

    const handleSave = async () => {
        const hasErrors = form.validate().hasErrors;
        if (!hasErrors) {
            try {
                const sanitizedValues = {
                    name: DOMPurify.sanitize(form.values.name),
                    surname: DOMPurify.sanitize(form.values.surname),
                    gender: form.values.gender, // Enum, no need for sanitization
                    birthDate: form.values.birthDate // Assuming this is handled correctly by the component
                };
                await updateAccountInformation(sanitizedValues).unwrap();
                addNotification({
                    type: 'success',
                    message: t('settings.form.notifications.accountUpdateSuccess'),
                });
            } catch (err) {
                addNotification({
                    type: 'error',
                    message: err.data?.message || t('settings.form.notifications.accountUpdateFail'),
                });
            }
        }
    };

    return (
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            {t('settings.form.accountInformation')}
          </h2>
          <form onSubmit={form.onSubmit((values) => console.log(values))} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.account.name')}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={t('settings.account.namePlaceholder')}
                  {...form.getInputProps('name')}
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.account.surname')}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={t('settings.account.surnamePlaceholder')}
                  {...form.getInputProps('surname')}
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.account.gender')}
                </label>
                <select
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  {...form.getInputProps('gender')}
                >
                  <option value="">{t('settings.account.genderPlaceholder')}</option>
                  <option value="male">{t('settings.account.male')}</option>
                  <option value="female">{t('settings.account.female')}</option>
                  <option value="other">{t('settings.account.other')}</option>
                </select>
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('settings.account.birthDate')}
                </label>
                <input
                  type="date"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  {...form.getInputProps('birthDate')}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
              className="w-full px-4 py-2 bg-[#40286C] text-white rounded-lg hover:bg-[#3e2866] transition-colors"
                type="submit"
              
              >
                {t('settings.account.saveChanges')}
              </button>
            </div>
          </form>
        </div>
      );
    };

export default AccountInformation;
