/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import {
  Grid,
  Group,
  Button,
  Stack,
  Textarea,
  NumberInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import TokenCounter from "../../utils/tokenCounter";
import Loading from "../../components/common/Loading"; // Adjust the import path as necessary
import Selector from "../../components/common/Selector"; // Adjust the import path as necessary
import Originalizer from "../../components/common/Originalizer";

import InputOutput from "../../components/common/InputOutput";
import BottomPopup from "../../components/common/BottomPopup"; // Adjust the import path as necessary
import Slider from "../../components/common/Slider"; // Adjust the import path as necessary
import ErrorDisplay from "../../components/common/Notification"; // Adjust the import path as needed

import { useNotifications } from '../../context/NotificationContext'; 
import { sanitizeInput } from "../../utils/sanitizeInput";
import { languageOptions, getAssistantOptions } from "../../utils/constants";
import { useParaphraserTextMutation } from '../../redux/api/paraphraserApiSlice';

import { useDispatch, useSelector } from 'react-redux';
import { appendToHistory } from '../../redux/slices/toolHistoriesSlice';
import { useFetchHistoryDetailsQuery } from '../../redux/api/toolHistoriesApiSlice';
import { setTokens } from '../../redux/slices/authSlice';
import { encode } from 'gpt-tokenizer'; // Ensure your tokenizer is ready

import { useMediaQuery } from '@mantine/hooks';
import * as Sentry from "@sentry/react";
import { Bot, LanguagesIcon, Ruler, Send, Sparkles } from 'lucide-react'
import StyledButton from "../../components/common/StyledButton";
import SingleStepTutorial from "../../components/common/Tutorial";
// eslint-disable-next-line react/prop-types
function ReadyPrompts({ handleReadyPromptClick, t }) {
  return (
    <Group>
       <StyledButton
   
      onClick={() => handleReadyPromptClick(t("paraphraser.readyPrompt1"))}
    >
      {t("paraphraser.placeholder.readyPrompt1")}
    </StyledButton>
    <StyledButton
   
      onClick={() => handleReadyPromptClick(t("paraphraser.readyPrompt2"))}
    >
      {t("paraphraser.placeholder.readyPrompt2")}
    </StyledButton>
    <StyledButton
   
      onClick={() => handleReadyPromptClick(t("paraphraser.readyPrompt3"))}
    >
      {t("paraphraser.placeholder.readyPrompt3")}
    </StyledButton>
    <StyledButton
   
      onClick={() => handleReadyPromptClick(t("paraphraser.readyPrompt4"))}
    >
      {t("paraphraser.placeholder.readyPrompt4")}
    </StyledButton>
    <StyledButton
   
      onClick={() => handleReadyPromptClick(t("paraphraser.readyPrompt5"))}
    >
      {t("paraphraser.placeholder.readyPrompt5")}
    </StyledButton>
    </Group>
  );
}

function ParaphraserTool() {
  const currentTool = useSelector(state => state.toolHistories.currentTool);
  const tokenBalance = useSelector((state) => state.auth.user.tokenBalance);
  const { addNotification } = useNotifications();
  const [paraphraseText, { isLoading, error }] = useParaphraserTextMutation();
  const dispatch = useDispatch();
  const selectedHistoryId = useSelector(state => state.toolHistories.selectedHistoryId);
  const { data: historyDetails, isError  } = useFetchHistoryDetailsQuery({
    serviceType: 'paraphraser',
    id: selectedHistoryId,
  }, {
    skip: !selectedHistoryId || currentTool !== 'paraphraser', // Skip the query if no ID or serviceType is selected
  });

  const { t } = useTranslation();
  const [showTutorial, setShowTutorial] = useState(false);
  
  const assistantOptions = getAssistantOptions(t);
  const [inputText, setInputText] = useState(""); // State for the input text
  const [outputText, setOutputText] = useState(""); // State for the output text


  const [tokenCount, setTokenCount] = useState(0); // Track the calculated token count
  //
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)
  const [isOriginalizerVisible, setIsOriginalizerVisible] = useState(false);
  const [originalizerProperties, setOriginalizerProperties] = useState({
    shealthMode: '',
    writingTone: '',
    intihalOrani: false,
    yapayZekaOrani: false,
    writingFeedback: false,
    intihalRaporu: false,
  });
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
const tutorialSeen = localStorage.getItem(`tutorial_paraphraser`);
  const handleTutorialStepChange = (step) => {
    setCurrentTutorialStep(step);
  };
  // for mobile popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);


  //end mobile popup

  // Apply these styles conditionally based on the screen width

  const form = useForm({
    initialValues: {
     
 selectedAssistant: assistantOptions[1].value, // Assuming you want to manage this via the form
      selectedLanguage: languageOptions[0].value, // Assuming you want to manage this via the form      length: 3,
      creativity: 3,
      length: 100,
      userPrompt: "", // Add userPrompt to the form's initial values
    },

    // Adding form validation rules
    validate: {
      userPrompt: (value) => {
        if (!value) return t("validation.userPrompt.required");
        
        if (value.length < 5) return t("validation.userPrompt.tooShort");
        if (value.length > 1000000) return t("validation.userPrompt.tooLong");
        // Add more custom validation as needed
        return null; // return null if the value passes validation
      },

      selectedAssistant: (value) =>
        value === null ? t("validation.selectedAssistant.required") : null,
      selectedLanguage: (value) =>
        value === null ? t("validation.selectedLanguage.required") : null,
        length: (value) => {
          if (value < 50 || value > 25000) return t("validation.length.range");
          return null;
        },
        creativity: (value) => {
          if (value < 0 || value > 5) return t("validation.creativity.range");
          return null;
        },
    },
  });

  useEffect(() => {
   

    if (historyDetails) {
      if (!selectedHistoryId) {
        form.reset();
        setOutputText('');
        setInputText('');
        return;
      }
      setOutputText(historyDetails.result);
      setInputText(historyDetails.userInput);
      form.setFieldValue("userPrompt", historyDetails.userPrompt);
      form.setFieldValue("selectedAssistant", historyDetails.assistantType);
      form.setFieldValue("selectedLanguage", historyDetails.language);
      form.setFieldValue("length", historyDetails.length);
      form.setFieldValue("creativity", historyDetails.creativity);
    }
   
    if (!tutorialSeen) {
      setShowTutorial(true);
    }
  }, [selectedHistoryId, historyDetails, isError, dispatch, t]);

    const handleSubmit = async (values) => {
      try {
        if (!inputText) {
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: t('validation.inputText.required'),
          });
          return;
        }
    
        if (inputText.length < 50) {
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: t('validation.inputText.tooShort'),
          });
          return;
        }
        if (inputText.length > 1000000) {
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: t('validation.inputText.tooLong'),
          });
          return;
        }
        if (tokenCount > tokenBalance) {
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: t('validation.tokenBalance.insufficient'),
          });
          setNotEnoughTokens(true);
          setTimeout(() => {
            setNotEnoughTokens(false);
          }
          , 3000)
          return;
        }
    
        const sanitizedInputText = DOMPurify.sanitize(inputText);
        const sanitizedValues = sanitizeInput(values);
    
        const paraphraseData = {
          userInput: sanitizedInputText,
          ...sanitizedValues,
        };
    
        const hasOriginalizerBeenUsed = Object.values(originalizerProperties).some(value => value !== false && value !== '');
    
        if (hasOriginalizerBeenUsed) {
          const sanitizedOriginalizerProperties = Object.keys(originalizerProperties).reduce((acc, key) => {
            acc[key] = typeof originalizerProperties[key] === 'string' ?
              DOMPurify.sanitize(originalizerProperties[key]) :
              originalizerProperties[key];
            return acc;
          }, {});
      
          paraphraseData.originalizerProperties = sanitizedOriginalizerProperties;
        }
    
        const result = await paraphraseText(paraphraseData).unwrap();
        dispatch(appendToHistory({ tool: 'paraphraser', entry: result.usageData }));
        setOutputText(result.result);
        dispatch(setTokens({ tokenBalance: result.tokenBalance }));
    
      } catch (err) {
        Sentry.captureException(err); // Capture the error for further analysis
        console.error(err);
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: t('paraphraser.error'), // Assuming you have a translation for general error handling
        });
      }
    };

  // prompt input functions end

 

  const handleReadyPromptClick = (prompt) => {
    form.setFieldValue("userPrompt", prompt);
  };
  const parapharserFormula = (normalTokenCount, selectedAssistant) => {
    let tokenFormula = 0;

    console.log(selectedAssistant, 'selectedAssistant', tokenFormula)
      if (selectedAssistant === assistantOptions[1].value) {
        tokenFormula = (4 * normalTokenCount) / 1000;
      } else if (selectedAssistant === assistantOptions[0].value) {
        tokenFormula = (4 * normalTokenCount) / 40;
      }
  

  
    // Round up the result and ensure at least 1 token is charged
    return Math.max(Math.ceil(tokenFormula), 1); 
  };

  const calculateTokens = () => {
    if (!inputText || inputText.trim() === "") {
      setTokenCount(0);
      return;
    }

    const normalTokenCount = encode(inputText).length;

    const newTokenCount =  parapharserFormula(
      normalTokenCount,
      form.values.selectedAssistant
    );
  
    setTokenCount(newTokenCount);
  };

  useEffect(() => {
    calculateTokens();
  }, [inputText, form.values.selectedAssistant]);

  const tutorialSteps = [
    {
      elementId: ['language-selector', 'length-selector', 'userprompt'],
      title: t('tutorial.paraphraser.allSteps.title'),
      content: t('tutorial.paraphraser.allSteps.content'),
      position: 'center'
    }
  ];
  return (
    <form
      onSubmit={form.onSubmit(
        (values) => handleSubmit(values),
        (errors) => console.log(errors)
      )}
      className="flex flex-col h-[calc(100vh-64px)]" // Adjust based on your header height
    >
      <div className="flex flex-col h-full gap-4">
        {/* Selector Section */}
        <div className="flex-none border-b border-gray-200 pb-4">
          {isLoading && <Loading />}
          <Originalizer 
            t={t} 
            onCancel={setIsOriginalizerVisible} 
            isOpen={isOriginalizerVisible} 
            formState={originalizerProperties} 
            setFormState={setOriginalizerProperties} 
          />
          <div className="flex flex-wrap gap-4">
            <div className="flex flex-wrap w-full gap-4 md:flex-row">
              {/* Selectors will be 2+1 on mobile, but stay in one row on desktop */}
           
              <Selector
                icon={<LanguagesIcon size={12}/>}
                label={t("language")}
                placeholder={t("Select")}
                data={languageOptions}
                defaultValue={languageOptions[0]}
                description={t('languageDescription')}
                required
                {...form.getInputProps("selectedLanguage")}
                id="language-selector"
                className="flex-1 min-w-[calc(50%-8px)] md:min-w-0"
              />
              
              <NumberInput
                label={
                  <div className="flex items-center gap-2">
                    <Ruler size={12}/>
                    <span>{t("length")}</span>
                  </div>
                }
                hideControls
                {...form.getInputProps("length")}
                description={t("lengthDescription")}
                id="length-selector"
                className="flex-1 min-w-full md:min-w-0"
              />
            </div>
          </div>
        </div>

        {/* Input/Output Section */}
        <div className="flex-1 min-h-0">
          <InputOutput
            inputText={inputText}
            setInputText={setInputText}
            outputText={outputText}
            loading={isLoading}
            getInputProps={form.getInputProps}
            fieldName="inputText"
            t={t}
          />
        </div>

        {/* Bottom Controls Section */}
        <div className="flex-none border-t border-gray-200 pt-4">
          {isMobile ? (
            <div className="flex flex-col gap-4">
              <Textarea
                disabled={isLoading}
                placeholder={t("userPrompt")}
                {...form.getInputProps("userPrompt")}
                minRows={1}
                maxRows={3}
                autosize
                styles={{
                  input: {
                    fontSize: '16px',
                  },
                }}
              />
              <div className="flex gap-4">
                <StyledButton
                  icon={<Send />}
                  variant="primary"
                  type="submit"
                  disabled={isLoading || !form.values.userPrompt || !inputText}
                  className="flex-1"
                >
                  {t("paraphraser.paraphrase")}
                </StyledButton>
                <TokenCounter 
                  isError={notEnoughTokens}
                  tokenCount={tokenCount}
                  tokenBalance={tokenBalance}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <ReadyPrompts handleReadyPromptClick={handleReadyPromptClick} t={t} />
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-8">
                  <Textarea
                    disabled={isLoading}
                    placeholder={t("userPrompt")}
                    {...form.getInputProps("userPrompt")}
                    minRows={1}
                    maxRows={3}
                    autosize
                    className="shadow-sm"
                    id="userprompt"
                  />
                </div>
                <div className="col-span-4">
                  <div className="flex gap-4">
                    <StyledButton
                      icon={<Send />}
                      variant="primary"
                      type="submit"
                      disabled={isLoading || !form.values.userPrompt || !inputText}
                      className="flex-1 "
                    >
                      {t("paraphraser.paraphrase")}
                    </StyledButton>
                    <TokenCounter 
                      isError={notEnoughTokens}
                      tokenCount={tokenCount}
                      tokenBalance={tokenBalance}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <SingleStepTutorial
        isVisible={showTutorial}
        onClose={() => setShowTutorial(false)}
        toolType="paraphraser"
        t={t}
        steps={tutorialSteps}
        currentStep={0}
        onStepChange={() => {}}
        showAllSteps={true}
      />
      
      <ErrorDisplay error={error} />
    </form>
  );
}

export default ParaphraserTool;
