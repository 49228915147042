// src/redux/api/humanizerApiSlice.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const humanizerApiSlice = createApi({
    reducerPath: 'humanizerApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Humanizer'],
    endpoints: (builder) => ({
        humanizerText: builder.mutation({
            query: (humanizerData) => ({
                url: '/results/humanizer',
                method: 'POST',
                body: humanizerData,
            }),
            invalidatesTags: ['Humanizer'],
        }),
        pollHumanizerResult: builder.mutation({
            query: ({documentId, humanizerModelId, usageDataId}) => ({
                url: '/results/poll-humanizer',
                method: 'POST',
                body: {  documentId, humanizerModelId, usageDataId },
            }),
            invalidatesTags: ['Humanizer'],
        }),
        detectAI: builder.mutation({
            query: (data) => ({
              url: '/results/detect-ai',
              method: 'POST',
              body: data,
            }),
          }),
    }),
});

export const { useHumanizerTextMutation, usePollHumanizerResultMutation, useDetectAIMutation, } = humanizerApiSlice;
