import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App.jsx'
import '@mantine/core/styles.css';
import '@mantine/core/styles/global.css';
import '@mantine/dates/styles.css';
import './styles/sass/_main.scss'
import * as Sentry from "@sentry/react";
import CustomErrorBoundary from './components/common/CustomErrorBoundary.jsx';


Sentry.init({
  dsn: "https://9c05ae43cf28ae99e88260bb3e8a7ea2@o4508207713157120.ingest.de.sentry.io/4508231276298320",
  integrations: [
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.1, // Higher sample rate in production
  tracePropagationTargets: [
    "easyegitim.com/app",
    /^https:\/\/easyegitim\.com\/app\/v1/,
  ],

  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ?/*  0.05  */ 0.5: 1.0, // Sample at 5% in production and 100% in development
  
  replaysOnErrorSampleRate: 1.0,
});

if (import.meta.env.MODE === 'production') {
  console.log = () => {}; // Override console.log in production
  console.error = () => {};
  console.debug = () => {};
}


ReactDOM.createRoot(document.getElementById('root')).render(
  <Sentry.ErrorBoundary fallback={<CustomErrorBoundary/>}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Sentry.ErrorBoundary>,
)
