import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import fileSearchAnimation from '../assets/forger/fileSearchLoading.json';
import thinkingAnimation from '../assets/forger/thinkingLoading.json';
import writingAnimation from '../assets/forger/writingLoading.json';

const loadingStates = [
  {
    animation: fileSearchAnimation,
    text: 'Kaynaklar araştırılıyor...',
    duration: 3000
  },
  {
    animation: thinkingAnimation,
    text: 'Makale planlanıyor...',
    duration: 3000
  },
  {
    animation: writingAnimation,
    text: 'Makale yazılıyor...',
    duration: 4000
  }
];

const ArticleGenerationLoading = ({ className = '', showProgress, current, total }) => {
  const [currentState, setCurrentState] = useState(0);

  useEffect(() => {
    if (!showProgress) {
      const timer = setInterval(() => {
        setCurrentState((prev) => (prev + 1) % loadingStates.length);
      }, loadingStates[currentState].duration);

      return () => clearInterval(timer);
    }
  }, [currentState, showProgress]);

  return (
    <div className={`flex flex-col items-center justify-center space-y-4 ${className}`}>
      <div className="w-40 h-40">
        <Lottie 
          animationData={loadingStates[currentState].animation}
          loop={true}
          autoplay={true}
        />
      </div>
      {showProgress ? (
        <p className="text-gray-700 text-lg font-medium">
          Bölüm {current}/{total} oluşturuluyor...
        </p>
      ) : (
        <p className="text-gray-700 text-lg font-medium">
          {loadingStates[currentState].text}
        </p>
      )}
      <p className="text-gray-500 text-sm text-center max-w-md mt-4">
        Makalenin uzunluğuna ve karmaşıklığına bağlı olarak işlem 3-5 dakika sürebilir, 
        lütfen pencereyi kapatmayın.
      </p>
    </div>
  );
};

export default ArticleGenerationLoading; 