
function SatisSozlesmesi() {
  return (
    <div>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Mesafeli Satış Sözleşmesi</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>1. Sözleşmenin Tarafları</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>1.1 Etiler Mah. Tuğcular Sk. No:15 D:9 34337 Beşiktaş, İstanbul adresine mukim Easy Eğitim ve Teknoloji İşletmeleri (“Easy”) ile diğer tarafta tüketici (“Kullanıcı”) arasında, Kullanıcı’nin Sözleşme’yi okuduğu ve onay verdiğini dijital ortamda kabulü ile yürürlüğe girmiştir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>1.2. Üye, Sözleşme hükümlerinin tamamını okuduğunu, anladığını, onayladığını ve easyegitim.com adresinde yer alan “Gizlilik ve Kişisel Verileri Korunması Politikası” metninin işbu Sözleşme’nin ayrılmaz bir parçası ve eki olduğunu kabul, beyan ve taahhüt eder.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>2. Sözleşmenin Konusu</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>2.1 Bu sözleşme Easy ile sözleşmeyi okuyup “Kayıt Ol” butonuna basarak kabul eden Easy hizmetinden yararlanmak isteyen kişi arasında aşağıda detayları açıklanan Online Eğitim Hizmeti için imzalanmıştır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>2.2 Kullanıcı bu hizmet sözleşmesini okuyup kabul ettikten sonra bu hizmetten yararlanabilir ve sadece bu anlaşmada geçen maddelerde belirtilmiş haklara sahiptir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>2.3 Bu hizmeti ilk defa kullanmadan önce Kullanım Sözleşmesinin tamamını dikkatle okuyunuz. Kullanıcı bu hizmeti ilk kullanımından veya çalıştırmasından itibaren burada geçen anlaşma koşullarından haberdar kabul edilir ve bu metni okuduğu, anladığı ve buradaki koşullarla bağlı olduğunu kabul etmiş sayılır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>2.4 İş bu sözleşmeyi kabul etmekle birlikte kullanıcı fiil ehliyetine sahip olduğunu kabul eder. Kullanıcı 18 yaşından küçük olması halinde Easy Uygulamasından velisinin onayı ve vasıtasıyla faydalanabileceğini kabul eder.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>2.5 Kullanıcı bu hükümleri kabul etmemesi halinde hizmetten yararlanamaz, özel olarak hazırlanmış videoları izleyemez, görselleri göremez.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3. Kullanıcı Hak ve Yükümlülükleri</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.1 Bu hizmetle ilgili tüm fikri ve sınai haklar Easy’e ait olup, eserin izinsiz olarak kullanılması, çoğaltılması, yayılması, satışa sunulması ve benzeri durumlarda ilgili kişi veya kişiler hakkında hukuki ve cezai yaptırımlara başvurulacaktır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.2 easy.vedubox.net/ internet sitesi içindeki sunumların, yazılımların, grafik kullanıcı arabirimlerinin, görsellerin, seslerin ve videoların kullanıcı tarafından aşağıda belirtilen hükümlere uygun olarak kullanılmasını şart koşar.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.3 Kullanıcı münhasıran eserin kullanma hakkına sahiptir. "Kullanım Hakkı", ürünün kullanılmasını, saklanmasını, yüklenmesini, çalıştırılmasını ve görüntülenmesini kullanıcının paketi satın aldığı süre boyunca içermektedir. Kullanım hakkı tanınan kullanıcı eserde değişiklik yapamaz, eseri çoğaltamaz, eseri yayamaz, başkalarının kullanımına sunamaz, eserden yararlanarak yeni bir eser oluşturamaz, eserin lisans ya da kontrol özelliklerinden herhangi birini devre dışı bırakamaz.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.4 Bu ürün ister bireysel, ister kurumsal olarak satın alınmış olunsun, Easy tarafından tek bir gerçek kişi üzerine kaydedilir ve sadece o kişi tarafından belirtilen süre içinde kullanılabilir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.5 Ürün hakkında yeterli bilgiye ve sistem gereksinimlerine sahip olduğuna inanan kullanıcı, telefon numarası, e-posta adresi, adı ve soyadı, üniversite ve bölüm bilgileri ile easyegitim.com internet sitesinden hesabını oluşturur. Telefon numarası veya e-posta, ve telefon numarasına SMS olarak gönderilen giriş kodu ile hesabına giriş yapar.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.6 Üye, sözleşme kapsamında kendisine daha önce başka üyelerce seçilmemiş, Easy tarafından belirlenen uzantılara sahip e-posta adresi ve telefon numarası belirler. E-posta ve telefon numarası seçimi, SMS ile gönderilen giriş kodlarının korunması tamamıyla üyenin sorumluluğundadır. SMS kodu alamayan üye giriş ekranında belirlenen süre geçtikten sonra yeni bir giriş kodu gönderilmesini isteyebilir. Telefon numarası seçimi ve SMS giriş kodlarının korunması tamamıyla kullanıcının sorumluluğundadır. Easy, kullanıcı tarafından SMS giriş kodlarının yanlış kullanımından, gönderici veya alıcı telekom şirketlerinin SMS giriş kodunu üyeye iletememesinden doğacak problemlerden kesinlikle sorumlu değildir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.7 Kullanıcının üyeliği gerektiren servislere bağlanabilmesi için telefon numarasını veya e-posta adresini ve telefon numarasına gönderilecek SMS giriş kodunu girmesi gereklidir. Bu işlem üye girişi yapmak şeklinde tanımlanır. Üyenin faydalanacağı uygulamalar, bedelini ödediği paketlere bağlı olarak giriş yaptıktan sonra üyeye gösterilecektir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.8 Kullanıcı olma kişiseldir. Başkasına devredilemez ve kullandırılamaz.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.9 Kullanıcı, Easy Eğitim Platformu ve easy.vedubox.net hizmetlerinden yararlandığı sırada, üyelik formunda yer alan bilgilerin doğru olduğunu ve bu bilgilerin gerekli olduğu (telefonuna SMS kodu için erişememe gibi) durumlarda bilginin hatalı veya eksik olmasından doğacak zararlardan dolayı sorumluluğun kendisine ait olduğunu, bu hallerde üyeliğinin sona erebileceğini kabul eder. Kullanıcı üyeliğin devamı sürecinde Easy Uygulamaları tarafından istenilen gerekli bilgileri eksiksiz ve doğru olarak vermekle yükümlüdür.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.10 Kullanıcı bilgilerindeki üniversite, adres, telefon vb. gibi her türlü değişikliklerde Easy’e bilgi vermek zorundadır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>3.11 Sisteme giriş yapan kullanıcı satın almak istediği hizmeti seçer, yönergeleri takip ederek ödeme işlemini gerçekleştirir ve işletmetarafından kullanıma sunulmuş olan içeriklere erişim hakkını satın alır. Kullanıcı almış olduğu hizmeti sadece kendi kullanabilir. Bu hizmeti başkalarının kullanımına açamaz, kiraya veremez.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>4. Telif Hakları ve Kullanım Kısıtlamaları</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>4.1 Sunumlar, görseller, metinler, videolar, ses kayıtları ve konuşmaların bütün telif hakları Easy’e aittir. Kullanıcı; sunumları, görselleri, metinleri, videoları ses kayıtlarını ya da konuşmaları Easy’den izin almadan kullanamaz.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>4.2 Özel olarak hazırlanmış olan videoların veya sağlanan materyallerin toplu halde izinsiz gösterimleri veya kullanımı olduğu takdirde gösterimi yapan organizasyon, şirket, kişi veya kurum; faaliyete katılan her bir kişi için gerekli olan kullanım bedelini Easy’e ödemekle yükümlüdür. Söz konusu gösterim için şifre bilgilerini veren ya da paylaşan kullanıcı doğacak olan hukuki ve cezai müeyyidelerden sorumludur.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>4.3 Özel olarak hazırlanmış olan videoları veya içinden alınmış görsel malzemeyi, sunumları, sesleri ve ilgili olabilecek her türlü medyayı aynı veya alıntılanmış şekilde herhangi bir medya şeklinde çoğaltmak, kopyalamak, yayınlamak ya da dağıtmak kesinlikle yasaktır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>4.4 Kullanıcı Easy Eğitim platformuna ve easy.vedubox.net internet sitesine ilişkin hiçbir yazılım veya içeriği kopyalayamaz, uyarlayamaz, değiştiremez.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>5. Sözleşme Bedeli ve Ödeme</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>5.1 Sözleşme bedeli tüm vergiler dahil, easy.vedubox.net internet sitesinde yazılı olarak belirtilen paket ücretidir. İnternet sayfasında gösterilen şekilde gerekli ödeme işleminin tam olarak yapılması şarttır. Bu konuda kullanıcı tarafından yapılabilecek hatalar nedeni ile üyelikte ve ödemede çıkabilecek aksaklıklardan Easy sorumlu değildir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>5.2 İnternet üzerinden kredi kartı ile yapılan ödemelerin bedelleri üyenin kredi kartından otomatik olarak çekilir. Ödeme işlemi onaylandıktan sonra satın alınan hizmet paketleri kullanıma açılır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>5.3 Satın alınan paketin kullanım süresi&nbsp;</span><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>4 (dört) aydır.&nbsp;</span><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Dört ay içerisinde kullanılmayan haklar geçerliliğini yitirir, kullanılamaz ve gelecek seneye devredilemez.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>5.4 Easy paket ücretlerinde indirim veya artırım uygulamasını döneme göre değiştirme hakkına sahiptir.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>5.5 Kullanıcı üyelerin satın aldıkları ürünün satış, teslim ve iadesi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir. Easy tarafından easy.vedubox.net internet sitesinde satılan tüm ürünler Mesafeli Sözleşmeler Yönetmeliği’nin 15 maddesinin “ğ” bendinde sayılan Cayma Hakkının Kullanılamadığı Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallardan olduğu için hiçbir şekilde iade söz konusu olamaz.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>6. Özel Hükümler</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>6.1 Kullanıcı verilerinin, Easy’nin ihmali görülmeden yetkisiz kişilerce okunması nedeni ile meydana gelebilecek zararlardan ötürü Easy’nin sorumlu olmayacağını kabul eder.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>6.2 Kullanıcı, kurallara aykırı davrandığı takdirde Easy gerekli müdahalelerde bulunma, üyeyi servis dışına çıkarma ve üyeliğe son verme hakkına sahip olduğunu kabul eder.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>6.3 Kullanıcı, hizmetin kullanımı ile ilgili internetten kaynaklanan sorunlardan Easy’i sorumlu tutmayacağını kabul eder.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>6.4 Kullanıcı site geneline zarar verecek veya Easy’i başka sitelerle davalık duruma getirecek zarar verici iş ve eylemlerde bulunmayacağını taahhüt eder. Bulunulduğu takdirde bundan doğacak zararlardan kullanıcı sorumlu olacaktır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>6.5 Özel olarak hazırlanmış videolar ile easy.vedubox.net internet sitesi üzerinden gönderilen materyaller, orjinal sanat eserleri ve tasarım ürünlerinin tüm fikri hakları eser ve tasarım sahiplerinin olup 5846 sayılı kanuna göre koruma altındadır. easy.vedubox.net internet sitesi ve içerisindeki her türlü yazılı ve görsel (video, fotoğraf, vs) içeriğin bilgisayara indirilmesi (download edilmesi), kopyalanması, çoğaltılması, bir web sitesinde veya diğer herhangi bir mecrada yayınlanması, bir web sitesinin içinde frame, i-frame ve benzeri &nbsp;şekillerde gösterimi, izin alınmadan link verilmesi yasaktır. Aksine davranışlar Fikir ve Sanat Eserleri Kanunu uyarınca suç teşkil edecek olup, bu tip davranışlarda bulunan kullanıcıların, easy.vedubox.net &nbsp;internet sitesi sahibi Easy Eğitim ve Teknoloji İşletmeleri cezai şart olarak her bir ihlal için ödenecek miktara ilişkin haklarımız saklı kalmak kaydıyla belirlenen cezayı ödemeyi, bu cezai şartın fahiş olmadığını, ihlalin ve kusurun ağırlığına göre Easy’nin ceza miktarını artırabileceğini ve mahkemelerden tenkisini talep etmeyeceğini kabul, beyan ve taahhüt eder.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>7. Uygulanacak Hükümler ve Yetkili Merci</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>7.1 Bu sözleşmeyle ilgili olarak çıkabilecek ihtilaflarda öncelikle bu sözleşmedeki hükümler ve bu sözleşmede hüküm bulunmayan hallerde ise Türk Kanunları uygulanacaktır.</span></p>
    <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '0pt', marginBottom: '11pt'}}><span style={{fontSize: '9pt', fontFamily: 'Arial, sans-serif', color: 'rgb(0, 0, 0)', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontVariantAlternates: 'normal', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontVariantPosition: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>7.2 İşbu sözleşmenin uygulanmasından doğabilecek her türlü uyuşmazlıkların çözümünde Beşiktaş mahkeme ve icra müdürlüklerinin yetkili olduğu kararlaştırılmıştır.</span></p>
    <p><br /></p>
    <p><br /></p>
    <p><br /></p>
  </div>
  )
}

export default SatisSozlesmesi