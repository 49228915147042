
import { Routes, Route } from 'react-router-dom';
import BaseLayout from '../components/layout/BaseLayout'; // Adjust the import path as needed
import Settings from '../pages/user/settings';
import Billing from '../pages/user/billing';
import Transactions from '../pages/user/transactions'
/* import ForgerPage from '../pages/tools/ForgerPage';
import TeacherPage from '../pages/tools/TeacherPage'; */
// Import other tool pages as needed

export const SettingsRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<BaseLayout />}>
        <Route path="general" element={<Settings />} />
        <Route path="billing" element={<Billing />} />
        <Route path="transactions" element={<Transactions />} />
      </Route>
    </Routes>
  );
};