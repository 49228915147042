import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useInitiatePaymentMutation } from '../../redux/api/paymentApiSlice';
import { Container, Loader } from '@mantine/core';
import { useNotifications } from '../../context/NotificationContext';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const { selectedPackage, paymentPeriod, currency, selectedAddressId } = location.state || {}; 
  const [initiatePayment, { isLoading: isApiLoading }] = useInitiatePaymentMutation();
  const { addNotification } = useNotifications();
  const [isFrameLoading, setIsFrameLoading] = useState(true);
  const hasInitiated = useRef(false);

let ct = 0
  useEffect(() => {
    
    const initiatePaymentProcess = async () => {
      console.log(ct)
      try {
        if (!selectedPackage || !paymentPeriod) {
          throw new Error('Missing package details');
        }
        if (hasInitiated.current) return;
        hasInitiated.current = true;
  
        const response = await initiatePayment({
          packageId: selectedPackage.id,
          paymentPeriod: paymentPeriod === 'coins' ? 'one-time' : paymentPeriod,
          currency: "TRY",
        }).unwrap();
        ct++
        
        console.log(response,ct);
        
      if (response.paymentUrl) {
          // Use the direct payment URL approach with an iframe
          const checkoutForm = document.getElementById('iyzipay-checkout-form');
          if (checkoutForm) {
            checkoutForm.innerHTML = '';
            
            const iframe = document.createElement('iframe');
            iframe.src = response.paymentUrl;
       
// Style it so it's centered, has no border, and hides overflow
iframe.style.display = 'block';
iframe.style.margin = '0 auto';
iframe.style.border = 'none';
iframe.style.width = '100%';
iframe.style.maxWidth = '600px';   // You can adjust the max width
iframe.style.height = '600px';     // Adjust the height as needed
iframe.style.overflow = 'hidden';  // Prevent internal scroll bars
            iframe.onload = () => {
              setIsFrameLoading(false);
            };
            
            checkoutForm.appendChild(iframe);
            
            // Fallback if onload doesn't trigger
            setTimeout(() => setIsFrameLoading(false), 5000);
          }
        }  else {
          throw new Error('Invalid payment response');
        }
      } catch (error) {
        console.log(error);
        addNotification({
          id: new Date().getTime(),
          type: 'error',
          message: error.data?.message || 'Payment initiation failed.',
        });
        navigate('/payments/payment-failure');
      }
    };

    initiatePaymentProcess();
    
    // Cleanu
    return () => {
      const existingScripts = document.querySelectorAll('script[data-iyzico]');
      existingScripts.forEach(script => script.remove());
    };
  }, []);

  // Show loader until both API call is complete AND iframe is loaded
  const isLoading = isApiLoading || isFrameLoading;

  return (
    <div>
      {isLoading && (
        <div style={{display: 'flex', justifyContent: 'center', padding: '50px'}}>
          <Loader size="xl" />
        </div>
      )}
      <div 
        id="iyzipay-checkout-form" 
        className="responsive"
        style={{ 
          display: isLoading ? 'none' : 'block',
      
        }} 
      />
    </div>
  );
};

export default Payment; 