/* eslint-disable react/prop-types */
import { Select } from '@mantine/core';

const Selector = ({ label, description, placeholder, value, onChange, data, icon, ...others }) => {
  return (
    <Select
    label={
      icon ? (
        <div className="flex items-center gap-2 mr-1">
          {icon}
          {label}
        </div>
      ) : label
    }
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      data={data}
      description={description}
      {...others}
    />
  );
};

export default Selector;
