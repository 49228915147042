import React, { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronRight, ChevronLeft, HelpCircle, Sparkles } from 'lucide-react';

const Tutorial = ({ isVisible, onClose, toolType, t, steps = [], currentStep, onStepChange }) => {
  const [elements, setElements] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [mobileOpened, setMobileOpened] = useState(false);
  const [activeElementIndex, setActiveElementIndex] = useState(0);
  const [currentElement, setCurrentElement] = useState(null);
  const [showSidebarTutorial, setShowSidebarTutorial] = useState(
    !localStorage.getItem('tutorial_sidebar')
  );
  console.log(toolType)

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Update current element when step changes
  useEffect(() => {
    if (elements.length > 0 && currentStep >= 0 && currentStep < elements.length) {
      setCurrentElement(elements[currentStep]);
    }
  }, [currentStep, elements]);

  const scrollToElements = useCallback((elements) => {
    if (!elements || elements.length === 0) return;
    
    // Find the center point of all elements
    const centerElement = elements[Math.floor(elements.length / 2)];
    if (!centerElement.element) return;
    
    const scrollOptions = {
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    };

    centerElement.element.scrollIntoView(scrollOptions);
  }, []);

  useEffect(() => {
    if (isVisible && steps.length > 0) {
      let foundElements;
   
      if (toolType === 'humanizer') {
        // Special grouping for humanizer tutorial
        const groupedSteps = [
          // Step 1 - Introduction
          steps[0],
          // Step 2 - Group selectors (readability, purpose, strength)
          {
            ...steps[1],
            title: t('tutorial.humanizer.selectorsTitle'),
            content: t('tutorial.humanizer.selectorsContent'),
            elementId: ['readability-selector', 'purpose-selector', 'strength-selector']
          },
          // Step 3 - Group submit and token counter
          {
            ...steps[4],
            title: t('tutorial.humanizer.actionsTitle'),
            content: t('tutorial.humanizer.actionsContent'),
            elementId: ['submitHumanize', 'tokenCounter'],
              position: 'center'
          }
        ];

        foundElements = groupedSteps
          .map(step => ({
            ...step,
            elements: Array.isArray(step.elementId) 
              ? step.elementId.map(id => ({
                  element: document.getElementById(id),
                  rect: document.getElementById(id)?.getBoundingClientRect()
                })).filter(el => el.element)
              : step.elementId 
                ? [{
                    element: document.getElementById(step.elementId),
                    rect: document.getElementById(step.elementId)?.getBoundingClientRect()
                  }].filter(el => el.element)
                : []
          }))
          .filter(item => !item.elementId || item.elements.length > 0);
      } else {
        // Original behavior for other tools
        foundElements = steps
          .map(step => ({
            ...step,
            elements: Array.isArray(step.elementId) 
              ? step.elementId.map(id => ({
                  element: document.getElementById(id),
                  rect: document.getElementById(id)?.getBoundingClientRect()
                })).filter(el => el.element)
              : step.elementId 
                ? [{
                    element: document.getElementById(step.elementId),
                    rect: document.getElementById(step.elementId)?.getBoundingClientRect()
                  }].filter(el => el.element)
                : []
          }))
          .filter(item => !item.elementId || item.elements.length > 0);
      }
      
      setElements(foundElements);

      if (foundElements.length > 0 && foundElements[0].elements?.length > 0) {
        scrollToElements(foundElements[0].elements);
      }
    }
  }, [isVisible, steps, scrollToElements, toolType, t]);

  useEffect(() => {
    if (currentStep < elements.length && elements[currentStep]?.elements) {
      scrollToElements(elements[currentStep].elements);
    }
  }, [currentStep, elements, scrollToElements]);

  const handleNext = () => {
    if (currentStep < elements.length - 1) {
      onStepChange(currentStep + 1);
    } else {
      handleClose();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      onStepChange(currentStep - 1);
    }
  };

  const handleClose = () => {
   /*  if (isMobile) {
      const burgerButton = document.getElementById("messages-tab");
      if (burgerButton) {
        burgerButton.click();
      }
    } */
    
    if (toolType === 'sidebar') {
      onClose();
      setShowSidebarTutorial(false);
      localStorage.setItem('tutorial_sidebar', 'true');
      // After sidebar tutorial, show virtualTeacher tutorial
      return;
    }
    
    localStorage.setItem(`tutorial_${toolType}`, 'true');
    onClose();
  };
  
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'ArrowRight') handleNext();
    if (e.key === 'ArrowLeft') handlePrevious();
    if (e.key === 'Escape') handleClose();
  }, [handleNext, handlePrevious, handleClose]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  const getTutorialPosition = useCallback((currentElement) => {
    if (!currentElement || !currentElement.elements?.[0]?.rect) {
      return {
        position: 'fixed',
        left:  isMobile ? '10%' : '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '300px' : '500px',
        width: '90%',
        zIndex: 1100
      };
    }

    const elementRect = currentElement.elements[0].rect;
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    // Mobile positioning
    if (isMobile) {
      // Special case for sidebar tutorial
      if (toolType === 'sidebar') {
        return {
          position: 'fixed',
          right: '20%',
          top: '50%',
          transform: 'translateY(-50%)',
          maxWidth: '300px',
          width: 'calc(100% - 40px)', // 20px padding on each side
          zIndex: 1100
        };
      }
      
      // Center positioning for all other tutorials on mobile
      return {
        position: 'fixed',
        left: '10%',
        top: '30%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '300px',
        width: 'calc(100% - 32px)', // 16px padding on each side
        margin: '0 16px',
        zIndex: 1100
      };
    }

    // Desktop positioning remains the same
    const position = currentElement.position || 'bottom';
    const baseStyles = {
      position: 'fixed',
      maxWidth: '500px',
      width: '90%',
      zIndex: 1100
    };

    switch (position) {
      case 'left':
        return {
          ...baseStyles,
          right: viewportWidth - elementRect.left + 20,
          top: elementRect.top + (elementRect.height / 2),
          transform: 'translateY(-50%)'
        };

      case 'right':
        return {
          ...baseStyles,
          left: elementRect.right + 20,
          top: elementRect.top + (elementRect.height / 2),
          transform: 'translateY(-50%)'
        };

      case 'top':
        return {
          ...baseStyles,
          left: elementRect.left + (elementRect.width / 2),
          bottom: viewportHeight - elementRect.top + 20,
          transform: 'translateX(-50%)'
        };

      case 'bottom':
        return {
          ...baseStyles,
          left: elementRect.left + (elementRect.width / 2),
          top: elementRect.bottom + 20,
          transform: 'translateX(-50%)'
        };

      case 'center':
        return {
          ...baseStyles,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        };

      default:
        return {
          ...baseStyles,
          left: '50%',
          transform: 'translateX(-50%)',
          ...(elementRect.top < viewportHeight / 2
            ? { top: elementRect.bottom + 20 }
            : { bottom: viewportHeight - elementRect.top + 20 }
          )
        };
    }
  }, [isMobile, toolType]);

  // Update the effect that handles tutorial step actions
  useEffect(() => {
    console.log(currentStep, toolType)
    if (toolType === "sidebar" && isMobile) {
        if (currentStep === 0) {
            const burgerButton =document.getElementsByClassName("mantine-Burger-burger")[0];
    
      if (burgerButton) {
        burgerButton.click();
      }
        }
       
    }
    if (toolType === 'virtualTeacher' && isMobile) {
      if (currentStep === 0) {
        const closeSidebar = document.getElementById("close-sidebar");
        if (closeSidebar) {
            closeSidebar.click();
        }
    }
    }
    if (toolType === 'questionAnswer') {
      if (currentStep === 1) {
        document.getElementById('course-selector').click();
      }
      if (currentStep === 2) {
        document.getElementsByClassName("mantine-Modal-header")[0]?.children[0]?.click()
      }
      if (currentStep === 3 && !isMobile) {
        document.getElementById('lesson-creator').click();
      }
      if (currentStep === 4 && !isMobile) {
        document.getElementsByClassName("mantine-Modal-header")?.[0]?.children?.[0]?.click()
      }
    }
    if (toolType === 'articleForger') {
      console.log(currentStep)
      if (currentStep === 2) {
        // Click the addOutline button and wait for modal to open
        document.getElementById('addOutline')?.click();
        
        // Wait for modal elements to load before updating elements state
        setTimeout(() => {
          if (isVisible && steps.length > 0) {
            const foundElements = steps
              .map(step => ({
                ...step,
                elements: Array.isArray(step.elementId) 
                  ? step.elementId.map(id => ({
                      element: document.getElementById(id),
                      rect: document.getElementById(id)?.getBoundingClientRect()
                    })).filter(el => el.element)
                  : step.elementId 
                    ? [{
                        element: document.getElementById(step.elementId),
                        rect: document.getElementById(step.elementId)?.getBoundingClientRect()
                      }].filter(el => el.element)
                    : []
              }))
              .filter(item => !item.elementId || item.elements.length > 0);
            
            setElements(foundElements);
            if (foundElements.length > 0 && foundElements[currentStep]?.elements?.length > 0) {
              scrollToElements(foundElements[currentStep].elements);
            }
          }
        }, 500); // Wait 1 second for modal elements to load
      }
      if (currentStep === 3) {
        const closeButton = document.querySelector('.mantine-Modal-close');
        closeButton?.click();
      }
    }
  /*   if (isMobile && toolType === 'humanizer' && currentStep === 2) {
      const burgerButton =document.getElementsByClassName("mantine-Burger-burger")[0];
      const messagesTab = document.getElementById("messages-tab");
      if (burgerButton && messagesTab) {
        burgerButton.click();
        messagesTab.click();
        setTimeout(() => {
          const featuresTab = document.getElementById("features-tab");
          if (featuresTab) {
            featuresTab.click();
            document.getElementById("close-sidebar").click();
          }
        }, 2000);
      }
    } */
  }, [currentStep, isMobile, steps.length, toolType, isVisible, scrollToElements]);

  // Move the cycling effect after currentElement is defined
  useEffect(() => {
    if (!currentElement?.elements || currentElement.elements.length <= 1) return;
    
    const interval = setInterval(() => {
      setActiveElementIndex(prev => 
        prev >= currentElement.elements.length - 1 ? 0 : prev + 1
      );
    }, 2000);
    
    return () => clearInterval(interval);
  }, [currentElement]);

  // Add effect to handle tutorial sequence
  useEffect(() => {
    if (toolType === 'virtualTeacher' && showSidebarTutorial) {
      // Don't show virtualTeacher tutorial until sidebar tutorial is complete
      return;
    }
  }, [toolType, showSidebarTutorial]);



  if (!isVisible || elements.length === 0) return null;
  console.log(currentElement)
  return (
    <div className="fixed inset-0 z-[10000]">
      {/* Backdrop with click-through holes */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black/50 transition-all duration-300" />
        {currentElement && currentElement.elements?.map((el, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ 
              opacity: 1, 
              scale: 1,
              boxShadow: index === activeElementIndex 
                ? '0 0 0 9999px rgba(0, 0, 0, 0)' 
                : '0 0 0 9999px rgba(0, 0, 0, 0)'
            }}
            className="absolute bg-transparent"
            style={{
              left: el.rect.left - 8,
              top: el.rect.top - 8,
              width: el.rect.width + 16,
              height: el.rect.height + 16,
              borderRadius: '8px',
              border: `2px solid ${index === activeElementIndex ? '#4F46E5' : 'white'}`,
              zIndex: 1050,
              transition: 'all 0.3s ease-in-out'
            }}
          >
            <div className={`absolute inset-0 border-2 rounded-lg ${
              index === activeElementIndex ? 'animate-pulse border-indigo-500' : 'border-white'
            }`} />
          </motion.div>
        ))}
      </div>


      {/* Tutorial content */}
      <AnimatePresence mode="wait">
        {!isMinimized && currentElement && (
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            style={{
              ...getTutorialPosition(currentElement),
              maxHeight: isMobile ? 'calc(100vh - 100px)' : 'auto',
              overflowY: isMobile ? 'auto' : 'visible'
            }}
            className="bg-white shadow-2xl rounded-xl overflow-hidden"
          >
            {/* Progress bar */}
            <div className="h-1 bg-gray-100">
              <div
                className="h-full bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-300"
                style={{ width: `${((currentStep + 1) / elements.length) * 100}%` }}
              />
            </div>

            {/* Content */}
            <div className={`p-6 ${isMobile ? 'pb-safe' : ''}`}>
              <div className="flex items-center gap-2 mb-4">
                <Sparkles className="w-5 h-5 text-purple-500" />
                <h3 className="text-xl font-semibold text-gray-900">
                  {currentElement.title}
                </h3>
              </div>
              <p className="text-gray-600 leading-relaxed">
                {currentElement.content}
              </p>
            </div>

            {/* Actions */}
            <div className={`border-t border-gray-100 p-4 flex items-center justify-between bg-gray-50 ${
              isMobile ? 'pb-safe' : ''
            }`}>
              <div className="flex items-center gap-2">
                <span
                  onClick={handlePrevious}
                  className={`p-2 rounded-lg transition-all duration-200 ${
                    currentStep === 0
                      ? 'text-gray-400 cursor-not-allowed opacity-50'
                      : 'text-gray-700 hover:bg-gray-100 hover:scale-105'
                  }`}
                  disabled={currentStep === 0}
                >
                  <ChevronLeft className="w-5 h-5" />
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {currentStep + 1} / {elements.length}
                </span>
                <span
                  onClick={handleNext}
                  className="p-2 rounded-lg text-gray-700 hover:bg-gray-100 hover:scale-105 transition-all duration-200"
                >
                  <ChevronRight className="w-5 h-5" />
                </span>
              </div>

              <div className="flex items-center gap-2">
                <span
                  onClick={handleClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-all duration-200"
                >
                  {currentStep === elements.length - 1 ? t('tutorial.finish') : t('tutorial.skip')}
                </span>
              </div>
            </div>

            {/* Keyboard shortcuts hint - Only show on desktop */}
            {!isMobile && (
              <div className="px-4 py-2 bg-gray-50 border-t border-gray-100">
                <p className="text-xs text-gray-500 text-center">
                  {t('tutorial.keyboardHint')}
                </p>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tutorial;