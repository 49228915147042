import React from 'react';
import { Container, Title, Center, Loader, Alert } from '@mantine/core';
import { useFetchBillingHistoryQuery } from '../../redux/api/transactionApiSlice';
import { useTranslation } from 'react-i18next';
import BillingList from '../../components/settings/BillingList';
import { useMediaQuery } from '@mantine/hooks';

const Billings = () => {
  const { t } = useTranslation();
  const { data: billingHistory, isLoading, isError } = useFetchBillingHistoryQuery();
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isLoading) {
    return (
      <Container size="m" mt={40}>
        <Center>
          <Loader size="lg" variant="bars" />
        </Center>
      </Container>
    );
  }

  if (isError) {
    return (
      <Container size="m" mt={40}>
        <Alert title={t('billings.errorTitle')} color="red">
          {t('billings.errorMessage')}
        </Alert>
      </Container>
    );
  }

  return (
    <Container size="m" mt={20}>
      <Center style={{ marginBottom: '20px' }}>
        <Title order={2}>{t('billings.title')}</Title>
      </Center>
      <BillingList purchases={billingHistory?.billingHistory || []} isMobile={isMobile} />
    </Container>
  );
};

export default Billings;
