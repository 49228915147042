import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { TextInput, Button, Title, Text, Anchor } from '@mantine/core';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../context/NotificationContext';

const VerifyForgotPassword = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const { loading, error, sendRequest } = useAxios();
  const { token } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => {
        if (value.length < 6) {
          return t('resetPassword.errors.passwordTooShort');
        }
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
        if (!passwordRegex.test(value)) {
          return t('resetPassword.errors.passwordInvalid');
        }
        return null;
      },
      confirmPassword: (value, values) =>
        value === values.password
          ? null
          : t('resetPassword.errors.passwordsDoNotMatch'),
    },
  });

  const handleSubmit = (values) => {
    sendRequest(
      {
        url: `/auth/reset-password/${token}`,
        method: 'POST',
        data: {
          password: values.password,
          confirmPassword: values.confirmPassword,
        },
      },
      (data) => {
        addNotification({
          id: new Date().getTime(),
          message: t('resetPassword.notifications.success'),
          type: 'success',
        });
        navigate('/auth/login', { replace: true });
      }
    ).catch((error) => {
      const errorMessage = error.response?.data?.message || t('resetPassword.notifications.error');
      addNotification({
        id: new Date().getTime(),
        message: errorMessage,
        type: 'error',
      });

      if (errorMessage.includes('must contain at least 1 letter and 1 number')) {
        form.setFieldError('password', t('resetPassword.errors.passwordInvalid'));
      }
    });
  };

  useEffect(() => {
    if (error) {
      console.error('Error during password reset request', error);
    }
  }, [error]);

  return (
    <div className="w-full max-w-md space-y-8">
      <div className="text-center space-y-3">
        <Title order={2} className="text-3xl font-bold">
          {t('resetPassword.title')}
        </Title>
        <Text className="text-gray-500 max-w-sm mx-auto">
          {t('resetPassword.instructions')}
        </Text>
      </div>

      <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-6">
        <div className="space-y-4">
          <TextInput
            size="md"
            label={t('resetPassword.newPasswordLabel')}
            placeholder={t('resetPassword.newPasswordPlaceholder')}
            type="password"
            className="rounded-xl"
            {...form.getInputProps('password')}
          />
          
          <TextInput
            size="md"
            label={t('resetPassword.confirmPasswordLabel')}
            placeholder={t('resetPassword.confirmPasswordPlaceholder')}
            type="password"
            className="rounded-xl"
            {...form.getInputProps('confirmPassword')}
          />
        </div>

        <button
          type="submit"
        className="w-full py-3 px-4 bg-[#40286c] text-white rounded-xl font-medium hover:bg-[#332056] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40286c]"
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              {t('resetPassword.processing')}
            </div>
          ) : (
            t('resetPassword.submitButton')
          )}
        </button>
      </form>

      <Text align="center" size="sm">
        <Anchor 
          component={Link} 
          to="/auth/login"
          className="font-medium text-blue-600 hover:text-blue-500"
        >
          {t('login.goBack')}
        </Anchor>
      </Text>
    </div>
  );
};

export default VerifyForgotPassword;
