import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetUserQuery } from '../redux/api/authApiSlice';
import { setCredentials, logOut } from '../redux/slices/authSlice';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const isAuthenticated = useSelector(
    (state) =>
      !!state.auth.user &&
      !!state.auth.accessToken &&
      !!state.auth.user.isVerified
  );
  const [shouldFetchUser, setShouldFetchUser] = useState(false);
  const [authInitialized, setAuthInitialized] = useState(false); // Tracks when auth process is done
  const [isAuthenticating, setIsAuthenticating] = useState(true); // New state for handling the delay

  useEffect(() => {
  
    if (accessToken) {
      
      setShouldFetchUser(true);
    } else {
     
      setAuthInitialized(true);
      setIsAuthenticating(false); // No token, mark authentication as done
    }
  }, [accessToken]);

  const { data: user, error } = useGetUserQuery(undefined, {
    skip: !accessToken || !shouldFetchUser,
  });

  useEffect(() => {
    if (shouldFetchUser) {
    
      if (user && !error) {
 
        dispatch(setCredentials({ user, accessToken: { token: accessToken } }));
        setIsAuthenticating(false); // Authentication has completed successfully
      } else if (error) {
  
        dispatch(logOut());
        localStorage.removeItem('accessToken');
        setIsAuthenticating(false); // Authentication failed
      }
      setAuthInitialized(true); // Auth check is completed whether successful or not
    }
  }, [user, error, accessToken, shouldFetchUser, dispatch]);

  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      user,
      authInitialized,
      isAuthenticating, // New state to handle the authenticating process
    }),
    [isAuthenticated, user, authInitialized, isAuthenticating]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
