// src/redux/api/paraphraseApiSlice.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const virtualTeacherApiSlice = createApi({
    reducerPath: 'virtualTeacherApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['VirtualTeacher'],
    endpoints: (builder) => ({
        getAnswer: builder.mutation({
            query: (virtualTeacherData) => ({
                url: '/results/virtualTeacher',
                method: 'POST',
                body: virtualTeacherData,
            }),
            invalidatesTags: ['VirtualTeacher'],
        }),
        // Add more paraphrasing-related endpoints here
    }),
});

export const { useGetAnswerMutation } = virtualTeacherApiSlice;
