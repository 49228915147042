  import Lottie from 'lottie-react';
  import loadingLottie from '../assets/loading.json';
  import { LoadingOverlay } from '@mantine/core';

  const LoadingAnimation = ({ type }) => {
    return (
      <LoadingOverlay
        visible={true}
        loaderProps={{
          children: (
            <Lottie
              animationData={
              loadingLottie
              }
            />
          ),
        }}
      />
    );
  };

  export default LoadingAnimation;