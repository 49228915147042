import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const lessonApiSlice = createApi({
  reducerPath: 'lessonApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Lessons'],
  endpoints: (builder) => ({
    fetchLessons: builder.query({
      query: () => '/lessons/fetch',
      providesTags: ['Lessons'],
    }),
    fetchGeneralLessons: builder.query({
      query: (params) => ({
        url: '/lessons/generalLessons',
        params: {
          page: params?.page || 1,
          limit: params?.limit || 10,
          universityName: params?.universityName,
          facultyName: params?.facultyName,
          courseName: params?.courseName,
          type: params?.type
        }
      }),
      providesTags: ['GeneralLessons'],
    }),
    addLesson: builder.mutation({
      query: (body) => ({
        url: '/lessons/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response) => response,
      // Capture the error status code and message
   
      invalidatesTags: ['Lessons'],
    }),
    updateLesson: builder.mutation({
      query: (body) => ({
        url: `/lessons/update`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Lessons'],
    }),
    deleteLesson: builder.mutation({
      query: (id) => ({
        url: `/lessons/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Lessons'],
    }),
  }),
});

export const {
  useFetchLessonsQuery,
  useFetchGeneralLessonsQuery,
  useAddLessonMutation,
  useUpdateLessonMutation,
  useDeleteLessonMutation,
} = lessonApiSlice;
