import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSendContactUsMutation } from '../../redux/api/authApiSlice';
import { 
  X, 
  Mail, 
  CheckCircle2, 
  AlertCircle,
  MessageCircle,
  HelpCircle,
  BugPlay
} from 'lucide-react';

const ContactUsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [sendContactUs] = useSendContactUsMutation();
  
  const userEmail = useSelector((state) => state.auth.user.email);
  const userName = useSelector((state) => state.auth.user.name);

  const form = useForm({
    initialValues: {
      subject: '',
      message: '',
      type: '',
    },
    validate: {
      subject: (value) => (value.trim().length > 0 ? null : t('contactUs.subjectRequired')),
      message: (value) => (value.trim().length > 0 ? null : t('contactUs.messageRequired')),
      type: (value) => (value ? null : t('contactUs.typeRequired')),
    },
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      await sendContactUs({
        name: userName,
        email: userEmail,
        subject: values.subject,
        message: values.message,
        type: values.type,
      }).unwrap();
      
      setSubmitSuccess(true);
      setTimeout(() => {
        onClose();
        setSubmitSuccess(false);
        form.reset();
      }, 2000);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  const contactTypes = [
    { value: 'support', label: t('contactUs.typeSupport'), icon: HelpCircle },
    { value: 'feedback', label: t('contactUs.typeFeedback'), icon: MessageCircle },
    { value: 'error', label: t('contactUs.typeError'), icon: BugPlay },
  ];

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm" style={{zIndex: 99999999999}}>
      <div className="bg-white rounded-xl shadow-xl w-full max-w-lg">
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-gray-100">
          <h2 className="text-xl font-semibold text-gray-900">
            {t('contactUs.title')}
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {submitSuccess ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="h-16 w-16 text-green-500 mb-4">
                <CheckCircle2 className="h-full w-full" />
              </div>
              <p className="text-lg font-medium text-green-600">
                {t('contactUs.submitSuccess')}
              </p>
            </div>
          ) : (
            <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-6">
              {/* Contact Type */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('contactUs.type')} <span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-3 gap-3">
                  {contactTypes.map(({ value, label, icon: Icon }) => (
                    <button
                      key={value}
                      type="button"
                      onClick={() => form.setFieldValue('type', value)}
                      className={`
                        flex flex-col items-center gap-2 p-3 rounded-lg border
                        ${form.values.type === value 
                          ? 'border-black bg-black text-white' 
                          : 'border-gray-200 hover:border-gray-300 text-gray-600 hover:bg-gray-50'
                        }
                        transition-all duration-200
                      `}
                    >
                      <Icon className="h-5 w-5" />
                      <span className="text-sm font-medium">{label}</span>
                    </button>
                  ))}
                </div>
                {form.errors.type && (
                  <p className="text-sm text-red-500 flex items-center gap-1 mt-1">
                    <AlertCircle className="h-4 w-4" />
                    {form.errors.type}
                  </p>
                )}
              </div>

              {/* Subject */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('contactUs.subject')} <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors"
                  placeholder={t('contactUs.subjectPlaceholder')}
                  {...form.getInputProps('subject')}
                />
                {form.errors.subject && (
                  <p className="text-sm text-red-500 flex items-center gap-1">
                    <AlertCircle className="h-4 w-4" />
                    {form.errors.subject}
                  </p>
                )}
              </div>

              {/* Message */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  {t('contactUs.message')} <span className="text-red-500">*</span>
                </label>
                <textarea
                  className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors min-h-[120px]"
                  placeholder={t('contactUs.messagePlaceholder')}
                  {...form.getInputProps('message')}
                />
                {form.errors.message && (
                  <p className="text-sm text-red-500 flex items-center gap-1">
                    <AlertCircle className="h-4 w-4" />
                    {form.errors.message}
                  </p>
                )}
              </div>

              {/* Actions */}
              <div className="flex items-center justify-end gap-3 pt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  {t('contactUs.cancel')}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center gap-2"
                >
                  <Mail className="h-4 w-4" />
                  {isSubmitting ? t('contactUs.submitting') : t('contactUs.submit')}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ContactUsModal;