import React from 'react';
import { AlertCircle, X, Coins, ArrowRight, Wallet } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TokenAlert = ({ 
  tokenBalance, 
  requiredTokens,
onClose,
isError,
  t 
}) => {
  const navigate = useNavigate();
  const isInsufficient = tokenBalance < requiredTokens;

  if (!isInsufficient && tokenBalance >= 100) return null;

  return (
    <div className="fixed bottom-4 left-2/4 -translate-x-1/2 w-full max-w-xl px-3" style={{zIndex: 99999999}}>
          <div className={`relative rounded-lg border ${isError ? 'border-red-200 bg-red-50/90' : 'border-amber-200 bg-amber-50/90'} backdrop-blur-md shadow-md`}>
            <div className="absolute -top-3 -right-3 h-16 w-16 bg-gradient-to-br from-red-500/20 to-transparent rounded-full blur-lg" />
            <div className="absolute -bottom-6 -left-6 h-20 w-20 bg-gradient-to-tr from-red-500/20 to-transparent rounded-full blur-lg" />
            
            <div className="relative p-4">
              <div className="flex items-start gap-3">
                <div className={`flex h-10 w-10 items-center justify-center rounded-full ${
                  isError 
                    ? 'bg-gradient-to-br from-red-100 via-red-50 to-red-200 text-red-600' 
                    : 'bg-gradient-to-br from-amber-100 via-amber-50 to-amber-200 text-amber-600'
                } shadow`}>
                  {isError ? <AlertCircle className="h-5 w-5" /> : <Wallet className="h-5 w-5" />}
                </div>
                
                <div className="flex-1 space-y-2">
                  <h3 className={`text-sm font-semibold ${isError ? 'text-red-900' : 'text-amber-900'}`}>
                    {isError ? t('tokenCounter.insufficientTitle') : t('tokenCounter.lowBalanceTitle')}
                  </h3>
                  
                  <p className={`text-xs ${isError ? 'text-red-700' : 'text-amber-700'}`}>
                    {isError 
                      ? t('tokenCounter.insufficientDescription', { tokenBalance })
                      : t('tokenCounter.lowBalanceDescription')}
                  </p>
                  
                  <div className="flex flex-wrap gap-2 pt-1">
                    <button 
                     onClick={() => navigate('/payments/subscription?tab=token')}
                      className={`group relative inline-flex items-center justify-center gap-1.5 rounded-md px-3 py-2 text-xs font-semibold text-white shadow-sm transition ${
                        isError 
                          ? 'bg-red-600 hover:bg-red-700' 
                          : 'bg-amber-600 hover:bg-amber-700'
                      }`}
                    >
                      <Wallet className="h-4 w-4" />
                      <span>{t('tokenCounter.buyTokens')}</span>
                    </button>
                    
                    <button 
                      onClick={onClose}
                      className={`inline-flex items-center justify-center gap-1.5 rounded-md border px-3 py-2 text-xs font-semibold transition-colors ${
                        isError 
                          ? 'border-red-300 text-red-700 hover:bg-red-100' 
                          : 'border-amber-300 text-amber-700 hover:bg-amber-100'
                      }`}
                    >
                      {t('tokenCounter.dismiss')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default TokenAlert;
