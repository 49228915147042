// src/redux/slices/toolHistoriesSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { toolHistoriesApiSlice } from '../api/toolHistoriesApiSlice';

/**
 * Extract the tool from the current pathname. 
 * Default to 'questionAnswer' if not found.
 */
const getPathSegment = (pathname) => {
  if (pathname.includes('/tools/')) {
    const segments = pathname.split('/');
    return segments[segments.length - 1] || 'questionAnswer';
  }
  return 'questionAnswer';
};

const initialState = {
  // Holds an array of items for each tool
  histories: {
    summarizer: [],
    paraphraser: [],
    articleForger: [],
    questionAnswer: [],
    humanizer: [],
    virtualTeacher: [],
  },
  // Keep track of pagination state (page, totalPages, hasMore, etc.) for each tool
  pagination: {
    summarizer: { page: 1, totalPages: 1, hasMore: false },
    paraphraser: { page: 1, totalPages: 1, hasMore: false },
    articleForger: { page: 1, totalPages: 1, hasMore: false },
    questionAnswer: { page: 1, totalPages: 1, hasMore: false },
    humanizer: { page: 1, totalPages: 1, hasMore: false },
    virtualTeacher: { page: 1, totalPages: 1, hasMore: false },
  },
  currentTool: getPathSegment(location.pathname),
  status: 'idle',
  error: null,
  selectedHistoryId: null,
};

export const toolHistoriesSlice = createSlice({
  name: 'toolHistories',
  initialState,
  reducers: {
    /**
     * Manually set all initial histories for each tool. 
     */
    setInitialHistories: (state, action) => {
      const allToolsData = action.payload; // e.g. { summarizer: [...], paraphraser: [...], ...}
      // For each tool in state.histories, set the data
      for (const tool of Object.keys(allToolsData)) {
        state.histories[tool] = allToolsData[tool] || [];
        // If we loaded 20 items, let's assume we have at least 1 page
        // and we might have more to fetch. We won't know totalPages from initial,
        // so let's guess page=1, totalPages=??? hasMore = (array length === 20).
        state.pagination[tool] = {
          page: 1,
          totalPages: 2, // Just assume at least 2 pages if we have 20. We'll get real data if user scrolls.
          hasMore: allToolsData[tool]?.length === 20,
        };
      }
      state.status = 'succeeded';
    },

    /**
     * Append new history items to the front (for newly created items).
     */
    appendToHistory: (state, action) => {
      const { tool, entry } = action.payload;
      if (!state.histories[tool]) {
        console.error(`Histories for toolType "${tool}" not initialized`);
        return;
      }
      state.histories[tool] = [entry, ...state.histories[tool]];
      state.selectedHistoryId = entry.id;
    },

    /**
     * Set selected ID when a user chooses a history item.
     */
    setSelectedHistoryId: (state, action) => {
      state.selectedHistoryId = action.payload;
    },

    /**
     * Clear the selected ID (e.g., when user creates a new item).
     */
    clearSelectedHistoryId: (state) => {
      state.selectedHistoryId = null;
    },

    /**
     * Switch current tool (if user navigates to a different tool).
     */
    setCurrentTool: (state, action) => {
      state.currentTool = action.payload;
    },

    clearCurrentTool: (state) => {
      state.currentTool = null;
    },
  },

  extraReducers: (builder) => {
    builder
      // On successful fetch of initial histories
      .addMatcher(
        toolHistoriesApiSlice.endpoints.fetchInitialHistories.matchFulfilled,
        (state, action) => {
          state.histories = action.payload;
          // For each tool, if length is 20, we guess we have more to fetch
          Object.keys(action.payload).forEach((tool) => {
            const items = action.payload[tool];
            state.pagination[tool] = {
              page: 1,
              totalPages: 2, // an assumption until we actually fetch
              hasMore: items.length === 20,
            };
          });
          state.status = 'succeeded';
        }
      )
      // On successful fetch of more histories for a single tool
      .addMatcher(
        toolHistoriesApiSlice.endpoints.fetchMoreHistory.matchFulfilled,
        (state, action) => {
          const { toolType, page, results, totalPages } = action.payload;
          if (!state.histories[toolType]) {
            console.error(`Histories for toolType "${toolType}" not initialized`);
            return;
          }

          // Append newly fetched results
          state.histories[toolType] = [
            ...state.histories[toolType],
            ...results,
          ];

          // Update pagination
          state.pagination[toolType].page = page;
          state.pagination[toolType].totalPages = totalPages;
          // If we're on the last page or if results returned less than we expected,
          // we set hasMore to false
          state.pagination[toolType].hasMore = page < totalPages;
        }
      )
      // On successful delete
      .addMatcher(
        toolHistoriesApiSlice.endpoints.deleteHistoryEntry.matchFulfilled,
        (state, action) => {
          const { id, toolType } = action.meta.arg.originalArgs;
          state.histories[toolType] = state.histories[toolType].filter(
            (entry) => entry._id !== id
          );
        }
      )

      // Pending initial fetch
      .addMatcher(
        toolHistoriesApiSlice.endpoints.fetchInitialHistories.matchPending,
        (state) => {
          state.status = 'loading';
        }
      )
      // Rejected initial fetch
      .addMatcher(
        toolHistoriesApiSlice.endpoints.fetchInitialHistories.matchRejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.error;
        }
      );
  },
});

export const {
  setInitialHistories,
  setSelectedHistoryId,
  appendToHistory,
  clearSelectedHistoryId,
  setCurrentTool,
  clearCurrentTool,
} = toolHistoriesSlice.actions;

export default toolHistoriesSlice.reducer;
