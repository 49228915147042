import { Container } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Parse the query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');
  const amount = searchParams.get('amount');
  const packageName = searchParams.get('packageName');

  return (
    <div className="min-h-screen py-12">
      <Container size="xs">
        <div className="bg-white rounded-2xl shadow-2xl p-8 relative overflow-hidden">
          {/* Success Icon */}
          <div className="flex justify-center mb-6">
            <div className="w-20 h-20 bg-[#40286C]/10 rounded-full flex items-center justify-center">
              <svg className="w-12 h-12 text-[#40286C]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>

          {/* Title and Message */}
          <h2 className="text-2xl font-bold text-center text-[#40286C] mb-4">
            {t('paymentSuccess.title')}
          </h2>
          <p className="text-gray-600 text-center mb-8">
            {t('paymentSuccess.message')}
          </p>

          {/* Order Details */}
          <div className="space-y-4 bg-gray-50 p-6 rounded-xl mb-8">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">{t('paymentSuccess.orderId')}</span>
              <span className="font-semibold text-[#40286C] bg-[#40286C]/10 px-4 py-1 rounded-full">
                {orderId}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">{t('paymentSuccess.packageName')}</span>
              <span className="font-semibold text-[#40286C] bg-[#40286C]/10 px-4 py-1 rounded-full">
                {packageName}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">{t('paymentSuccess.amount')}</span>
              <span className="font-semibold text-[#40286C] bg-[#40286C]/10 px-4 py-1 rounded-full">
                {amount} TL
              </span>
            </div>
          </div>

          {/* Action Button */}
          <Link 
            to="/tools/questionanswer"
            className="block w-full bg-[#40286C] hover:bg-[#503485] text-white text-center py-4 rounded-xl font-semibold transition-colors duration-200"
          >
            {t('paymentSuccess.goToTools')}
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default PaymentSuccess;
