  import i18n from "i18next";
  import { initReactI18next } from "react-i18next";
  import HttpApi from 'i18next-http-backend';

  const supportedLanguages = ['tr', 'en'];
  const browserLanguage = navigator.language.slice(0, 2); // Get the language code (e.g., 'en' from 'en-US')
  const initialLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'tr';

  i18n
    .use(HttpApi) // load translation using http -> see /public/locales
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: localStorage.getItem('i18nextLng') || initialLanguage, // Set language based on localStorage or browser language
      fallbackLng: "tr", // Fallback to Turkish if no matching language is found
      supportedLngs: supportedLanguages, // Define supported languages
      interpolation: {
        escapeValue: false, // React already safes from XSS
      },
      backend: {
        loadPath: `${process.env.NODE_ENV === 'production' ? '/app' : ''}/locales/{{lng}}/translation.json`, // Path for loading translation files
      },
      detection: {
        order: ['localStorage', 'navigator'], // Order of detection
        caches: ['localStorage'], // Cache language selection in localStorage
      },
    });

  export default i18n;
