/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { TextInput, Button, Group, Box, Loader } from '@mantine/core';
import { useNotifications } from '../../context/NotificationContext'; // Adjust the import path as necessary
import { useSuggestTitlesMutation } from '../../redux/api/articleForgerApiSlice';
import { PlusCircle, Trash2, ChevronRight, Loader2, X } from 'lucide-react';
import React, { memo } from 'react';
import Lottie from 'lottie-react';
import loadingAnimationData from '../assets/lottieSpinning.json';
import { v4 as uuidv4 } from 'uuid'; 

/**
 * Example of how your form might look, including default titles:
 *
 * const form = useForm({
 *   initialValues: {
 *     selectedAssistant: 'uzman',
 *     selectedLanguage: 'tr',
 *     course: '',
 *     length: '',
 *     extraPrompt: "",
 *     topic: "",
 *     promptChain: false,
 *     threadId: false,
 *     queue: 0,
 *     inputs: [
 *       { id: "input1", inputLabel: "1", parentId: null, undeletable: true, done: false, value: "Giriş" },
 *       { id: "input2", inputLabel: "2", parentId: null, undeletable: true, done: false, value: "İçerik 1" },
 *       { id: "input3", inputLabel: "3", parentId: null, undeletable: true, done: false, value: "İçerik 2" },
 *       { id: "input4", inputLabel: "4", parentId: null, undeletable: true, done: false, value: "Sonuç" },
 *     ],
 *   },
 * });
 */

/* -------------------------------------------
 *         Helper Functions
 * -------------------------------------------
 */

/**
 * Split API lines into structured objects {title, childTitles[]}
 * so we can create parent–child relationships.
 */
function transformData(lines) {
  const suggestedData = [];
  let currentMainTitle = null;

  lines.forEach((line) => {
    // Check if line matches top-level: "1- Something"
    if (/^\d+-/.test(line)) {
      const title = line.split('-')[1].trim();
      currentMainTitle = { title, childTitles: [] };
      suggestedData.push(currentMainTitle);
    }
    // Or sub-title: "1.1- Something"
    else if (/^\d+\.\d+-/.test(line)) {
      const title = line.split('-')[1].trim();
      if (currentMainTitle) {
        currentMainTitle.childTitles.push(title);
      }
    }
  });

  return suggestedData;
}

/** Recursively gather `id` of item + all children so we can remove them together. */
function collectChildren(itemId, inputs, toRemove) {
  toRemove.add(itemId);
  const children = inputs.filter((input) => input.parentId === itemId);
  children.forEach((child) => collectChildren(child.id, inputs, toRemove));
}

/**
 * Recompute numbering for the entire outline:
 * 1) Identify top-level items (parentId===null) in array order.
 * 2) Label them "1", "2", "3", ...
 * 3) Recursively label children "1.1", "1.2", ...
 */
function reorderOutline(inputs) {
  const newArray = [...inputs]; // shallow copy to avoid mutating original

  // All top-level items
  const topLevel = newArray.filter((i) => i.parentId === null);

  // Assign them 1,2,3,... based on order in the array
  topLevel.forEach((parent, index) => {
    parent.inputLabel = String(index + 1);
    labelChildren(parent, newArray);
  });

  return newArray;
}

/** Recursively label child items. If parent is "2", children become "2.1", "2.2", etc. */
function labelChildren(parentItem, allItems) {
  const children = allItems.filter((x) => x.parentId === parentItem.id);
  children.forEach((child, index) => {
    child.inputLabel = `${parentItem.inputLabel}.${index + 1}`;
    labelChildren(child, allItems);
  });
}

/* -------------------------------------------
 *         Outline Input (Child)
 * -------------------------------------------
 */
const OutlineInput = memo(function OutlineInput({
  input,
  onAddSubtitle,
  onDelete,
  onInputChange,
  isMain, // true if top-level
  isLast, // true if this item is the last among siblings
  t,
}) {
  return (
    <div className="relative flex items-center gap-2 py-2 px-2 hover:bg-gray-50 rounded-lg">
      {/* Left side: numbering + text field */}
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-500 min-w-[50px]">
            {input.inputLabel}.
          </span>
          <input
            type="text"
            value={input.value}
            onChange={(e) => onInputChange(input.id, e.target.value)}
            className="w-full bg-transparent border-0 focus:ring-0 p-0 text-gray-900"
            placeholder={t('outlineCreator.title') /* e.g. "Başlık girin..." */}
          />
        </div>
      </div>

      {/* Right side: action buttons */}
      <div className="flex items-center gap-1">
        {/* "Alt Başlık Ekle" for top-level items */}
        {isMain && (
          <button
            onClick={() => onAddSubtitle(input.id)}
            className="p-1 rounded-md hover:bg-gray-200 text-gray-600 transition-colors"
            title={t('outlineCreator.subtitle')}
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        )}

        {/* Delete, if not undeletable */}
        {!input.undeletable && (
          <button
            onClick={() => onDelete(input.id)}
            className="p-1 rounded-md hover:bg-red-100 text-red-600 transition-colors"
            title={t('common.delete')}
          >
            <Trash2 className="w-4 h-4" />
          </button>
        )}

        {/* "Başlık Ekle" next to the last top-level item only */}
        {isMain && isLast && (
          <button
            onClick={() => onAddSubtitle(null)} 
            className="p-1 rounded-md hover:bg-green-100 text-green-600 transition-colors"
            title={t('outlineCreator.addTitle')}
            id="plusTitle"
          >
            <PlusCircle className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
});

/* -------------------------------------------
 *       Main Component: ForgerOutlineCreator
 * -------------------------------------------
 */
export default function ForgerOutlineCreator({ form, t  }) {
  // Redux & notifications
  const [suggestTitles, { isLoading }] = useSuggestTitlesMutation();
  const { addNotification } = useNotifications();

  // ---------------------------------------
  //      Fetch & Convert Outline
  // ---------------------------------------
  const fetchSuggestedTitles = async () => {
    const { topic, course, selectedLanguage, length } = form.values;

    // Basic checks
    if (!topic || !course || !selectedLanguage || !length) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: t('validation.outlineCreator.required'),
      });
      return;
    }

    try {
      const payload = { topic, course, language: selectedLanguage, length };
      const result = await suggestTitles(payload).unwrap();

      // Convert result into lines
      const lines = result.result
        .split('\n')
        .map((l) => l.trim())
        .filter(Boolean);

      // Build { title, childTitles[] }
      const structured = transformData(lines);

      // Convert to array of items for the form
      const newInputs = [];
      structured.forEach((group) => {
        // A top-level parent
        const parentId = uuidv4();
        newInputs.push({
          id: parentId,
          parentId: null,
          value: group.title,
          undeletable: false,
          inputLabel: '',
        });
        // Children
        group.childTitles.forEach((sub) => {
          newInputs.push({
            id: uuidv4(),
            parentId,
            value: sub,
            undeletable: false,
            inputLabel: '',
          });
        });
      });

      const reordered = reorderOutline(newInputs);
      form.setFieldValue('inputs', reordered);
    } catch (err) {
      console.error(err);
      addNotification({
        id: Date.now(),
        type: 'error',
        message: t('errors.suggestTitlesFailed'),
      });
    }
  };

  // ---------------------------------------
  //         Add / Delete / Edit
  // ---------------------------------------

  // Add top-level or child item
  const addInput = (parentId = null) => {
    const total = form.values.inputs.length;
    if (total >= 20) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: t('validation.outlineCreator.maxTitles'),
      });
      return;
    }

    const newItem = {
      id: uuidv4(),
      parentId,
      value: '',
      undeletable: false,
      inputLabel: '',
    };

    const updated = [...form.values.inputs, newItem];
    const reordered = reorderOutline(updated);
    form.setFieldValue('inputs', reordered);
  };

  // Delete item + all its children
  const deleteInput = (id) => {
    const removeSet = new Set();
    collectChildren(id, form.values.inputs, removeSet);

    const filtered = form.values.inputs.filter(
      (item) => !removeSet.has(item.id)
    );
    const reordered = reorderOutline(filtered);
    form.setFieldValue('inputs', reordered);
  };

  // Edit title text
  const handleInputChange = (id, val) => {
    const updated = form.values.inputs.map((inp) =>
      inp.id === id ? { ...inp, value: val } : inp
    );
    form.setFieldValue('inputs', updated);
  };

  // ---------------------------------------
  //        Recursively Render
  // ---------------------------------------
  const renderInputs = (parentId = null) => {
    const items = form.values.inputs.filter((x) => x.parentId === parentId);
    return items.map((item, index) => {
      const isLast = index === items.length - 1;
      return (
        <div
          key={item.id}
          className={
            parentId
              ? 'ml-6 pl-2 border-l border-gray-200'
              : '' /* Indent child items */
          }
        >
          <OutlineInput
            input={item}
            onAddSubtitle={addInput}
            onDelete={deleteInput}
            onInputChange={handleInputChange}
            isMain={parentId === null}
            isLast={isLast}
            t={t}
          />
          {renderInputs(item.id)}
        </div>
      );
    });
  };

  // ---------------------------------------
  //        JSX
  // ---------------------------------------
  return (
    <div className="bg-white rounded-lg shadow-sm">
      {/* Header */}
      <div className="flex items-center justify-between border-b border-gray-200 p-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t('outlineCreator.title')}
        </h2>
        <button
          onClick={fetchSuggestedTitles}
          disabled={isLoading}
          id="suggestTitles"
          className="inline-flex items-center px-3 py-1.5 rounded-md bg-[#40286C] text-white
                     hover:shadow-lg hover:scale-[1.02] focus:outline-none
                     focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                     transition-colors duration-200 disabled:opacity-50
                     disabled:cursor-not-allowed text-sm font-medium"
        >
          {isLoading && (
            <div className="w-4 h-4 mr-2">
              <Lottie animationData={loadingAnimationData} loop autoplay />
            </div>
          )}
          {t('outlineCreator.suggest')}
        </button>
      </div>

      {/* Content */}
      <div className="p-4">
        {form.values.inputs.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            {t('outlineCreator.noTitles')}
          </div>
        ) : (
          <div className="space-y-1">{renderInputs(null)}</div>
        )}
      </div>

      {/* Footer: Big "Başlık Ekle" at the bottom */}
      {form.values.inputs.length > 0 && (
        <div className="border-t border-gray-200 p-4">
          <button
          id="addTitle"
            onClick={() => addInput(null)}
            className="w-full py-2 px-3 border border-gray-300 rounded-md text-sm 
                       text-gray-700 hover:bg-gray-50 flex items-center justify-center gap-2
                       transition-colors"
          >
            <PlusCircle className="w-4 h-4" />
            {t('outlineCreator.addTitle')}
          </button>
        </div>
      )}
    </div>
  );
}
