// src/redux/api/paraphraseApiSlice.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const summarizerApiSlice = createApi({
    reducerPath: 'summarizerApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Summarizer'],
    endpoints: (builder) => ({
        summarizerText: builder.mutation({
            query: (summarizerData) => ({
                url: '/results/summarizer',
                method: 'POST',
                body: summarizerData,
            }),
            invalidatesTags: ['Summarizer'],
        }),
        // Add more paraphrasing-related endpoints here
    }),
});

export const { useSummarizerTextMutation } = summarizerApiSlice;