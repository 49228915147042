
import { Routes, Route } from 'react-router-dom';
import BaseLayout from '../components/layout/BaseLayout'; // Adjust the import path as needed
import Lessons from '../pages/user/lessons';

/* import ForgerPage from '../pages/tools/ForgerPage';
import TeacherPage from '../pages/tools/TeacherPage'; */
// Import other tool pages as needed

export const LessonRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<BaseLayout />}>
        <Route path="edit" element={<Lessons />} />
      </Route>
    </Routes>
  );
};