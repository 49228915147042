import React from 'react';

const StyledButton = ({ 
  variant = 'default', 
  icon, 
  children, 
  onClick, 
  disabled = false, 
  fullWidth = false, 
  size = 'md',
  type = 'button',
  loading = false,
  className = '',
  iconPosition = 'left',
  ...props 
}) => {
  const baseStyles = 'inline-flex items-center justify-center gap-2 font-medium rounded-lg transition-all duration-200 shadow-sm focus:ring-2 focus:ring-offset-2';
  const variants = {
    // Crisp white with darker hover
    default: 'bg-white border border-gray-300 text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:ring-gray-300',
    primary: 'bg-[#40286C] text-white hover:bg-[#563a8d]  ',
    
    // Secondary actions - matches the "Yeni Oluştur" button style
    secondary: 'bg-gray-900 text-white hover:bg-gray-800 focus:ring-gray-400',
    
    // Navigation/menu items - subtle style matching the left sidebar
    nav: 'bg-transparent text-gray-700 hover:bg-gray-100 hover:text-indigo-600 focus:ring-gray-200',
    
    // Form actions - clean, minimal style matching the dropdown fields
    form: 'bg-white border border-gray-200 text-gray-800 hover:border-gray-300 hover:bg-gray-50 focus:ring-gray-200',
    
    // Success/confirmation actions
    success: 'bg-gradient-to-r from-emerald-500 to-green-600 text-white hover:from-emerald-600 hover:to-green-700 focus:ring-green-300',
    
    // Danger/warning actions
    danger: 'bg-gradient-to-r from-red-500 to-rose-600 text-white hover:from-red-600 hover:to-rose-700 focus:ring-red-300'
  };

  const sizes = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2.5 text-sm',
    lg: 'px-6 py-3 text-base',
    xl: 'px-8 py-4 text-lg'
  };

  const iconSizes = {
    sm: 'w-3.5 h-3.5',
    md: 'w-4 h-4',
    lg: 'w-5 h-5',
    xl: 'w-6 h-6'
  };

  const LoadingSpinner = () => (
    <div className={`${iconSizes[size]} animate-spin`}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  );

  const IconComponent = icon && React.cloneElement(icon, {
    className: `${iconSizes[size]} ${icon.props.className || ''}`
  });

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={`
        ${baseStyles}
        ${variants[variant]}
        ${sizes[size]}
        ${fullWidth ? 'w-full' : ''}
        ${disabled || loading ? 'opacity-50 cursor-not-allowed' : 'hover:transform hover:-translate-y-0.5'}
        ${className}
      `}
      {...props}
    >
      {loading ? (
        <>
          <LoadingSpinner />
          {children}
        </>
      ) : (
        <>
          {IconComponent && iconPosition === 'left' && IconComponent}
          {children}
          {IconComponent && iconPosition === 'right' && IconComponent}
        </>
      )}
    </button>
  );
};

export default StyledButton;