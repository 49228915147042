// src/redux/api/paraphraseApiSlice.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const paraphraserApiSlice = createApi({
    reducerPath: 'paraphraserApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Paraphraser'],
    endpoints: (builder) => ({
        paraphraserText: builder.mutation({
            query: (paraphraserData) => ({
                url: '/results/paraphraser',
                method: 'POST',
                body: paraphraserData,
            }),
            invalidatesTags: ['Paraphraser'],
        }),
        // Add more paraphrasing-related endpoints here
    }),
});

export const { useParaphraserTextMutation } = paraphraserApiSlice;
