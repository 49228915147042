import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProgressSteps = ({ currentStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const steps = [
    { 
      name: t('checkoutProgress.selectPackage'), 
      status: currentStep === 1 ? "current" : currentStep > 1 ? "complete" : "upcoming",
      path: '/payments/subscription',
      step: 1 
    },
/*     { 
      name: t('checkoutProgress.address'), 
      status: currentStep === 2 ? "current" : currentStep > 2 ? "complete" : "upcoming",
      path: '/payments/billingAddress',
      step: 2 
    }, */
    { 
      name: t('checkoutProgress.checkout'), 
      status: currentStep === 3 ? "current" : currentStep > 3 ? "complete" : "upcoming",
      path: '/payments/checkout',
      step: 3 
    }
  ];

  const handleStepClick = (step) => {
    if (currentStep > step.step) {
      navigate(step.path);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-4 py-8">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <div className="relative flex items-center justify-between">
            {steps.map((step, index) => (
              <React.Fragment key={step.name}>
                <div
                  onClick={() => handleStepClick(step)}
                  className={`
                    flex h-12 w-12 items-center justify-center rounded-full text-sm font-semibold
                    transition-all duration-200 cursor-pointer
                    ${step.status === "complete" || step.status === "current"
                      ? "bg-[#40286C] text-primary-foreground"
                      : "border-2 border-gray-300 bg-white text-gray-500 hover:border-gray-400"
                    }
                  `}
                  role="button"
                  tabIndex={0}
                  aria-label={`Step ${index + 1}: ${step.name}`}
                  aria-current={step.status === "current" ? "step" : undefined}
                >
                  {index + 1}
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={`
                      flex-1 h-1 transition-all duration-300
                      ${step.status === "complete" ? "bg-[#40286C]" : "bg-gray-200"}
                    `}
                    role="presentation"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="mt-4 flex justify-between text-sm font-medium">
            {steps.map((step) => (
              <span
                key={step.name}
                className={`
                  transition-colors duration-200
                  ${step.status === "complete" || step.status === "current"
                    ? "text-[#40286C]"
                    : "text-gray-500"
                  }
                `}
              >
                {step.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressSteps;