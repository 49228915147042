import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../redux/slices/authSlice';
import { useNotifications } from '../../context/NotificationContext';

const GoogleCallback = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();

  useEffect(() => {
    const googleGeriCagirmaIsle = async () => {
      try {
        const params = new URLSearchParams(location.search);

        const accessToken = params.get('accessToken');
        const encodedUser = params.get('user');

        if (!accessToken || !encodedUser) {
          throw new Error('Kimlik doğrulama verileri eksik');
        }

        const user = JSON.parse(decodeURIComponent(encodedUser));
        localStorage.setItem('accessToken', accessToken);

        // Redux store'a kimlik bilgilerini kaydet
        dispatch(setCredentials({
          user,
          accessToken: { token: accessToken }
        }));

        // Başarı bildirimi göster
        addNotification({
          id: Date.now(),
          message: 'Google ile giriş başarılı',
          type: 'success'
        });

        // Ana uygulamaya yönlendir
        navigate('/tools/virtualTeacher');
      } catch (error) {
        console.error('Google geri çağırma hatası:', error);
        addNotification({
          id: Date.now(),
          message: 'Google girişi tamamlanamadı',
          type: 'error'
        });
        navigate('/auth/login');
      }
    };

    googleGeriCagirmaIsle();
  }, [location, navigate, dispatch, addNotification]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="text-center">
        <div className="mb-4">Google girişi tamamlanıyor...</div>
        {/* Buraya bir yükleme animasyonu ekleyebilirsiniz */}
      </div>
    </div>
  );
};


export default GoogleCallback