/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseLayout from "./BaseLayout";
import { AppShell } from '@mantine/core';
import InfiniteScrollHistory from '../common/HistoryBar'; // Adjust the import path as necessary
import { useFetchInitialHistoriesQuery } from '../../redux/api/toolHistoriesApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTool, clearSelectedHistoryId } from '../../redux/slices/toolHistoriesSlice';
import { useAuth } from '../../context/authContext';
import { useMediaQuery } from '@mantine/hooks';
const ToolsLayout = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth(); // This assumes your auth context provides these values
  const [previousTool, setPreviousTool] = useState(null);
  // Use the skip option to prevent the query from running until the user is authenticated
  const { data, isFetching, error } = useFetchInitialHistoriesQuery(undefined, {
    skip: !isAuthenticated || isLoading
  });

  const getCurrentToolType = () => {
    const pathSegments = location.pathname.split('/');
    // Ensure the pathSegments array length is as expected to avoid undefined values
    return pathSegments.length > 2 ? pathSegments[2] : null;
  };
  
  

  const toolHistories = useSelector(state => state.toolHistories.histories);
  const currentToolType = getCurrentToolType(location.pathname);
  const isMobile = useMediaQuery('(max-width: 768px)');
  // Update current tool when route changes
  useEffect(() => {
    if (currentToolType) {
      dispatch(setCurrentTool(currentToolType));
      
      // Only clear history if we're actually changing tools
      if (currentToolType !== previousTool) {
        dispatch(clearSelectedHistoryId());
        setPreviousTool(currentToolType);
      }
    }
  }, [currentToolType, previousTool, dispatch]);

  // Don't render the Aside component on mobile
  const renderAside = () => {
    if (isMobile) return null;

    return (
      <AppShell.Aside 
        p="md" 
        width={{ base: 250 }} 
        style={{ 
          backgroundColor: '#f9f9fa',
          marginTop: 0,
          zIndex: 1000
        }} 
        hiddenBreakpoint="sm" 
        id="toolHistory"
      >
        {currentToolType && (
          <InfiniteScrollHistory
            toolType={currentToolType}
            initialHistories={toolHistories[currentToolType] || []}
          />
        )}
      </AppShell.Aside>
    );
  };

  return (
    <BaseLayout 
      historyContent={isMobile && currentToolType ? (
        <InfiniteScrollHistory
          toolType={currentToolType}
          initialHistories={toolHistories[currentToolType] || []}
        />
      ) : null}
    >
      {renderAside()}
      <Outlet />
    </BaseLayout>
  );
};

export default ToolsLayout;
