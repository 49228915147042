import { Outlet, useLocation } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import CheckoutProgressBar from '../common/CheckoutProgressBar'; // Adjust the import path as needed


const PaymentLayout = () => {
    const location = useLocation();
  
    const getCurrentStep = () => {
/*       if (location.pathname.includes('billingAddress')) return 2; */
      if (location.pathname.includes('/payments/payment')) return 3;
      return 1;
    };
  
    return (
      <BaseLayout>
        <CheckoutProgressBar currentStep={getCurrentStep()} />
        <Outlet />
      </BaseLayout>
    );
  };
  
  export default PaymentLayout;