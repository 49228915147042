import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Card,
  Text,
  Title,
  Button,
  Grid,
  Stack,
  TextInput,
  Select,
  Group,
  Modal,
  Textarea,
  Badge,
  Skeleton,
  LoadingOverlay,
  Alert,
  Anchor,
  Checkbox
} from '@mantine/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useFetchAddressesQuery,
  useSaveAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation, // Import the delete mutation
} from '../../redux/api/paymentApiSlice';
import { citiesAndDistricts } from './ililce';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMapMarkerAlt,
  faEdit,
  faCheckCircle,
  faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../../context/NotificationContext';
import { openConfirmModal } from '@mantine/modals';
import ConfirmationModal from '../../components/common/ConfirmModal';
import AydinlatmaMetni from '../../components/AydinlatmaMetni';
import SatisSozlesmesi from '../../components/SatisSozlesmesi';
import { useMediaQuery } from '@mantine/hooks';
import { GraduationCap, Star, Book, Upload, Brain, Check } from 'lucide-react';

const OrderSummary = ({
  selectedPlan,
  isAddressesLoading,
  selectedAddress,
  acceptedTerms,
  setAcceptedTerms,
  handlePaymentClick,
  t,
}) => {
  const [openContractModal, setOpenContractModal] = useState(false);
  const [openAydinlatmaMetniModal, setOpenAydinlatmaMetniModal] = useState(false);
  console.log(selectedPlan)
  // Feature icons mapping
  const featureIcons = {
    'payments.features.professorAccess': GraduationCap,
    'payments.features.monthlyTokens': Star,
    'payments.features.coursesCount': Book,
    'payments.features.uploadLimit': Upload,
    'payments.features.aiDetection': Brain,
    'payments.features.originality': Check,
  };

  return (
    <div className="w-full max-w-lg mx-auto bg-white rounded-lg shadow-sm p-8">
      <h2 className="text-lg font-bold mb-4">{t('payments.orderSummary')}</h2>
      
      {/* Package and Price */}
      <div className="space-y-4 mb-4">
        <div className="flex justify-between items-center">
          <span className="text-sm">{t('payments.package')}:</span>
          <span className="text-sm">{t(selectedPlan.title)}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm">{t('payments.price')}:</span>
          <span className="text-sm font-bold">{selectedPlan.price} TL</span>
        </div>
      </div>

      {/* Features Section */}
      {selectedPlan.type !== 'coin' && (
      <div className="mb-8">
        <h3 className="text-md mb-4">{t('payments.features')}:</h3>
        <div className="space-y-4">
          {selectedPlan.features?.map((feature, index) => {
            const IconComponent = featureIcons[feature] || Check;
            return (
              <div key={index} className="flex items-center gap-3 text-gray-600">
                <IconComponent className="w-4 h-4" />
                <span className="text-sm">{t(feature)}</span>
              </div>
            );
          })}
        </div>
      </div>
      )}

      {/* Terms Section */}
      <div className="space-y-4 mb-6">
        <div className="flex items-start gap-2">
          <input
            type="checkbox"
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.currentTarget.checked)}
            className="mt-1 h-4 w-4 rounded border-gray-300"
          />
          <span className="text-sm">
            {t('payments.form.acceptForm')}{' '}
            <button
              onClick={() => setOpenContractModal(true)}
              className="text-blue-600 hover:underline"
            >
              {t('payments.form.mesafeliSatis')}
            </button>
          </span>
        </div>
        
        <p className="text-sm text-gray-500 text-center">
          {t('payments.form.personalDataText')}{' '}
          <button
            onClick={() => setOpenAydinlatmaMetniModal(true)}
            className="text-blue-600 hover:underline"
          >
            {t('payments.form.aydinlatmaMetni')}
          </button>
        </p>
      </div>

      {/* Action Button */}
      <button
        onClick={handlePaymentClick}
        disabled={!selectedAddress || isAddressesLoading || !acceptedTerms}
        className="w-full py-3 bg-black text-white rounded-md font-medium
                   hover:bg-gray-800 transition-colors duration-200
                   disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        {isAddressesLoading
          ? t('payments.processing')
          : t('payments.confirmAndPay')}
      </button>

      {/* Modals */}
      <Modal
        opened={openContractModal}
        onClose={() => setOpenContractModal(false)}
        title={t('payments.form.mesafeliSatisTitle')}
        size="lg"
      >
        <SatisSozlesmesi />
      </Modal>

      <Modal
        opened={openAydinlatmaMetniModal}
        onClose={() => setOpenAydinlatmaMetniModal(false)}
        title={t('payments.form.aydinlatmaMetniTitle')}
        size="lg"
      >
        <AydinlatmaMetni />
      </Modal>
    </div>
  );
};


const Checkout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPlan,billingCycle, currency } = location.state || {};
  const {
    data: savedAddressesData = [],
    isLoading: isAddressesLoading,
    error: fetchError,
  } = useFetchAddressesQuery();
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [saveAddress] = useSaveAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [deleteAddress] = useDeleteAddressMutation(); // Use delete mutation
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);


  const [openContractModal, setOpenContractModal] = useState(false);
  const [openAydinlatmaMetniModal, setOpenAydinlatmaMetniModal] = useState(false);
  
  const [acceptedTerms, setAcceptedTerms] = useState(false); // Checkbox 1



  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // Modal visibility state
  const [addressToDelete, setAddressToDelete] = useState(null); // Address to be deleted

const [isSubmitting, setIsSubmitting] = useState(false);  
  const { addNotification } = useNotifications();

  useEffect(() => {
    setSavedAddresses(savedAddressesData);
    if (savedAddressesData.length > 0) {
      const lastUsedAddress = savedAddressesData[savedAddressesData.length - 1];
      setSelectedAddress(lastUsedAddress.id);
    }
  }, [savedAddressesData]);

  const formik = useFormik({
    initialValues: {
      title: '',
      name: '',
      surname: '',
      gsmNumber: '',
      email: '',
      registrationAddress: '',
      city: '',
      district: '',
      country: 'Turkey',
      zipCode: '',
      identityNumber: '',
      birthYear: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(3, t('payments.form.title.errorMinLength'))
        .max(90, t('payments.form.title.errorMaxLength'))
        .required(t('payments.form.title.required')),
      name: Yup.string().required(t('payments.form.name.required')),
      surname: Yup.string().required(t('payments.form.surname.required')),
      gsmNumber: Yup.string().required(t('payments.form.gsmNumber.required')),
      email: Yup.string()
        .email(t('payments.form.email.invalidEmail'))
        .required(t('payments.form.email.required')),
      registrationAddress: Yup.string().required(
        t('payments.form.registrationAddress.required')
      ),
      city: Yup.string().required(t('payments.form.city.required')),
      district: Yup.string().required(t('payments.form.district.required')),
      country: Yup.string().required(t('payments.form.country.required')),
      zipCode: Yup.string().required(t('payments.form.zipCode.required')),
      identityNumber: Yup.string()
        .min(11, t('payments.form.identityNumber.errorMinLength'))
        .max(11, t('payments.form.identityNumber.errorMaxLength'))
        .required(t('payments.form.identityNumber.required')),
      birthYear: Yup.number()
        .min(1900, t('payments.form.birthYear.errorMinYear'))
        .max(
          new Date().getFullYear(),
          t('payments.form.birthYear.errorMaxYear')
        )
        .required(t('payments.form.birthYear.required')),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const { user, id, ...sanitizedValues } = values;
        if (editingAddress) {
          const updatedAddress = await updateAddress({
            addressId: editingAddress.id,
            addressData: sanitizedValues,
          }).unwrap();

          setSavedAddresses((prevAddresses) =>
            prevAddresses.map((address) =>
              address.id === editingAddress.id ? updatedAddress : address
            )
          );
          setEditingAddress(null);
        } else {
          const savedAddress = await saveAddress(sanitizedValues).unwrap();
          setSavedAddresses((prevAddresses) => [
            ...prevAddresses,
            savedAddress,
          ]);
          setSelectedAddress(savedAddress.id);
        }
        setIsModalOpen(false);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 1000); // 1000 milliseconds = 1 second
      } catch (error) {
        setIsSubmitting(false);
        console.error('Failed to save or update address', error);
        if (error?.data?.message) {
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: error.data.message,
          });
        } else {
          addNotification({
            id: new Date().getTime(),
            type: 'error',
            message: t('payments.errors.unexpectedError'),
          });
        }
      }
    },
  });

  useEffect(() => {
    if (selectedCity) {
      const selectedCityData = citiesAndDistricts.find(
        (city) => city.text === selectedCity
      );
      setSelectedDistrict(
        selectedCityData.districts.find(
          (district) => district.text === formik.values.district
        )?.text || ''
      );
    }
  }, [selectedCity]);

  const handleAddNewAddress = () => {
    formik.resetForm();
    setEditingAddress(null);
    setIsModalOpen(true);
  };

  const handleEditAddress = (address) => {
    setSelectedCity(address.city);
    setSelectedDistrict(address.district);

    formik.setValues({
      ...address,
      city: address.city,
      district: address.district,
    });

    setEditingAddress(address);
    setIsModalOpen(true);
  };

  const handleSelectAddress = (id) => {
    setSelectedAddress(id);
  };

  const handleDeleteAddress = (addressId) => {
    setAddressToDelete(addressId); // Set the address to be deleted
    setIsConfirmationOpen(true); // Open the confirmation modal
  };
  const confirmDeleteAddress = async () => {
    try {
      await deleteAddress(addressToDelete).unwrap();
      setSavedAddresses(savedAddresses.filter((address) => address.id !== addressToDelete));
      addNotification({
        id: new Date().getTime(),
        type: 'success',
        message: t('payments.deleteAddressSuccess', { defaultValue: 'Address deleted successfully' }),
      });
    } catch (error) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('payments.deleteAddressError', { defaultValue: 'Failed to delete the address' }),
      });
    } finally {
      setIsConfirmationOpen(false); // Close the modal
      setAddressToDelete(null); // Clear the selected address to delete
    }
  };

  const handleSave = () => {
    openConfirmModal({
      title: editingAddress
        ? t('payments.confirmUpdateAddressTitle')
        : t('payments.confirmSaveAddressTitle'),
      children: (
        <Text>
          {editingAddress
            ? t('payments.confirmUpdateAddressText')
            : t('payments.confirmSaveAddressText')}
        </Text>
      ),
      onConfirm: formik.handleSubmit,
    });
  };

  const handlePaymentClick = () => {
    if (!acceptedTerms) {
      addNotification({
        id: new Date().getTime(),
        type: 'error',
        message: t('payments.acceptTheTerms'),
      });
      return;
    }
    const selectedPackage = {
      id: selectedPlan.id,
      price: selectedPlan.price,
      title: selectedPlan.title,
    };

  
    navigate('/payments/payment', {
      state: {
        selectedPackage,
        selectedAddressId: selectedAddress,
        paymentPeriod:billingCycle, // The selected payment period (monthly/yearly)
        currency, // The chosen currency (TRY/USD)
      },
    });
  };
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Container size="lg">
      <LoadingOverlay visible={isAddressesLoading} />

      {fetchError && (
        <Alert title="Error" color="red">
          {fetchError.data.message || t('payments.errors.fetchAddressesError')}
        </Alert>
      )}
  <ConfirmationModal
        isOpen={isConfirmationOpen}
        title={t('payments.deleteAddressTitle')}
        message={t('payments.deleteAddressConfirmation', { defaultValue: 'Bu adresi silmek istediğinize emin misiniz ?' })}
        onConfirm={confirmDeleteAddress}
        onCancel={() => setIsConfirmationOpen(false)}
        t={t}
      />
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          editingAddress
            ? t('payments.modalEditAddressTitle')
            : t('payments.modalAddNewAddressTitle')
        }
        size="xl"
        rightSection={
          <Button onClick={handleSave} color="blue">
            {editingAddress
              ? t('payments.updateAddress')
              : t('payments.saveAddress')}
          </Button>
        }
      >
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing="md">
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label={t('payments.form.title.label')}
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && formik.errors.title}
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={t('payments.form.name.label')}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name}
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={t('payments.form.surname.label')}
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  error={formik.touched.surname && formik.errors.surname}
                  required
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={6}>
                <InputMask
                  mask="+\9\0 999 999 99 99"
                  value={formik.values.gsmNumber}
                  onChange={(event) =>
                    formik.setFieldValue('gsmNumber', event.target.value)
                  }
                >
                  {(inputProps) => (
                    <TextInput
                      {...inputProps}
                      label={t('payments.form.gsmNumber.label')}
                      placeholder={t('payments.form.gsmNumber.placeholder')}
                      error={formik.touched.gsmNumber && formik.errors.gsmNumber}
                      required
                    />
                  )}
                </InputMask>
              </Grid.Col>
              <Grid.Col span={6}>
                <Select
                  label={t('payments.form.city.label')}
                  placeholder={t('payments.form.city.placeholder')}
                  value={selectedCity}
                  onChange={(value) => {
                    setSelectedCity(value);
                    formik.setFieldValue('city', value);
                  }}
                  data={citiesAndDistricts.map((city) => ({
                    value: city.text,
                    label: city.text,
                  }))}
                  required
                  error={formik.touched.city && formik.errors.city}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={6}>
                <Select
                  label={t('payments.form.district.label')}
                  placeholder={t('payments.form.district.placeholder')}
                  value={selectedDistrict}
                  onChange={(value) => {
                    setSelectedDistrict(value);
                    formik.setFieldValue('district', value);
                  }}
                  data={
                    selectedCity
                      ? citiesAndDistricts
                          .find((city) => city.text === selectedCity)
                          .districts.map((district) => ({
                            value: district.text,
                            label: district.text,
                          }))
                      : []
                  }
                  required
                  error={formik.touched.district && formik.errors.district}
                  disabled={!selectedCity}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={t('payments.form.email.label')}
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && formik.errors.email}
                  required
                />
              </Grid.Col>
            </Grid>
            <Textarea
              label={t('payments.form.registrationAddress.label')}
              name="registrationAddress"
              value={formik.values.registrationAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.registrationAddress &&
                formik.errors.registrationAddress
              }
              required
              minRows={4}
            />
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label={t('payments.form.zipCode.label')}
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={formik.touched.zipCode && formik.errors.zipCode}
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={t('payments.form.identityNumber.label')}
                  name="identityNumber"
                  value={formik.values.identityNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.identityNumber &&
                    formik.errors.identityNumber
                  }
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={t('payments.form.birthYear.label')}
                  name="birthYear"
                  value={formik.values.birthYear}
                  onChange={formik.handleChange}
                  error={formik.touched.birthYear && formik.errors.birthYear}
                  required
                />
              </Grid.Col>
            </Grid>
            <Button type="submit" fullWidth color="#011627" disabled={isSubmitting}>
              {editingAddress
                ? t('payments.updateAddress')
                : t('payments.saveAddress')}
            </Button>
          </Stack>
        </form>
      </Modal>

      <Grid gutter="lg">
        <Grid.Col span={isMobile ? 12 : 8}>
          <Card shadow="sm" padding="md">
            <Group justify="space-between" mb="md">
              <Group position="apart">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#e9ecef',
                    borderRadius: '50px',
                    height: '60px',
                    width: '60px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{ height: '1.75rem' }}
                  />
                </div>
                <Stack gap="xs">
                  <Title order={3}>{t('payments.chooseAddress')}</Title>
                  <Text>{t('payments.chooseAddressSubtitle')}</Text>
                </Stack>
              </Group>
              <Button
                color="#011627"
                onClick={handleAddNewAddress}
                variant="filled"
                leftSection={<FontAwesomeIcon icon={faPlus} />}
              >
                {t('payments.addNewAddress')}
              </Button>
            </Group>
            <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
              {isAddressesLoading ? (
                <Skeleton height={50} radius="md" />
              ) : (
                savedAddresses.map((address) => (
                  <Card
                    key={address.id}
                    shadow="sm"
                    padding="lg"
                    mb="md"
                    radius="md"
                    style={{
                      backgroundColor:
                        selectedAddress === address.id ? '#f1f3f5' : '#fff',
                      border:
                        selectedAddress === address.id
                          ? '1px solid #1c7ed6'
                          : '1px solid #e9ecef',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSelectAddress(address.id)}
                  >
                    <Group
                      position="apart"
                      justify="space-between"
                      align="center"
                    >
                      <Group>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            color:
                              selectedAddress === address.id
                                ? '#011627'
                                : '#ced4da',
                          }}
                          size="lg"
                        />
                        <div>
                          <Text weight={700} size="lg">
                            {address.title} - {address.name}
                          </Text>
                          <Text size="sm">{address.gsmNumber}</Text>
                          <Text size="sm">{address.registrationAddress}</Text>
                          <Text weight={500} size="sm">
                            {address.city} / {address.district} /{' '}
                            {address.country}
                          </Text>
                        </div>
                      </Group>
                      <Button
                          variant="subtle"
                          color="red"
                          size="xs"
                          style={{position: 'absolute', top: '5px', right: '7px'}}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAddress(address.id); // Trigger delete address
                          }}
                        >
                          <FontAwesomeIcon icon={faXmarkSquare} color="#011627" style={{ height: '18px' }} />
                        </Button>
                      <Button
                        variant="subtle"
                        color="blue"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditAddress(address);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#011627"
                          style={{ marginRight: '5px' }}
                        />
                        <Text size="sm" color="#011627">
                          {t('payments.edit')}
                        </Text>
                      </Button>
                    </Group>
                  </Card>
                ))
              )}
            </div>
            <Stack>
              <Button color="#011627" onClick={() => navigate(-1)}>
                {t('payments.goBack')}
              </Button>
            </Stack>
          </Card>
        </Grid.Col>
        <Grid.Col span={isMobile ? 12 : 4}>
        <OrderSummary
  selectedPlan={selectedPlan}
  isAddressesLoading={isAddressesLoading}
  selectedAddress={selectedAddress}
  acceptedTerms={acceptedTerms}
  setAcceptedTerms={setAcceptedTerms}
  handlePaymentClick={handlePaymentClick}
  t={t}
/>
        </Grid.Col>
      </Grid>
    

      <Modal opened={openContractModal} onClose={() => setOpenContractModal(false)} title={t('payments.form.mesafeliSatisTitle')}>
    <SatisSozlesmesi />
      </Modal>

      <Modal opened={openAydinlatmaMetniModal} onClose={() => setOpenAydinlatmaMetniModal(false)} title={t('payments.form.aydinlatmaMetniTitle')}>
        {/* Content for Müşteri Aydınlatma Metni */}
       <AydinlatmaMetni />
      </Modal>

    </Container>
  );
};

export default Checkout;
