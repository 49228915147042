import React, { createContext, useState, useContext } from 'react';

const TutorialContext = createContext();

export const TutorialProvider = ({ children }) => {
  const [isTutorialActive, setIsTutorialActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentTool, setCurrentTool] = useState(null);

  // Check if the tutorial has already been completed for the given tool
  const hasCompletedTutorial = (tool) => {
    return localStorage.getItem(`tutorial_${tool}`) === 'completed';
  };

  // Start the tutorial if it hasn't been completed yet
  const startTutorial = (tool) => {
    if (!hasCompletedTutorial(tool)) {
      setCurrentTool(tool);
      setCurrentStep(1);
      setIsTutorialActive(true);
    }
  };

  // End the tutorial and store that it has been completed for the given tool
  const endTutorial = () => {
    if (currentTool) {
      localStorage.setItem(`tutorial_${currentTool}`, 'completed');
    }
    setIsTutorialActive(false);
    setCurrentTool(null);
    setCurrentStep(1);
  };

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const previousStep = () => setCurrentStep((prev) => Math.max(1, prev - 1));

  return (
    <TutorialContext.Provider
      value={{
        isTutorialActive,
        currentStep,
        currentTool,
        startTutorial,
        endTutorial,
        nextStep,
        previousStep,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = () => useContext(TutorialContext);
