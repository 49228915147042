import React, { useEffect, useState } from 'react';
import { AlertCircle, CheckCircle2, XCircle, Info, X } from 'lucide-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const CustomNotification = ({ message, type = 'info' }) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 4500);
      
      return () => clearTimeout(timer);
    }
  }, [message]);

  const getNotificationConfig = (type) => {
    const configs = {
      success: {
        icon: CheckCircle2,
        className: 'bg-green-50 text-green-800 border-green-200',
        iconClass: 'text-green-500'
      },
      error: {
        icon: XCircle,
        className: 'bg-red-50 text-red-800 border-red-200',
        iconClass: 'text-red-500'
      },
      warning: {
        icon: AlertCircle,
        className: 'bg-yellow-50 text-yellow-800 border-yellow-200',
        iconClass: 'text-yellow-500'
      },
      info: {
        icon: Info,
        className: 'bg-blue-50 text-blue-800 border-blue-200',
        iconClass: 'text-blue-500'
      }
    };
    return configs[type] || configs.info;
  };

  if (!visible || !message) return null;

  const config = getNotificationConfig(type);
  const Icon = config.icon;

  return (
    <div
      className={`
        flex items-center gap-3 p-4 max-w-md w-full
        rounded-lg border shadow-lg transition-all duration-300 ease-in-out
        ${config.className}
      `}
    >
      <Icon className={`h-5 w-5 ${config.iconClass}`} />
      
      <div className="flex-1">
        <p className="text-sm font-medium">
          {message}
        </p>
      </div>

      <button
        onClick={() => setVisible(false)}
        className="p-1 rounded-full hover:bg-black/5 transition-colors"
      >
        <X className="h-4 w-4" />
      </button>
    </div>
  );
};




export default CustomNotification;