import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TokenAlert from '../components/common/TokenAlert';

const TokenCounter = ({ tokenCount, tokenBalance = 0, isError }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null); // Track which type of alert is showing
  const { t } = useTranslation();

  useEffect(() => {
    // Reset alert type when isError changes to false
    if (!isError) {
      setAlertType(null);
    }

    // Handle error alert
    if (isError && tokenBalance < tokenCount) {
      setShowAlert(true);
      setAlertType('error');
      return;
    }

    // Only check low balance if there's no error and no alert currently showing
    if (!isError && !alertType) {
      const lastShownTime = localStorage.getItem('tokenAlertLastShown');
      const currentTime = Date.now();
      const tenMinutes = 10 * 60 * 1000;

      if (tokenBalance < 100 && (!lastShownTime || currentTime - parseInt(lastShownTime) >= tenMinutes)) {
        setShowAlert(true);
        setAlertType('lowBalance');
        localStorage.setItem('tokenAlertLastShown', currentTime.toString());
      }
    }
  }, [tokenBalance, isError, tokenCount]);

  const handleDismiss = () => {
    setShowAlert(false);
    setAlertType(null);
    
    // Only update localStorage when dismissing low balance alert
    if (!isError && tokenBalance < 100) {
      localStorage.setItem('tokenAlertLastShown', Date.now().toString());
    }
  };

  return (
    <>
      <div id="tokenCounter" className='flex justify-center' style={{color: '#71717a'}} >
        <div className="text-sm text-muted-foreground text-center">
          {t('balance')}{' '}
          <span className="font-medium">
            {tokenBalance}
          </span>
        </div>
        <span id="tokenCounterSeparator">{' '}|{' '}</span>
        <div className="text-sm text-muted-foreground text-center">
          {' '}{t('cost')}{' '}
          <span className="font-medium">
            {Math.ceil(tokenCount)}
          </span>
        </div>
      </div>

      {showAlert && (
        <TokenAlert
          tokenBalance={tokenBalance}
          requiredTokens={tokenCount}
          onClose={handleDismiss}
          isError={alertType === 'error'}
          t={t}
        />
      )}
    </>
  );
};

export default TokenCounter;