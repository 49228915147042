// src/redux/api/paraphraseApiSlice.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './authApiSlice'; // Import baseQueryWithReauth from authApiSlice

export const questionAnswerApiSlice = createApi({
    reducerPath: 'questionAnswerApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['QuestionAnswer'],
    endpoints: (builder) => ({
        getAnswer: builder.mutation({
            query: (questionAnswerData) => ({
                url: '/results/question',
                method: 'POST',
                body: questionAnswerData,
            }),
            invalidatesTags: ['QuestionAnswer'],
        }),
        // Add more paraphrasing-related endpoints here
    }),
});

export const { useGetAnswerMutation } = questionAnswerApiSlice;
