const KVKK = () => {
      return (
        <div>
          <p style={{textAlign: 'justify'}}><strong>Gizlilik ve Kişisel Verileri Koruma Politikası</strong></p>
          <p style={{textAlign: 'justify'}}><strong>1. Amaç ve Kapsam</strong></p>
          <p style={{textAlign: 'justify'}}>Easy (Easy Eğitim ve Teknoloji İşletmeleri) olarak kişisel verilerinizin güvenliği hususuna azami hassasiyet göstermekteyiz. Kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK)’na uygun olarak işlenmekte ve muhafaza edilmektedir.</p>
          <p style={{textAlign: 'justify'}}>Bu web sitesini ziyaret etmeniz ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmanız sırasında, size ve talep ettiğiniz hizmetlere ilişkin olarak elde ettiğimiz bilgilerin ne şekilde kullanılacağı ve korunacağı, işbu "Gizlilik Politikası"nda belirtilen şartlara tabidir. Bu web sitesini ziyaret etmekle ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmayı talep etmekle işbu "Gizlilik Politikası"nda belirtilen şartları kabul etmektesiniz.</p>
          <p style={{textAlign: 'justify'}}><strong>2. Tanımlar</strong></p>
          <p style={{textAlign: 'justify'}}><strong>Easy:</strong> Nispetiye Mah. Tuğcular Sk. No:15 D:9 34337 Beşiktaş, İstanbul adresine mukim Easy Eğitim ve Teknoloji İşletmeleri</p>
          <p style={{textAlign: 'justify'}}><strong>Açık Rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</p>
          <p style={{textAlign: 'justify'}}><strong>Anonim Hâle Getirme:</strong> Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesi.</p>
          <p style={{textAlign: 'justify'}}><strong>Başvuru Formu:</strong> Kişisel veri sahiplerinin haklarını kullanmak için yapacakları başvuruyu içeren “6698 Sayılı Kişisel Verilerin Korunması Kanunu Gereğince İlgili Kişi (Veri Sahibi) Tarafından Veri Sorumlusuna Yapılacak Başvurulara İlişkin Başvuru Formu”.</p>
          <p style={{textAlign: 'justify'}}><strong>Çalışan:</strong> Easy personeli.</p>
          <p style={{textAlign: 'justify'}}><strong>Çalışan Adayı:</strong>Easy’e herhangi bir yolla iş başvurusunda bulunmuş ya da özgeçmiş ve ilgili bilgilerini şirketimizin incelemesine açmış olan gerçek kişiler</p>
          <p style={{textAlign: 'justify'}}><strong>İmha:</strong> Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi.</p>
          <p style={{textAlign: 'justify'}}><strong>İş Ortağı:</strong>Easy’nin ticari faaliyetlerini yürütürken, birlikte muhtelif projeler yürütmek, hizmet almak gibi amaçlarla iş ortaklığı kurduğu taraflar. &nbsp;</p>
          <p style={{textAlign: 'justify'}}><strong>Kanun/KVKK:</strong> 6698 Sayılı Kişisel Verilerin Korunması Kanunu</p>
          <p style={{textAlign: 'justify'}}><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</p>
          <p style={{textAlign: 'justify'}}><strong>Kişisel Verilerin İşlenmesi:</strong>Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem.</p>
          <p style={{textAlign: 'justify'}}><strong>Kişisel Verilerin Silinmesi:</strong>Kişisel verilerin silinmesi, kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi.</p>
          <p style={{textAlign: 'justify'}}><strong>Kişisel Verilerin Yok Edilmesi:</strong>Kişisel verilerin yok edilmesi, kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi.</p>
          <p style={{textAlign: 'justify'}}><strong>Kişisel Verilerin Anonim Hale Getirilmesi:</strong>Kişisel verilerin anonim hale getirilmesi, kişisel verilerin başka verilerle eşleştirilirse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi.</p>
          <p style={{textAlign: 'justify'}}><strong>Kurul:</strong> Kişisel Verileri Koruma Kurulu</p>
          <p style={{textAlign: 'justify'}}><strong>Kurum:</strong> Kişisel Verileri Koruma Kurumu</p>
          <p style={{textAlign: 'justify'}}><strong>Müşteri:</strong> Easy ile herhangi bir sözleşmesel ilişkisi olup olmadığına bakılmaksızın Easy iş birimlerinin yürüttüğü operasyonlar kapsamında Easy iş ilişkileri üzerinden kişisel verileri elde edilen gerçek kişiler.</p>
          <p style={{textAlign: 'justify'}}><strong>Özel Nitelikli Kişisel Veri:</strong>Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik veriler.</p>
          <p style={{textAlign: 'justify'}}><strong>Periyodik İmha:</strong> Kanunda yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla resen gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi.</p>
          <p style={{textAlign: 'justify'}}><strong>Politika:</strong> Easy tarafından, Kanun uyarınca hazırlanan, işbu kişisel verilerin korunması ve işlenmesi politikası.</p>
          <p style={{textAlign: 'justify'}}><strong>işletmeHissedarı:</strong>Easy’nin hissedarı gerçek kişiler.</p>
          <p style={{textAlign: 'justify'}}><strong>işletmeYetkilisi:</strong>İşletme adına imza yetkisine sahip gerçek kişiler . &nbsp;</p>
          <p style={{textAlign: 'justify'}}><strong>Tedarikçi:</strong> Easy’nin ticari faaliyetlerini yürütürken,&nbsp;&nbsp;Easy’nin emir ve talimatlarına uygun olarak, sözleşme temelli olarak,&nbsp;&nbsp;Easy’e hizmet sunan taraflar.</p>
          <p style={{textAlign: 'justify'}}><strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişi.</p>
          <p style={{textAlign: 'justify'}}><strong>Veri Kayıt Sistemi:</strong> Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini</p>
          <p style={{textAlign: 'justify'}}><strong>Veri Sorumlusu:</strong> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişi.</p>
          <p style={{textAlign: 'justify'}}><strong>Veri Sahibi/İlgili Kişi:</strong>Kişisel verisi işlenen gerçek kişi.</p>
          <p style={{textAlign: 'justify'}}><strong>Yönetmelik:</strong> 28.10.2017 Tarihli Resmi Gazete’de yayımlanan “Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik”.</p>
          <p style={{textAlign: 'justify'}}><strong>Ziyaretçi:</strong> Easy’nin faaliyet gösterdiği fiziksel yerleşkelere çeşitli amaçlarla girmiş olan veya internet sitelerimizi ziyaret eden gerçek kişiler.</p>
          <p style={{textAlign: 'justify'}}><strong>3. KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN USUL VE ESASLAR</strong></p>
          <p style={{textAlign: 'justify'}}><strong>&nbsp;3.1. GENEL İLKELER</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Anayasa’nın m. 20/III kişisel verilerin ancak kanunda öngörülen hallerde veya kişinin açık rızasıyla işlenebileceği belirtilerek kişisel verilerin korunması güvence altına alınmıştır. Kişisel veri sahiplerine tanınan bu hak doğrultusunda Easy, kişisel verileri ilgili mevzuatta belirtilen ilkeler doğrultusunda veya kişinin açık rızasının bulunduğu durumlarda aşağıdaki ilkelere uygun olarak işlemektedir:</p>
          <p style={{textAlign: 'justify'}}><strong>3.1.1. Hukuka ve Dürüstlük Kuralına Uygun İşleme</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, Kişisel Verilerin işlenmesinde, tüm hukuki düzenlemeler ve dürüstlük kuralına uygun hareket etmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>3.1.2. Kişisel Verilerin Doğru ve Gerektiğinde Güncel Olmasını Sağlama</strong></p>
          <p style={{textAlign: 'justify'}}>Easy tarafından işlenen Kişisel Verilerin doğru ve güncel duruma uygun olması için gerekli tedbirler alınmakta ve işlenmekte olan verilerin gerçek durumu yansıtmasını sağlamak amacıyla bilgilendirmelerde bulunularak Veri Sahipleri’ne gerekli imkânlar tanınmaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.1.3. Belirli, Açık ve Meşru Amaçlarla İşleme</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, meşru ve hukuka uygun olan kişisel veri işleme amacını açık ve kesin olarak belirlemektedir. Easy, yürütmekte olduğu ticari faaliyet ile bağlantılı ve bunlar için gerekli olan kadar işlemektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>3.1.4. İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olma</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, Kişisel Verileri belirlenen amaçların gerçekleştirilebilmesine elverişli bir biçimde işlemekte ve amacın gerçekleştirilmesi ile ilgili olmayan veya ihtiyaç duyulmayan Kişisel Verilerin işlenmesinden kaçınmaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.1.5. İlgili Mevzuatta Öngörülen veya İşlendikleri Amaç İçin Gerekli Olan Süre Kadar Muhafaza Etme</strong></p>
          <p style={{textAlign: 'justify'}}>Easy tarafından işlenen kişisel veriler, ancak ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilmektedir. Bu kapsamda, Easy, ilgili mevzuatta verilerin saklanması için öngörülen bir süre varsa bu süreye uymakta; böyle bir süre yoksa verileri, ancak işlendikleri amaç için gerekli olan süre kadar muhafaza etmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>3.2. KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</strong></p>
          <p style={{textAlign: 'justify'}}>Easy tarafından elde edilen Kişisel Verileriniz, aşağıda açıklanan kapsamlar dahilinde işlenebilecektir:</p>
          <p style={{textAlign: 'justify'}}>Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde hukuki yükümlülüklerimizin yerine getirilmesini sağlamak,</p>
          <p style={{textAlign: 'justify'}}>Operasyonel faaliyetlerinin yerine getirilmesi için yazılım hizmetleri ve diğer dış kaynak hizmetlerinin sağlanması,</p>
          <p style={{textAlign: 'justify'}}>Easy tarafından sunulan hizmet ürün ve projelerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek önerilmesi, ürün, hizmet ve projeler hakkında bilgi verilmesi,</p>
          <p style={{textAlign: 'justify'}}>Etkin bir müşteri hizmetinin sunulabilmesi,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Hizmet ve tekliflerin sunulması,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Her türlü promosyon, kampanya, çekilişler hakkında bilgi verilmesi,</p>
          <p style={{textAlign: 'justify'}}>Her türlü pazarlama ve reklam faaliyetlerinin yürütülebilmesi,</p>
          <p style={{textAlign: 'justify'}}>Ziyaretçi profillerinin belirlenebilmesi,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Easy’nin ticari güvenilirliğinin sağlanabilmesi,</p>
          <p style={{textAlign: 'justify'}}>İstek, talep ve şikayetlerin cevaplanarak çözümlenmesinin sağlanması,</p>
          <p style={{textAlign: 'justify'}}>Sözleşme kapsamında ve hizmet standartları çerçevesinde Müşteri’lere ve Ziyaretçiler’e destek hizmetinin sağlanması, &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Pazar araştırmaları ve istatistiksel çalışmalar yapılabilmesi,</p>
          <p style={{textAlign: 'justify'}}>Easy ile iş ilişkisi içinde bulunan kişiler ile irtibat sağlanması,</p>
          <p style={{textAlign: 'justify'}}>Pazarlama, uyum yönetimi, satıcı/tedarikçi yönetimi,</p>
          <p style={{textAlign: 'justify'}}>Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası,</p>
          <p style={{textAlign: 'justify'}}>Bilgi teknolojileri altyapısının oluşturulması ve yönetilmesi,</p>
          <p style={{textAlign: 'justify'}}>Çalışanların Veri Sahibi bilgilerine erişim yetkilerinin planlanması ve icrası,</p>
          <p style={{textAlign: 'justify'}}>Faturalandırma da dahil, finans ve/veya muhasebe işlemlerinin takibi,</p>
          <p style={{textAlign: 'justify'}}>Hukuk işlerinin takibi,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Kurumsal iletişim faaliyetlerinin planlanması ve icrası,</p>
          <p style={{textAlign: 'justify'}}>Verilerin doğru ve güncel olmasının sağlanması.</p>
          <p style={{textAlign: 'justify'}}>Easy ana sözleşmesinde belirtilen ticari faaliyetlerin mevzuata ve Easy politikalarına uygun olarak yerine getirilmesi için ilgili birimler tarafından gerekli çalışmaların yapılması ve bu doğrultuda faaliyetlerin yürütülmesi,</p>
          <p style={{textAlign: 'justify'}}>Easy’nin kısa, orta, uzun vadeli ticari politikalarının tespiti, planlanması ve uygulanması,</p>
          <p style={{textAlign: 'justify'}}><strong>3.3. KİŞİSEL VERİLERİN İŞLENME ŞARTLARI</strong></p>
          <p style={{textAlign: 'justify'}}>Kanun ile kişisel verilerin işlenme koşulları düzenlenmiş olup, Easy tarafından kişisel veriler aşağıda belirtilen söz konusu koşullara uygun olarak işlenmektedir.</p>
          <p style={{textAlign: 'justify'}}>Kanun'da sayılan istisnalar dışında, Easy ancak veri sahiplerinin açık rızasını temin etmek suretiyle kişisel veri işlemektedir. Kanun’da sayılan aşağıdaki hallerin varlığı durumunda ise, veri sahibinin açık rızası olmasa dahi kişisel veriler işlenebilmektedir.</p>
          <p style={{textAlign: 'justify'}}>Kanunlarda açıkça öngörülmesi,</p>
          <p style={{textAlign: 'justify'}}>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,</p>
          <p style={{textAlign: 'justify'}}>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</p>
          <p style={{textAlign: 'justify'}}>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</p>
          <p style={{textAlign: 'justify'}}>Veri sahibinin kendisi tarafından alenileştirilmiş olması,</p>
          <p style={{textAlign: 'justify'}}>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</p>
          <p style={{textAlign: 'justify'}}>Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</p>
          <p style={{textAlign: 'justify'}}><strong>3.4. KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN ÖNLEMLER</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, KVK Kanunu’nun 12. maddesine uygun olarak, işlemekte olduğu kişisel verilerin hukuka aykırı olarak işlenmesini önlemek, verilere hukuka aykırı olarak erişilmesini önlemek ve verilerin muhafazasını sağlamak için uygun güvenlik düzeyini sağlamaya yönelik gerekli teknik ve idari tedbirleri almakta, bu kapsamda gerekli denetimleri yapmak veya yaptırmaktadır.</p>
          <p style={{textAlign: 'justify'}}>Bu kapsamda Easy tarafından gerekli (a) idari ve (b) teknik tedbirler alınmalı, (c) işletmebünyesinde denetim sistemi kurulmalı ve (d) kişisel verilerin kanuni olmayan yollarla ifşası durumunda KVK Kanunu’nda öngörülen tedbirler alınmalıdır. Easy, kişisel verilerin hukuka uygun işlenmesini sağlamak için, teknolojik imkânlar ve uygulama maliyetine göre teknik ve idari tedbirler almaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.4.1. Kişisel Verilerin Hukuka Uygun İşlenmesini Sağlamak ve Kişisel Verilere Hukuka Aykırı Erişilmesini Önlemek ve Kişisel Verilerin Güvenli Ortamda Saklanması için Alınan Teknik Tedbirler</strong></p>
          <p style={{textAlign: 'justify'}}>Kişisel verilerin korunmasına ilişkin olarak, teknolojinin imkân verdiği ölçüde teknik önlemler alınmalı ve alınan önlemler periyodik olarak güncellenmeli ve yenilenmelidir.</p>
          <p style={{textAlign: 'justify'}}>Teknik konularda, uzman personel istihdam edilmelidir.</p>
          <p style={{textAlign: 'justify'}}>Alınan önlemlerin uygulanmasına yönelik düzenli aralıklarla denetim yapılmalıdır,</p>
          <p style={{textAlign: 'justify'}}>Güvenliği temin edecek yazılım ve donanımlar kurulmalıdır,</p>
          <p style={{textAlign: 'justify'}}>Easy tarafından işlenmekte olan kişisel verilere erişim yetkisi, belirlenen işleme amacı doğrultusunda ilgili işletmeçalışanı ile sınırlandırılmalıdır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.4.2. Kişisel Verilerin Hukuka Uygun İşlenmesini Sağlamak ve Kişisel Verilere Hukuka Aykırı Erişilmesini Önlemek ve Kişisel Verilerin Güvenli Ortamda Saklanması için Alınan İdari Tedbirler</strong></p>
          <p style={{textAlign: 'justify'}}>Easy kişisel verilerin korunması hukukuna ilişkin olarak çalışanlarını bilgilendirmekte ve eğitmektedir.</p>
          <p style={{textAlign: 'justify'}}>Easy tarafından kişisel verilerin hukuka uygun olarak aktarıldığı kişiler ile akdedilen sözleşmelere; kişisel verilerin aktarıldığı kişilerin, kişisel verilerin korunması amacıyla gerekli güvenlik tedbirlerini alacağına ve kendi kuruluşlarında bu tedbirlere uyulmasını sağlayacağına ilişkin hükümler eklenmektedir.</p>
          <p style={{textAlign: 'justify'}}>Easy tarafından gerçekleştirilen süreçler detaylı olarak incelenmeli, süreç kapsamında yürütülen kişisel veri işleme faaliyetleri her birim özelinde tespit edilmelidir. Bu kapsamda, yürütülen veri işleme faaliyetlerinin KVK Kanunu'nda öngörülen kişisel veri işleme şartlarına uygunluğunun sağlanması için atılması gereken adımlar belirlenmelidir.</p>
          <p style={{textAlign: 'justify'}}>Easy, işletmeyapılarına göre hukuksal uyum gerekliliklerinin sağlanması için yerine getirilmesi gereken uygulamaları tespit etmeli, bu uygulamaların denetimini ve sürekliliğini sağlamak için gerekli idari tedbirler, işletmeiçi politikalar ve eğitimler düzenlemelidir.</p>
          <p style={{textAlign: 'justify'}}>Easy ile çalışanlar arasındaki hukuki ilişkiyi yöneten sözleşme ve belgelere, Easy’nin talimatları ve kanunla getirilen istisnalar dışında, kişisel verileri işlememe, ifşa etmeme ve kullanmama yükümlülüğü getiren kayıtlar konulmakta ve bu konuda çalışanların farkındalığı yaratılmakta ve denetimler yürütülmektedir.</p>
          <p style={{textAlign: 'justify'}}>Çalışanlar, öğrendikleri kişisel verileri KVK Kanunu hükümlerine aykırı olarak başkasına açıklayamayacağı ve işleme amacı dışında kullanamayacağı ve bu yükümlülüğün görevden ayrılmalarından sonra da devam edeceği konusunda bilgilendirilmekte ve bu doğrultuda kendilerinden gerekli taahhütler alınmaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.4.3. Kişisel Verilerin Korunmasına İlişkin Alınan Tedbirlerin Denetim Faaliyetleri</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Easy, KVK Kanunu’nun 12. Maddesine uygun olarak, kişisel verilerin korunması ve güvenliğinin sağlanması kapsamında alınan teknik ve idari tedbirlerin gerekli denetimlerini yapmakta veya yaptırmaktadır. Bu denetim sonuçları Easy’nin iç işleyişi kapsamında konu ile ilgili birime raporlanmakta ve alınan tedbirlerin iyileştirilmesi için gerekli faaliyetler yürütülmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>3.5. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENME ŞARTLARI</strong></p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi açısından korunmasının çeşitli açılardan daha kritik önem teşkil ettiğine inanılan Özel Nitelikli Kişisel Verilerin işlenmesinde, Easy tarafından özel hassasiyet gösterilmektedir.</p>
          <p style={{textAlign: 'justify'}}>Kanun’un 6. maddesinde, hukuka aykırı olarak işlendiğinde kişilerin mağduriyetine veya ayrımcılığa sebep olma riski taşıyan bir takım kişisel veriler “özel nitelikli” olarak belirlenmiştir. Bu veriler; ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik verilerdir.</p>
          <p style={{textAlign: 'justify'}}>Özel Nitelikli Kişisel Veriler, Easy tarafından, Kanun’a uygun bir şekilde, Kurul’ca belirlenecek yeterli önlemlerin alınması kaydıyla, aşağıdaki şartların varlığı halinde işlenmektedir: &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin açık rızası var ise veya</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin açık rızası yok ise;</p>
          <p style={{textAlign: 'justify'}}>(i) Veri Sahibi’nin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler, kanunlarda öngörülen hallerde, &nbsp;</p>
          <p style={{textAlign: 'justify'}}>(ii) Veri Sahibi’nin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel verileri ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla,</p>
          <p style={{textAlign: 'justify'}}>sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından işlenmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>3.6. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN ÖNLEMLER</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Easy, Kanun’un 6. Maddesinde yer alan, Özel Nitelikli Kişisel Verilerin işlenmesinde, Kurul’un 31.01.2018 Tarihli ve 2018/10 Numaralı kararı uyarınca, veri sorumlusu sıfatıyla, aşağıda belirtilen önlemleri almaktadır:</p>
          <p style={{textAlign: 'justify'}}><strong>3.6.1. Özel nitelikli kişisel verilerin işlenmesi süreçlerinde yer alan Çalışan’lara yönelik,</strong></p>
          <p style={{textAlign: 'justify'}}>Kanun ve buna bağlı yönetmelikler ile Özel Nitelikli Kişisel Veri güvenliği konularında düzenli olarak eğitimler verilmektedir,</p>
          <p style={{textAlign: 'justify'}}>Gizlilik sözleşmelerinin yapılmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Verilere erişim yetkisine sahip kullanıcıların, yetki kapsamları ve süreleri net olarak tanımlanmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Periyodik olarak yetki kontrolleri gerçekleştirilmektedir,</p>
          <p style={{textAlign: 'justify'}}>Görev değişikliği olan ya da işten ayrılan Çalışanlar’ın bu alandaki yetkileri derhal kaldırılmaktadır. Bu kapsamda, Veri Sorumlusu tarafından kendisine tahsis edilen envanteri iade almaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.6.2. Özel Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği ortamlar, elektronik ortam ise,</strong></p>
          <p style={{textAlign: 'justify'}}>Kişisel Veriler, kriptografik yöntemler kullanılarak muhafaza edilmektedir,</p>
          <p style={{textAlign: 'justify'}}>Kriptografik anahtarlar güvenli ve farklı ortamlarda tutulmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Veriler üzerinde gerçekleştirilen tüm hareketlerin işlem kayıtları güvenli olarak loglanmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin bulunduğu ortamlara ait güvenlik güncellemelerinin sürekli takip edilmekte, gerekli güvenlik testleri düzenli olarak yapılmakta/yaptırılmakta, test sonuçları kayıt altına alınmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilere bir yazılım aracılığı ile erişiliyor ise bu yazılıma ait kullanıcı yetkilendirmeleri yapılmakta, bu yazılımların güvenlik testleri düzenli olarak yapılmakta/yaptırılmakta, test sonuçları kayıt altına alınmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilere uzaktan erişim gerekiyorsa en az iki kademeli kimlik doğrulama sisteminin sağlanmaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.6.3. Özel Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği ortamlar, fiziksel ortam ise;</strong></p>
          <p style={{textAlign: 'justify'}}>Özel Nitelikli Kişisel Verilerin bulunduğu ortamın niteliğine göre yeterli güvenlik önlemleri (elektrik kaçağı, yangın, su baskını, hırsızlık vb. durumlara karşı) alınmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Bu ortamların fiziksel güvenliğinin sağlanarak yetkisiz giriş çıkışlar engellenmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>3.6.4. Özel Nitelikli Kişisel Veriler aktarılacaksa,</strong></p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin e-posta yoluyla aktarılması gerekiyorsa şifreli olarak kurumsal e-posta adresiyle veya Kayıtlı Elektronik Posta (KEP) hesabı kullanılarak aktarılmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Taşınabilir Bellek, CD, DVD gibi ortamlar yoluyla aktarılması gerekiyorsa kriptografik yöntemlerle şifrelenmekte ve kriptografik anahtar farklı ortamda tutulmaktadır,</p>
          <p style={{textAlign: 'justify'}}>Farklı fiziksel ortamlardaki sunucular arasında aktarma gerçekleştiriliyorsa, sunucular arasında VPN kurularak veya sFTP yöntemiyle veri aktarımı gerçekleştirilmektedir,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin kağıt ortamı yoluyla aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler alınmakta ve evrak "Gizli ” formatta gönderilmektedir.</p>
          <p style={{textAlign: 'justify'}}>Yukarıda belirtilen önlemlerin yanı sıra Kişisel Verileri Koruma Kurumunun internet sitesinde yayımlanan Kişisel Veri Güvenliği Rehberinde belirtilen uygun güvenlik düzeyini temin etmeye yönelik teknik ve idari tedbirler de dikkate alınmalıdır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.7. İNTERNET SİTESİ ZİYARETÇİ VERİLERİNİN İŞLENMESİ</strong></p>
          <p style={{textAlign: 'justify'}}>Easy sahibi olduğu internet sitelerinde; bu siteleri ziyaret eden kişilerin sitelerdeki ziyaretlerini ziyaret amaçlarıyla uygun bir şekilde gerçekleştirmelerini temin etmek; kendilerine özelleştirilmiş içerikler gösterebilmek ve çevrimiçi reklamcılık faaliyetlerinde bulunabilmek maksadıyla teknik vasıtalarla (Kurabiyeler-cookie gibi) site içerisindeki internet hareketlerini kaydedilmektedir (“Çerez Politikası”).&nbsp;&nbsp;Easy’nin yapmış olduğu bu faaliyetlere ilişkin kişisel verilerin korunması ve işlenmesine ilişkin detaylı açıklamalar Politika metni içerisinde yer almaktadır.</p>
          <p style={{textAlign: 'justify'}}><strong>3.8. KİŞİSEL VERİLERİN AKTARILMASI</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, hukuka uygun olarak elde etmiş olduğu Kişisel Verileri, veri işleme amaçları doğrultusunda, gerekli güvenlik önlemlerini alarak, Veri Sahibi’nin Kişisel Verileri’ni ve/veya Özel Nitelikli Kişisel Verileri’ni üçüncü kişilere aktarabilmektedir. Bu doğrultuda, Easy, Kişisel verileri, işbu Politika’da belirtilen işleme şartlarından ve aşağıda belirtilen şartlardan birinin varlığı halinde üçüncü kişilere aktarabilecektir.</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin açık rızası var ise,</p>
          <p style={{textAlign: 'justify'}}>Kanunlarda Kişisel Veri’nin aktarılacağına ilişkin açık bir düzenleme var ise,</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün korunması için zorunlu ise ve Veri Sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;</p>
          <p style={{textAlign: 'justify'}}>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin aktarılması gerekli ise,</p>
          <p style={{textAlign: 'justify'}}>Easy’un hukuki yükümlülüğünü yerine getirmesi için kişisel veri aktarımı zorunlu ise,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Veriler, Veri Sahibi tarafından alenileştirilmiş ise,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Veri aktarımı bir hakkın tesisi, kullanılması veya korunması için zorunlu ise,</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Easy’nin meşru menfaatleri için kişisel veri aktarımı zorunlu ise.</p>
          <p style={{textAlign: 'justify'}}><strong>3.9. KİŞİSEL VERİLERİN YURTDIŞINA AKTARILMASI</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, meşru ve hukuka uygun Kişisel Veri işleme amaçları doğrultusunda Veri Sahibi’nin kişisel verilerini, açık rızası var ise veya, Veri Sahibi’nin açık rızası yok ise, aşağıdaki hallerden birinin varlığı durumunda, Kişisel verileri Yeterli korumaya sahip veya yeterli korumayı taahhüt eden veri sorumlusunun bulunduğu yabancı ülkelere aktarabilecektir:</p>
          <p style={{textAlign: 'justify'}}>Kanunlarda Kişisel Verinin aktarılacağına ilişkin açık bir düzenleme var ise,</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün korunması için zorunlu ise ve Veri Sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;</p>
          <p style={{textAlign: 'justify'}}>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin aktarılması gerekli ise,</p>
          <p style={{textAlign: 'justify'}}>Easy’nin hukuki yükümlülüğünü yerine getirmesi için kişisel veri aktarımı zorunlu ise,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Veriler, Veri Sahibi tarafından alenileştirilmiş ise,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Veri aktarımı bir hakkın tesisi, kullanılması veya korunması için zorunlu ise,</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Easy’nin meşru menfaatleri için kişisel veri aktarımı zorunlu ise.</p>
          <p style={{textAlign: 'justify'}}><strong>3.10. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN YURTDIŞINA AKTARILMASI</strong></p>
          <p style={{textAlign: 'justify'}}>Easy, gerekli özeni göstererek, gerekli güvenlik tedbirlerini ve Kurul tarafından öngörülen yeterli önlemleri alarak, meşru ve hukuka uygun Kişisel Veri işleme amaçları doğrultusunda, Veri Sahibi’nin Özel Nitelikli Kişisel Veriler’ini aşağıdaki durumlarda yeterli korumaya sahip veya yeterli korumayı taahhüt eden veri sorumlusunun bulunduğu yabancı ülkelere aktarabilmektedir.</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin açık rızası var ise veya</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin açık rızası yok ise;</p>
          <p style={{textAlign: 'justify'}}>(i) Veri Sahibi’nin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler, kanunlarda öngörülen hallerde, &nbsp;</p>
          <p style={{textAlign: 'justify'}}>(ii) Veri Sahibi’nin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel verileri ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla,</p>
          <p style={{textAlign: 'justify'}}>sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından işlenmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>4. KİŞİSEL VERİLERİN SAKLANMA SÜRELERİNE İLİŞKİN ESASLAR</strong></p>
          <p style={{textAlign: 'justify'}}>Kişisel veriler, Easy tarafından ilgili mevzuatta öngörülen süreler boyunca ve hukuki yükümlülükleri doğrultusunda saklanmaktadır. Kişisel verilerin ne kadar süre boyunca saklanması gerektiğine ilişkin mevzuatta bir süre düzenlenmemişse, kişisel veri Easy’nin o veriyi işlerken yürüttüğü faaliyet ile bağlantılı olarak Easy’nin uygulamaları ile ticari teamüller doğrultusunda işlenmesini gerektiren süre kadar işlenmekte daha sonra silinmekte, yok edilmekte veya anonim hale getirilmektedir. İşlenme amacı sona ermiş kişisel veriler ile kişisel veri sahipleri tarafından silinmesi/anonimleştirilmesi talep edilmiş olan kişisel veriler, ilgili mevzuat ve Easy’nin belirlediği saklama sürelerinin de sonuna gelinmişse; yalnızca olası hukuki uyuşmazlıklarda delil teşkil etmesi veya kişisel veriye bağlı ilgili hakkın ileri sürülebilmesi veya savunmanın tesis edilmesi amacıyla saklanabilmektedir. Easy kişisel verilerin saklanma sürelerini belirlerken ilgili mevzuatta öngörülen zamanaşımı sürelerini esas almaktadır. Bu amaç doğrultusunda saklanan kişisel verilere yalnızca ilgili hukuki uyuşmazlıkta kullanılması gerektiği zaman sınırlı kişiler tarafından erişilmekte olup bu amaç dışında başka bir amaçla erişilmemektedir. Bu sürenin sonunda da kişisel veriler silinmekte, yok edilmekte veya anonim hale getirilmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>5. KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE ANONİM HALE GETİRİLMESİ</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Kişisel Veriler, Kanunu’nun 7. Maddesinde ve Türk Ceza Kanunu’nun 138. maddesinde ve düzenlendiği üzere ilgili kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde, re’sen veya kişisel veri sahibinin talebi üzerine, Easy tarafından silinir, yok edilir veya anonim hâle getirilir.</p>
          <p style={{textAlign: 'justify'}}><strong>6. YÜKÜMLÜLÜKLER VE HAKLAR</strong></p>
          <p style={{textAlign: 'justify'}}><strong>6.1.&nbsp;</strong>EASY<strong>’nin AYDINLATMA VE BİLGİLENDİRME YÜKÜMLÜLÜĞÜ</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Easy, Kanun’un 10. maddesine uygun olarak, kişisel verilerin elde edilmesi sırasında Veri Sahiplerini aydınlatmaktadır. Bu kapsamda Easy;</p>
          <p style={{textAlign: 'justify'}}>Veri Sorumlusu ve varsa temsilcisinin kimliği,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin hangi amaçla işleneceği,</p>
          <p style={{textAlign: 'justify'}}>İşlenen Kişisel Verilerin kimlere ve hangi amaçla aktarılabileceği,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Veri toplamanın yöntemi ve hukuki sebebi ile</p>
          <p style={{textAlign: 'justify'}}>Veri Sahibi’nin sahip olduğu hakları konusunda Veri Sahibi’ne bilgi vermektedir. &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Anayasa’nın 20. maddesinde herkesin, kendisiyle ilgili kişisel veriler hakkında bilgilendirilme hakkına sahip olduğu ortaya konulmuştur. Bu doğrultuda Kanun’un 11. maddesinde kişisel veri sahibinin hakları arasında “bilgi talep etme” de sayılmıştır. Easy bu kapsamda, Anayasa’nın 20. ve Kanun’un 11. maddelerine uygun olarak Veri Sahibi’nin bilgi talep etmesi durumunda gerekli bilgilendirmeyi yapmaktadır. Bu konu ile ilgili ayrıntılı bilgiye Politika’nın Veri Sahibi’nin Hakları bölümünden ve Aydınlatma metninden ulaşabilirsiniz. &nbsp;</p>
          <p style={{textAlign: 'justify'}}><strong>6.2. VERİ SAHİBİ’NİN HAKLARI</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Veri Sahipleri, aşağıdaki haklara sahiptirler:</p>
          <p style={{textAlign: 'justify'}}>Easy’nin Veri Sahibi’nin kişisel verilerini işleyip işlemediğini öğrenme,</p>
          <p style={{textAlign: 'justify'}}>Eğer Easy nezdinde Kişisel Veriler işleniyorsa, bu veri işleme faaliyeti hakkında bilgi talep etme,</p>
          <p style={{textAlign: 'justify'}}>Eğer Easy nezdinde Kişisel Veriler işleniyorsa, kişisel veri işleme faaliyetinin amacını ve işlenme amacına uygun kullanılıp kullanmadığını öğrenme,</p>
          <p style={{textAlign: 'justify'}}>Eğer Kişisel Veriler yurtiçinde veya yurtdışında üçüncü kişilere aktarılıyorsa, bu üçüncü kişiler hakkında bilgi talep etme,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin eksik veya yanlış işlenmiş olması halinde, bunların düzeltilmesini talep etme,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin Easy nezdinde eksik veya yanlış işlenmiş olması halinde, Kişisel Verilerin aktarıldığı üçüncü kişilere bu durumun bildirilmesini talep etme,</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde Kişisel Verilerin silinmesini, yok edilmesini veya anonim hale getirilmesini istiyorum.</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin işlenmesini gerektiren sebepler ortadan kalktıysa, bu durumun Kişisel Verilerin aktarıldığı üçüncü kişilere bildirilmesini talep etme, &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Easy tarafından işlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi ve bu analiz neticesinde ilgili kişinin (Veri Sahibi) aleyhine bir sonuç doğduğunu düşündüğü sonuçların ortaya çıkması halinde, bu sonuca itiraz etme.</p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması halinde zararın giderilmesini talep etme.</p>
          <p style={{textAlign: 'justify'}}>Veri Sahipleri, Kanun’un uygulanmasına ilişkin taleplerini, “6698 Sayılı Kişisel Verilerin Korunması Kanunu Gereğince İlgili Kişi (Veri Sahibi) Tarafından Veri Sorumlusuna Yapılacak Başvurulara İlişkin Başvuru Formu”nu, yazılı olarak ve Kurul’un belirleyeceği diğer yöntemler ile Easy’e iletebilirler. Forma ulaşmak için tıklayınız.</p>
          <p style={{textAlign: 'justify'}}><strong>6.3. İSTİSNALAR</strong></p>
          <p style={{textAlign: 'justify'}}>&nbsp;Kanun’un 28. Maddesi uyarınca, aşağıdaki haller Kanun kapsamı dışında tutulduğundan, Veri Sahipleri, yukarıda belirtilen haklarını ileri süremezler:</p>
          <p style={{textAlign: 'justify'}}>Kişisel verilerin, üçüncü kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak kaydıyla gerçek kişiler tarafından tamamen kendisiyle veya aynı konutta yaşayan aile fertleriyle ilgili faaliyetler kapsamında işlenmesi.</p>
          <p style={{textAlign: 'justify'}}>Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi.</p>
          <p style={{textAlign: 'justify'}}>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi.</p>
          <p style={{textAlign: 'justify'}}>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi.</p>
          <p style={{textAlign: 'justify'}}>Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları veya infaz mercileri tarafından işlenmesi.</p>
          <p style={{textAlign: 'justify'}}>Easy’nin Aydınlatma Yükümlülüğü, Kanun’un 28/2 maddesi uyarınca aşağıdaki hâllerde uygulanmaz:</p>
          <p style={{textAlign: 'justify'}}>Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması.</p>
          <p style={{textAlign: 'justify'}}>İlgili kişinin kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi.</p>
          <p style={{textAlign: 'justify'}}>Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli olması.</p>
          <p style={{textAlign: 'justify'}}>Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak Devletin ekonomik ve mali çıkarlarının korunması için gerekli olması.</p>
          <p style={{textAlign: 'justify'}}><strong>7. YETKİ VE SORUMLULUK</strong></p>
          <p style={{textAlign: 'justify'}}>Kişisel Verilerin Korunması ve İşlenmesi Politikası, şirket yetkilisinin onayı ile yürürlüğe girmektedir. Ayrıca, Kişisel Verilerin Korunması ve İşlenmesi Politikası’nda değişiklik yapılması da yine şirket yetkilisinin onayı ile gerçekleşmektedir.</p>
          <p style={{textAlign: 'justify'}}>Easy tarafından Kanun’a uygunluğun sağlanması, korunması ve sürdürülmesi, bu konuyla ilgili olan departmanlar arasında koordinasyonun sağlanması, yürütülen işletmefaaliyetlerinin Kanun’a uygunluğunun tespiti konularından, şirket yetkilisi tarafından bu konuda usulünce yetkilendirilmiş şirket yetkilisi üyesi sorumludur.&nbsp;</p>
          <p style={{textAlign: 'justify'}}>8. KİŞİSEL VERİLERİN KATEGORİZASYONU</p>
          <p style={{textAlign: 'justify'}}>Easy nezdinde; Easy’nin meşru ve hukuka uygun kişisel veri işleme amaçları doğrultusunda, Kanun’un 5. maddesinde belirtilen kişisel veri işleme şartlarından bir veya birkaçına dayalı ve sınırlı olarak, başta kişisel verilerin işlenmesine ilişkin 4. maddede belirtilen ilkeler olmak üzere Kanun’da belirtilen genel ilkelere ve Kanun’da düzenlenen bütün yükümlülüklere uyularak ve Politika kapsamındaki süjelerle (Müşteriler, Ziyaretçi, Çalışan Adayı, işletmeYetkilileri, Tedarikçiler, İş Ortakları vb.) aşağıda belirtilen kategorilerdeki kişisel veriler, Kanun’un 10. maddesi uyarınca ilgili kişiler bilgilendirilmek suretiyle işlenmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>Kimlik Bilgisi&nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; kişinin kimliğine dair bilgilerin bulunduğu verilerdir;</p>
          <p style={{textAlign: 'justify'}}>Ad-soyad, T.C. kimlik numarası, uyruk bilgisi, anne adı-baba adı, doğum yeri, doğum tarihi, cinsiyet gibi bilgileri içeren ehliyet, nüfus cüzdanı ve pasaport gibi belgeler ile vergi numarası, SGK numarası, imza bilgisi, taşıt plakası v.b. bilgiler;</p>
          <p style={{textAlign: 'justify'}}><strong>İletişim Bilgisi &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; telefon numarası, adres, e-mail adresi, faks numarası, IP adresi gibi bilgiler</p>
          <p style={{textAlign: 'justify'}}><strong>Lokasyon Verisi &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; kişisel veri sahibinin Easy iş birimleri tarafından yürütülen operasyonlar çerçevesinde, Easy’nin ürün ve hizmetlerinin kullanımı sırasında veya işbirliği içerisinde olduğumuz kurumların çalışanlarının Easy’nin araçlarını kullanırken bulunduğu yerin konumunu tespit eden bilgiler; GPS lokasyonu, seyahat verileri v.b.</p>
          <p style={{textAlign: 'justify'}}><strong>Aile Bireyleri ve Yakın Bilgisi &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy’nin iş birimleri tarafından yürütülen operasyonlar çerçevesinde, Easy’nin sunduğu ürün ve hizmetlerle ilgili veya Easy’nin ve kişisel veri sahibinin hukuki ve diğer menfaatlerini korumak amacıyla kişisel veri sahibinin aile bireyleri (örn. eş, anne, baba, çocuk), yakınları ve acil durumlarda ulaşılabilecek diğer kişiler hakkındaki bilgiler &nbsp;</p>
          <p style={{textAlign: 'justify'}}><strong>Fiziksel Mekan Güvenlik Bilgisi &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen;&nbsp;&nbsp;fiziksel mekana girişte, fiziksel mekanın içerisinde kalış sırasında alınan kayıtlar ve belgelere ilişkin kişisel veriler; kamera kayıtları, parmak izi kayıtları ve güvenlik noktasında alınan kayıtlar v.b.</p>
          <p style={{textAlign: 'justify'}}><strong>Finansal Bilgi&nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy’nin kişisel veri sahibi ile kurmuş olduğu hukuki ilişkinin tipine göre yaratılan her türlü finansal sonucu gösteren bilgi, belge ve kayıtlara ilişkin işlenen kişisel veriler ile banka hesap numarası, IBAN numarası, kredi kartı bilgisi, finansal profil, malvarlığı verisi, gelir bilgisi gibi veriler</p>
          <p style={{textAlign: 'justify'}}><strong>Görsel/İşitsel Bilgi &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; fotoğraf ve kamera kayıtları (Fiziksel Mekan Güvenlik Bilgisi kapsamında giren kayıtlar hariç), ses kayıtları ile kişisel veri içeren belgelerin kopyası niteliğindeki belgelerde yer alan veriler</p>
          <p style={{textAlign: 'justify'}}><strong>Özlük Bilgisi&nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy ile çalışma ilişkisi içerisinde olan gerçek kişilerin özlük haklarının oluşmasına temel olacak bilgilerin elde edilmesine yönelik işlenen her türlü kişisel veri</p>
          <p style={{textAlign: 'justify'}}><strong>Özel Nitelikli Kişisel Veri &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; KVK Kanunu’nun 6. maddesinde belirtilen veriler (örn. kan grubu da dahil sağlık verileri)</p>
          <p style={{textAlign: 'justify'}}><strong>Talep/Şikâyet Yönetimi Bilgisi</strong></p>
          <p style={{textAlign: 'justify'}}>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenen; Easy’a yöneltilmiş olan her türlü talep veya şikayetin alınması ve değerlendirilmesine ilişkin kişisel veriler Easy, Politika’da düzenlenmiş, hususlara ve Kanun’un 8. ve 9. maddelerine uygun olarak, Politika ile yönetilen veri sahiplerinin kişisel verilerini aşağıda sıralanan kişi kategorilerine aktarılabilir: &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Easy iş ortaklarına,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Easy tedarikçilerine,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Easy Şirketler Grubu bünyesindeki şirketlere,</p>
          <p style={{textAlign: 'justify'}}>Easy hissedarlarına,&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Easy işletmeyetkililerine, &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Hukuken yetkili kamu kurum ve kuruluşlarına&nbsp;</p>
          <p style={{textAlign: 'justify'}}>Hukuken yetkili özel hukuk kişilerine &nbsp;</p>
          <p style={{textAlign: 'justify'}}>Aktarımda bulunulan yukarıda belirtilen kişilerin kapsamı ve veri aktarım amaçları aşağıda belirtilmektedir.</p>
          <p style={{textAlign: 'justify'}}><strong>Veri Aktarımı Yapılabilecek Kişiler / Tanımı / Veri Aktarım Amacı</strong></p>
          <p style={{textAlign: 'justify'}}><strong>İş Ortağı &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Easy’nin ticari faaliyetlerini yürütürken bizzat veya Grup Şirketleri ile birlikte muhtelif projeler yürütmek, hizmet almak gibi amaçlarla iş ortaklığı kurduğu tarafları tanımlamaktadır. / İş ortaklığının kurulma amaçlarının yerine getirilmesini temin etmek amacıyla sınırlı olarak.</p>
          <p style={{textAlign: 'justify'}}><strong>Tedarikçi &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Easy’nin ticari faaliyetlerini yürütürken Easy’nin emir ve talimatlarına uygun olarak sözleşme temelli olarak Easy’e hizmet sunan tarafları tanımlamaktadır. / Easy’nin tedarikçiden dış kaynaklı olarak temin ettiği ve Easy’nin ticari faaliyetlerini yerine getirmek için gerekli hizmetlerin Easy’e sunulmasını sağlamak amacıyla sınırlı olarak. &nbsp;</p>
          <p style={{textAlign: 'justify'}}><strong>Hissedarlar &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Easy’nin hissedarı gerçek kişiler / İlgili mevzuat hükümlerine göre Easy’nin şirketler hukuku, etkinlik yönetimi ve kurumsal iletişim süreçleri kapsamında yürüttüğü faaliyetlerin amaçlarıyla sınırlı olarak.</p>
          <p style={{textAlign: 'justify'}}><strong>işletmeYetkilileri&nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>Easy şirket yetkilisi üyeleri ve diğer yetkili gerçek kişiler / İlgili mevzuat hükümlerine göre Easy’nin ticari faaliyetlerine ilişkin stratejilerin tasarlanması, en üst düzeyde yönetiminin sağlanması ve denetim amaçlarıyla sınırlı olarak.</p>
          <p style={{textAlign: 'justify'}}><strong>Hukuken Yetkili Kamu Kurum ve Kuruluşları &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>İlgili mevzuat hükümlerine göre Easy’den bilgi ve belge almaya yetkili kamu kurum ve kuruluşları / İlgili kamu kurum ve kuruluşlarının hukuki yetkisi dahilinde talep ettiği amaçla sınırlı olarak.</p>
          <p style={{textAlign: 'justify'}}><strong>Hukuken Yetkili Özel Hukuk Kişileri &nbsp;</strong></p>
          <p style={{textAlign: 'justify'}}>İlgili mevzuat hükümlerine göre Easy’den bilgi ve belge almaya yetkili özel hukuk kişileri / İlgili özel hukuk kişilerinin hukuki yetkisi dahilinde talep ettiği amaçla sınırlı olarak.</p>
          <p><br /></p>
          <p><br /></p>
          <p><br /></p>
        </div>
      );
    }

    export default KVKK;