import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { motion } from 'framer-motion';

const PromotionalBanner = ({ isEnabled }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  if (!isEnabled) return null;

  return (
    <div className="fixed top-0 left-0 right-0 z-[96] bg-[#1B1A31] shadow-lg">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-center gap-x-4 px-4 py-2">
          <p className={`text-center font-medium text-white text-base`}>
            Yeni Yıl İndirimi: Tüm planlarda{' '}
            <motion.span 
              className="font-bold text-[#953EFF]"
     
            >
              50% indirim!
            </motion.span>
          </p>
        
        </div>
      </div>

      {/* Animated decorative elements */}
 
       
    </div>
  );
};

export default PromotionalBanner; 