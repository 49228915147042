  // src/redux/api/toolHistoriesApiSlice.js
  import { createApi } from '@reduxjs/toolkit/query/react';
  import { baseQueryWithReauth } from './authApiSlice';

  export const toolHistoriesApiSlice = createApi({
    reducerPath: 'toolHistoriesApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['ToolHistories'],

    endpoints: (builder) => ({
      /**
       * Fetch the first 20 entries for every tool in one request.
       */
      fetchInitialHistories: builder.query({
        query: () => `/data/initialHistories`,
        providesTags: ['ToolHistories'],
        transformResponse: (response) => {
          // The response should look like:
          // { summarizer: [...], paraphraser: [...], questionAnswer: [...], ... }
          // each array up to 20 items.
          return response;
        },
      }),

      /**
       * Fetch additional histories for a specific tool in pages of 10.
       */
      fetchMoreHistory: builder.query({
        query: ({ toolType, page, limit = 10 }) =>
          `/data/histories/${toolType}/${page}?limit=${limit}`,
        transformResponse: (response) => ({
          results: response.results,       // array of items
          totalPages: response.totalPages, // total pages
          page: response.page,             // current page
          limit: response.limit,           // page limit
          totalResults: response.totalResults,
          toolType: response.toolType,
        }),
        providesTags: (result, error, { toolType }) => // bu ek
          toolType ? [{ type: 'ToolHistories', id: toolType }] : ['ToolHistories'],
      }),

      /**
       * Fetch full details for one history entry by tool and ID.
       */
      fetchHistoryDetails: builder.query({
        query: ({ serviceType, id, cursor = null, limit = 20 }) =>
          `/data/historyDetails/${serviceType}/${id}?cursor=${cursor}&limit=${limit}`,
        providesTags: (result, error, { id }) => [{ type: 'HistoryDetails', id }],
        transformResponse: (response) => {
          // response: { usageData, messages, currentPage, totalPages, totalMessages }
          return response;
        },
      }),


      /**
       * Delete a single history entry by ID. 
       */
      deleteHistoryEntry: builder.mutation({
        query: ({ id }) => ({
          url: `/data/deleteHistory/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ToolHistories'],
      }),
    }),
  });

  export const {
    useFetchInitialHistoriesQuery,
    useFetchMoreHistoryQuery,
    useFetchHistoryDetailsQuery,
    useLazyFetchHistoryDetailsQuery,  // Added this line
    useDeleteHistoryEntryMutation,
  } = toolHistoriesApiSlice;
